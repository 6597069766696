import React from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_LANDINGPAGES, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  // Table,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";

const List = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState([]);
  const [tasklist, setTasklist] = React.useState([]);

  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState();
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);

  React.useEffect(() => {
    // console.log(user)
    dispatch({ type: LOAD_LANDINGPAGES, payload: pageParams, pageactivePage });
  }, [dispatch, user]);

  const { get_landing_pages, get_auth, get_errors, get_activePage } =
    useSelector(
      (state) => ({
        get_landing_pages: state.landingpages.landingpages,
        get_auth: state.auth,
        get_errors: state.errors,
        get_activePage: state.paginate.landingpage,
      }),
      shallowEqual
    );

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)
  // console.log(user)
  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_landing_pages) {
      setTasklist(get_landing_pages.results);
      setTotalResults(get_landing_pages.count);
      setPerPage(get_landing_pages.page_size);
      setTotalPages(
        Math.ceil(get_landing_pages.count / get_landing_pages.page_size)
      );
    }
  }, [get_landing_pages]);
  // console.log(user)

  React.useEffect(() => {
    if (get_activePage) {
      setPageActivePage(get_activePage);
      setActivePage(get_activePage > 0 ? get_activePage - 1 : 0);
    }
  }, [get_activePage]);
  const [page, setPage] = React.useState(0);

  const handleClick = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "landingpage", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };
  // console.log(tasklist)
  return (
    <>
      <Row>
        <Col md="12">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Heading</StyledTableCell>
                    <StyledTableCell align="center">Meta</StyledTableCell>
                    <StyledTableCell align="center">URL</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasklist
                    ? tasklist.map((p, k) => (
                        <StyledTableRow key={k}>
                          <StyledTableCell>{p.heading}</StyledTableCell>
                          <StyledTableCell align="center">
                            {p.meta_description}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.url}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {p.status_text}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Link to={`/admin/landing-page-detail/${p.id}`}>
                              <RemoveRedEyeOutlinedIcon
                                size={40}
                                color={"preAuto"}
                              />
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    : " No tasks found"}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[12]}
                component="div"
                count={totalResults || 0}
                rowsPerPage={
                  totalPages > 12 ? tasklist.length || 0 : totalPages || 0
                }
                page={page}
                onPageChange={handleClick}
                ActionsComponent={TablePaginationActions}
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    marginTop: "auto",
                  },
                  ".MuiTablePagination-displayedRows": {
                    marginTop: "auto",
                  },
                }}
              />
            </TableContainer>
          </Paper>

          {/* <Table responsive>
            <thead className="table-active">
              <tr>
                <th>Heading</th>
                <th>Meta</th>
                <th>URL</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tasklist ? (
                tasklist.map((p, k) => (
                  <tr key={k}>
                    <td>{p.heading}</td>
                    <td>{p.meta_description}</td>
                    <td>{p.url}</td>
                    <td>{p.status_text}</td>
                    <td>
                      <Link to={`/admin/landing-page-detail/${p.id}`}>
                        View More
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No tasks found</td>
                </tr>
              )}
            </tbody>
          </Table> */}
        </Col>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
      </Row>
    </>
  );
};

export default List;
