import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import {
  LOGIN_REQUEST,
  USER_LOADED,
  LOAD_REQUEST,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_USER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_DEALER_USER,
  CHECK_PAYMENT_STATUS,
  REGISTER_DEALER_SUCCESS,
  REGISTER_DEALER_FAIL,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  WEBSITE_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAIL,
  CREATE_MESSAGE,
  CLEAR_LEADS,
  CLEAR_ALL,
  CLEAR_PASSWORD_DATA,
  GET_ERRORS,
  FETCH_EMPLOYEE,
  FETCH_DEALER,
  FETCH_RELATED_DEALER,
  SG_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  EMPLOYEE_AUTH_LOADED,
  SG_EMPLOYEE_AUTH_LOADED,
  DEALER_AUTH_LOADED,
  SG_DEALER_AUTH_LOADED,
  ALL_ERRORS,
} from "constants/actions";
import * as api from "constants/api";
import _ from "lodash";

// CHECK TOKEN & LOAD USER
function* loadUser(action) {
  try {
    const json = yield fetch(api.GET_DEALERS(action.payload)).then((response) =>
      response.json()
    );
    yield put({ type: USER_LOADED, payload: json });
  } catch (e) {
    // console.log(e);
    yield put({ type: ALL_ERRORS, message: e });
    yield put({ type: LOGIN_FAIL, payload: e });
  }
}

export function* websiteLoadUser() {
  yield takeEvery(LOAD_REQUEST, loadUser);
}
//  // Add supplier
//   // http://localhost:8080/suppliers
//   // http://localhost:8080/products
//   const addSuppliers = newSupplier => api.post('/suppliers/addSupplier', newSupplier);
//   const addProducts = newProduct => api.post('/products/addProduct', newProduct);

// LOGIN USER
function* loginUser(action) {
  // console.log(action.payload);
  try {
    const login = yield call(api.LOGIN_USER, action.payload);
    if(login?.data?.payment_link?.length){
      window.location.href =login?.data?.payment_link;

    }
    else
    yield put({ type: LOGIN_SUCCESS, payload: login.data });
    // console.log(login.data)
    // if (_.get(login.data, "token")) {
    //   // console.log(login.data.token)
    //   // const employee = yield call(api.FETCH_LOGIN_EMPLOYEE, login.data);
    //   // yield put({ type: FETCH_EMPLOYEE, payload: employee.data });

    //   // const dealer = yield call(api.FETCH_LOGIN_DEALER, login.data);
    //   // yield put({ type: FETCH_DEALER, payload: dealer.data });

    //   // const related = yield call(api.GET_LOGIN_RELATED_DEALERS,
    //   //   login.data)
    //   //   yield put({ type: FETCH_RELATED_DEALER, payload: related.data });
    // }
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
    yield put({ type: LOGIN_FAIL, payload: e });
  }
}

export function* websiteLoginUser() {
  yield takeEvery(LOGIN_REQUEST, loginUser);
}

function* loginEmployee(action) {
  try {
    const json = yield call(api.FETCH_EMPLOYEE, action.payload)
    yield put({ type: EMPLOYEE_AUTH_LOADED, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* employeeLoggedIn() {
  yield takeLatest(SG_EMPLOYEE_AUTH_LOADED, loginEmployee);
}

function* loginDealer(action) {
  try {
    const json = yield call(api.FETCH_DEALER, action.payload)
    yield put({ type: DEALER_AUTH_LOADED, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* dealerLoggedIn() {
  yield takeLatest(SG_DEALER_AUTH_LOADED, loginDealer);
}

// LOGOUT USER
function* logoutUser(action) {
  // console.log(action.payload)
  try {
    yield put({ type: CLEAR_LEADS });
    yield put({ type: CLEAR_ALL });
    yield call(api.LOGOUT_USER, action.payload);
    yield put({ type: LOGOUT_SUCCESS });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* websiteLogoutUser() {
  yield takeEvery(LOGOUT_REQUEST, logoutUser);
}

function* forgotPassword(action) {
  try {
    yield put({ type: CLEAR_LEADS });
    yield put({ type: CLEAR_ALL });
    const json = yield call(api.FORGOT_PASSWORD, action.payload);

    // console.log(json)
    yield put({ type: CREATE_MESSAGE, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* websiteForgotPassword() {
  yield takeEvery(REQUEST_PASSWORD_RESET, forgotPassword);
}


function* resetPassword(action) {
  try {

    yield put({ type: CLEAR_ALL });
    const json = yield call(api.RESET_PASSWORD, action.payload);

    yield put({ type: CONFIRM_RESET_PASSWORD_SUCCESS, payload: json.data });
    yield put({ type: CREATE_MESSAGE, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });

    yield put({ type: CONFIRM_RESET_PASSWORD_FAIL, payload: e.message });
  }
}

export function* websiteResetPassword() {
  yield takeEvery(WEBSITE_RESET_PASSWORD, resetPassword);
}

function* registerUser(action) {
  try {
    const json = yield call(api.REGISTER_USER, action.payload);
   
    yield put({ type: REGISTER_SUCCESS, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });

    yield put({ type: REGISTER_FAIL, payload: e.message });
  }
}

export function* websiteRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}
function* registerDealerUser(action) {
  try {
    const json = yield call(api.REGISTER_DEALER_USER, action.payload);
   
    yield put({ type: REGISTER_DEALER_SUCCESS, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });

    yield put({ type: REGISTER_DEALER_FAIL, payload: e.message });
  }
}
function* checkPaymentStatus(action) {
  console.log(action,'vghjk');
  // try {
  //   const json = yield call(api.CHECK_PAYMENT_STATUS, action.payload);
   
  //   yield put({ type: CHECK_PAYMENT_STATUS, payload: json });
  // } catch (e) {
  //   console.log(e);
  //   yield put({ type: ALL_ERRORS, message: e });

  //   // yield put({ type: CHECK_PAYMENT_STATUS, payload: e.message });
  // }
}

export function* websiteRegisteDealerUser() {
  yield takeEvery(REGISTER_DEALER_USER, registerDealerUser);
}
export function* websitePaymentStatus() {
  yield takeEvery(CHECK_PAYMENT_STATUS, checkPaymentStatus);
}


function* changePassword(action) {
  try {
    const json = yield call(api.CHANGE_PASSWORD, action.payload);

    yield put({ type: CHANGE_PASSWORD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* websiteChangePassword() {
  yield takeEvery(SG_CHANGE_PASSWORD, changePassword);
}

export default function* index() {
  yield all([
    websiteLoginUser(),
    employeeLoggedIn(),
    dealerLoggedIn(),
    websiteLoadUser(),
    websiteLogoutUser(),
    websiteForgotPassword(),
    websiteRegisterUser(),
    websiteRegisteDealerUser(),
    websitePaymentStatus(),
    websiteResetPassword(),
    websiteChangePassword(),
  ]);
}
