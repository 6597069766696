import React, { Component } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row, Col
} from "reactstrap";

import "./style.css"

function EditorConvertToHTML(props) {
  const {editContent, heading, BtnCallBack} = props
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

  React.useEffect(() => {
    if (editContent) {
      const _contentBlock = htmlToDraft(editContent)
      const _contentState = ContentState.createFromBlockArray(_contentBlock.contentBlocks);
      const _editorState = EditorState.createWithContent(_contentState)
      setEditorState(_editorState)
    }
  }, [editContent])

  return (
    <Card>
      <CardHeader><h6>{heading}</h6></CardHeader>
      <CardBody>
        <Editor
          editorState={editorState}
          // wrapperClassName="demo-wrapper"
          // editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </CardBody>
      <CardFooter>
        <Row className="align-items-end">
          <Col md="2" className="align-self-end">
            <Button
              className="btn"
              color="default"
              outline
              size="sm"
              onClick={() => BtnCallBack(stateToHTML(editorState.getCurrentContent()))}
            >
              Update

            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );

}

export default EditorConvertToHTML

export function RawHtmlToDraftEditor(content) {
  if (content) {
    const _contentBlock = htmlToDraft(content)
    const _contentState = ContentState.createFromBlockArray(_contentBlock.contentBlocks);
    const _editorState = EditorState.createWithContent(_contentState)

    return _editorState
  }

}

export function EditorInLineConvertToHTML({editContent, heading, BtnCallBack}) {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

  React.useEffect(() => {
    // console.log(editContent(editContent))
    if (editContent) {
      const _contentBlock = htmlToDraft(editContent)
      const _contentState = ContentState.createFromBlockArray(_contentBlock.contentBlocks);
      const _editorState = EditorState.createWithContent(_contentState)
      setEditorState(_editorState)

      // setEditorState(EditorState.createWithContent(
      //   ContentState.createFromBlockArray(
      //     convertFromHTML(editContent)
      //   )))
    }

  }, [editContent])



  return (
    <div className='border-div'>
      <h6>{heading}</h6>

      <Editor
        editorState={editorState}
        // wrapperClassName="demo-wrapper"
        // editorClassName="demo-editor"
        onEditorStateChange={setEditorState}
      />


      <div >
        <Row className="align-items-end">
          <Col md="2" className="align-self-end">
            <Button
              className="btn"
              color="default"
              outline
              size="sm"
              onClick={() => BtnCallBack(
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
               )}
            >
              Update

            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );

}