import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";

import {
  LOAD_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS,
  REMOVE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  CREATE_PRODUCT_GROUP,
  ADD_PRODUCT_GROUP,
  ONE_PRODUCT_GROUP,
  FETCH_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  CLEAR_PRODUCT_GROUP,
  ALL_ERRORS,
} from "constants/actions";

function* loadProductGroup(action) {
  try {
    const json = yield call(api.GET_PRODUCT_GROUPS, action.payload);
    yield put({ type: GET_PRODUCT_GROUPS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productGroupLoad() {

  yield takeLatest(LOAD_PRODUCT_GROUPS, loadProductGroup);
}

function* fetchProductGroup(action) {
  try {
    const json = yield call(api.FETCH_PRODUCT_GROUP, action.payload);

    yield put({ type: FETCH_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productGroupFetch() {
  yield takeLatest(ONE_PRODUCT_GROUP, fetchProductGroup);
}

function* editProductGroup(action) {
  try {
    const json = yield call(api.EDIT_PRODUCT_GROUP, action.payload);
    yield put({ type: EDIT_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productGroupEdit() {
  yield takeLatest(UPDATE_PRODUCT_GROUP, editProductGroup);
}

function* createProductGroup(action) {
  try {
    const json = yield call(api.ADD_PRODUCT_GROUP, action.payload);
    yield put({ type: ADD_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productGroupCreate() {
  yield takeLatest(CREATE_PRODUCT_GROUP, createProductGroup);
}

function* deleteProductGroup(action) {
  try {
    const json = yield call(api.DELETE_PRODUCT_GROUP, action.payload);
    yield put({ type: DELETE_PRODUCT_GROUP, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productGroupDelete() {
  yield takeLatest(REMOVE_PRODUCT_GROUP, deleteProductGroup);
}

export default function* index() {
  yield all([
    productGroupLoad(),
    productGroupFetch(),
    productGroupEdit(),
    productGroupCreate(),
    productGroupDelete(),
  ]);
}
