import React from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOGOUT_REQUEST } from "constants/actions";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import _ from "lodash";
// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  // Button
} from "reactstrap";

import bgImage from "assets/img/bg/fabio-mangione.jpg";
import { Button } from "@mui/material";

const Logout = () => {
  let history = useHistory();

  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [criticalError, setCriticalError] = React.useState(null);
  const [reloaded, setReloaded] = React.useState(false);
  const { get_error_state } = useSelector(
    (state) => ({
      get_error_state: state.error_state,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (!_.isEmpty(get_error_state.msg)) {
      console.log(get_error_state);

      setVisible(true);
      setCriticalError("Critical Error - please logout and login again!");
      //      if (!reloaded) {
      //   setReloaded(true)
      //   window.location.reload(true);
      // }
    }
  }, [get_error_state]);

  React.useEffect(() => {
    dispatch({ type: LOGOUT_REQUEST });
    // history.push("/auth/login-page")
    //  window.location.reload(true);

    //
  }, [dispatch, history]);

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Row>
              <Col>
                <Alert
                  color="danger"
                  isOpen={visible}
                  toggle={() => setVisible(!visible)}
                >
                  {criticalError
                    ? criticalError
                    : JSON.stringify(get_error_state.msg)}

                  {/*  */}
                </Alert>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={5} md={8} xs={12}>
                <Card className="card-signup">
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Logged Out Successfully</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="description">
                      <p className="description text-dark">
                        You have successfully logged out, click the link below
                        to log back in
                      </p>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center">
                    <a href={`/auth/login`}>
                      <Button
                        variant="contained"
                        color="preAuto"
                        size="lg"
                        className="btn-round"
                      >
                        Login
                      </Button>
                    </a>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
      {alert}
    </>
  );
};

export default Logout;
