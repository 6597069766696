import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_VIN_UPLOAD, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";
import AddNewLeadStage from "./AddNewLeadVinUpload";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "mui/tables/TablePaginationActions";
import { currentApi } from "_config";
import CreateEdit from "Vinupload/Basic/CreateEdit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Spinner,
  Fade,
  Table,
  Row,
  Col,
} from "reactstrap";
import { Button as MUIButton } from "@mui/material";
import AppDialog from "crema/AppDialog";
import AddNewLeadVinUpload from "./AddNewLeadVinUpload";

const List = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [vinuploadFileupload, setVinuploadFileupload] = React.useState([]);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [showVinDecode, setShowVinDecode] = React.useState(0);
  const [inventoryId, setInventoryId] = React.useState(0);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);
  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)
    dispatch({
      type: LOAD_VIN_UPLOAD,
      payload: { page: pageactivePage, params: pageParams },
    });
  }, [dispatch, pageParams, pageactivePage]);

  const {
    vindecode_files,
    get_pending,
    get_auth,
    get_errors,
    get_inventory_select,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      vindecode_files: state.vinupload.vinuploads,
      get_pending: state.vinupload.pending,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setVinuploadFileupload(vindecode_files.results);
  }, [vindecode_files]);

  React.useEffect(() => {
    setPending(get_pending);
  }, [get_pending]);

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (vindecode_files) {
      setTotalResults(vindecode_files.count);
      if (vindecode_files.page_size) {
        setPerPage(vindecode_files.page_size);
      }
      setTotalPages(Math.ceil(vindecode_files.count / perPage));
    }
  }, [vindecode_files]);
  // console.log(user)
  // const handleClick = (page) => {
  //   // console.log(page)
  //   dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: page.selected });
  //   setActivePage(page.selected);
  //   setPageActivePage(page.selected + 1);
  // };
  // console.log(errormessages);

  const [page, setPage] = React.useState(0);
  const handleClick = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "lead", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };
  const SelectedVinDecode = (vinid, invid) => {
    setInventoryId(invid);
    setShowVinDecode(vinid);
    // setColWidth([4, 8]);
  };

  const resetColWidth = (updated) => {
    setInventoryId(false);
    setShowVinDecode(false);

    setColWidth([12, 0]);
  };

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  // React.useEffect(() => {
  //   // console.log('get_activePage', get_activePage, "****************************************")
  //   {vinuploadFileupload.map((p, k) => {
  //     console.log(p.id, "***********************")
  //   SelectedVinDecode(p.id, p.inventory.id)
  //   })}
  // }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md={colWidth[0]}>
            <Card>
              <CardBody>
                <CardTitle tag="h4">VIN List</CardTitle>
                <Row>
                  {/* <Col md="12">
                    <div
                      className={
                        colWidth[1] > 0
                          ? "d-none"
                          : "d-flex justify-content-end"
                      }
                    >
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakClassName={"page-item"}
                        pageCount={totalPages}
                        marginPagesDisplayed={isMobile ? 0 : 2}
                        pageRangeDisplayed={isMobile ? 0 : 5}
                        onPageChange={handleClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"page-item pagination"}
                        activeClassName={"page-item active"}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        forcePage={activePage}
                      />
                    </div>
                  </Col> */}
                  <div className={pending ? "" : "d-none mt-3"}>
                    <div className="d-flex justify-content-center">
                      <Fade in={pending} tag="h5">
                        <Spinner>Loading...</Spinner>
                      </Fade>
                    </div>
                  </div>
                </Row>
                <div className={pending ? "d-none" : ""}>
                  <Row>
                    <Col md="12">
                      <Table responsive>
                        {vinuploadFileupload ? (
                          vinuploadFileupload.map((p, k) => (
                            <tbody
                              key={k}
                              style={{
                                border: "solid 2px black",
                              }}
                            >
                              <tr
                              // className="table-warning"
                              >
                                <td>Uploaded</td>
                                <td>{p.id}</td>
                                <td>{p.vin} </td>
                                <td>
                                  {p.year}-{p.make}-{p.model}
                                </td>
                                <td>{p.color}</td>
                                <td>{p.mileage}</td>
                                <td>
                                  {p.notes} {p.status}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/full-vin-decode-edit/${p.id}${
                                      p.inventory ? `/${p.inventory.id}` : ""
                                    }`}
                                  >
                                    <MUIButton
                                      color="preAuto"
                                      variant="contained"
                                      size="sm"
                                    >
                                      Resolve
                                    </MUIButton>
                                  </Link>
                                </td>
                              </tr>

                              {p.inventory ? (
                                <tr
                                  // className="table-success"
                                  style={{
                                    backgroundColor: "#cac8c8",
                                  }}
                                >
                                  <td>
                                    Processed <br />
                                    <Link
                                      to={`/admin/inventory-detail/${p.inventory.id}`}
                                    >
                                      <Button
                                        color="link"
                                        size="sm"
                                        style={{
                                          color: "#656262",
                                        }}
                                      >
                                        View
                                      </Button>
                                    </Link>
                                  </td>
                                  <td>{p.inventory.id}</td>
                                  <td>{p.inventory.vin} </td>
                                  <td>
                                    {p.inventory.year}-{p.inventory.make}-
                                    {p.inventory.model}
                                  </td>
                                  <td>
                                    {p.inventory.color}
                                    <br />
                                    <small>{p.inventory.mfd_color_code}</small>
                                  </td>
                                  <td>{p.inventory.kilometers}</td>
                                  <td>
                                    {p.inventory.trim} {p.status}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <MUIButton
                                      variant="contained"
                                      size="sm"
                                      style={{
                                        backgroundColor: "white",
                                        color: "#656262",
                                      }}
                                      onClick={() => {
                                        SelectedVinDecode(p.id, p.inventory.id);
                                        onOpenAddTask();
                                      }}
                                    >
                                      VIEW
                                    </MUIButton>
                                  </td>
                                </tr>
                              ) : (
                                <tr className="table-success">
                                  <td colSpan="8" className="text-center">
                                    Not Processed
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  </Row>
                </div>
                {/* <Row sx={{ justifyContent: "flex-end !important" }}> */}
                <TablePagination
                  rowsPerPageOptions={[12]}
                  component="div"
                  count={totalResults || 0}
                  rowsPerPage={
                    totalPages > 12
                      ? vinuploadFileupload.length || 0
                      : totalPages || 0
                  }
                  page={page}
                  onPageChange={handleClick}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    ".MuiTablePagination-selectLabel": {
                      marginTop: "auto",
                    },
                    ".MuiTablePagination-displayedRows": {
                      marginTop: "auto",
                    },
                    justifyContent: "flex-end !important",
                  }}
                />
                {/* <Col md="12">
                    <div
                      className={
                        colWidth[1] > 0
                          ? "d-none"
                          : "d-flex justify-content-end"
                      }
                    >
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        // breakLabel={isMobile? '': <a className="page-link">...</a>}
                        breakClassName={"page-item"}
                        pageCount={totalPages}
                        marginPagesDisplayed={isMobile ? 0 : 2}
                        pageRangeDisplayed={isMobile ? 0 : 5}
                        onPageChange={handleClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"page-item pagination"}
                        activeClassName={"page-item active"}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        forcePage={activePage}
                      />
                    </div>
                  </Col> */}
                {/* </Row> */}
              </CardBody>
            </Card>
          </Col>
          {/* <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>
            <CreateEdit
              id={inventoryId}
              vinid={showVinDecode}
              resetColWidth={resetColWidth}
            />
          </Col> */}
        </Row>
      </div>
    
        <AddNewLeadVinUpload
          id={inventoryId}
          vinid={showVinDecode}
          resetColWidth={resetColWidth}
          isAddTaskOpen={isAddTaskOpen}
          onCloseAddTask={onCloseAddTask}
          setAddTaskOpen={setAddTaskOpen}
        />
      
    </>
  );
};

export default List;
