import React from "react";
import orange from "@mui/material/colors/orange";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const UserInfo = ({ color, user }) => {
  // const navigate = useNavigate();
  // const [user, setUser] = React.useState([]);

  // React.useEffect(() => {
  //   if (user) {
  //     setUser(user);
  //   }
  // }, [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    return user.first_name ? user.first_name.charAt(0) : "A";
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          pt: 1.5,
          pb: 1.3,
          px: 1.5,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        className="user-info-view"
      >
        <Box sx={{ py: 0.5, display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              height: 40,
              width: 40,
              // fontSize: 24,
              backgroundColor: orange[500],
            }}
          >
            {user.first_name ? user.first_name.charAt(0) : "A"}
          </Avatar>
        </Box>
        <Box
          sx={{
            width: { xs: "calc(100% - 62px)", xl: "calc(100% - 72px)" },
            ml: 2,
            color: color,
          }}
          className="user-info"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                mb: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 16,
                fontWeight: 400,
                color: "inherit",
              }}
              component="span"
            >
              {user.first_name} {user.last_name}
            </Box>
            <Box
              sx={{
                ml: 3,
                color: "inherit",
                display: "flex",
              }}
            >
              <ExpandMoreIcon />
            </Box>
          </Box>
          {/* <Box
            sx={{
              mt: -0.25,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "inherit",
              fontSize: 14,
            }}
          >
            System Manager
          </Box> */}
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Link
          to="/admin/user-profile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              // navigate("/my-profile");
            }}
          >
            My account
          </MenuItem>
        </Link>
        {/* <MenuItem onClick={() => {}}>Logout</MenuItem> */}
      </Menu>
    </>
  );
};

export default UserInfo;

UserInfo.defaultProps = {
  color: "text.secondary",
};

UserInfo.propTypes = {
  color: PropTypes.string,
};
