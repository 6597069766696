import React from "react";

import {
  Alert,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const AcceptableUse = () => {
  return (
    <>
      <div>
        <Container>
          <Card>
            <CardHeader>
              <h1>Acceptable Use Policy</h1>
            </CardHeader>
            <CardBody>
              <p>1. Introduction</p>
              <p>
                1.1 This acceptable use policy (the "Policy") sets out the rules
                governing: (a) the use of the website at www.datastruk.com or
                www.preautoco.com, any successor website, and the services
                available on that website or any successor website (the
                "Services"); and (b) the transmission, storage and processing of
                content by you, or by any person on your behalf, using the
                Services ("Content").
              </p>
              <p>
                1.2 References in this Policy to "you" are to any customer for
                the Services and any individual user of the Services (and "your"
                should be construed accordingly); and references in this Policy
                to "us" are to Datastruk Software Solutions (and "we" and "our"
                should be construed accordingly).
              </p>
              <p>
                1.3 By using the Services, you agree to the rules set out in
                this Policy.
              </p>
              <p>
                1.4 We will ask for your express agreement to the terms of this
                Policy before you upload or submit any Content or otherwise use
                the Services.
              </p>
              <p>
                1.5 You must be at least 18 years of age to use the Services;
                and by using the Services, you warrant and represent to us that
                you are at least 18 years of age.
              </p>
              <p>2. General usage rules</p>
              <p>
                2.1 You must not use the Services in any way that causes, or may
                cause, damage to the Services or impairment of the availability
                or accessibility of the Services.
              </p>
              <p>
                2.2 You must not use the Services: (a) in any way that is
                unlawful, illegal, fraudulent, deceptive or harmful; or (b) in
                connection with any unlawful, illegal, fraudulent, deceptive or
                harmful purpose or activity.
              </p>
              <p>
                2.3 You must ensure that all Content complies with the
                provisions of this Policy.
              </p>
              <p>3. Unlawful Content</p>
              <p>
                3.1 Content must not be illegal or unlawful, must not infringe
                any person's legal rights, and must not be capable of giving
                rise to legal action against any person (in each case in any
                jurisdiction and under any applicable law).
              </p>
              <p>
                3.2 Content, and the use of Content by us in any manner licensed
                or otherwise authorised by you, must not: (a) be libellous or
                maliciously false; (b) be obscene or indecent; (c) infringe any
                copyright, moral right, database right, trade mark right, design
                right, right in passing off, or other intellectual property
                right; (d) infringe any right of confidence, right of privacy or
                right under data protection legislation; (e) constitute
                negligent advice or contain any negligent statement; (f)
                constitute an incitement to commit a crime, instructions for the
                commission of a crime or the promotion of criminal activity; (g)
                be in contempt of any court, or in breach of any court order]
                (h) constitute a breach of racial or religious hatred or
                discrimination legislation; (i) be blasphemous; (j) constitute a
                breach of official secrets legislation; or (k) constitute a
                breach of any contractual obligation owed to any person.
              </p>
              <p>
                3.3 You must ensure that Content is not and has never been the
                subject of any threatened or actual legal proceedings or other
                similar complaint.
              </p>
              <p>4. Graphic material</p>
              <p>
                4.1 Content must be appropriate for all persons who have access
                to or are likely to access the Content in question, and in
                particular for children over 12 years of age.
              </p>
              <p>
                4.2 Content must not depict violence in an explicit, graphic or
                gratuitous manner.
              </p>
              <p>4.3 Content must not be pornographic or sexually explicit.</p>
              <p>5. Factual accuracy</p>
              <p>
                5.1 Content must not be untrue, false, inaccurate or misleading.
              </p>
              <p>
                5.2 Statements of fact contained in Content and relating to
                persons (legal or natural) must be true; and statements of
                opinion contained in Content and relating to persons (legal or
                natural) must be reasonable, be honestly held and indicate the
                basis of the opinion.
              </p>
              <p>6. Negligent advice</p>
              <p>
                6.1 Content must not consist of or contain any legal, financial,
                investment, taxation, accountancy, medical or other professional
                advice, and you must not use the Services to provide any legal,
                financial, investment, taxation, accountancy, medical or other
                professional advisory services.
              </p>
              <p>
                6.2 Content must not consist of or contain any advice,
                instructions or other information that may be acted upon and
                could, if acted upon, cause death, illness or personal injury,
                damage to property, or any other loss or damage.
              </p>
              <p>7. Etiquette</p>
              <p>
                7.1 Content must be appropriate, civil and tasteful, and accord
                with generally accepted standards of etiquette and behaviour on
                the internet.
              </p>
              <p>
                7.2 Content must not be offensive, deceptive, threatening,
                abusive, harassing, menacing, hateful, discriminatory or
                inflammatory.
              </p>
              <p>
                7.3 Content must not be liable to cause annoyance, inconvenience
                or needless anxiety.
              </p>
              <p>
                7.4 You must not use the Services to send any hostile
                communication or any communication intended to insult, including
                such communications directed at a particular person or group of
                people.
              </p>
              <p>
                7.5 You must not use the Services for the purpose of
                deliberately upsetting or offending others.
              </p>
              <p>
                7.6 You must not unnecessarily flood the Services with material
                relating to a particular subject or subject area, whether alone
                or in conjunction with others.
              </p>
              <p>
                7.7 You must ensure that Content does not duplicate other
                content available through the Services.
              </p>
              <p>
                7.8 You must ensure that Content is appropriately categorised.
              </p>
              <p>
                 7.9
              You should use appropriate and informative titles for all Content.
   
              </p>
                         7.10 You must at all times be courteous and polite to other users
              of the Services. 
              <p>
8. Marketing and spam </p>
              <p> 8.1 You must not without
              our written permission use the Services for any purpose relating
              to the marketing, advertising, promotion, sale or supply of any
              product, service or commercial offering. </p>
              <p>
              8.2 Content must not
              constitute or contain spam, and you must not use the Services to
              store or transmit spam - which for these purposes shall include
              all unlawful marketing communications and unsolicited commercial
              communications. </p>
              <p>
              8.3 You must not send any spam or other marketing
              communications to any person using any email address or other
              contact details made available through the Services or that you
              find using the Services. </p>
              <p>
              8.4 You must not use the Services to
              promote, host or operate any chain letters, Ponzi schemes, pyramid
              schemes, matrix programs, multi-level marketing schemes, "get rich
              quick" schemes or similar letters, schemes or programs. </p>
              <p>
               8.5 You
              must not use the Services in any way which is liable to result in
              the blacklisting of any of our IP addresses. 
              </p>
              
              <p>
 9. Regulated
              businesses </p>
              <p>
                9.1 You must not use the Services for any purpose
              relating to gambling, gaming, betting, lotteries, sweepstakes,
              prize competitions or any gambling-related activity. 
              </p>
              <p>
                9.2 You must
              not use the Services for any purpose relating to the offering for
              sale, sale or distribution of drugs or pharmaceuticals. 
              </p>
              <p>
                9.3 You
              must not use the Services for any purpose relating to the offering
              for sale, sale or distribution of knives, guns or other weapons.
              

              </p>
             <p>

                10. Monitoring
                </p>
              <p> 10.1 You acknowledge that we do not actively
              monitor the Content or the use of the Services. 
             </p>
             <p>
                11. Data mining
                </p>
              <p>
              11.1 You must not conduct any systematic or automated data
              scraping, data mining, data extraction or data harvesting, or
              other systematic or automated data collection activity, by means
              of or in relation to the Services. 
             </p>
              
             <p>
                12. Hyperlinks
                </p>
              <p> 12.1 You must
              not link to any material using or by means of the Services that
              would, if it were made available through the Services, breach the
              provisions of this Policy.
             </p>
              
              <p>
                 13. Harmful software
                 </p>
              <p> 13.1 The Content
              must not contain or consist of, and you must not promote,
              distribute or execute by means of the Services, any viruses,
              worms, spyware, adware or other harmful or malicious software,
              programs, routines, applications or technologies. 
              </p>
              <p>13.2 The Content
              must not contain or consist of, and you must not promote,
              distribute or execute by means of the Services, any software,
              programs, routines, applications or technologies that will or may
              have a material negative effect upon the performance of a computer
              or introduce material security risks to a computer.
              </p>

              
             
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AcceptableUse;
