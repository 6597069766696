import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

import Select from "react-select";

import {
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE,
  SG_SELECT_EMPLOYEE,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";
import auth from "reducers/auth";
import { LOAD_ALL_EMPLOYEES } from "constants/actions";
import { onKeyPress } from "Utilities/ErrorFormat";
import { Button as MUIButton } from "@mui/material";

const ActiveOptions = [
  { value: 1, label: "Active" },
  { value: 2, label: "Paused" },
  { value: 3, label: "In Active" },
];

const RoleOptions = [
  { value: 99, label: "Employee" },
  { value: 8, label: "Sales" },
  { value: 6, label: "Supervisor" },
  { value: 3, label: "Manager" },
  { value: 1, label: "Admin" },
];

const email_validation =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const CreateEmployee = ({ setAddTaskOpen, setLabel, ...props }) => {
  const dispatch = useDispatch();
  // const { id, slug } = useParams();
  const [employeeId, setEmployeeId] = React.useState(props.employeeId);
  const [dealerId, setDealerId] = React.useState(props.dealerId);
  const [employee, setEmployee] = React.useState({});

  const [employeeCreated, setEmployeeCreated] = React.useState(null);
  const [pageerrors, setPageerrors] = React.useState([]);

  const [visible, setVisible] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [roleOption, setRoleOption] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);
  const [getLeads, setGetLeads] = React.useState(false);
  const [getLeadsEmails, setGetLeadsEmails] = React.useState(false);
  React.useEffect(() => {
    return () => {
      setEmployee({});
    };
  }, []);
  React.useEffect(() => {
    if (props.employeeId > 0) {
      setEmployeeId(props.employeeId);
    }
    if (props.employeeId === 0) {
      setEmployee({});
      setEmployeeId(0);
    }
  }, [props.employeeId]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.

    if (!employeeId) {
      setEmployee({});
    }
    if ([parseInt(employeeId)] > 0) {
      dispatch({ type: SG_SELECT_EMPLOYEE, payload: employeeId });
    }
  }, [dispatch, employeeId]);

  const {
    get_employee_created,
    get_employee_updated,
    get_employee_selected,
    get_errors,
    get_auth,
    loading,
  } = useSelector(
    (state) => ({
      employee_list: state.employees.employees,
      get_employee_created: state.employees.created,
      get_employee_updated: state.employees.updated,
      get_employee_selected: state.employees.selected,
      get_auth: state.auth,
      get_errors: state.errors,
      loading: state.employees.pending_employee,
    }),
    shallowEqual
  );
  // [parseInt(id)]
  React.useEffect(() => {
    // if (_.get(get_errors, "errors.detail")) {
    //   setPageerrors(get_errors);
    //   setVisible(true);
    // }
    if (
      get_errors?.errors?.data &&
      get_errors?.errors?.data["detail"] === "Not found."
    ) {
      setPageerrors("");
      setVisible(false);
    }
  }, [get_errors]);

  React.useEffect(() => {
    const loopErrorObj = get_errors?.errors?.data;
    {
      get_errors?.errors?.data &&
        Object.keys(loopErrorObj).forEach(function (key) {
          setVisible(true);
          setPageerrors(loopErrorObj[key]);
        });
    }
  }, [get_errors?.errors]);

  // React.useEffect(() => {
  //   if (get_errors.msg.data && get_errors.msg.data['detail'] !== 'Not found.') {
  //     setVisible(true);
  //   }
  // }, [get_errors]);

  React.useEffect(() => {
    if (_.get(get_employee_created, "id")) {
      setEmployeeCreated(get_employee_created);
      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }
    setEmployee({});
    // reset();
    // reset({
    //   role: null,
    // });
    if (props.dealerId) {
      dispatch({
        type: LOAD_ALL_EMPLOYEES,
        payload: `company=${props.dealerId}`,
      });
    } else {
      dispatch({ type: LOAD_ALL_EMPLOYEES });
    }
    props.resetColWidth(Math.random());
  }, [get_employee_created]); //This Runs when employee is created
  React.useEffect(() => {
    props.resetColWidth(Math.random());
    if (_.get(get_employee_updated, "id")) {
      setEmployeeCreated(get_employee_created);
      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }
    setEmployee({});
    reset();
    reset({
      role: null,
    });
    if (props.dealerId) {
      dispatch({
        type: LOAD_ALL_EMPLOYEES,
        payload: `company=${props.dealerId}`,
      });
    } else {
      dispatch({ type: LOAD_ALL_EMPLOYEES });
    }
    // setEmployeeId();
  }, [get_employee_updated]); //This Runs when employee is updated

  React.useEffect(() => {
    setPageerrors([]);
    setVisible(false);
    if ([parseInt(employeeId)] > 0) {
      reset();

      setEmployee(get_employee_selected[parseInt(employeeId)]);
    }
  }, [get_employee_selected, employeeId]); //This runs only when new member is selected for edit or create

  React.useEffect(() => {
    reset();
    // reset();
    // reset({
    //   role:null
    // })
    if (employee && employee.id === employeeId) {
      if ([parseInt(employeeId)] > 0) {
        if (_.get(employee, "status")) {
          setStatus(
            ActiveOptions.filter(
              (fl) =>
                fl.value === get_employee_selected[parseInt(employeeId)].status
            )
          );
        }

        if (_.get(employee, "role")) {
          setRoleOption(
            RoleOptions.filter(
              (fl) =>
                fl.value === get_employee_selected[parseInt(employeeId)].role
            )
          );
        }
      }
    }
  }, [employee, employeeId]);

  // console.log("dealerId", dealerId, get_auth.dealer_id, Math.random());

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    reset,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    // console.log(selStatus);
    if (status) {
      setValue("status", status);
    }
  }, [status]);
  React.useEffect(() => {
    // console.log(selStatus);
    if (roleOption) {
      setValue("role", roleOption);
    }
  }, [roleOption]);

  const onSubmit = (data) => {
    if (employeeId) {
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          id: employeeId,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          external_name: data.external_name,
          notes: data.notes,
          role: data.role.value,
          status: data.status.value,
          get_leads: getLeads,
          get_leads_email: getLeadsEmails,
          company: dealerId ? dealerId : get_auth.dealer_id,
        },
      });

      setTimeout(() => {
        setAddTaskOpen(false);
      }, 1500);

      //   if (!visible) {
      //     setTimeout(() => {
      //    props.resetColWidth(Math.random());
      //  }, 2000);
      //  }
    } else {
      dispatch({
        type: CREATE_EMPLOYEE,
        payload: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          notes: data.notes,
          external_name: data.external_name,
          role: data.role.value,
          status: data.status.value,
          get_leads: getLeads,
          get_leads_email: getLeadsEmails,
          company: dealerId ? dealerId : get_auth.dealer_id,
        },
      });
      setTimeout(() => {
        setAddTaskOpen(false);
      }, 1500);
      // if (!visible) {
      //    setTimeout(() => {
      //   props.resetColWidth(Math.random());
      // }, 2000);
      // }
    }
    // reset();
    // <Redirect />
  };

  React.useEffect(() => {
    setGetLeads(employee?.get_leads);
    setGetLeadsEmails(employee?.get_leads_email);
  }, [employee]);
  //   const ChangedStatus = (value) => {
  //     setSelStatus(value);
  //   };

  setLabel(`${employeeId ? "Edit " : "Create "}Employee`);

  return (
    <>
      {/* {employeeCreated ? (
            <Redirect to={`/admin/employee-detail/${employeeCreated.id}`} />
          ) : null}
       {toDetail? (
            <Redirect to={`/admin/employee-detail/${id}`} />
          ) : null} */}
      {/*{toHome? <Redirect to={`/admin/leads`}  /> : null} */}

      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center vh-100"
          md="12"
        >
          <Spinner>Loading...</Spinner>
        </div>
      ) : (
        <div className="content">
          <ErrorBoundary>
            <Alert
              color="danger"
              isOpen={pageerrors !== "Not found." && visible}
              toggle={() => setVisible(!visible)}
            >
              {/* {pageerrors.errors
              ? pageerrors.errors["detail"]
              : JSON.stringify(pageerrors.errors)}
            {pageerrors.errors
              ? pageerrors.errors["non_field_errors"]
              : JSON.stringify(pageerrors.errors)} */}
              {/* {JSON.stringify(pageerrors.errors)} */}
              {pageerrors !== "Not found." && pageerrors}
            </Alert>

            <Row>
              <Col className="ml-auto mr-auto" md="10">
                {/* <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <CardTitle tag="h4">
                        {employeeId ? "Edit " : "Create "}Employee
                      </CardTitle>

                      <Button
                        className="btn-fill"
                        size="sm"
                        color="info"
                        onClick={() => {
                          setEmployee();
                          setEmployeeId(Math.random());
                          props.resetColWidth(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div> */}

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <label>First Name </label>
                    <Input
                      type="text"
                      name="first_name"
                      defaultValue={employee?.first_name || ""}
                      innerRef={register({
                        required: "Please enter First Name",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.first_name && (
                        <span className="d-flex">
                          {errors.first_name.message}
                        </span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>Last Name </label>
                    <Input
                      type="text"
                      name="last_name"
                      defaultValue={employee?.last_name || ""}
                      innerRef={register({
                        required: "Please enter Last Name",
                      })}
                    />
                    <p className="text-danger small">
                      {errors.last_name && (
                        <span className="d-flex">
                          {errors.last_name.message}
                        </span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>
                      Curated List (Please enter the curated link for this user)
                    </label>
                    <Input
                      type="text"
                      name="curated_list"
                      defaultValue={employee?.curated_list || ""}
                    />
                    <p className="text-danger small">
                      {errors.curated_list && (
                        <span className="d-flex">
                          {errors.curated_list.message}
                        </span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>Phone</label>
                    <Input
                      type="text"
                      name="phone"
                      onKeyPress={onKeyPress}
                      defaultValue={employee?.phone || ""}
                      innerRef={register({
                        required: "Please enter Phone number",
                        maxlength: 20,
                      })}
                    />
                    <p className="text-danger small">
                      {errors.phone && (
                        <span className="d-flex">{errors.phone.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>Email</label>
                    <Input
                      type="text"
                      // disabled={true}
                      disabled={!props.employeeId ? false : true}
                      name="email"
                      defaultValue={employee?.email || ""}
                      innerRef={register({
                        required: "Email is required",
                        pattern: {
                          value: email_validation,
                          message: "invalid email address",
                        },
                        maxlength: 50,
                      })}
                    />
                    <p className="text-danger small">
                      {errors.email && (
                        <span className="d-flex">{errors.email.message}</span>
                      )}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <label>External Ref</label>
                    <Input
                      type="text"
                      // disabled={dealerId ? false : true}
                      name="external_name"
                      defaultValue={employee?.external_name || ""}
                      innerRef={register}
                    />
                    <p className="text-danger small">
                      {errors.external_name && (
                        <span className="d-flex">
                          {errors.external_name.message}
                        </span>
                      )}
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <label>Role</label>
                    <Controller
                      as={<Select />}
                      name="role"
                      options={RoleOptions}
                      className="text-dark"
                      control={control}
                      rules={{ required: "Please enter Role" }}
                      isDisabled={
                        Number(get_auth.role_id) === 10 &&
                        Number(props.employeeId) ===
                          Number(get_auth.employee_id)
                          ? true
                          : false
                      }
                    />{" "}
                    <p className="text-danger small">
                      {errors.role && (
                        <span className="d-flex">{errors.role.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>Active Status</label>
                    <Controller
                      as={<Select />}
                      name="status"
                      options={ActiveOptions}
                      className="text-dark"
                      control={control}
                      rules={{ required: "Please enter Status" }}
                    />{" "}
                    <p className="text-danger small">
                      {errors.status && (
                        <span className="d-flex">{errors.status.message}</span>
                      )}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={getLeads}
                          // value={getLeads}
                          type="checkbox"
                          onClick={() => setGetLeads(!getLeads)}
                        />
                        <span className="form-check-sign" />
                        Distribute Leads
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={getLeadsEmails}
                          // defaultValue={getLeads}
                          type="checkbox"
                          onClick={() => setGetLeadsEmails(!getLeadsEmails)}
                        />
                        <span className="form-check-sign" />
                        Distribute Leads Emails
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup>
                    <label>Description</label>
                    <Input
                      type="textarea"
                      defaultValue={employee?.notes || ""}
                      name="notes"
                      id="notes"
                      innerRef={register}
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <MUIButton
                      color="preAuto"
                      variant="contained"
                      type="submit"
                    >
                      {employeeId ? "Update " : "Save "}
                    </MUIButton>
                    {/* <Button className="btn-fill" color="primary" type="submit">
                      {employeeId ? "Update " : "Save "}
                    </Button> */}
                  </div>
                </Form>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </ErrorBoundary>
        </div>
      )}
    </>
  );
};

export default CreateEmployee;
