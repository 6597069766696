import ErrorBoundary from "ErrorBoundary";
import React from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { LOAD_GET_CASHFORCARS } from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_GET_STRIPE_CUSTOMER } from "constants/actions";
import { LOAD_GET_STRIPE_PRODUCTS } from "constants/actions";
import { useState } from "react";
import { ConsoleView } from "react-device-detect";
import { STRIPE_CREATE_INVOICE_TYPE } from "constants/actions";
import customer from "reducers/customer";
import { toast } from "react-toastify";
import { LOAD_COMPANY } from "constants/actions";

export default function CreateInvoice(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    formState,
    control,
  } = useForm();

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const notify = () => toast.success(`Invoice Created successfully`);
  const { get_customers, get_products, company_list } = useSelector(
    (state) => ({
      get_customers: state.stripecustomer.stripecustomer,
      get_products: state.stripeproducts.stripeproducts,
      company_list: state.company.companies,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_GET_STRIPE_CUSTOMER });
  }, [dispatch]);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_GET_STRIPE_PRODUCTS });
  }, [dispatch]);

  React.useEffect(() => {
    if (get_customers) {
      setCustomers(get_customers?.data);
      setProducts(get_products?.data);

      // console.log("Customer id", get_customers?.data?.[0].id)
    }
  }, [get_customers, get_products]);

  const [companies, setCompanies] = React.useState([]);
  React.useEffect(() => {
    setCompanies(company_list);
    console.log("companies", companies);
  }, [company_list]);

  const onSubmit = (data) => {
    console.log("Onsubmit");
    console.log(data.customer_id.id);
    dispatch({
      type: STRIPE_CREATE_INVOICE_TYPE,
      payload: {
        company: data?.customer_id?.id,
        product: data?.product_id?.id,
        company_id: data?.customer_id?.value,
      },
    });
    history.push('/admin/admin-invoice');

    notify();
  };

  

  const [pageParams, setPageParams] = React.useState(props.params);
  React.useEffect(() => {
    dispatch({ type: LOAD_COMPANY, payload: { params: pageParams } });
  }, [dispatch, pageParams]);
  return (
    <div className="content">
      <ErrorBoundary>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Create Invoice</CardTitle>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <label>Customer</label>
                    <Controller
                      as={<Select />}
                      name="customer_id"
                      placeholder="Select Customer"
                      options={companies}
                      // defaultValue={(option) => `${option.id}`}
                      getOptionLabel={(option) => `${option?.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      // value={(option) => `${option.stripe_id}`}
                      className="text-dark"
                      control={control}
                      valueName={(option) => `${option.id}`}
                      innerRef={register()}
                    />
                  </FormGroup>

                  {/* <FormGroup>
                    <label>Product</label>
                    <Controller
                      as={<Select />}
                      name="product_id"
                      placeholder="Select Product"
                      options={products}
                      isClearable
                      getOptionLabel={(option) => `${option?.name}`}
                      getOptionValue={(option) => `${option.id}`}
                      className="text-dark"
                      control={control}
                      innerRef={register()}
                    />
                  </FormGroup> */}

                  <Button className="btn-fill" color="primary" type="submit" >
                    Save
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ErrorBoundary>
    </div>
  );
}
