import React from 'react';
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
  } from "reactstrap";



const TotalLeads = ({dateLabel, leadLabel}) => {

  const chartExample1 = {
    data: {
      labels: dateLabel,
      datasets: [
        {
          label: "New Leads",
          borderColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 3,
          data: leadLabel
        }
      ]
    },
    options: {
      legend: {
        display: false
      },
  
      tooltips: {
        enabled: true
      },
  
      scales: {
        // yAxes: [
        //   {
        //     ticks: {
        //       fontColor: "#9f9f9f",
        //       beginAtZero: false,
        //       maxTicksLimit: 5
        //       //padding: 20
        //     },
        //     gridLines: {
        //       drawBorder: false,
        //       zeroLineColor: "transparent",
        //       color: "rgba(255,255,255,0.05)"
        //     }
        //   }
        // ],
  
        // xAxes: [
        //   {
        //     barPercentage: 1.6,
        //     gridLines: {
        //       drawBorder: false,
        //       color: "rgba(255,255,255,0.1)",
        //       zeroLineColor: "transparent",
        //       display: false
        //     },
        //     ticks: {
        //       padding: 20,
        //       fontColor: "#9f9f9f"
        //     }
        //   }
        // ]
      }
    }
  };
    return (
        <Card>
        <CardHeader>
          <Row>
            <Col sm="7">
              <div className="numbers pull-left">Total Leads</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
                {/* <Badge color="success" pill>
                  +18%
                </Badge> */}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h6 className="big-title">
          {/* Total Sales (Value) */}
          </h6>
          <Bar
            data={chartExample1.data}
            options={chartExample1.options}
            height={380}
            width={826}
          />
        </CardBody>
       
      </Card>
    );
};

export default TotalLeads;