import React, { useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import AddNewLeadStage from "./AddNewLeadStage";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
} from "@mui/material";
import _ from "lodash";
import "./leads.css";
import {
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  SG_FETCH_WEB_LEAD,
  SG_GET_SELECT_INVENTORIES,
  LOAD_LEADSOURCES,
  UPDATE_CUSTOMER,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

import MiniCreate from "TaskLists/MiniCreate";
import LeadLog from "./LeadLog"

import TaskList from "TaskLists/List";
import { func } from "prop-types";

const VehicleOptions = [
  { value: "Car", label: "Car" },
  { value: "SUV", label: "SUV" },
  { value: "Truck", label: "Truck" },
  { value: "Van", label: "Van" },
  { value: "Other as described", label: "Other as described" },
];

const CONTACT_CHOICES = [
  { value: 0, label: 'No Information' },
  { value: 1, label: 'Contact me with offers' },
  { value: 2, label: 'Contact me for specific announcements' },
  { value: 9, label: 'No Contact Whatsoever' },
]


function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function JsonDescription(str) {
  console.log(JSON.parse(str));
  for (var key in str) {
    if (str.hasOwnProperty(key)) {
      console.log(key + " -> " + str[key]);
    }
  }
  // console.log(str);
  if (IsJsonString(str)) {
    return <p>Awesome</p>;
  } else {
    return str;
  }
}

const CreateWebLead = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const [webLead, setWebLead] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(null);
  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);
  const [toDetail, setToDetail] = React.useState(false);
  const [errorData, setErrorData] = React.useState(false);

  const [newTaskAdded, setNewTaskAdded] = React.useState(0);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_WEB_LEAD, payload: id });
    }
    dispatch({ type: SG_GET_SELECT_INVENTORIES });
    dispatch({ type: LOAD_LEADSOURCES });
    dispatch({ type: LOAD_LEADTYPES });
    dispatch({ type: LOAD_LEAD_STAGE });
  }, [dispatch]);

  const {
    get_web_leads,
    get_auth,
    get_errors,
    get_inventory_select,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_web_leads: state.webleads,
      get_inventory_select: state.inventories.select_inventory,
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.leadstage.leadstages,
      lead_type: state.leadtypes.leadtypes,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]
  React.useEffect(() => {
    setSelLeadType(lead_type);
  }, [lead_type]);

  React.useEffect(() => {
    setSelLeadStage(lead_stage);
  }, [lead_stage]);

  React.useEffect(() => {
    setSelInventory(get_inventory_select);
  }, [get_inventory_select]);

  React.useEffect(() => {
    setSelLeadSource(lead_sources);
  }, [lead_sources]);

  useEffect(() => {
    if (get_errors?.msg?.data && get_errors?.msg?.data['detail'] !== 'Not found.') {
      setErrorData(get_errors?.msg?.data)
    }
  }, [get_errors]);

  React.useEffect(() => {
    if (get_web_leads[parseInt(id)]) {
      setWebLead(get_web_leads[parseInt(id)]);
      setCustomerId(get_web_leads[parseInt(id)].customer);

      // const SelectedOption = OriginOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].origin
      // )[0];
      // setSelOrigin(SelectedOption);

      // const SelectedStatus = StatusOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].status
      // )[0];
      // setSelStatus(SelectedStatus);
    }
  }, [get_web_leads, id]);
  function CreatedNewTask(value) {
    setNewTaskAdded(value);
  }

  const onSubmit = (data, e) => {
    // console.log(data)
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: {
        id: webLead.customer.id,
        contact_status: data.contact_status.value,
      },
    });
  };


  React.useEffect(() => {
    // console.log(selStatus);
    // if (webLead.customer?.contact_status) {

    let emp = CONTACT_CHOICES.filter(em => em.value === webLead.customer?.contact_status)[0]

    setValue("contact_status", emp);

    // }

  }, [webLead, CONTACT_CHOICES]);

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }

    return null;
  };

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };


  return (
    <>
      <div className="content">
        {errorData &&

          <Alert
            color="warning"
          >
            <p className="alert-heading">
              Error Found
            </p>
            <ul>
              {Object.keys(errorData).map((i, k) => {
                return <p key={k}>
                  <strong>{i}</strong> <em>{errorData[i]}</em>
                </p>
              })}
            </ul>

          </Alert>
        }
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle tag="h4">Lead Details</CardTitle>

                      <Link to={`/admin/leads/`}>View Entire List</Link>
                    </Col>
                    <Col className="ml-auto" md="2">
                      <MUIButton
                        color="preAuto"
                        variant="contained"
                        // component={Link} to={`/admin/lead-create/${id}`}
                        onClick={onOpenAddTask}
                        // textDecoration="none"
                        // style={{ color: '#FFF' }}
                      >
                        Edit
                      </MUIButton>
                      {/* <Button
                        color="default"
                        size="sm"
                        to={`/admin/lead-create/${id}`}
                        tag={Link}
                      >
                        Edit
                      </Button> */}
                    </Col>
                  </Row>

                  <div className="block-div">
                    <Row>
                      <Col>
                        <p className="lead">Customer Details</p>
                      </Col>

                      <Col>
                        {webLead?.customer ? (
                          <Button
                            color="info"
                            size="sm"
                            to={`/admin/customer-detail/${webLead?.customer?.id}`}
                            tag={Link}
                          >
                            Customer
                          </Button>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label>First Name </label>
                        <p>{webLead ? webLead.first_name : ""}</p>
                      </Col>

                      <Col>
                        <label>Middle Name </label>
                        <p>{webLead ? webLead.middle_name : ""}</p>
                      </Col>

                      <Col>
                        <label>Last Name </label>
                        <p>{webLead ? webLead.last_name : ""}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label>Phone</label>
                        <div className={webLead.phone ? "large-phone-number" : "no-phone-number"}>
                          {webLead.phone ? webLead.phone :
                            // formatPhoneNumber(webLead.phone) : 
                            "No Phone Number"}
                        </div>
                      </Col>

                      <Col>
                        <label>Email</label>
                        <p>{webLead ? webLead.email : ""}</p>
                      </Col>
                    </Row>
                  </div>

                  <div className="block-div">
                    <Row>
                      <Col>
                        <p className="lead">Customer Contact Settings</p>
                      </Col>
                    </Row>


                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md="8">

                          <Controller
                            as={<Select />}
                            name="contact_status"
                            options={CONTACT_CHOICES}
                            className="text-dark"
                            control={control}
                            // defaultValue={emp}
                            rules={{ required: "Please select Status" }}
                          />{" "}
                          <p className="text-danger small">
                            {errors.contact_status && (
                              <span>{errors.contact_status.message}</span>
                            )}
                          </p>
                        </Col>
                        <Col md="4">
                          <Button
                            className="btn-fill btn-sm"
                            color="primary"
                            type="submit"
                          >
                            Change
                          </Button>
                        </Col>
                      </Row>
                    </Form>




                  </div>

                  <div className="block-div">
                    <p className="lead">Marketing Info</p>

                    <Row>
                      <Col>
                        <label>Lead Code</label>

                        <p>{webLead ? webLead.lead_code : ""}</p>
                      </Col>
                      <Col>
                        <label>Campaign Code</label>
                        <p>{webLead ? webLead.campaign_code : ""}</p>
                      </Col>

                      <Col>
                        <label>Device</label>
                        <p>{webLead ? webLead.device : ""}</p>
                      </Col>
                      <Col>
                        <label>Browser</label>
                        <p>{webLead ? webLead.browser : ""}</p>
                      </Col>
                      <Col>
                        <label>IP</label>
                        <p>{webLead ? webLead.ip : ""}</p>
                      </Col>
                    </Row>
                    {/* 'gclid','pixelid', 'mssclid', 'utm_source', */}
                    <Row>
                      <Col>
                        <label>Facebook Code</label>

                        <p>{webLead ? webLead.pixelid : ""}</p>
                      </Col>
                      <Col>
                        <label>Google Code</label>
                        <p>{webLead ? webLead.gclid : ""}</p>
                      </Col>

                      <Col>
                        <label>Bing Code</label>
                        <p>{webLead ? webLead.mssclid : ""}</p>
                      </Col>
                      <Col>
                        <label>UTM Source Code</label>
                        <p>{webLead ? webLead.utm_source : ""}</p>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                  <div className="block-div">
                    <label>Lead Stage</label>
                    <Row>
                      <Col>
                        <p className="lead">
                          {webLead.web_lead_stage
                            ? webLead.web_lead_stage.name
                            : ""}
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <div className="block-div">
                    <p className="lead">Lead Info</p>
                    <Row>
                      <Col>
                        <label>Lead Source</label>

                        <p>
                          {webLead.web_lead_source
                            ? webLead.web_lead_source.name
                            : ""}
                        </p>
                      </Col>

                      <Col>
                        <label>Lead Type</label>
                        <p>
                          {webLead.web_lead_type
                            ? webLead.web_lead_type.name
                            : ""}
                        </p>
                      </Col>
                      <Col>
                        {webLead.web_lead_type ? (
                          webLead.external_ref_id ? (
                            <>
                              <Button
                                color="info"
                                size="sm"
                                to={`/admin/${webLead.web_lead_type.internal_url}/${webLead.external_ref_id}`}
                                tag={Link}
                              >
                                More
                              </Button>
                            </>
                          ) : (
                            ""
                          )
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <p>
                          Created:{" "}
                          {webLead.created_at
                            ? moment(webLead.created_at).format(
                              "YYYY-MM-DD hh:mm a"
                            )
                            : "No date given"}
                        </p>
                      </Col>
                      <Col>
                        <p>
                          Updated:{" "}
                          {webLead.updated_at
                            ? moment(webLead.updated_at).format(
                              "YYYY-MM-DD hh:mm a"
                            )
                            : "No date given"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="block-div">
                    <p className="lead">Inventory Interested In</p>
                    <Row>
                      <Col>
                        {webLead.inventory ? (
                          <ListGroup flush>
                            {webLead.inventory.map((p, k) => {
                              return (
                                <ListGroupItem
                                  key={k}
                                  className="list-group-item list-group-item-success"
                                >
                                  <Row>
                                    <Col>
                                      <p>
                                        {p.id} {p.year} {p.make} {p.model}{" "}
                                      </p>
                                    </Col>
                                    <Col>
                                      <Link
                                        to={`/admin/inventory-detail/${p.id}`}
                                      >
                                        View
                                      </Link>
                                    </Col>
                                  </Row>
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        ) : (
                          <p>"No Inventory Selected"</p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label>Expected Purchase Date</label>
                        <p>
                          {webLead
                            ? moment(webLead.expected_purchase_date).format(
                              "YYYY-MM-DD"
                            )
                            : ""}
                        </p>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>Estimated or Stated Budget</label>
                          <p>{webLead ? webLead.estimated_budget : ""}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {webLead ? (
                    webLead.get_in_amount ? (
                      <div className="block-div">
                        <p className="lead">Looking for Payment Plan</p>
                        <Row>
                          <Col>
                            <label>Amount </label>
                            <p>{webLead ? webLead.get_in_amount : ""}</p>
                          </Col>
                          <Col>
                            <label>Duration</label>
                            <p>{webLead ? webLead.get_in_duration : ""}</p>
                          </Col>
                          <Col>
                            <label>Payment Cycle </label>
                            <p>
                              {webLead
                                ? webLead.get_in_frequency === 1
                                  ? "Weekly"
                                  : webLead.get_in_frequency === 2
                                    ? "Bi Weekly"
                                    : "Monthly"
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {webLead ? (
                    webLead.get_out_amount ? (
                      <div className="block-div">
                        <p className="lead">Current Payment</p>
                        <Row>
                          <Col>
                            <label>Amount </label>
                            <p>{webLead ? webLead.get_out_amount : ""}</p>
                          </Col>
                          <Col>
                            <label>Duration</label>
                            <p>{webLead ? webLead.get_out_duration : ""}</p>
                          </Col>
                          <Col>
                            <label>Payment Cycle </label>
                            <p>
                              {webLead
                                ? webLead.get_out_frequency === 1
                                  ? "Weekly"
                                  : webLead.get_out_frequency === 2
                                    ? "Bi Weekly"
                                    : "Monthly"
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <FormGroup>
                    <label>Description / comments</label>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {webLead ? webLead.description : ""}
                    </p>
                  </FormGroup>

                  <FormGroup>
                    <label>Managed By</label>

                    <p>
                      {webLead.employee
                        ? `${webLead.employee.first_name} ${webLead.employee.last_name} - ${webLead.employee.role_text} (${webLead.employee.status_text})`
                        : ""}
                    </p>
                  </FormGroup>
                  <Row>

                    <Col className="ml-auto" md="2">
                      <Button
                        color="warning"
                        // size="sm"
                        to={`/admin/lead-create/${id}`}
                        tag={Link}
                      >
                        Edit Lead Details
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {errorData &&
            <Alert
              color="warning"
            >
              <p className="alert-heading">
                Error Found
              </p>

              {Object.keys(errorData).map((i, k) => {
                return <p key={k}>
                  <strong>{i}</strong> <em>{errorData[i]}</em>
                </p>
              })}

            </Alert>
          }
          {customerId && <LeadLog logFile={webLead?.lead_log} />}
          {customerId ? (
            <MiniCreate
              lead_id={id}
              customer_id={customerId}
              CreatedNewTask={CreatedNewTask}
            />
          ) : (
            ""
          )}
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <CardTitle tag="h4">Task Lists for this Lead</CardTitle>
                  <TaskList
                    params={`web_leads=${id}`}
                    newTaskAdded={newTaskAdded}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>

      <AddNewLeadStage
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default CreateWebLead;
