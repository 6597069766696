import React from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Alert, Button, Spinner, Fade, Row, Col } from "reactstrap";

import {
  LOAD_INVENTORY_LIST,
  LOAD_COMPARED_INVENTORY_LIST,
  LOAD_VALID_VIN_UPLOAD_LIST,
  SG_FETCH_RELATED_DEALER,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_INVENTORY,
  SG_EDIT_MILEAGE,
} from "constants/actions";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
  Box,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const LocationId = 2;

const ShowDetails = ({ inventoryList, p }) => {
  const Inventory = inventoryList.filter((fl) => fl.vin === p.vin)[0];
  if (Inventory) {
    return (
      <>
        Mfd Col: {Inventory.mfd_color_code ? Inventory.mfd_color_code : ""}
        <hr />
        Seats: {Inventory.pass_capacity ? Inventory.pass_capacity : ""}
        <hr />
        Engine: {Inventory.engine ? Inventory.engine : ""}
        <hr />
        Images: {Inventory.total_images ? Inventory.total_images : ""}
      </>
    );
  } else {
    return (
      <p
        style={{ background: "#f33333", borderRadius: "5px" }}
        className="text-white"
      >
        <MUIButton
          variant="text"
          style={{
            cursor: "text",
            color: "#ffffff",
            padding: "5px 5px",
          }}
        >
          Resolve
        </MUIButton>
      </p>
    );
  }
};

const ViewBtn = ({ inventoryList, p }) => {
  // if (inventoryList.id === 350) {
  const VinFound = inventoryList
    .filter((fl) => fl.id == 351)
    .map((tl) => tl.vin)[0];

  return inventoryList.filter((fl) => fl.vin === p.vin)[0] ? (
    <Link
      to={`/admin/inventory-detail/${
        inventoryList.filter((fl) => fl.vin === p.vin)[0].id
      }`}
    >
      <RemoveRedEyeOutlinedIcon color={"preAuto"} />
    </Link>
  ) : (
    ""
  );
};

const XmlCompare = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [creating, setCreating] = React.useState();
  const [inventoryList, setInventoryList] = React.useState([]);
  const [comparedList, setComparedList] = React.useState([]);

  const [vinuploadList, setVinuploadList] = React.useState([]);
  const [pricesUpdated, setPricesUpdated] = React.useState([]);
  // const [updateAllLists, setUpdateAllLists] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_LIST });
    dispatch({ type: LOAD_VALID_VIN_UPLOAD_LIST });
    dispatch({ type: LOAD_COMPARED_INVENTORY_LIST });
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const {
    get_inventories,
    get_compared,
    get_pending,
    get_vinupload,
    get_prices_updated,
  } = useSelector(
    (state) => ({
      get_inventories: state.inventories.inventoryList,
      get_compared: state.inventories.compared,
      get_pending: state.vinupload.creating,
      get_vinupload: state.vinupload,
      get_prices_updated: state.inventories.updated_prices,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_inventories) {
      setInventoryList(get_inventories);
    }
  }, [get_inventories]);

  React.useEffect(() => {
    if (get_compared) {
      setComparedList(get_compared.results);
    }
  }, [get_compared]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinuploadList(get_vinupload.validlist);
    }
  }, [get_vinupload]);

  React.useEffect(() => {
    if (get_prices_updated) {
      setPricesUpdated(get_prices_updated);
    }
  }, [get_prices_updated]);

  // console.log(get_vinupload)
  // console.log(vinuploadList)

  React.useEffect(() => {
    if (get_vinupload?.created?.id) {
      history.push(
        `/admin/full-vin-decode-edit/${get_vinupload.created.id}/${get_vinupload.created.inventory_id}?return-url=xml-inventory`
      );
    }
  }, [get_vinupload, get_pending]);

  React.useEffect(() => {
    setCreating(get_pending);
  }, [get_pending]);

  const PullRecent = () => {
    // dispatch({ type: LOAD_COMPARED_INVENTORY_LIST,
    //   payload: "pull=True" });
    setVisible(true);
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
        // console.log(p)
        let condition = 2;
        if (p.trade == "1") {
          condition = 3;
        }
        const invId = inventoryList.filter((fl) => fl.vin === p.vin)[0];
        dispatch({
          type: SG_EDIT_MILEAGE,
          payload: {
            id: invId.id,
            list_price: p.retail,
            condition: condition,
          },
        });
      }
    });
  };

  const NotFound = [];
  if (comparedList && inventoryList) {
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
        // console.log(p.VIN)
      } else {
        NotFound.push({
          vin: p.VIN,
          year: p.year,
          make: p.make,
          mode: p.model,
        });
      }
    });
  }

  const PullFile = (e) => {
    dispatch({
      type: SG_ADD_VIN_UPLOAD,
      payload: {
        ...e,
        location_id: LocationId,
        retail_price: e.retail,
        cost_price: Number(e?.cost),
      },
    });
  };

  return (
    <>
      <div className="content">
        <div className={creating ? "" : "d-none mt-3"}>
          <Fade in={creating} tag="h5">
            <div className="d-flex justify-content-center">
              <Spinner>Loading...</Spinner>
            </div>
          </Fade>
        </div>
        <div className={creating ? "d-none" : ""}>
          <Row>
            <Col>
              <Button color="primary" onClick={() => PullRecent()}>
                Update All Prices
              </Button>
            </Col>
            <Col>
              {pricesUpdated.length > 0 ? (
                <Alert color="info" isOpen={visible} toggle={onDismiss}>
                  <h6>
                    {pricesUpdated.reduce(
                      (total, x) => (x.status == 1 ? total + 1 : total),
                      0
                    )}{" "}
                    Inventory Prices have been updated
                  </h6>
                  {pricesUpdated.map((p, k) => (
                    <p key={k}>
                      {p.year} {p.make} {p.model} {p.list_price}
                    </p>
                  ))}
                </Alert>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell align="center">
                          Purchased
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Year Make Model <br />
                          VIN
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Color Engine Images
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Mileage
                        </StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>
                        <StyledTableCell align="center">Found</StyledTableCell>
                        <StyledTableCell align="center">MORE</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {comparedList
                        ? comparedList.map((p, k) => (
                            <StyledTableRow key={k}>
                              <StyledTableCell align="left">
                                {k + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="left"
                                // className={
                                // inventoryList.filter((fl) => fl.vin === p.vin)[0]
                                //   ? inventoryList.filter(
                                //     (fl) => fl.vin === p.vin
                                //   )[0].status === 4
                                //     ? "bg-success"
                                //     : "bg-primary"
                                //   : "bg-danger"
                                // }
                              >
                                <Box
                                  component="div"
                                  sx={{
                                    px: 3,
                                    py: 1,
                                    color: "#ffffff",
                                    borderRadius: "30px",
                                    // width: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                    textAlign: "center",
                                    fontSize: 15,
                                    backgroundColor:
                                      inventoryList.find(
                                        (item) => item.vin === p.vin
                                      )?.status_text == "Under Process"
                                        ? "#51cbce"
                                        : "#ef8157",
                                    // fontWeight: Fonts.SEMI_BOLD,
                                    // bgcolor: {()=>{inventoryList.filter(
                                    //   (fl) => fl.vin === p.vin
                                    // )[0].status_text == "Under Process" ? "Red" : "Black"}},
                                  }}
                                >
                                  {p.purchased}
                                  <br />
                                  {inventoryList.filter(
                                    (fl) => fl.vin === p.vin
                                  )[0]
                                    ? inventoryList.filter(
                                        (fl) => fl.vin === p.vin
                                      )[0].status_text
                                    : "VIN Not Found in website inventory"}
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {p.year} {p.make} {p.model} <br />
                                {p.vin} <br />
                                {p.notes}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {p.color} <br />
                                <ShowDetails
                                  p={p}
                                  inventoryList={inventoryList}
                                />
                              </StyledTableCell>
                              {/* <StyledTableCell align="center">
                        <TextField
                          sx={{
                            maxWidth: "80px",
                            margin: 0,
                            padding: 0,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#1d4aa7",
                              },
                              "&:hover fieldset": {
                                borderColor: "#1d4aa7",
                              },
                            },
                            " input": {
                              px: 1,
                              py: 0.5,
                            },
                          }}
                          defaultValue={row.kilometers}
                          //  onChange={(e) => onKilometerChange(e)}
                          //  onFocus={(e) => onKilometerFocus(e)}
                          onBlur={(e) => onKilometerBlur(e, row.id)}
                        /> */}
                              {/* {row.kilometers} */}
                              {/* </StyledTableCell> */}
                              <StyledTableCell StyledTableCell align="left">
                                {p.mileage}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Backend: {p.retail}
                                <hr />
                                Website:
                                {inventoryList.filter(
                                  (fl) => fl.vin === p.vin
                                )[0]
                                  ? inventoryList.filter(
                                      (fl) => fl.vin === p.vin
                                    )[0].list_price
                                  : "Not Found in website"}
                                {pricesUpdated.filter(
                                  (fl) => fl.vin === p.vin
                                )[0] ? (
                                  <p>
                                    {" "}
                                    <span className="show-updated-text">
                                      Price Updated
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {vinuploadList.filter(
                                  (fl) => fl.vin === p.vin
                                )[0] ? (
                                  <Link
                                    to={`/admin/full-vin-decode-edit/${
                                      vinuploadList.filter(
                                        (fl) => fl.vin === p.vin
                                      )[0].id
                                    }/${
                                      vinuploadList.filter(
                                        (fl) => fl.vin === p.vin
                                      )[0].inventory_id
                                    }?return-url=gsheet-inventory`}
                                  >
                                    <MUIButton
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "blueviolet",
                                        color: "#ffffff",
                                        padding: "5px 5px",
                                      }}
                                    >
                                      Resolve
                                    </MUIButton>
                                  </Link>
                                ) : (
                                  // <Button
                                  //   size="sm"
                                  //   color="success"
                                  //   onClick={() => PullFile(p)}
                                  // >
                                  //   Decode
                                  // </Button>
                                  <MUIButton
                                    type="submit"
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#F5EDFC",
                                      color: "#9E49E6",
                                    }}
                                    onClick={() => PullFile(p)}
                                  >
                                    Decode
                                  </MUIButton>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {/* {
                          <Link to={`/admin/inventory-detail/${row.id}`}>
                            <p
                              style={{
                                color: "#1d4aa7",
                                margin: 0,
                              }}
                            >
                              <RemoveRedEyeOutlinedIcon />
                            </p>
                          </Link>
                        } */}
                                <ViewBtn p={p} inventoryList={inventoryList} />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
              </Paper>
            </Col>

            <CSVLink
              data={NotFound}
              filename={"inventory-missing.csv"}
              className="btn btn-primary"
              target="_blank"
            >
              Download Missing
            </CSVLink>
          </Row>
        </div>
      </div>
    </>
  );
};

export default XmlCompare;
