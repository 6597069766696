import React, { useState } from "react";

import PropTypes from "prop-types";
import AppDialog from "../../crema/AppDialog";

import CreateEmployee from "../CreateEdit";

const AddNewEmployee = ({
  isAddTaskOpen,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
  title,
  dealerId,
  resetColWidth,
  employeeId,
}) => {
  const [label, setLabel] = useState("");
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={label}
    >
      <CreateEmployee
        setAddTaskOpen={setAddTaskOpen}
        setLabel={setLabel}
        dealerId={dealerId}
        resetColWidth={resetColWidth}
        employeeId={employeeId}
      />
    </AppDialog>
  );
};

export default AddNewEmployee;
