import React from 'react';
// company: "Schneider-Kuhlman"
// address: "395 Clarendon Pass"
// city: "Feyẕābād"
// postcode: null
// website_url: null
// phone: "399-638-4030"
// email: "dgouley0@g.co"
// api_key: "VBG97qmvm913prfI0GS63"
// website_key: "CTZ83gyny872zlnrNUejF"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap";

const Codes = ({dealer}) => {
    return (
        <>
          <Row>
            <Col md="12">
              
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Dealer Codes</CardTitle>
                </CardHeader>
                <CardBody>

                   <ul className="list-unstyled team-members">
                   <li>
                      <Row>
                        <Col md="3" xs="3">
                          Employee Registration Code
                        </Col>
                        <Col md="6" xs="6">
                        {dealer? dealer.company_external_code: ''}
                        </Col>
                        <Col className="text-right" md="3" xs="3">
                          
                        </Col>
                      </Row>
                    </li>
                  
                   
                  </ul>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </>
    );
};

export default Codes;