import React from "react";
import logo from "assets/img/circle.svg";

import StatsItemCard from "../../modules/StatsItemCard";

import Coins from "../../crema/Coins";
import { Grid } from "@mui/material";
import AppGridContainer from "../../crema/AppGridContainer";

const CardStats = ({ totals, days, get_lead_rotation }) => {
  const [lefttooltipOpen, setLeftTooltipOpen] = React.useState(false);
  const [righttooltipOpen, setRightTooltipOpen] = React.useState(false);
  const toggleLeft = () => setLeftTooltipOpen(!lefttooltipOpen);
  const toggleRight = () => setRightTooltipOpen(!righttooltipOpen);

  return (
    <>
      {/* <Row> */}

      <AppGridContainer>
        <Grid item lg={3} md={4} xs={12} sm={6} key={1} mb={3}>
          <StatsItemCard
            stats={{
              id: 1,
              title: `Total No of Cars Live on Website`,
              count: totals ? totals.no_of_cars : "",
              // new: "New 10",
              badgeColor: "#0A8FDC",
              bgcolor: "#E7F4FB",
              icon: <i className="mdi mdi-car float-right"></i>,
            }}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12} sm={6} key={1} mb={3}>
          <StatsItemCard
            stats={{
              id: 1,
              title: `Total value of Inventory`,
              count:
                totals && totals.value_of_cars
                  ? `$${totals.value_of_cars}`
                  : "$0",
              // new: "New 10",
              badgeColor: "#9E49E6",
              bgcolor: "#F5EDFC",
              icon: <i className="mdi mdi-semantic-web float-right"></i>,
            }}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12} sm={6} key={1} mb={3}>
          <StatsItemCard
            stats={{
              id: 1,
              title: `Average Days to close a sale`,
              count: totals ? totals.avg_days_to_sell_inv : "",
              // new: "New 10",
              badgeColor: "#F49820",
              bgcolor: "#FFECDC",
              icon: <i className="mdi mdi-handshake-outline float-right"></i>,
            }}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12} sm={6} key={1} mb={3}>
          <StatsItemCard
            stats={{
              id: 1,
              title: `Average Leads Per car`,
              count: totals ? totals.avg_lead_per_inv : "",
              // new: "New 10",
              badgeColor: "#F44D50",
              bgcolor: "#F04F47",
              icon: <i className="mdi mdi-television-guide float-right"></i>,
            }}
          />
        </Grid>

        {/* <Grid item lg={12} xs={12} md={12} mb={3}>
          <Coins
            coinsData={[
              {
                value: totals ? totals.no_of_cars : "",
                footer: ` Total No of Cars Live on Website`,
                title: "Total Cars ",
                icon: null,
                bgColor: "#9E49E6",
              },
              {
                value:
                  totals && totals.value_of_cars
                    ? `$${totals.value_of_cars}`
                    : "$0",
                footer: `Total value of Inventory`,
                title: "Total Value",
                icon: null,
                bgColor: "#0A8FDC",
              },
              {
                value: totals ? totals.avg_days_to_sell_inv : "",
                footer: ` Average Days to close a sale`,
                title: "Sell Average",
                icon: null,
                bgColor: "#FFA940",
              },
              {
                value: totals ? totals.avg_lead_per_inv : "",
                footer: `Average Leads Per car`,
                title: "Leads Per Vehicle",
                icon: null,
                bgColor: "#F44D50",
              },
            ]}
          />
        </Grid> */}
      </AppGridContainer>

      {/* <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Cars</p>
                        <CardTitle tag="p">{totals? totals.no_of_cars: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-refresh" />
                    Total No of Cars Live on Website
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Value</p>
                        <CardTitle tag="p">$ {totals? totals.value_of_cars: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                     <i className="fa fa-refresh" />
                    Total value of Inventory
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-vector text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Sell Average</p>
                        <CardTitle tag="p">{totals? totals.avg_days_to_sell_inv: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                    Average Days to close a sale
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-clock-o text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Leads Per Vehicle</p>
                        <CardTitle tag="p">{totals? totals.avg_lead_per_inv: ''}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  Average Leads Per car
                  </div>
                 
                </CardFooter>
              </Card>
            </Col> */}
      {/* </Row> */}
    </>
  );
};

export default CardStats;
