import React from 'react'
import { Bar } from 'react-chartjs-2'

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
  } from "reactstrap";
const CarViews = (props) => {
    const data = {
        labels: props.carLabel,
        datasets: [
          {
            label: '# of Website Views',
            data: props.carNos,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 159, 52, 0.2)',
              'rgba(255, 123, 64, 0.2)',
              'rgba(85, 25, 50, 0.2)',
              'rgba(200, 95, 36, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 159, 52, 0.2)',
              'rgba(255, 123, 64, 0.2)',
              'rgba(85, 25, 50, 0.2)',
              'rgba(200, 95, 36, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 159, 52, 1)',
              'rgba(255, 123, 64, 1)',
              'rgba(85, 25, 50, 1)',
              'rgba(200, 95, 36, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 159, 52, 1)',
              'rgba(255, 123, 64, 1)',
              'rgba(85, 25, 50, 1)',
              'rgba(200, 95, 36, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }
      
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }
      
    return (
  <>
  <Card>
        <CardHeader>
          <Row>
            <Col sm="7">
              <div className="numbers pull-left">Highest Page Views</div>
              
            </Col>
            <Col sm="5">
              <div className="pull-right">
                <Button color="warning" pill
                  disabled={props.carStatus === 'all'}
                onClick={() => props.setCarStatus('all')}>
                  All Cars
                </Button>
                <Button color="success" pill
                 disabled={props.carStatus === 'live'}
                onClick={() => props.setCarStatus('live')}>
                  Live Cars
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h6 className="big-title">
          {/* Total Sales (Value) */}
          </h6>
          <Bar data={data} options={options} />
        </CardBody>
       
      </Card>

  </> 
  )
}

export default CarViews