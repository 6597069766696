import { put, call, takeLatest } from 'redux-saga/effects'
import { GET_DEALERS, DEALER_WEBSITE_REQUESTED, ALL_ERRORS } from "../constants/actions";
import * as api from '../constants/api';

function* loadDealer(action) {
    try {
    const json = yield call(api.GET_DEALERS, action.payload)
    // console.log(json)
          // .then(response => response.json(), );  
          
    yield put({ type: GET_DEALERS, payload: json.data[0], });
  }
  catch (e) {
    yield put({type: ALL_ERRORS, error: e.message});
    }
}
  
export function* websiteLoad() {

   yield takeLatest(DEALER_WEBSITE_REQUESTED, loadDealer);

}


export default websiteLoad;