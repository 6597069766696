import React, { useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Container,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

import {
  CREATE_TRADE_IN,
  SG_FETCH_TRADE_INS,
  UPDATE_TRADE_INS,
} from "constants/actions";

import BlackbookCreateList from "./BlackbookList";
import { Button as MUIButton } from "@mui/material";

import {
  BlackBookDetailedLog,
  BlackBookDetails,
  AddDeduct_List,
} from "Inventory/InventoryDetail/BlackBookInterDetails";
import { Box } from "@mui/material";

const VinCreate = ({ setAddTaskOpen, setLabel, ...props }) => {
  const dispatch = useDispatch();
  console.log({ props });
  let history = useHistory();
  const [tradeinId, setTradeinId] = useState(props.tradeIn);
  const [tradein, setTradein] = useState([]);
  const [blackbook, setBlackbook] = useState([]);
  const [blackBookList, setBlackBookList] = useState([]);
  const [isBlackBookOpen, setIsBlackBookOpen] = useState(false);
  const [selectedBlackBook, setSelectedBlackBook] = useState([]);

  const [errorMessge, setErrorMessage] = useState([]);

  const {
    register,
    handleSubmit,
    // watch,
    errors,
    setValue,
    control,
  } = useForm();

  React.useEffect(() => {
    // console.log(user)
    setTradeinId(props.tradeIn);
  }, [props]);

  React.useEffect(() => {
    // console.log(tradeinId)
    if (tradeinId) {
      dispatch({ type: SG_FETCH_TRADE_INS, payload: { id: tradeinId } });
    }
    setSelectedBlackBook([]);
  }, [dispatch, tradeinId]);

  const { load_tradeins, trade_in_created } = useSelector(
    (state) => ({
      load_tradeins: state.tradein[parseInt(tradeinId)],
      trade_in_created: state.tradein.created,
    }),
    shallowEqual
  );

  const onSubmit = (data) => {
    // console.log(data)
    dispatch({
      type: CREATE_TRADE_IN,
      payload: {
        vin: data.vin,
        kilometers: data.kilometers,
      },
    });
  };

  React.useEffect(() => {
    setTradein(load_tradeins);
  }, [load_tradeins]);
  // console.log(load_tradeins)

  // React.useEffect(() => {

  //   if (trade_in_created?.id) {
  //     // setTradein(trade_in_created);
  //     // setTradeinId(trade_in_created.id);
  //     // props.setUpdatePage(trade_in_created);

  //   } else {

  //     setTradein(load_tradeins);
  //   }
  // }, [load_tradeins, trade_in_created]);

  // console.log(trade_in_created)
  // console.log(load_tradeins)

  React.useEffect(() => {
    if (trade_in_created) {
      if (trade_in_created.blackbook_response) {
        if (
          trade_in_created.blackbook_response.used_vehicles.used_vehicle_list
            .length > 1
        ) {
          setBlackBookList(
            trade_in_created.blackbook_response.used_vehicles.used_vehicle_list
          );
          setIsBlackBookOpen(true);
        } else {
          setBlackbook(
            trade_in_created.blackbook_response.used_vehicles
              .used_vehicle_list[0]
          );
          // console.log(trade_in_created)
          dispatch({
            type: UPDATE_TRADE_INS,
            payload: {
              id: trade_in_created.id,
              make: trade_in_created.blackbook_response.used_vehicles
                .used_vehicle_list[0]?.make,
              model:
                trade_in_created.blackbook_response.used_vehicles
                  .used_vehicle_list[0]?.model,
              year: trade_in_created.blackbook_response.used_vehicles
                .used_vehicle_list[0]?.model_year,
              trim: `${trade_in_created.blackbook_response.used_vehicles.used_vehicle_list[0]?.style} 
              ${trade_in_created.blackbook_response.used_vehicles.used_vehicle_list[0]?.series}`,
              black_book_uvc:
                trade_in_created.blackbook_response.used_vehicles
                  .used_vehicle_list[0]?.uvc,
            },
          });
          setIsBlackBookOpen(false);
        }
      }

      props.ShowDetails(trade_in_created?.id);
    }
  }, [trade_in_created]);

  React.useEffect(() => {
    if (load_tradeins) {
      if (load_tradeins.blackbook_response) {
        if (
          load_tradeins.blackbook_response.used_vehicles.used_vehicle_list
            .length > 1
        ) {
          setBlackBookList(
            load_tradeins.blackbook_response.used_vehicles.used_vehicle_list
          );
          setIsBlackBookOpen(true);
        } else {
          setBlackbook(
            load_tradeins.blackbook_response.used_vehicles.used_vehicle_list[0]
          );
          // console.log(trade_in_created)
          // dispatch({
          //   type: UPDATE_TRADE_INS,
          //   payload: {
          //     id: tradeinId,
          //     make:
          //       load_tradeins.blackbook_response.used_vehicles
          //         .used_vehicle_list[0].make,
          //     model:
          //       load_tradeins.blackbook_response.used_vehicles
          //         .used_vehicle_list[0].model,
          //     year:
          //       load_tradeins.blackbook_response.used_vehicles
          //         .used_vehicle_list[0].model_year,
          //     trim: `${load_tradeins.blackbook_response.used_vehicles.used_vehicle_list[0].style} ${load_tradeins.blackbook_response.used_vehicles.used_vehicle_list[0].series}`,
          //     black_book_uvc:
          //       load_tradeins.blackbook_response.used_vehicles
          //         .used_vehicle_list[0].uvc,
          //   },
          // });
          setIsBlackBookOpen(false);
        }
      }
    }
  }, [load_tradeins]);

  const changeBlackBookTrim = (e) => {
    setBlackbook(e);
    dispatch({
      type: UPDATE_TRADE_INS,
      payload: {
        id: tradeinId,
        make: e.make,
        model: e.model,
        year: e.model_year,
        trim: `${e.style} ${e.series}`,
        black_book_uvc: e.uvc,
      },
    });
  };
  //
  const ExitDisplay = () => {
    props.resetColWidth(false);
    // props.setUpdatePage(Math.random());
  };
  React.useEffect(() => {
    if (tradein?.black_book_uvc) {
      // console.log(tradein.black_book_uvc)
      setSelectedBlackBook(
        blackBookList.filter((fl) => fl.uvc == tradein.black_book_uvc)[0]
      );
      //   console.log(blackBookList.filter(fl => fl.uvc == tradein.black_book_uvc))
    }
  }, [tradein, blackBookList]);

  setLabel && setLabel(`${tradeinId ? "Show " : "Get "}` + "Blackbook Value");

  // console.log(load_tradeins)
  return (
    <>
      <div className="content">
        <Box
          sx={{
            mx: 3,
          }}
        >
          {tradeinId ? (
            <>
              <Card className="card-tasks">
                <CardBody>
                  <CardTitle className="text-center" tag="h3"></CardTitle>
                  <div className="d-flex justify-content-between">
                    <CardTitle tag="h4">
                      {tradeinId ? "Show" : "Get"} Blackbook Value
                    </CardTitle>

                    <Button color="link" onClick={() => ExitDisplay()}>
                      <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Collapse isOpen={isBlackBookOpen}>
                <div className="p-3 bg-secondary my-2 rounded">
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <label>Black Book Trim</label>{" "}
                        </Col>
                        <Col>
                          <label>
                            {/* Selected: {decoded_vin[vindecodeId]? decoded_vin[vindecodeId].revised_bb_style_series: "None" } */}
                          </label>
                        </Col>
                      </Row>

                      <Select
                        name="multipleBlackbook"
                        getOptionLabel={(option) =>
                          `${option.style} ${option.series}`
                        }
                        getOptionValue={(option) => `${option.uvc}`}
                        options={blackBookList}
                        value={selectedBlackBook}
                        className="text-dark"
                        onChange={(e) => changeBlackBookTrim(e)}
                        // isClearable={true}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
              {load_tradeins?.blackbook_response?.message_list?.length > 0 && (
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">Message</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {load_tradeins?.blackbook_response?.message_list.map(
                        (trade) => {
                          return (
                            <Col>
                              <label>{trade?.type} </label>
                              <p>{trade?.description}</p>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )}
              <Card className="card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">Vehicle Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <label>VIN </label>
                      <p>{tradein ? tradein.vin : ""}</p>
                    </Col>
                    <Col>
                      <label>Kilometers </label>
                      <p>{tradein ? tradein.kilometers : ""}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Year </label>
                      <p>{tradein ? tradein.year : ""}</p>
                    </Col>

                    <Col>
                      <label>Make</label>
                      <p>{tradein ? tradein.make : ""}</p>
                    </Col>

                    <Col>
                      <label>Model</label>
                      <p>{tradein ? tradein.model : ""}</p>
                    </Col>

                    <Col>
                      <label>Trim</label>
                      <p>{tradein ? tradein.trim : ""}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">BlackBook Details</CardTitle>
                </CardHeader>
                <CardBody>
                  {blackbook ? (
                    <>
                      <BlackBookDetails
                        blackbook={blackbook}
                        tradein={tradein}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </>
          ) : (
            <>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <p>
                    VIN <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="vin"
                    maxLength={17}
                    placeholder="enter the VIN..."
                    type="text"
                    innerRef={register({
                      required: "Please enter VIN",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.vin && <span>{errors.vin.message}</span>}
                  </p>
                </FormGroup>

                <FormGroup>
                  <p>
                    Kilometers <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="kilometers"
                    placeholder="enter the kilometers..."
                    type="text"
                    innerRef={register({
                      required: "Please enter odometer mileage",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.kilometers && (
                      <span>{errors.kilometers.message}</span>
                    )}
                  </p>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <div className="p-2"></div>
                  <div className="p-2">
                    <MUIButton
                      color="preAuto"
                      variant="contained"
                      type="submit"
                    >
                      Process
                    </MUIButton>
                  </div>
                </div>
              </Form>

              <BlackbookCreateList setUpdatePage={props.setUpdatePage} />
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default VinCreate;
