import React from "react";
import { createBrowserHistory } from "history";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as serviceWorker from "./serviceWorkerRegistration";
import { Toast, ToastBody, ToastHeader, Spinner, Button } from "reactstrap";
import CssBaseline from "@mui/material/CssBaseline"; // Move this import to the top
import "app.css";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "&:focus": {
            outline: "none",
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "&:focus": {
            outline: "none",
          },
        }),
      },
    },
  },
  root: {
    fontFamily: `'Be Vietnam', serif, 'Montserrat', 'Helvetica Neue', Arial, sans-serif`, // Replace 'YourFontFamily' with your desired font family
  },
  typography: {
    fontFamily: [
      "Be Vietnam",
      "serif",
      "Montserrat",
      "Helvetica Neue",
      "Arial",
      "sans - serif",
    ].join(","),
  },
  palette: {
    preAuto: createColor("#1d4aa7"),
    customWhite: createColor("#ffffff"),
  },
});

// import LogRocket from 'logrocket';
// LogRocket.init('tt25ss/preautoco');

import { SW_INIT, SW_UPDATE } from "constants/actions";
import { IntlProvider } from "react-intl";

const hist = createBrowserHistory();
const App = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState({});
  const [redirectLogin, setRedirectLogin] = React.useState(false);

  const updateServiceWorker = () => {
    // const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(false);
    // window.location.reload();
    window.location.assign("/auth/login");
    // history.push("/auth/login");
    setRedirectLogin(true);
  };

  React.useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration.waiting);
        setNewVersionAvailable(true);
      },
    });
  }, []);
  // React.useEffect(() => {
  //   if (newVersionAvailable) {
  //     dispatch({
  //       type: SW_UPDATE,
  //       payload: {
  //         newVersionAvailable,
  //         waitingWorker,
  //       },
  //     });
  //   }
  // }, [newVersionAvailable]);
  // console.log(redirectLogin);
  if (redirectLogin) {
    return <Redirect to="/auth/login" />;
  } else if (newVersionAvailable) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <IntlProvider>
            <CssBaseline />
            <div className="centered">
              {" "}
              <Toast>
                <ToastHeader icon={<Spinner size="sm" />}>
                  New Version Available
                </ToastHeader>
                <ToastBody className="centered">
                  <Button
                    size="sm"
                    className="reload-text"
                    onClick={updateServiceWorker}
                  >
                    reload
                  </Button>
                </ToastBody>
              </Toast>{" "}
            </div>
          </IntlProvider>
        </ThemeProvider>
      </>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <IntlProvider>
          <CssBaseline />
          <Router history={hist}>
            <Switch>
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route
                path="/payment"
                render={(props) => <AuthLayout {...props} />}
              />

              <Redirect to="/auth/login" />
            </Switch>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    );
  }
};

export default App;
