import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  GET_EMPLOYEES,
  LOAD_ALL_EMPLOYEES,
  LOAD_GET_CASHFORCARS,
  
  LOAD_CMS_PRIVACY,
  LOAD_HOMEPAGE_TYPE,
  LOAD_HOMEPAGE,
  LOAD_CMS_TERMS_TYPE,
  LOAD_CMS_TERMS,
  LOAD_CMS_ABOUT,
  LOAD_CMS_PRIVACY_TYPE,
  LOAD_CMS_ABOUT_TYPE,
  LOAD_CMS_CONTACT_PAGE_TYPE,
  GET_EMPLOYEES_PENDING,
  DELETE_EMPLOYEE,
  ADD_EMPLOYEE,
  CASH_FOR_CARS,
  CMS_CREATE_CONTACT_PAGE,
  CMS_PRIVACY_POLICY,
  CMS_HOMEPAGE,
  STRIPE_CREATE_INVOICE,
  STRIPE_CREATE_CUSTOMER,
  STRIPE_CREATE_CUSTOMER_TYPE,
  STRIPE_CREATE_INVOICE_TYPE,
  STRIPE_FETCH_INVOICE,
  STRIPE_FETCH_INVOICE_TYPE,
  CMS_HOMEPAGE_TYPE,
  CMS_TERMS,
  CMS_TERMS_TYPE,
  CMS_PRIVACY_POLICY_TYPE,
  CMS_ABOUT_TYPE,
  CMS_CREATE_CONTACT_PAGE_TYPE,
  UPDATE_CMS_CONTACT_PAGE_TYPE,
  CASH_FOR_CARS_CMS,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_CASH_FOR_CARS,
  UPDATE_CMS_TERMS_PAGE_TYPE,
  CLEAR_EMPLOYEE,
  FETCH_EMPLOYEE,
  FETCH_CASH_FOR_CARS_CMS,
  SELECTED_EMPLOYEE,
  SG_SELECT_EMPLOYEE,
  FETCH_EMPLOYEE_PENDING,
  SG_FETCH_EMPLOYEE,
  GET_CASHFORCARS,
  LOAD_CMS_CONTACT_PAGE,
  FETCH_CASH_FOR_CARS_CMS_VALUE,
  UPLOAD_EMPLOYEE_PIC,
  ADD_EMPLOYEE_PIC,
  EDIT_EMPLOYEE,
  EDIT_CASH_FOR_CARS,
  UPDATE_CMS_CONTACT_PAGE,
  CMS_ABOUT,
  UPDATE_CMS_PRIVACY_PAGE,
  UPDATE_CMS_HOME_PAGE_TYPE,
  UPDATE_CMS_HOME_PAGE,
  UPDATE_CMS_TERMS_PAGE,
  UPDATE_CMS_ABOUT_PAGE_TYPE,
  UPDATE_CMS_ABOUT_PAGE,
  UPDATE_CMS_PRIVACY_PAGE_TYPE,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "constants/api";
import { GET_STRIPE_CUSTOMER, GET_STRIPE_PRODUCTS } from "constants/actions";
import { LOAD_GET_STRIPE_CUSTOMER } from "constants/actions";
import { LOAD_GET_STRIPE_PRODUCTS } from "constants/actions";

function* loadCashforcars(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.GET_CASHFORCARS, action.payload);
    yield put({ type: GET_CASHFORCARS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* cashforcarsLoad() {
  yield takeLatest(LOAD_GET_CASHFORCARS, loadCashforcars);
}

function* loadstripe(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.GET_STRIPE_CUSTOMER, action.payload);
    yield put({ type: GET_STRIPE_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* stripeLoad() {
  yield takeLatest(LOAD_GET_STRIPE_CUSTOMER, loadstripe);
}

function* loadstripeproducts(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.GET_STRIPE_PRODUCTS, action.payload);
    yield put({ type: GET_STRIPE_PRODUCTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* stripeLoadProducts() {
  yield takeLatest(LOAD_GET_STRIPE_PRODUCTS, loadstripeproducts);
}

function* loadCmsPrivacy(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.LOAD_CMS_PRIVACY, action.payload);
    yield put({ type: LOAD_CMS_PRIVACY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* PrivacyLoad() {
  yield takeLatest(LOAD_CMS_PRIVACY_TYPE, loadCmsPrivacy);
}

function* loadCmsHomepage(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.LOAD_HOMEPAGE, action.payload);
    yield put({ type: LOAD_HOMEPAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* HomepageLoad() {
  yield takeLatest(LOAD_HOMEPAGE_TYPE, loadCmsHomepage);
}

function* loadCmsTerms(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.LOAD_CMS_TERMS, action.payload);
    yield put({ type: LOAD_CMS_TERMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* TermsLoad() {
  yield takeLatest(LOAD_CMS_TERMS_TYPE, loadCmsTerms);
}

function* loadCmsAbout(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.LOAD_CMS_ABOUT, action.payload);
    yield put({ type: LOAD_CMS_ABOUT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* AboutLoad() {
  yield takeLatest(LOAD_CMS_ABOUT_TYPE, loadCmsAbout);
}

function* loadContactPageCMS(action) {
  // yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.LOAD_CMS_CONTACT_PAGE, action.payload);
    yield put({ type: LOAD_CMS_CONTACT_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* contactCmsLoad() {
  yield takeLatest(LOAD_CMS_CONTACT_PAGE_TYPE, loadContactPageCMS);
}

function* fetchCashForCarsCms(action) {
  // yield put({ type: FETCH_EMPLOYEE_PENDING });
  try {
    const json = yield call(api.FETCH_CASH_FOR_CARS_CMS, action.payload);
    yield put({ type: FETCH_CASH_FOR_CARS_CMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* cmsCashForCarsFetch() {
  yield takeLatest(FETCH_CASH_FOR_CARS_CMS_VALUE, fetchCashForCarsCms);
}

function* createCashForCarsCMS(action) {
  try {
    const json = yield call(api.CASH_FOR_CARS, action.payload);
    yield put({ type: CASH_FOR_CARS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* cashForCarsCreate() {
  yield takeLatest(CASH_FOR_CARS_CMS, createCashForCarsCMS);
}

function* createContactPageCMS(action) {
  try {
    const json = yield call(api.CMS_CREATE_CONTACT_PAGE, action.payload);
    yield put({ type: CMS_CREATE_CONTACT_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* contactPageCreate() {
  yield takeLatest(CMS_CREATE_CONTACT_PAGE_TYPE, createContactPageCMS);
}

function* createPrivacyCMS(action) {
  try {
    const json = yield call(api.CMS_PRIVACY_POLICY, action.payload);
    yield put({ type: CMS_PRIVACY_POLICY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* privacyPageCreate() {
  yield takeLatest(CMS_PRIVACY_POLICY_TYPE, createPrivacyCMS);
}

function* createHomepageCMS(action) {
  try {
    const json = yield call(api.CMS_HOMEPAGE, action.payload);
    yield put({ type: CMS_HOMEPAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* homePageCreate() {
  yield takeLatest(CMS_HOMEPAGE_TYPE, createHomepageCMS);
}

function* createStripeInvoice(action) {
  try {
    const json = yield call(api.STRIPE_CREATE_INVOICE, action.payload);
    yield put({ type: STRIPE_CREATE_INVOICE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* invoiceCreate() {
  yield takeLatest(STRIPE_CREATE_INVOICE_TYPE, createStripeInvoice);
}

function* createStripeCustomer(action) {
  try {
    const json = yield call(api.STRIPE_CREATE_CUSTOMER, action.payload);
    yield put({ type: STRIPE_CREATE_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* customerStripeCreate() {
  yield takeLatest(STRIPE_CREATE_CUSTOMER_TYPE, createStripeCustomer);
}

function* fetchStripeInvoice(action) {
  try {
    const json = yield call(api.STRIPE_FETCH_INVOICE, action.payload);
    yield put({ type: STRIPE_FETCH_INVOICE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* invoiceFetch() {
  yield takeLatest(STRIPE_FETCH_INVOICE_TYPE, fetchStripeInvoice);
}

function* createTermsCMS(action) {
  try {
    const json = yield call(api.CMS_TERMS, action.payload);
    yield put({ type: CMS_TERMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* termsPageCreate() {
  yield takeLatest(CMS_TERMS_TYPE, createTermsCMS);
}

function* createAboutCMS(action) {
  try {
    const json = yield call(api.CMS_ABOUT, action.payload);
    yield put({ type: CMS_ABOUT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* aboutPageCreate() {
  yield takeLatest(CMS_ABOUT_TYPE, createAboutCMS);
}

function* editCashforcars(action) {
  try {
    const json = yield call(api.EDIT_CASH_FOR_CARS, action.payload);
    yield put({ type: EDIT_CASH_FOR_CARS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* cashforcarsEdit() {
  yield takeLatest(UPDATE_CASH_FOR_CARS, editCashforcars);
}

function* editcontactpagecms(action) {
  try {
    const json = yield call(api.UPDATE_CMS_CONTACT_PAGE, action.payload);
    yield put({ type: UPDATE_CMS_CONTACT_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* contactPageCmsEdit() {
  yield takeLatest(UPDATE_CMS_CONTACT_PAGE_TYPE, editcontactpagecms);
}


function* editPrivacyPageCms(action) {
  try {
    const json = yield call(api.UPDATE_CMS_PRIVACY_PAGE, action.payload);
    yield put({ type: UPDATE_CMS_PRIVACY_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* privacyPageCmsEdit() {
  yield takeLatest(UPDATE_CMS_PRIVACY_PAGE_TYPE, editPrivacyPageCms);
}

function* editHomePageCms(action) {
  try {
    const json = yield call(api.UPDATE_CMS_HOME_PAGE, action.payload);
    yield put({ type: UPDATE_CMS_HOME_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* homePageCmsEdit() {
  yield takeLatest(UPDATE_CMS_HOME_PAGE_TYPE, editHomePageCms);
}

function* editTermsPageCms(action) {
  try {
    const json = yield call(api.UPDATE_CMS_TERMS_PAGE, action.payload);
    yield put({ type: UPDATE_CMS_TERMS_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* termsPageCmsEdit() {
  yield takeLatest(UPDATE_CMS_TERMS_PAGE_TYPE, editTermsPageCms);
}

function* editAboutPageCms(action) {
  try {
    const json = yield call(api.UPDATE_CMS_ABOUT_PAGE, action.payload);
    yield put({ type: UPDATE_CMS_ABOUT_PAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* aboutPageCmsEdit() {
  yield takeLatest(UPDATE_CMS_ABOUT_PAGE_TYPE, editAboutPageCms);
}



export default function* index() {
  yield all([
    cashForCarsCreate(),
    cmsCashForCarsFetch(),
    cashforcarsLoad(),
    cashforcarsEdit(),
    contactPageCreate(),
    contactCmsLoad(),
    contactPageCmsEdit(),
    privacyPageCreate(),
    PrivacyLoad(),
    privacyPageCmsEdit(),
    aboutPageCreate(),
    AboutLoad(),
    aboutPageCmsEdit(),
    termsPageCreate(),
    TermsLoad(),
    termsPageCmsEdit(),
    homePageCreate(),
    HomepageLoad(),
    homePageCmsEdit(),
    stripeLoad(),
    stripeLoadProducts(),
    invoiceCreate(),
    invoiceFetch(),
    customerStripeCreate(),
  ]);
}
// export default inventoryLoad;
