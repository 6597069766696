import React from "react";

import { Button, ButtonGroup } from "reactstrap";

const ButtonGrouped = (props) => {

  return (
    <>
      <ButtonGroup>
        {props.commentOptions.map((prop, k) => {
          return (
            <Button
              key={k}
              size="sm"
              color={props.status === prop.id ? "success" : "secondary"}
              onClick={() => props.BtnClick(prop.id)}
              disabled={props.status === prop.id ? true : false}
            >
              {prop.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default ButtonGrouped;
