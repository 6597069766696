import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import _ from "lodash";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import {
  LOAD_INVENTORY_NOTES,
  UPDATE_INVENTORY_NOTES,
} from "constants/actions";

import EditorConvertToHTML from "Inventory/InventoryDetail/EditorConvertToHTML";

import InventoryNotes from "Inventory/InventoryDetail/InventoryNotes";
import StandardEquipment from "Inventory/InventoryDetail/StandardEquipment";
import ConsumerInformation from "Inventory/InventoryDetail/ConsumerInformation";
import GenericEquipment from "Inventory/InventoryDetail/GenericEquipment";
import TechnicalSpecifications from "Inventory/InventoryDetail/TechnicalSpecifications";

import ImageCard from "Inventory/Images/ImageCard"


const FactoryImages = (props) => {
  return (
    <Row>
      {props.images.map((p, k) => (
        p.small ?<Col md="4" sm="12" key={k}>
          <ImageCard
            imageUrl={p.small}
            id={p.id}

          />

        </Col>:null
      )

      )}

    </Row>

  );
};


const Details = ({ id,
  decodedStyle,
  standardEquipment,
  technicalSpecification,
  genericEquipment,
  blackbook,
  consumerInformation,
  factoryImages,
  ShowAllSpecs,
  descodeSummary }) => {

  const dispatch = useDispatch();

  // const [textDescription, setTextDescription] = useState(ShowAllSpecs);
  const [standEquipInfo, setStandEquipInfo] = useState(ShowAllSpecs);
  const [genericEquipInfo, setGenericEquipInfo] = useState(ShowAllSpecs);
  const [techSpecInfo, setTechSpecInfo] = useState(ShowAllSpecs);
  const [consumerInfo, setConsumerInfo] = useState(ShowAllSpecs);
  const [changedText, setChangedText] = useState(false);
  const [showfactoryimage, setShowfactoryimage] = useState(ShowAllSpecs);

  const [inventoryNotes, setInventoryNotes] = useState(ShowAllSpecs);
  const [runOnce, setRunOnce] = useState(0)

  const [editShortDesc, setEditShortDesc] = useState(descodeSummary ? descodeSummary : null);

  const { get_inventory_notes } = useSelector(
    (state) => ({
      get_inventory_notes: state.inventorynotes[parseInt(id)],

    }),
    shallowEqual
  );

  useEffect(() => {
    setInventoryNotes(get_inventory_notes)
  }, [get_inventory_notes]);

  let mySet = new Set();
  let LargeString = '';

  // console.log(descode_summary_html)
  // console.log(get_inventory_notes)

  useEffect(() => {
    if (!changedText) {
      setChangedText(true)
    }
    if (descodeSummary) {
      setEditShortDesc(descodeSummary);
    } else {
      if (standardEquipment && id) {
        standardEquipment.map((p) => mySet.add(p.name));
        for (let item of mySet.values()) {
          LargeString += `<h3>${item}</h3> <br />`;
          LargeString += `<ul>`
          standardEquipment
            .filter((fl) => fl.name === item)[0].value
            .map((lft) => {
              LargeString += `<li>${lft.name}</li> `;
              return lft.name;
            })
          LargeString += `</ul>`
          LargeString += `<br />`;
          setEditShortDesc(LargeString);
        }
        if (runOnce < 1) {
          if (
            !inventoryNotes?.descode_summary_html ||
            inventoryNotes?.descode_summary_html?.length < 5) {
            // console.log("inventory notes updated")
            dispatch({
              type: UPDATE_INVENTORY_NOTES,
              payload: { id, descode_summary_html: LargeString.substring(0, 8000) },
            });
            setRunOnce(1)
          }
        }
        if (runOnce < 2) {
          if (
            !inventoryNotes?.overall ||
            inventoryNotes?.overall?.length < 5) {
            if (typeof decodedStyle === 'string' || decodedStyle instanceof String) {
              dispatch({
                type: UPDATE_INVENTORY_NOTES,
                payload: { id, overall: decodedStyle },
              });
              setRunOnce(2)
            }
          }
        }
      }
    }

  }, [standardEquipment, id, inventoryNotes, runOnce]);


  const BtnCallBackShortDesc = (data) => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, descode_summary_html: data.substring(0, 8000) },
    });
  };


  return (
    <>
      {id ?


        <Row className="pt-1">
          <Col md="12">
            {editShortDesc ? (
              <EditorConvertToHTML
                heading="Short Desc for syndication"
                editContent={editShortDesc}
                BtnCallBack={BtnCallBackShortDesc}
              />
            ) : (
              <p>Loading ...</p>
            )}
          </Col>
        </Row> : ''
      }

      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Standard Equipment</CardTitle>
                </Col>

                <Col className="ml-auto" md="2">
                  <Button
                    className="btn"
                    color={standardEquipment.length > 0 ? "success" : "warning"}
                    size="sm"
                    onClick={() => setStandEquipInfo(!standEquipInfo)}
                  >
                    {standEquipInfo ? "Hide" : "Show"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {standEquipInfo ? (
                <StandardEquipment equipment={standardEquipment} />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Generic Equipment</CardTitle>
                </Col>

                <Col className="ml-auto" md="2">
                  <Button
                    className="btn"
                    color={genericEquipment.length > 0 ? "success" : "warning"}
                    size="sm"
                    onClick={() => setGenericEquipInfo(!genericEquipInfo)}
                  >
                    {genericEquipInfo ? "Hide" : "Show"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {genericEquipInfo ? (
                <GenericEquipment equipment={genericEquipment} />
              ) : (
                " "
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Technical Specification</CardTitle>
                </Col>

                <Col className="ml-auto" md="2">
                  <Button
                    className="btn"
                    color={technicalSpecification.length > 0 ? "success" : "warning"}
                    size="sm"
                    onClick={() => setTechSpecInfo(!techSpecInfo)}
                  >
                    {techSpecInfo ? "Hide" : "Show"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {techSpecInfo ? (
                <TechnicalSpecifications equipment={technicalSpecification} />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Consumer Information</CardTitle>
                </Col>

                <Col className="ml-auto" md="2">
                  <Button
                    className="btn"
                    color={consumerInformation.length > 0 ? "success" : "warning"}
                    size="sm"
                    onClick={() => setConsumerInfo(!consumerInfo)}
                  >
                    {consumerInfo ? "Hide" : "Show"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {consumerInfo ? (
                <ConsumerInformation information={consumerInformation} />
              ) : (
                " "
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
          <Col md="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">
                      Black Book - <small>{car.blackbook_category}</small>
                    </CardTitle>
                  </Col>

                  <Col className="ml-auto" md="2">
                    <Button
                      color={blackbookInfo ? "success" : "default"}
                      outline
                      size="sm"
                      onClick={() => setBlackbookInfo(!blackbookInfo)}
                    >
                      {blackbookInfo ? "Hide" : "Show"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {blackbookInfo ? (
                  <BlackBookHeader
                    blackbook={blackbook}
                    category={car.blackbook_category}
                  />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row> */}

      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Factory Images </CardTitle>
                </Col>

                <Col className="ml-auto" md="2">
                  <Button
                    color={factoryImages.length > 0 ? "success" : "warning"}
                    size="sm"
                    onClick={() => setShowfactoryimage(!showfactoryimage)}
                  >
                    {showfactoryimage ? "Hide" : "Show"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {showfactoryimage ? (
                <FactoryImages images={factoryImages} />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default Details;