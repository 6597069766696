import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import _ from "lodash";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Collapse,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import EmployeeList from "Employees/List";

import Codes from "Dealer/Codes";
import AddLogo from "Company/AddLogo";

import LeadStage from "LeadStage/index";
import LeadCampaign from "LeadCampaign/index";
import LeadType from "LeadType/index";

import Information from "CompanyInformation/index";
import Product from "Product/index"
import Billing from "Billing";

import { SG_FETCH_COMPANY } from "constants/actions";
import defaultImage from "assets/img/image_placeholder.jpg";

import "./style.css"
const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [dealer, setDealer] = React.useState([]);
  const [relatedDealer, setRelatedDealer] = React.useState([]);
  const [fadeIn, setFadeIn] = React.useState(false);
  const [stateImg1, setStateImg1] = React.useState("");
  const [stateImg2, setStateImg2] = React.useState("");

  React.useEffect(() => {
    // console.log('props.newTaskAdded dispatch', props.newTaskAdded)
    dispatch({ type: SG_FETCH_COMPANY, payload: id ? id : logged_user?.dealer_id });
  }, [dispatch]);
  const {
    logged_user,
  } = useSelector(
    (state) => ({
      logged_user: state.auth,

    }),
    shallowEqual
  );

  const { get_auth, get_company } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_company: state.company[parseInt(id ? id : logged_user?.dealer_id)],
    }),
    shallowEqual
  );

  React.useEffect(() => {
    // if (_.get(get_auth, 'dealer_id')) {

    // }
    if (get_company) {
      setDealer(get_company);
    }
  }, [get_company]);

  //657b6891-ab19-46c0-929e-87979b841384.png
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    <h5 className="title">Dealer Profile</h5>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-round"
                      color="info"
                      size="sm"
                      to={`/admin/company-create/${id?id:logged_user?.dealer_id}`}
                      tag={Link}
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-round"
                      color="primary"
                      size="sm"
                      onClick={() => setFadeIn(!fadeIn)}
                    >
                      Add Logo
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="9">
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label>Dealer</label>
                            <p>{dealer ? dealer.name : ""}</p>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <p>{dealer ? dealer.address : ""}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>City</label>
                            <p>{dealer ? dealer.city : ""}</p>
                          </FormGroup>
                        </Col>

                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label>Postal Code</label>
                            <p>{dealer ? dealer.postalcode : ""}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label>Country</label>

                            <Input
                              disabled
                              defaultValue="Canada"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md="3">
                    <div className={"thumbnail"}>
                      <img
                        // src={dealer?.logo ? dealer?.logo : defaultImage}
                        src={stateImg2 ? stateImg2 : dealer.logo ? dealer.logo : defaultImage}
                        alt="..."
                      />
                    </div>
                    <p>
                      <small>
                        For use in mailer and other communication
                      </small>

                    </p>
                    <p>
                      <small>
                        Image Url {dealer.logo ? dealer.logo : defaultImage}
                      </small>
                    </p>

                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        <Collapse isOpen={fadeIn}>
          <AddLogo company={id?id:logged_user?.dealer_id} setStateImg1={setStateImg1} setStateImg2={setStateImg2} stateImg1={stateImg1} />
        </Collapse>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Website: {dealer ? dealer.website : ""}{" "}
                </CardTitle>
              </CardHeader>
              <CardBody>

                <Row>
                  <Col className="pr-1" md="4">

                    <label>Phone</label>
                    <p>{dealer ? dealer.phone : ""}</p>

                  </Col>
                  <Col className="px-1" md="4">

                    <label>Toll Free</label>
                    <p>{dealer ? dealer.tollfree : ""}</p>

                  </Col>
                  <Col className="pl-1" md="4">

                    <label>Email</label>
                    <p>{dealer ? dealer.email : ""}</p>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Dealer Code (For Registeration)</label>
                    <p>{dealer ? dealer.company_external_code : ""}</p>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>{dealer ? dealer.data_name : ""}</label>
                    <p>{dealer ? dealer.data_url : ""}</p>
                    <hr />
                    <p>
                      To share Gsheets please share it with
                      <Badge color="primary">
                        preautoco@preautoco-spreadshet.iam.gserviceaccount.com
                      </Badge>

                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Facebook Feed URL</label>
                    <p>{dealer ? dealer.facebook_feed_url : ""}</p>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Google Feed URL</label>
                    <p>{dealer ? dealer.google_feed_url : ""}</p>

                  </Col>
                </Row>
                {/* / */}
                {/*  */}
              </CardBody>
              {/* <CardFooter>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            >
                            Update
                        </Button>
                    </div>
                  
                </CardFooter> */}
            </Card>
          </Col>
        </Row>

        <EmployeeList params={`company=${id ? id : logged_user.dealer_id}`} dealerId={id ? id : logged_user.dealer_id} showAdd={true} />

        {/* <Codes dealer={dealer} /> */}

        <LeadStage params={`company=${id ? id : logged_user.dealer_id}`} id={id ? id : logged_user.dealer_id} showDefault="true" />
        <LeadCampaign params={`company=${id ? id : logged_user.dealer_id}`} id={id ? id : logged_user.dealer_id} showDefault="true" />
        <LeadType params={`company=${id ? id : logged_user.dealer_id}`} id={id ? id : logged_user.dealer_id} showDefault="true" />

        <Information params={`company=${id ? id : logged_user.dealer_id}`} id={id ? id : logged_user.dealer_id} />
        {/* <Billing /> */}
        <Codes dealer={dealer} />
        {Number(logged_user?.role_id) !== 10 ?

          <Product company_id={id ? id : logged_user.dealer_id} />
          :
          null
        }

      </div>
    </>
  );
};

export default Detail;
