import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    REGISTER_DEALER_SUCCESS,
    REGISTER_DEALER_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CHANGE_PASSWORD,
    CONFIRM_RESET_PASSWORD_SUCCESS,
    CONFIRM_RESET_PASSWORD_FAIL,
    EMPLOYEE_AUTH_LOADED,
    DEALER_AUTH_LOADED,
    CLEAR_PASSWORD_DATA,
} from "constants/actions";;

const initialState = {
    token: localStorage.getItem("token"),
    key: localStorage.getItem("token"),
    isAuthenticated: localStorage.getItem("isAuthenticated"),
    user: localStorage.getItem("user"),
    role_id: localStorage.getItem("role_id"),
    dealer_id: localStorage.getItem("dealer_id"),
    employee_id: localStorage.getItem("employee_id"),
    expires_in: localStorage.getItem("expires_in"),
    is_staff: localStorage.getItem("is_staff"),
    isLoading: false,
    employee: [],
    dealer: [],
    registered:[],
    registeredDealer:[],
    registeredDealerError:{}
};

const removeLocalStorage = () =>{
    localStorage.removeItem("token");
    // expires_in
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("role_id");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("dealer_id")
    localStorage.removeItem("employee_id")
    localStorage.removeItem("expires_in")
    localStorage.removeItem("is_staff")
    
}
export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            // console.log(`User Loaded reducer ${JSON.stringify(action.payload)} now check state`)
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload,
                employee_id: action.payload.employee_id,
                dealer_id: action.payload.company_id,
                is_staff: action.payload.is_staff,
                role_id: action.payload.role_id,
            };
        case EMPLOYEE_AUTH_LOADED:
                return { ...state,
                employee: action.payload }
        case DEALER_AUTH_LOADED:
                return { ...state,
                dealer: action.payload }
        case LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("isAuthenticated", action.payload.isAuthenticated)
            localStorage.setItem("expires_in", action.payload.expires_in);
            localStorage.setItem("role_id", action.payload.role_id);
            localStorage.setItem("dealer_id", action.payload.dealer_id);
            localStorage.setItem("employee_id", action.payload.employee_id);
            localStorage.setItem("is_staff", action.payload.is_staff);           
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                is_staff : action.payload.is_staff,
                isLoading: false
            };
        case REGISTER_SUCCESS:
            
            return { ...state,
                registered: action.payload }
        case REGISTER_DEALER_SUCCESS:
            return { ...state,registeredDealer: action.payload }
        case REGISTER_DEALER_FAIL:
            return { ...state,registeredDealerError: action.payload }
        case AUTH_ERROR:
            removeLocalStorage()
            return {
                ...state,
                token: null,
                key: null,
                user: null,
                dealer_id: null,
                employee_id: null,
                isAuthenticated: false,
                isLoading: false,
                loginerror: null,
                is_staff: null,
                employee: [],
                dealer: [],
            };
        case LOGIN_FAIL:
            removeLocalStorage()
            return {
                ...state,
                token: null,
                user: null,
                key: null,
                dealer_id: null,
                employee_id: null,
                is_staff: null,
                isAuthenticated: false,
                isLoading: false,
                loginError: true,
                loginerror: action.payload,
                employee: [],
                dealer: [],
            };
        case LOGOUT_SUCCESS:
            removeLocalStorage()
            return {
                ...state,
                token: null,
                key: null,
                user: null,
                dealer_id: null,
                employee_id: null,
                is_staff: null,
                isAuthenticated: false,
                isLoading: false,
                employee: [],
                dealer: [],
            };
        case REGISTER_FAIL:
            removeLocalStorage()          
            return {
                ...state,
                token: null,
                key: null,
                user: null,
                dealer_id: null,
                is_staff: null,
                employee_id: null,
                isAuthenticated: false,
                isLoading: false,
                registererror: action.payload,
                employee: [],
                dealer: [],
            };
        case RESET_PASSWORD_SUCCESS:
            removeLocalStorage()
            return {
                ...state,
                reset_password_ok: true,
            };
        case RESET_PASSWORD_FAIL:
            removeLocalStorage()  
            return {
                ...state,
                token: null,
                key: null,
                reset_password_ok: false,
                reset_password_error: action.payload
            };
        case CONFIRM_RESET_PASSWORD_SUCCESS:
            removeLocalStorage()
            return {
                ...state,
                confirm_password_ok: true,
            };
        case CHANGE_PASSWORD:
                removeLocalStorage()
                return {
                    ...state,
                    change_password_ok: true,
                };
        case CONFIRM_RESET_PASSWORD_FAIL:
            removeLocalStorage() 
            return {
                ...state,
                token: null,
                key: null,
                confirm_password_ok: false,
                confirm_password_error: action.payload
            };
        case CLEAR_PASSWORD_DATA:
            // removeLocalStorage() 
            return {
                ...state,
                token: null,
                key: null,
                reset_password_ok: null,
                is_staff: null,
                reset_password_error: null,
                confirm_password_ok: null,
                confirm_password_error: null,
                employee: [],
                dealer: [],
            };
        default:
            return state;
    }
}