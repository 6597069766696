import { GET_SPECS, DELETE_SPEC, ADD_SPEC, CLEAR_ALL, FETCH_SPEC, EDIT_SPEC } from "constants/actions";
import _ from 'lodash'
const initialState = {
    specs: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SPECS:
            return {...state,specs: action.payload};
        case FETCH_SPEC:
            return { ...state, [action.payload.id]: action.payload };
        case ADD_SPEC:
            return { ...state, [action.payload.id]: action.payload };
        case EDIT_SPEC:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_ALL:
            return { ...state, specs: [] };
        case DELETE_SPEC:
            // payload is the id for delete
            return _.omit(state, action.payload)
        default:
            return state;
    }
}