import {
  GET_CUSTOMERS,
  GET_CUSTOMER_LIST,
  FETCH_CUSTOMER,
  DELETE_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  CLEAR_CUSTOMER,
  SEARCH_CUSTOMER_VALUE,
  CLEAR_SEARCH_CUSTOMER_VALUE,
  CLEAR_ALL,
  GET_CUSTOMERS_PENDING,
} from "constants/actions";

const initialState = {
  customers: [],
  customer_list: [],
  pending: false,
  search_value: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMERS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        pending: false,
      };
    case SEARCH_CUSTOMER_VALUE:
      return {
        ...state,
        search_value: action.payload
      }
    case CLEAR_SEARCH_CUSTOMER_VALUE:
      return {
        ...state,
        search_value: ''
      }
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customer_list: action.payload,
        pending: false,
      };
    case FETCH_CUSTOMER:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_CUSTOMER:
      return { ...state, [action.payload.id]: action.payload };

    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(
          (customer) => customer.id !== action.payload
        ),
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        customers: [],
        customer_list: [],
        search_value: ''
      };
    case CLEAR_ALL:
      return {
        ...state,
        customers: [],
        customer_list: [],
        search_value: ''
      };
    default:
      return state;
  }
}
