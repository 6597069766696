import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import moment from "moment";
import _ from "lodash";

import keyConfig from "../_config";
import "./style.css";

import { toast } from "react-toastify";
import { LOGIN_REQUEST } from "constants/actions";
import AuthWrapper from "./auth/AuthWrapper";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
  Typography,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { Fonts } from "../crema/constants/src";

import IntlMessages from "../crema/helpers/src/IntlMessages";
import AppTextField from "../crema/AppFormComponents/AppTextField";
const validationSchema = yup.object({
  email: yup
    .string()
    .email(<IntlMessages id="The Email you entered is not a valid format!" />)
    .required(<IntlMessages id="Please enter Email Address!" />),
  password: yup.string().required(<IntlMessages id="Please enter password!" />),
  agreeTerms: yup
    .boolean()
    .oneOf([true], "You must agree to the terms and conditions"),
});

function LoginPage(props) {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);
  const [criticalError, setCriticalError] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);
  const [expires, setExpires] = React.useState(false);

  const [regError, setRegError] = React.useState(false);

  const { register, handleSubmit, watch, errors, control } = useForm();
  const notify2 = (props) => toast.success(`Sucessfully Logged In!`);
  const onSubmit = (data) => {
    setIsSubmitting(true);
    console.log(data, "from login api");
    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: data.email,
        password: data.password,
        dealer_token: keyConfig.portalkey,
      },
    });
  };
  const {
    auth,
    token,
    registerError,
    authenticated,
    loginError,
    expires_in,
    get_error_state,
  } = useSelector(
    (state) => ({
      auth: state.auth,
      registerError: state.errors.msg,
      authenticated: state.auth.isAuthenticated,
      token: state.auth.token,
      expires_in: state.auth.expires_in,
      loginError: state.errors,
      get_error_state: state.error_state,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setIsSubmitting(false);
    // console.log(_.isEmpty(loginError.errors))
    if (!_.isEmpty(loginError.errors)) {
      // console.log(loginError)

      setVisible(true);
      setPageErrors(loginError);
    }
    if (!_.isEmpty(get_error_state.msg)) {
      // console.log(loginError)

      setVisible(true);
      setCriticalError("Critical Error - please logout and login again!");
    }
    if (expires_in) {
      setExpires(expires_in);
    }
  }, [loginError, expires_in, get_error_state]);

  let { from } = props.location.state || {
    from: { pathname: "/admin/dashboard-sales" },
  }; // new

  const ResetErrors = () => {
    setVisible(false);
    setPageErrors([]);
  };

  React.useEffect(() => {
    if (_.get(registerError, "data.non_field_errors")) {
      setRegError(registerError.data.non_field_errors);
    } else if (_.get(registerError, "data")) {
      setRegError(
        registerError?.data &&
          registerError?.data["username password not matching"]
      );
    }
    // if (_.get(registerError, "data")) {
    //   setRegError(registerError.data)
    // }
  }, [registerError]);
  // console.log("Logged errors",pageerrors.msg)
  // if (pageerrors.msg) {
  //   console.log("Logged pageerrors",pageerrors.msg.status)
  //   if (pageerrors.msg.status === 401 || pageerrors.msg.status === 404) {
  //     // history.push( "/auth/logout")
  //     history.push("/auth/try-again")
  //   }

  // }

  // const expiry_date = moment(expires_in, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
  //   var expiry_datedd = moment(expires_in).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  // console.log('now', expires_in)
  // console.log('dateLimit', Date(expires))
  // console.log('expiry_datedd', expiry_datedd)
  // console.log('dated', Date(expires_in))

  if (authenticated) {
    const dateLimit = moment(expires_in, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const now = moment();
    // console.log(token)
    // console.log('now', expires_in, now)
    // console.log('is after ', now.isAfter(dateLimit))
    // console.log('is before ', now.isBefore(dateLimit))

    // setTimeout(() => {
    //   return console.log("waiting 5 secs");
    // }, 5000);
    notify2();
    // console.log("Logged in")
    // history.push( "/admin/dashboard")

    // setTimeout(() => {
    //   console.log("waiting 5 secs", token);
    // }, 300);
    return <Redirect to={from} />;
  }

  return (
    <>
      {toHome ? <Redirect to="/admin/dashboard-sales" /> : null}
      <AuthWrapper
        title="Login"
        rightSection={
          <>
            <Typography
              component="h2"
              sx={{
                fontWeight: Fonts.BOLD,
                fontSize: 30,
                mb: 2,
              }}
            >
              Dealer Admin Portal
            </Typography>
            <Typography>
              Contact Us
              {/* Crema is purely based on Material ui components and follows
              Material ui guidelines. */}
            </Typography>
          </>
        }
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", mb: 2.5 }}
          >
            <Formik
              validateOnChange={true}
              initialValues={{
                email: "",
                password: "",
                agreeTerms: false,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ field, values, errors, setFieldValue }) => (
                <Form
                  style={{ textAlign: "left", fontSize: 14 }}
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ mb: { xs: 1.5, xl: 2 } }}>
                    <AppTextField
                      placeholder={messages["Email"]}
                      name="email"
                      label={<IntlMessages id="Email" />}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: { xs: 1.5, xl: 2 } }}>
                    <AppTextField
                      type="password"
                      placeholder={messages["Password"]}
                      label={<IntlMessages id="Password" />}
                      name="password"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      mb: { xs: 1.5, xl: 2 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 14,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={values.agreeTerms}
                            onChange={() => {
                              setFieldValue("agreeTerms", event.target.checked);
                            }}
                            color="primary"
                            sx={{ ml: -1.5 }}
                          />
                        }
                        label={
                          <>
                            <Box
                              component="span"
                              sx={{
                                color: "grey.500",
                                fontSize: 14,
                                mb: -0.5,
                              }}
                            >
                              <IntlMessages id="I agree to the " />
                              <Link
                                to={"/auth/acceptable-use-policy"}
                                style={{
                                  textDecoration: "none",
                                  color: "#1d4aa7",
                                }}
                              >
                                acceptable use policy.
                              </Link>
                            </Box>
                          </>
                        }
                      />
                    </Box>
                    {errors.agreeTerms && (
                      <Box
                        component="span"
                        sx={{
                          color: "red",
                          fontSize: 12,
                        }}
                      >
                        {errors.agreeTerms}
                      </Box>
                    )}
                    <Link
                      to={`/auth/forgot-password`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          color: "#1d4aa7",
                          fontWeight: Fonts.MEDIUM,
                          cursor: "pointer",
                          display: "block",
                          textAlign: "right",
                          fontSize: 14,
                        }}
                      >
                        <IntlMessages id="Forgot Your Password?" />
                      </Box>
                    </Link>
                  </Box>

                  <div>
                    <Button
                      variant="contained"
                      color="preAuto"
                      type="submit"
                      disabled={isSubmitting}
                      sx={{
                        minWidth: 160,
                        fontWeight: Fonts.REGULAR,
                        fontSize: 14,
                        textTransform: "capitalize",
                        padding: "4px 16px 8px",
                      }}
                    >
                      <IntlMessages id="Login" />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>

          <Box
            sx={{
              color: "grey.500",
              mb: 2.5,
              fontSize: 14,
            }}
          >
            <span style={{ marginRight: 2 }}>
              <IntlMessages id="Got your account from Dealership?" />
            </span>
            <Box
              component="span"
              sx={{
                fontWeight: Fonts.MEDIUM,
                "& a": {
                  color: "#1d4aa7",
                  textDecoration: "none",
                  fontSize: 14,
                },
              }}
            >
              <Link to="/auth/register">
                <IntlMessages id="Register here" />
              </Link>
            </Box>
          </Box>
        </Box>
      </AuthWrapper>
      <Snackbar
        open={(criticalError !== null || regError) && visible}
        autoHideDuration={4000}
      >
        <Alert
          // onClose={handleClose}

          severity="warning"
        >
          {regError}
          {pageerrors?.errors?.type && pageerrors?.errors?.type !== "ALL_ERRORS"
            ? pageerrors.errors
            : ""}
          {pageerrors.errors ? pageerrors.errors["non_field_errors"] : ""}
          {criticalError ? criticalError : ""}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LoginPage;
