import React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import IntlMessages from "../../../../crema/helpers/src/IntlMessages";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Form, Formik } from "formik";
import AppGridContainer from "../../../../crema/AppGridContainer";
import AppTextField from "../../../../crema/AppFormComponents/AppTextField";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { SG_CHANGE_PASSWORD } from "constants/actions";
import * as yup from "yup";

const validationSchema = yup.object({
  old_password: yup.string().required("Old Password is required"),
  password: yup.string().required("Password is required"),
  confirmed_password: yup
    .string()
    .required("Repeat Password is required")
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePasswordForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showRetypeNewPassword, setShowRetypeNewPassword] =
    React.useState(false);

  const dispatch = useDispatch();
  let history = useHistory();

  const { authenticated, registerError, registerFail } = useSelector(
    (state) => ({
      registerError: state.errors.msg,
      resetError: state.confirm_password_error,
      reset: state.auth.confirm_password_ok,
    }),
    shallowEqual
  );

  // console.log(registerError)
  // console.log(registerFail)

  if (authenticated) {
    // notify()
    history.push("/user/dashboard-sales");
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  // console.log(token)
  const onSubmit = (data) => {
    // dispatch(resetPassword(data.email, data.token, keyConfig.portalkey))
    dispatch(SG_CHANGE_PASSWORD({ ...data }));
    // notify()
    // history.push( "/auth/login-page")
  };

  const onShowOldPassword = () => {
    setShowPassword(!showPassword);
  };

  const onDownOldPassword = (event) => {
    event.preventDefault();
  };

  const onShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const onDownNewPassword = (event) => {
    event.preventDefault();
  };

  const onShowRetypeNewPassword = () => {
    setShowRetypeNewPassword(!showRetypeNewPassword);
  };

  const onDownRetypeNewPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        old_password: "",
        password: "",
        confirmed_password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setTimeout(() => {
          setSubmitting(false);
        }, 500);
      }}
    >
      {() => (
        <Form autoComplete="off">
          <AppGridContainer spacing={4}>
            <Grid item xs={12} md={6}>
              <AppTextField
                type={showPassword ? "text" : "password"}
                name="old_password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onShowOldPassword}
                        onMouseDown={onDownOldPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={<IntlMessages id="Old Password" />}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: "0 !important" }} />
            <Grid item xs={12} md={6}>
              <AppTextField
                type={showNewPassword ? "text" : "password"}
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onShowNewPassword}
                        onMouseDown={onDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={<IntlMessages id="New Password" />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppTextField
                type={showRetypeNewPassword ? "text" : "password"}
                name="confirmed_password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onShowRetypeNewPassword}
                        onMouseDown={onDownRetypeNewPassword}
                        edge="end"
                      >
                        {showRetypeNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={<IntlMessages id="Re-type New Password" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    position: "relative",
                    minWidth: 100,
                  }}
                  color="preAuto"
                  variant="contained"
                  type="submit"
                >
                  <IntlMessages id="Save Changes" />
                </Button>
                <Button
                  sx={{
                    position: "relative",
                    minWidth: 100,
                    ml: 2.5,
                  }}
                  color="preAuto"
                  variant="outlined"
                >
                  <IntlMessages id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </AppGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
