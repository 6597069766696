import React from 'react';
import { useParams } from "react-router";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { LOAD_CARIMAGES } from "constants/actions";

// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";

import ImageCard from "./ImageCard"


const ImageGallery = (props) => {
    // console.log(props)
    return (
        <Container>
        <Row>
         
          {props.images.map((p, k) => (
                 <Col md="4" sm="12" key={k}>
                <ImageCard 
                        imageUrl={p.url_320}
                        id={p.id}
                        name={p.name}
                        editable={false}
                       
                    />
                
                </Col>
            )

            )}
            
        </Row>
       
            
           
            
        </Container>
    );
};

export default ImageGallery;