import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import _ from "lodash";
import {
  SG_UPDATE_VIN_UPLOAD,
  FETCH_INVENTORY_ONE,
  SG_FETCH_UPLOAD_FILES,
  LOAD_VIN_UPLOAD,
} from "constants/actions";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import { currentApi } from "_config";

import DetailedComp from "Vinupload/Common/DetailedComp"
import CreateEdit from "./discard/CreateEditNew"

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import Detail from "Company/Detail";

const UploadedFileDetail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [errormessages, setErrormessages] = React.useState(false);
  const [fileuploadlog, setFileuploadlog] = React.useState(false);
  const [vinuploadFileupload, setVinuploadFileupload] = React.useState([]);
  const [newUploadedFiles, setNewUploadedFiles] = React.useState([]);
  const [vinupload, setVinupload] = React.useState([]);

  const [updated, setUpdated] = React.useState(false);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [showVinDecode, setShowVinDecode] = React.useState(0);
  const [inventoryId, setInventoryId] = React.useState(0);

  React.useEffect(() => {
    dispatch({ type: SG_FETCH_UPLOAD_FILES, payload: id });
    // dispatch({ type: FETCH_INVENTORY_ONE, payload: id });
    dispatch({ type: LOAD_VIN_UPLOAD, payload: `fileupload_id=${id}` });
  }, [dispatch, updated]);

  const {
    new_uploaded_files,
    related_dealer,
    get_auth,
    get_errors,
    get_vinupload,
  } = useSelector(
    (state) => ({
      new_uploaded_files: state.fileupload[parseInt(id)],
      related_dealer: state.dealers.related,
      get_auth: state.auth,
      get_errors: state.errors,
      get_error_state: state.errorstate,
      get_vinupload: state.vinupload,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (new_uploaded_files) {
      setNewUploadedFiles(new_uploaded_files);
      if (new_uploaded_files.vinupload_fileupload) {
        setVinuploadFileupload(new_uploaded_files.vinupload_fileupload);
      }
    }

    if (get_errors) {
      setErrormessages(get_errors);
    }
    if (get_vinupload) {
      setVinupload(get_vinupload);
    }
  }, [new_uploaded_files, related_dealer, get_auth, get_vinupload, get_errors]);

  // console.log(errormessages);
  const SelectedVinDecode = (vinid, invid) => {
    setInventoryId(invid)
    setShowVinDecode(vinid);
    setColWidth([4, 8]);
  };
  const resetColWidth = (updated) => {
    setInventoryId(false)
    setShowVinDecode(false);
    setUpdated(updated);
    setColWidth([12, 0]);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12" sm="12">
            <Card>
              <CardBody>
                <CardTitle tag="h4">Uploaded VIN</CardTitle>
                <Row>
                  <Col md="12">
                    <Table bordered>
                      <thead className="table-active">
                        <tr>
                          <th>Date</th>
                          <th>Name</th>
                          <th>File</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            {newUploadedFiles.created_at
                              ? moment(newUploadedFiles.created_at).format(
                                  "YYYY-MM-DD"
                                )
                              : null}
                          </td>
                          <td>{newUploadedFiles.name}</td>
                          <td>
                            {newUploadedFiles.data_file ? (
                              <a
                                href={currentApi + newUploadedFiles.data_file}
                                target="_blank"
                              >
                                Download File
                              </a>
                            ) : (
                              " "
                            )}
                          </td>
                          <td>{newUploadedFiles.status}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div
                      className={
                        fileuploadlog ? "d-block table-full-width " : "d-none"
                      }
                    >
                      <h4>Log</h4>
                      <p>{newUploadedFiles.messages}</p>
                    </div>
                    <Row className="border-top pt-2 pb-2">
                      <Col>
                        <div className="d-flex justify-content-end">
                          {" "}
                          <Button
                            className="btn"
                            color={fileuploadlog ? "default" : "success"}
                            outline
                            size="sm"
                            onClick={() => setFileuploadlog(!fileuploadlog)}
                          >
                            {fileuploadlog ? "Hide Log" : "Show Log"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={colWidth[0]}>
            <Card>
              <CardBody>
                <CardTitle tag="h4">Uploaded VIN Details</CardTitle>
                <Row>
                  <Col md="12">
                    <Table responsive>

                      {vinuploadFileupload? vinuploadFileupload.map((p, k) => (
                        <tbody key={k} style={{
                          border: "solid 2px black"
                        }}>
                          <tr className="table-warning">
                                <td>Uploaded</td>
                                <td>{p.id}</td>
                                <td>{p.vin} </td>
                                <td>
                                  {p.year}-{p.make}-{p.model}
                                </td>
                                <td>{p.color}</td>
                                <td>{p.mileage}</td>
                                <td>
                                  {p.notes} {p.status}
                                </td>
                                <td>
                                <Link
                                      to={`/admin/full-vin-decode-edit/${p.id}${p.inventory? `/${p.inventory.id}`:''}/${id}`}
                                    >
                                      <Button color="link" size="sm">
                                        View
                                      </Button>
                                    </Link>
                                </td>
                              </tr>
                         {p.inventory? 

                         <tr className={p.inventory.status === 'Live'? "table-success" : "table-primary"}>
                           
                            <td>
                              <small>{p.inventory.status}</small> <br />
                              
                              <Link
                                to={`/admin/inventory-detail/${p.inventory.id}`}
                              >
                                <Button color="link" size="sm">
                                  View
                                </Button>
                              </Link>
                              
                              
                            </td>
                            <td>{p.inventory.id}</td>
                            <td>{p.inventory.vin} </td>
                            <td>
                              {p.inventory.year}-{p.inventory.make}-
                              {p.inventory.model}
                            </td>
                            <td>
                              {p.inventory.color}
                              <br />
                              <small>{p.inventory.mfd_color_code}</small>
                            </td>
                            <td>{p.inventory.kilometers}</td>
                            <td>
                              {p.inventory.trim} {p.status}
                            </td>
                            <td>
                              <Button
                                className="btn"
                                size="sm"
                                onClick={() => SelectedVinDecode(p.id, p.inventory.id)}
                                color="primary"
                              >
                                More
                              </Button>
                            </td>
                          </tr> :
                          <tr className="table-success"><td colSpan="8" className="text-center">Not Processed</td></tr>
}
                        </tbody>
                      )): (
                        <tbody>
                          <tr>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>

          {/* <DetailedComp 
            id={showVinDecode}
            resetColWidth={resetColWidth} 
       />  */}

       <CreateEdit id={inventoryId} vinid={showVinDecode} resetColWidth={resetColWidth} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadedFileDetail;
