import React from "react";

import Datetime from "react-datetime";
import moment from "moment-timezone";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_FETCH_LEAD_STAGE } from "constants/actions";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { Button as MUIButton } from "@mui/material";
import AddNewLeadStage from "./AddNewLeadStage";

const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [leadSource, setLeadSource] = React.useState([]);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)]) {
      dispatch({ type: SG_FETCH_LEAD_STAGE, payload: id });
    }
  }, [dispatch]);

  const { get_lead_stage, get_auth, get_errors } = useSelector(
    (state) => ({
      get_lead_stage: state.leadstage[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_lead_stage) {
      setLeadSource(get_lead_stage);
    }
  }, [get_lead_stage]);
  // console.log(leadSource);
  // console.log(get_lead_stage);
  return (
    <>
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Lead Stage</CardTitle>
                  </Col>
                  <Col className="ml-auto" md="2">
                    <MUIButton
                      color="preAuto"
                      onClick={onOpenAddTask}
                      variant="contained"
                    >
                      Edit
                    </MUIButton>
                  </Col>
                </Row>

                <FormGroup>
                  <label>Name </label>
                  <p>{leadSource ? leadSource.name : ""}</p>
                </FormGroup>
                <Row>
                  <Col>
                    <label>Status</label>
                    <p>
                      {leadSource
                        ? leadSource.active
                          ? "Active"
                          : "Inactive"
                        : ""}
                    </p>
                  </Col>
                  <Col>
                    <label>Sort Order</label>
                    <p>{leadSource ? leadSource.sort_order : ""}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Description</label>
                    <p>{leadSource ? leadSource.description : ""}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <AddNewLeadStage
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Detail;
