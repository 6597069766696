import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import * as jsonexport from "jsonexport/dist";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_COMPANY_INFORMATION,
  SG_FETCH_COMPANY,
  LOAD_LEADSOURCES,
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  CREATE_COMPANY_INFORMATION,
  UPDATE_COMPANY_INFORMATION,
  REMOVE_COMPANY_INFORMATION,
} from "constants/actions";

import ReactPaginate from "react-paginate";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile, deviceType
// } from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

import config from "./DefaultConfig";
// import { date } from "faker";

const CompanyInformation = (props) => {
  const dispatch = useDispatch();
  const [companyInformation, setCompanyInformation] = React.useState([]);

  const [pageParams, setPageParams] = React.useState(props.params);
  const [generateRows, setGenerateRows] = React.useState(false);
  const [filedownloadlink, setFiledownloadlink] = React.useState("");
  const [resetPage, setResetPage] = React.useState(false)

  React.useEffect(() => {
    // console.log(user)
    dispatch({ type: LOAD_COMPANY_INFORMATION, payload: { pageParams } });
    dispatch({ type: SG_FETCH_COMPANY, payload: props.id });
    dispatch({ type: LOAD_LEADTYPES, payload: pageParams });
    dispatch({ type: LOAD_LEADSOURCES, payload: pageParams });
    dispatch({ type: LOAD_LEAD_STAGE, payload: pageParams });
  }, [dispatch, pageParams, resetPage]);

  const {
    get_company_information,
    get_company,
    lead_sources,
    lead_stage,
    lead_types,
  } = useSelector(
    (state) => ({
      get_company_information: state.companyinformation.companyinformations,
      get_company: state.company[parseInt(props.id)],
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.leadstage.leadstages,
      lead_types: state.leadtypes.leadtypes,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_company_information) {
      setCompanyInformation(get_company_information);
    }
  }, [get_company_information]);

  const GenerateDefault = () => {
    // config.map(p => console.log(p))
    setGenerateRows(true);
  };

  function DownloadFile(FileName) {
    jsonexport(FileName, function (err, csv) {
      if (err) return console.log(err);
      var myURL = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
      const obj = FileName;
      var blob = new Blob([JSON.stringify(obj, null, 2)], {
        type: "application/js",
      });
      var csvUrl = myURL.createObjectURL(blob);
      setFiledownloadlink(csvUrl);
    });
  }
  React.useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    const FullInformation = [];
    let companyInfo;
    // const entries = Object.entries(config)
    // console.log(entries)
    // for (const [name, value] of entries) {

    // console.log(name === 'googleKey'? value : null)

    // console.log(`There are ${name} ${value}`)
    if (get_company) {
      FullInformation.push({
        name: "googleKey",
        value: "AIzaSyBeFwFPIB8G91Ye3f-p3t_zmz8MjvidgcI",
      });
      FullInformation.push({
        name: "loginproduction",
        value: "https://api.datastruk.com/api/customer",
      });
      FullInformation.push({
        name: "loginlocal",
        value: "http://127.0.0.1:8000/api/customer",
      });
      FullInformation.push({
        name: "loginapi",
        value: "https://api.datastruk.com/api/customer",
      });
      FullInformation.push({
        name: "production",
        value: "https://api.datastruk.com/api/web",
      });
      FullInformation.push({
        name: "local",
        value: "http://127.0.0.1:8000/api/web",
      });
      FullInformation.push({
        name: "serverapi",
        value: "https://api.datastruk.com/api/web",
      });
      FullInformation.push({
        name: "serverurl",
        value: "https://api.datastruk.com",
      });
      FullInformation.push({ name: "company", value: get_company.name });
      FullInformation.push({ name: "website", value: get_company.website });
      FullInformation.push({ name: "apikey", value: get_company.company_code });
      FullInformation.push({
        name: "portalkey",
        value: get_company.customer_portal_code,
      });
      FullInformation.push({
        name: "port",
        value: get_company.location,
      });
      FullInformation.push({ name: "address", value: get_company.address });
      FullInformation.push({ name: "city", value: get_company.city });
      FullInformation.push({ name: "pincode", value: get_company.postalcode });
      FullInformation.push({ name: "province", value: get_company.province });
      FullInformation.push({ name: "emailid", value: get_company.email });
      FullInformation.push({ name: "phone", value: get_company.phone });
      FullInformation.push({ name: "fax", value: get_company.fax });
      FullInformation.push({ name: "tollfree", value: get_company.tollfree });
      FullInformation.push({ name: "logo", value: get_company.logo });
      FullInformation.push({ name: "lng", value: get_company.map_lng });
      FullInformation.push({ name: "lat", value: get_company.map_lng });
      FullInformation.push({ name: "map_zoom", value: 16 });
      //  FullInformation.push({name: "terms_updated", value: today})
      //  FullInformation.push({name: "privacy_updated", value: today})
    }

    if (lead_stage) {
      const lead_stage_id = lead_stage.filter((ls) => ls.sort_order === 1)[0];
      if (lead_stage_id) {
        FullInformation.push({
          name: "web_lead_stage",
          value: lead_stage_id.id,
        });
      }
    }

    if (lead_sources) {
      const lead_sources_id = lead_sources.filter((ls) => ls.origin === 1)[0];

      if (lead_sources_id) {
        FullInformation.push({
          name: "web_lead_source",
          value: lead_sources_id.id,
        });
      }
    }
    
    // console.log(lead_stage)
    // console.log(lead_sources)
    // console.log(lead_types)
    
    if (lead_types) {
      lead_types.map((p) => {
        if (p.sort_order === 5) {
          FullInformation.push({
            name: "web_lead_type_credit_app",
            value: p.id,
          });
        }
        if (p.sort_order === 3) {
          FullInformation.push({
            name: "web_lead_type_trade_in",
            value: p.id,
          });
        }
        if (p.sort_order === 4) {
          FullInformation.push({
            name: "web_lead_type_price_enquiry",
            value: p.id,
          });
        }
        if (p.sort_order === 6) {
          FullInformation.push({
            name: "web_lead_type_interested",
            value: p.id,
          });
        }
        if (p.sort_order === 7) {
          FullInformation.push({
            name: "web_lead_type_test_drive",
            value: p.id,
          });
        }
        if (p.sort_order === 9) {
          FullInformation.push({
            name: "web_lead_type_contact",
            value: p.id,
          });
        }
        if (p.sort_order === 10) {
          FullInformation.push({
            name: "web_lead_type_email",
            value: p.id,
          });
        }
        if (p.sort_order === 8) {
          FullInformation.push({
            name: "web_lead_type_feedback",
            value: p.id,
          });
        }
      });
    }

    if (generateRows) {
      // console.log(companyInfo);
      // web_lead_stage:6,

      dispatch({
        type: CREATE_COMPANY_INFORMATION,
        payload: {
          company: props.id,
          name: "config",
          full_information: FullInformation,
        },
      });

      DownloadFile(FullInformation);

      setGenerateRows(false);
    }
  }, [config, generateRows]);

  const DeleteFile = (id) => {
    // config.map(p => console.log(p)) REMOVE_COMPANY_INFORMATION
    dispatch({ type: REMOVE_COMPANY_INFORMATION, payload: id });
    setResetPage(id)

  };
  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Company Information</CardTitle>
                <h5 className="card-category">Company Config</h5>
              </Col>
              <Col className="ml-auto" md="2">
                {/* <Button
                  color="default"
                  size="sm"
                  to="/admin/comp-info-create"
                  tag={Link}
                >
                  Create New
                </Button> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {companyInformation.length > 0 ? (
              <Row>
                <Col md="10">
                  <Table bordered striped>
                    <thead>
                      <tr>
                        <th>Name</th>

                        <th className="text-right">Updated</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyInformation.map((p, k) => (
                        <tr key={k}>
                          <td>{p.name}</td>

                          <td className="text-right">
                            {moment(p.updated_at).format("YYYY-MM-DD")}
                          </td>

                          <td>
                            <Link to={`/admin/comp-info-detail/${p.id}`}>
                              View More
                            </Link>
                          </td>
                          <td>
                          <Button color="danger" size="sm" 
                          onClick={() => DeleteFile(p.id)}>
                    Delete
                  </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md="2">
                  {filedownloadlink && (
                    <a download="_config.json" href={filedownloadlink}>
                      Download
                    </a>
                  )}
                </Col>
               
              </Row>
            ) : (
              <Row>
                <Col>
                  <Button color="warning" size="sm" onClick={GenerateDefault}>
                    Generate website Config
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CompanyInformation;
