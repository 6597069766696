import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import routes from "routes.js";
import { Box } from "@mui/material";
// import PwaAlert from 'PwaAlert'
// reactstrap components
var ps;

function Pages() {
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.fullPages);
  //   }
  // })

  const currentUrl = window.location.href;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUrl]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }else if(prop.layout === "/payment"){
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {/* <AuthNavbar /> */}
      {/* <div className="">
        <div className=""> */}
      {/* <PwaAlert /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: "100vh",
          maxWidth: { xl: 1650 },
          mx: { xl: "auto" },
          width: { xl: "100%" },
          backgroundColor: "#f4f7fe",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "50vh",
            p: { xs: 5, md: 7.5, xl: 12.5 },
          }}
          className="app-content"
        >
          <Switch>{getRoutes(routes)}</Switch>
        </Box>
      </Box>
      <Footer  />
      {/* </div>
      </div> */}
    </>
  );
}

export default Pages;
