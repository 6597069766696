import { GET_INVENTORY_ENGINE, GET_INVENTORY_ENGINE_PENDING, FETCH_INVENTORY_ENGINE, EDIT_INVENTORY_ENGINE,
    ADD_INVENTORY_ENGINE, DELETE_INVENTORY_ENGINE, CLEAR_ALL} from "constants/actions";

const initialState = {
    inventoryengine: [],
    loading: false,
    pending: false
};

export default function (state = initialState, action) { 
    switch (action.type) {
        case GET_INVENTORY_ENGINE_PENDING:
            return {
                ...state,
                pending: true,
                loading: true,
            }
        case GET_INVENTORY_ENGINE:
            return {
                ...state,
                inventoryengine: action.payload, 
                loading: false,
                pending: false
            };
        case FETCH_INVENTORY_ENGINE:
            return { ...state, [action.payload.id]: action.payload, 
                loading: false };

        case EDIT_INVENTORY_ENGINE:
            return { ...state, [action.payload.id]: action.payload, 
                loading: false };

        case DELETE_INVENTORY_ENGINE:
            return {
                ...state,
                inventoryengine: state.inventoryengine.filter(Inv => Inv.id !== action.payload)
            };
        case ADD_INVENTORY_ENGINE:
            return {
                ...state,
                inventoryengine: [...state.inventoryengine, action.payload], 
                loading: false
            };
        case CLEAR_ALL:
            return {
                ...state,
                inventoryengine: []
            };
        default:
            return state;
    }
}


