import { put, call, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  LOAD_INVENTORY,
  GET_INVENTORIES_PENDING,
  GET_INVENTORIES,
  LOAD_VALID_INVENTORIES,
  GET_VALID_INVENTORIES,
  FETCH_INVENTORY_PENDING,
  FETCH_INVENTORY,
  FETCH_INVENTORY_ONE,
  EDIT_INVENTORY,
  SG_EDIT_INVENTORY,
  ADD_INVENTORY,
  CREATE_INVENTORY,
  DELETE_INVENTORY,
  DESTROY_INVENTORY,
  RESET_ADD_INVENTORY,
  RESET_CREATE_INVENTORY,
  GET_SELECT_INVENTORIES,
  SG_GET_SELECT_INVENTORIES,
  SG_EDIT_MILEAGE,
  ALL_ERRORS,

  GET_SPECS,
  LOAD_SPECS,
  GET_CATEGORIES,
  LOAD_CATEGORIES,
  LOAD_GSHEET_INVENTORY_LIST,
  GET_GSHEET_INVENTORY_LIST,
  UPDATED_INVENTORY_PRICES,
  GET_INVENTORY_FINANCE,
  FETCH_INVENTORY_FINANCE_ONE,
  FETCH_INVENTORY_FINANCE_PENDING,
  FETCH_INVENTORY_FINANCE,
  EDIT_INVENTORY_FINANCE,
  ADD_INVENTORY_FINANCE,
  DELETE_INVENTORY_FINANCE,
  GET_INVENTORY_EXTRA,
  FETCH_INVENTORY_EXTRA_PENDING,
  FETCH_INVENTORY_EXTRA,
  FETCH_INVENTORY_EXTRA_ONE,
  EDIT_INVENTORY_EXTRA,
  ADD_INVENTORY_EXTRA,
  DELETE_INVENTORY_EXTRA,
  PUT_INVENTORY,
  UPDATED_INVENTORY,
  RESET_PUT_INVENTORY,
  RESET_UPDATED_INVENTORY,
  LOAD_INVENTORY_LIST,
  GET_INVENTORY_LIST,
  GET_COMPARED_INVENTORY_LIST,
  LOAD_COMPARED_INVENTORY_LIST,
} from "constants/actions";

import * as api from "constants/api";

function* loadResetInventory() {
  yield put({ type: RESET_PUT_INVENTORY});
}

export function* inventoryReset() {
  yield takeLatest(RESET_UPDATED_INVENTORY, loadResetInventory);
}

function* loadInventory(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  try {
    const json = yield call(api.GET_INVENTORIES, action.payload);
    yield put({ type: GET_INVENTORIES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryLoad() {
  yield takeLatest(LOAD_INVENTORY, loadInventory);
}

function* loadValidInventoryList(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  try {
    const json = yield call(api.GET_VALID_INVENTORY_LIST, action.payload);
    yield put({ type: GET_INVENTORY_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryValidListLoad() {
  yield takeLatest(LOAD_INVENTORY_LIST, loadValidInventoryList);
}

function* loadGsheetInventoryList(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  try {
    const json = yield call(api.GET_GSHEET_INVENTORY, action.payload);
    yield put({ type: GET_GSHEET_INVENTORY_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryGSheetLoad() {
  yield takeLatest(LOAD_GSHEET_INVENTORY_LIST, loadGsheetInventoryList);
}

function* loadComparedInventoryList(action) {
  try {
    const json = yield call(api.GET_COMPARED_INVENTORY, action.payload);
    yield put({ type: GET_COMPARED_INVENTORY_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryComparedValidListLoad() {
  yield takeLatest(LOAD_COMPARED_INVENTORY_LIST, loadComparedInventoryList);
}

function* loadInventoryList(action) {
  yield put({ type: GET_INVENTORIES_PENDING });
  try {
    const json = yield call(api.GET_VALID_INVENTORIES, action.payload);
    yield put({ type: GET_VALID_INVENTORIES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryListLoad() {
  yield takeLatest(LOAD_VALID_INVENTORIES, loadInventoryList);
}

function* fetchInventory(action) {
  yield put({ type: FETCH_INVENTORY_PENDING });
  try {
    const json = yield call(api.FETCH_INVENTORY, action.payload);
    yield put({ type: FETCH_INVENTORY, payload: json.data });
    yield put({ type: RESET_PUT_INVENTORY});
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryFetchOne() {
  yield takeLatest(FETCH_INVENTORY_ONE, fetchInventory);
}

// edit
function* editInventory(action) {
  // console.log(action)
  yield put({ type: FETCH_INVENTORY_PENDING });
  yield put({ type: RESET_PUT_INVENTORY});
  try {
    const json = yield call(
      api.EDIT_INVENTORY,
      action.payload.id,
      action.payload
    );
    yield put({ type: EDIT_INVENTORY, payload: json.data });
    yield put({type: PUT_INVENTORY, payload: json.data.id});
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryEdit() {
  yield takeEvery(SG_EDIT_INVENTORY, editInventory);
}

function* addInventory(action) {
  try {
    const json = yield call(api.ADD_INVENTORY, action.payload);
    yield put({ type: ADD_INVENTORY, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryAdd() {
  yield takeLatest(CREATE_INVENTORY, addInventory);
}

function* resetAddInventory() {
  yield put({ type: RESET_ADD_INVENTORY});
}

export function* inventoryResetAdd() {
  yield takeLatest(RESET_CREATE_INVENTORY, resetAddInventory);
}

function* deleteInventory(action) {
  try {
    const json = yield call(api.DELETE_INVENTORY, action.payload);
    yield put({ type: DELETE_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryDelete() {
  yield takeLatest(DESTROY_INVENTORY, deleteInventory);
}

// edit
function* editMileage(action) {
  // console.log(action)

  try {
    const json = yield call(
      api.EDIT_MILEAGE,
      action.payload.id,
      action.payload
    );

    yield put({ type: UPDATED_INVENTORY_PRICES, 
      payload: { id: action.payload.id,
        status: json.data? 1: 0,
        list_price: json.data? json.data.list_price: null, 
        vin: json.data? json.data.vin: null,
        year: json.data? json.data.year: null,
        make: json.data? json.data.make: null,
        model: json.data? json.data.model: null 
        }
     });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* mileageEdit() {
  yield takeEvery(SG_EDIT_MILEAGE, editMileage);
}


// INVENTORY_SPECS
function* getSpecs() {
  try {
    const json = yield call(api.GET_SPECS);
    yield put({ type: GET_SPECS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* specsGet() {
  yield takeLatest(LOAD_SPECS, getSpecs);
}

// INVENTORY_CATEGORIES
function* getCategories() {
  try {
    const json = yield call(api.GET_CATEGORIES);
    yield put({ type: GET_CATEGORIES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}
export function* categoriesGet() {
  yield takeLatest(LOAD_CATEGORIES, getCategories);
}

// INVENTORY_FINANCE
function* fetchInventoryFinance(action) {
  yield put({ type: FETCH_INVENTORY_FINANCE_PENDING });
  try {
    const json = yield call(api.getPagedInventories, action.payload);
    // let car_finance = car_data_finance.filter(function(item) {
    //   return item.inventory_id === parseInt(action.payload);
    // })[0];
    yield put({ type: FETCH_INVENTORY_FINANCE, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryFetchFinanceOne() {
  yield takeLatest(FETCH_INVENTORY_FINANCE_ONE, fetchInventoryFinance);
}

// INVENTORY_EXTRA
function* fetchInventoryExtra(action) {
  yield put({ type: FETCH_INVENTORY_EXTRA_PENDING });
  try {
    const json = yield call(api.getPagedInventories, action.payload);
    // let car_extra = car_data_extra.filter(function(item) {
    //   return item.inventory_id === parseInt(action.payload);
    // })[0];
    yield put({ type: FETCH_INVENTORY_EXTRA, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryFetchExtraOne() {
  yield takeLatest(FETCH_INVENTORY_EXTRA_ONE, fetchInventoryExtra);
}

function* selectInventory(action) {
  try {
    const json = yield call(api.GET_SELECT_INVENTORIES, action.payload);

    yield put({ type: GET_SELECT_INVENTORIES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventorySelect() {
  yield takeLatest(SG_GET_SELECT_INVENTORIES, selectInventory);
}

export default function* index() {
  yield all([
    inventoryLoad(),
    inventoryValidListLoad(),
    inventoryFetchOne(),
    inventoryEdit(),
    inventoryAdd(),
    inventoryListLoad(),
    inventoryReset(),
    inventoryResetAdd(),
    inventoryDelete(),
    inventoryComparedValidListLoad(),
    inventoryGSheetLoad(),
    mileageEdit(),
    inventoryFetchFinanceOne(),
    inventoryFetchExtraOne(),
    inventorySelect(),
    specsGet(),
    categoriesGet(),
  ]);
}

// export default inventoryLoad;
