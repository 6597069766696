import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    LOAD_KIJIJI_LISTINGS,
    GET_KIJIJI_LISTINGS,
    DELETE_KIJIJI_LISTING,
    SG_DELETE_KIJIJI_LISTING,
    ADD_KIJIJI_LISTING,
    SG_ADD_KIJIJI_LISTING,
    FETCH_KIJIJI_LISTING,
    SG_FETCH_KIJIJI_LISTING,
    UPDATE_KIJIJI_LISTING,
    EDIT_KIJIJI_LISTING,
    CLEAR_KIJIJI_LISTING,
  CLEAR_ALL,
  ALL_ERRORS,
} from "constants/actions";

function* loadKijiji(action) {
  try {
    const json = yield call(api.GET_INVENTORY_KIJIJI, action.payload);

    yield put({ type: GET_KIJIJI_LISTINGS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* kijijiLoad() {
  yield takeLatest(LOAD_KIJIJI_LISTINGS, loadKijiji);
}


function* addKijiji(action) {
  try {
    const json = yield call(api.PUSH_TO_KIJIJI,  action.payload);

    yield put({ type: ADD_KIJIJI_LISTING, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* kijijiAdd() {
  yield takeLatest(SG_ADD_KIJIJI_LISTING, addKijiji);
}


// function* fetchCustomer(action) {
//   try {
//     const json = yield call(api.FETCH_CUSTOMER, action.payload);

//     yield put({ type: FETCH_CUSTOMER, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, error: e.message });
//   }
// }

// export function* customerFetchOne() {
//   yield takeLatest(SG_FETCH_CUSTOMER, fetchCustomer);
// }

// function* editCustomer(action) {
//   try {
//     const json = yield call(api.EDIT_CUSTOMER, action.payload.id, action.payload);

//     yield put({ type: EDIT_CUSTOMER, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, error: e.message });
//   }
// }

// export function* customerEdit() {
//   yield takeLatest(UPDATE_CUSTOMER, editCustomer);
// }


export default function* index() {
  yield all([
    kijijiLoad(),
    kijijiAdd(),
    // customerEdit(),
    // customerAdd(),
  ]);
}
