import React from "react";
import { useHistory } from "react-router-dom";
import {useSelector, useDispatch,shallowEqual} from 'react-redux'
import { useForm } from 'react-hook-form';
import _ from "lodash";

import { WEBSITE_RESET_PASSWORD } from 'constants/actions'
// import { logout } from "../actions/auth";
import SweetAlert from "react-bootstrap-sweetalert";


// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Button
} from "reactstrap";

// core components
import bgImage from "assets/img/bg/jan-sendereks.jpg";

const ResetPassword = () => {
  const { register, handleSubmit, watch, errors, control  } = useForm()
  const [reMsg, setRegMsg] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [emailFocus, setemailFocus] = React.useState(false);
  const [passwordFocus, setpasswordFocus] = React.useState(false);
  const [password1Focus, setpassword1Focus] = React.useState(false);
  const [submitPage, setSubmitPage] = React.useState(false)

  const [visible, setVisible] = React.useState(false);
  const [regError, setRegError] = React.useState(false);

  const [ipData, setIpData] = React.useState(null);
  const [showReport, setShowReport] = React.useState(true);

  const password = React.useRef({});
  password.current = watch("password", "");

  const dispatch = useDispatch();
  let history = useHistory();

  React.useEffect(() => {
    if (showReport) {
      fetch("https://extreme-ip-lookup.com/json")           // Get the IP data
        .then(res => res.json())
        .then((ip) => {
            setIpData(ip);
            setShowReport(false);
          });
        // .then(ip => Promise.all([ip, getFingerprint()]))    // Get the fingerprint
        // .then(([ip, fp]) => {
        //   setFingerprint(fp);                               // Update the state
        //   setIpData(ip);
        //   setShowReport(false);
        // });
    }
  }, [showReport]);

  

  const { registerError, registerFail, error, message  } = useSelector(state => ({
    registerError: state.errors.msg,
    resetError: state.confirm_password_error,
    reset: state.auth.confirm_password_ok,
    error: state.errors.errors,
    message: state.messages.status,
  }), shallowEqual);


  // console.log(registerError.status)
  // console.log(error)
  // console.log(message)
 

  React.useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if ((registerError.status === 404)) {
      
      setRegError(registerError.data )
      setVisible(true)
    }


  }, [registerError, error]);

  React.useEffect(() => {
    if (message) {
      setRegMsg(message);
    }
    if (message === 200) {
      // console.log(message, reMsg)
      // redirect after 5 seconds
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Sucess"
          onConfirm={() => goLogin()}
          confirmBtnBsStyle="info"
        >
          Password Reset Successful
        </SweetAlert>
      );
      setSubmitPage(true)
      setTimeout(() => {
        history.push("/auth/login");
      }, 10000);
    }
  }, [message]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  // console.log(token)
  const onSubmit= (data) => {
    // console.log(data)
    // console.log(token)
    dispatch({
      type: WEBSITE_RESET_PASSWORD,
      payload: {
        ...data,
        token: token, 
        user_ip:ipData
      },
    });
    // dispatch(resetPassword(data.email, data.token, keyConfig.portalkey))
   
    // notify()
    // history.push( "/auth/login-page")
  }

  function goLogin() {
    history.push("/auth/login");
  }
  const ResetErrors = () => {
    setVisible(false);
    setRegError(false);
    history.push("/auth/login");
  };

    return (
      <>

          <div className="register-page">
            <Container>
            <Alert color="info" isOpen={visible} toggle={ResetErrors}>
            Looks like we have an error, the link is no longer valid {JSON.stringify(regError)}
            Please try generating another link
            {/* non_field_errors */}
            {/* {JSON.stringify(pageerrors.errors)} */}
          </Alert>
              <Row className="justify-content-center">
               
     
                <Col lg={5} md={8} xs={12}>
                  <Card className="card-signup">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">New Password</CardTitle>
              
                    </CardHeader>
                    <CardBody>
                    <div className="description">
                      <p className="description text-dark">
                        Please enter your new secure password
                      </p>
                    </div>
                    <p className="text-danger small ">
                           <span>{registerError['password']}</span>
                          {/* {registerError? <span>{registerError}</span>: ""} */}
                    </p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      
                    <FormGroup className={errors.password? "has-danger" : ""}>
                         
                         <Label className="text-dark">Password</Label>
                          <InputGroup
                           className={
                             passwordFocus ? "input-group-focus" : ""
                           }
                         >
                           {/* <InputGroupAddon addonType="prepend"> */}
                             <InputGroupText>
                               <i className="now-ui-icons ui-1_lock-circle-open" />
                             </InputGroupText>
                           {/* </InputGroupAddon> */}
                           <Input type="password" name="password" placeholder="Password" 
                           
                           innerRef={register({
                             required: "You must specify a password",
                             minLength: {
                               value: 8,
                               message: "Password must have at least 8 characters"
                             }
                           })}
                           
                           />
                           </InputGroup>
                           <p className="text-danger small">
                           {errors.password && <span>{errors.password.message}</span>}
                           
                           </p>
                         </FormGroup>
                         
                         <FormGroup className={errors.password? "has-danger" : ""}>
                          
                         <Label className="text-dark">Repeat Password</Label>
                          <InputGroup
                           className={
                             passwordFocus ? "input-group-focus" : ""
                           }
                         >
                           {/* <InputGroupAddon addonType="prepend"> */}
                             <InputGroupText>
                               <i className="now-ui-icons ui-1_lock-circle-open" />
                             </InputGroupText>
                           {/* </InputGroupAddon> */}
                           <Input type="password" name="password2" placeholder="Re Type Password" 
                           
                           innerRef={register({
                             validate: value =>
                               value === password.current || "The passwords do not match"
                           })}
                           
                           />
                           </InputGroup>
                           <Label className="text-danger">
                           {errors.password2 && <span>{errors.password2.message}</span>}
                           
                           </Label>
                         </FormGroup>

                        

                        
                        
                      </Form>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        color="primary"
                        size="lg"
                        className="btn-round"
                        onClick={handleSubmit(onSubmit)}
                        // onClick={() => {
                        //   handleSubmit(onSubmit);
                        //   getAlert()}
                        // }
                        >
                        Reset Password
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
      
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
        {alert}
      </>
    );
  
}

export default ResetPassword;
