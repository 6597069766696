import React from "react";


import _ from "lodash";

// react plugin used to create datetimepicker
import format from "date-fns/format";

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Button,
    ButtonGroup,
    Label,
    FormGroup,
    Form,
    Input,
    Fade,
    Collapse,
    Row,
    Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";


const LeadLog = ({ logFile }) => {
    const [fadeIn, setFadeIn] = React.useState(false);
    return (
        <>

            <div className="content">
                <ErrorBoundary>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10">
                            <Row>
                                <Col>
                                    <Button color="primary" onClick={() => setFadeIn(!fadeIn)}>
                                        Show Log
                                    </Button>
                                </Col>

                                <Col md="8">
                                    <Collapse isOpen={fadeIn} className="mt-3">
                                        <Card>
                                            <CardBody>
                                                <CardTitle tag="h4">Lead Log</CardTitle>
                                                <hr />
                                                {logFile && Object.keys(logFile).map((i, k) => {
                                                    return <div key={k}>
                                                        <p><strong>
                                                            {format(
                                                                new Date(i?.toLocaleString()),
                                                                "EEEE, MMM do h:mm a"
                                                            )}
                                                        </strong> </p>

                                                        {Object.keys(logFile[i]).map((ii, ki) => {
                                                            return <div key={ki} className="left-shift">
                                                                <p><strong>{ii}</strong> <em>
                                                                    {logFile[i][ii]}
                                                                </em></p>
                                                            </div>
                                                        })}


                                                    </div>
                                                })}
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                </ErrorBoundary>
            </div>
        </>
    );
};

export default LeadLog;
