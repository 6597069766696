import { GET_KIJIJI_LISTINGS, DELETE_KIJIJI_LISTING, ADD_KIJIJI_LISTING, 
    CLEAR_KIJIJI_LISTING, CLEAR_ALL, FETCH_KIJIJI_LISTING, 
    FETCH_RELATED_DEALER, 
    EDIT_KIJIJI_LISTING } from "constants/actions";

const initialState = {
    kijiji: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_KIJIJI_LISTINGS:
            return {
                ...state,
                kijiji: action.payload
            }; 
        case FETCH_RELATED_DEALER:
                return { ...state, related: action.payload };
                
        case FETCH_KIJIJI_LISTING:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_KIJIJI_LISTING:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_KIJIJI_LISTING:
            return {
                ...state,
                kijiji: state.kijiji.filter(dealer => dealer.id !== action.payload)
            };
        case ADD_KIJIJI_LISTING:
            return {
                ...state,
                kijiji: [...state.kijiji, action.payload]
            };
        case CLEAR_KIJIJI_LISTING:
            return {
                ...state,
                kijiji: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                kijiji: []
            };
        default:
            return state;
    }
}