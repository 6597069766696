import React from "react";
import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

import { useForm, Controller } from "react-hook-form";

// reactstrap components
import {
  Button,
  Collapse,
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  Input,
  Form,
  Spinner,
  Fade,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import Octicon, { BeakerIcon, ZapIcon } from "@primer/octicons-react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import {
  LOAD_INVENTORY,
  SG_UPDATE_ACTIVE_PAGE,
  // SG_GET_ACTIVE_PAGE,
  // SG_EDIT_INVENTORY,
  SG_EDIT_MILEAGE,
} from "constants/actions";
import TablePaginationActions from "mui/tables/TablePaginationActions";
import { Button as MUIButton, TextField } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

const searchOptions = [
  { value: "vin", label: "vin" },
  { value: "color", label: "color" },
  { value: "kilometers", label: "kilometers" },
  { value: "makes", label: "make" },
  { value: "body", label: "body" },
  { value: "models", label: "model" },
  { value: "years", label: "year" },
];

const SimpleList = (props) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,

    watch,
    errors,
    control,
    setValue,
    getValues,
    formState,
  } = useForm();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  let qMakeList = params.get("makes");
  let qYearList = params.get("years");
  let qModelList = params.get("models");
  qYearList = qYearList ? qYearList.split(",") : "";
  qMakeList = qMakeList ? qMakeList.split(",") : "";
  qModelList = qModelList ? qModelList.split(",") : "";

  const getParams = (years, makes, models) => {
    if (!years && !makes && !models) {
      return false;
    }
    // console.log(years, makes, models)
    let yearParams = [];
    let makeParams = [];
    let modelParams = [];
    if (years) {
      years.forEach((e) => {
        if (e) {
          yearParams.push(`&years=${e}`);
        }
      });
    }

    if (makes) {
      makes.forEach((e) => {
        if (e) {
          makeParams.push(`&makes=${e}`);
        }
      });
    }
    if (models) {
      models.forEach((e) => {
        if (e) {
          modelParams.push(`&models=${e}`);
        }
      });
    }
    const makePageParams = `${yearParams ? `${yearParams.join("")}` : ""}${
      makeParams ? `${makeParams.join("")}` : ""
    }${modelParams ? `${modelParams.join("")}` : ""}`;
    // console.log(activePage, pageparams)
    // console.log(`${yearParams? `&${yearParams}`: ""}${makeParams? `&${makeParams}` : ""}${modelParams? `&${modelParams}`: ""}`)
    return makePageParams;
  };
  const [fadeIn, setFadeIn] = React.useState(false);
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState(1);
  const [resetPage, setResetPage] = React.useState(false);
  const [pageparams, setPageparams] = React.useState(
    getParams(qYearList, qModelList, qModelList)
  );
  const [collapses, setCollapses] = React.useState([1]);
  const [inventoryList, setInventoryList] = React.useState([]);
  const [totalResults, setTotalResults] = React.useState(1);
  const [perPage, setPerPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  // let inventoryList = []

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log("Load Inventory", pageactivePage, pageparam
    dispatch({ type: LOAD_INVENTORY, payload: { pageactivePage, pageparams } });
  }, [dispatch, pageparams, pageactivePage]);

  // collapse states and functions
  React.useEffect(() => {
    const makePageParams = getParams(qYearList, qMakeList, qModelList);
    setPageparams(makePageParams);
  }, [qYearList, qMakeList, qModelList]);

  const { get_activePage, get_inventories, get_pending } = useSelector(
    (state) => ({
      get_activePage: state.paginate.inventory,
      get_inventories: state.inventories.inventories,
      // loadingInventory: state.inventories.loadinginventory,
      get_pending: state.inventories.pending,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_activePage) {
      setPageActivePage(get_activePage);
      setActivePage(get_activePage > 0 ? get_activePage - 1 : 0);
    }
  }, [get_activePage]);

  React.useEffect(() => {
    if (get_inventories) {
      setInventoryList(get_inventories.results);
      setTotalResults(get_inventories.count);
      setPerPage(get_inventories.page_size);
      setTotalPages(
        Math.ceil(get_inventories.count / get_inventories.page_size)
      );
    }
  }, [get_inventories]);

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    setPending(get_pending);
    // console.log('get_pending', get_pending, "****************************************")
  }, [get_pending]);

  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const paramChanged = (years, makes, models) => {
    // console.log('paramChanged')
    // dispatch(updateActivepage(1))
    setActivePage(0);
    setPageActivePage(1);
    const makePageParams = getParams(years, makes, models);
    setPageparams(makePageParams);
  };

  // const onKilometerChange = (e) => {
  //   console.log(e.target.value)
  // }

  // const onKilometerFocus = (e, id) => {
  //   console.log(e.target.value)

  // }

  const onKilometerBlur = (e, id) => {
    dispatch({
      type: SG_EDIT_MILEAGE,
      payload: { id, kilometers: e.target.value },
    });
    // console.log(e.target.value)
    // console.log(id)
  };

  const onSubmit = (data) => {
    let yearParams = [];
    // console.log(data.search.value);
    // console.log(data.value);
    if (data.search.value === "years") {
      let values = data.value.split(",");

      values.forEach((e) => {
        if (e) {
          yearParams.push(`&years=${e.trim()}`);
        }
      });

      setPageparams(yearParams.join(""));
    } else {
      setPageparams(`&${data.search.value}=${data.value}`);
    }
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "inventory", page: 0 },
    });
    setActivePage(0);
    setPageActivePage(1);
    setPage(0);
    // dispatch({type: LOAD_INVENTORY, payload: {pageactivePage, pageparams}})

    // console.log(data);
  };
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: {
        name: "inventory",
        page: newPage + 1,
      },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);

    // setActivePage(page.selected);
    // setPageActivePage(page.selected + 1);
  };

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)
  const showSpinner = () => {
    return <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />;
  };
  const resetParams = () => {
    dispatch({ type: LOAD_INVENTORY });
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "inventory", page: 0 },
    });
    setActivePage(0);
    setPageActivePage(1);
  };

  const handleClick = (page) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "inventory", page: page.selected + 1 },
    });
    setActivePage(page.selected);
    setPageActivePage(page.selected + 1);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <MUIButton
          color="preAuto"
          type="submit"
          variant="outlined"
          onClick={() => setFadeIn(!fadeIn)}
        >
          {fadeIn ? "Hide Filter" : "Show Filter"}
        </MUIButton>
        {/* <div className={fadeIn? "visible": "invisible"}>
        <Button  color="secondary" size="sm" onClick={resetParams}>
          Reset 
        </Button></div> */}
      </div>

      {/* <Collapse isOpen={fadeIn} className="mt-3 mb-3"> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row
          style={{
            alignItems: "center",
          }}
        >
          <Col md="3">
            <label>Search For</label>
            <Controller
              as={<Select />}
              name="search"
              options={searchOptions}
              control={control}
              className="text-dark"
              rules={{ required: "Please enter search" }}
            />{" "}
            <p className="text-danger small">
              {errors.search && <span>{errors.search.message}</span>}
            </p>
          </Col>
          <Col md="7">
            <label>Value</label>
            <Input
              type="text"
              name="value"
              innerRef={register({
                required: "Please enter a search value",
              })}
            />
            <p className="text-danger small">
              {errors.value && <span>{errors.value.message}</span>}
            </p>
          </Col>

          <Col md="2" className="mt-2">
            <MUIButton color="preAuto" type="submit" variant="outlined">
              Search
            </MUIButton>
          </Col>
        </Row>
      </Form>
      {/* </Collapse> */}
      <div className={pending ? "" : "d-none mt-3"}>
        <Fade in={pending} tag="h5">
          <div className="d-flex justify-content-center">
            <Spinner>Loading...</Spinner>
          </div>
        </Fade>
      </div>
      <div className={pending ? "d-none" : ""}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>YEAR</StyledTableCell>
                  <StyledTableCell align="center">MAKE</StyledTableCell>
                  <StyledTableCell align="center">MODEL</StyledTableCell>
                  <StyledTableCell align="center">COLOR</StyledTableCell>
                  <StyledTableCell align="center">KILOMETERS</StyledTableCell>
                  <StyledTableCell align="center">IN Date</StyledTableCell>
                  <StyledTableCell align="center">COST</StyledTableCell>
                  <StyledTableCell align="center">MORE</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryList.map((row) => (
                  <StyledTableRow key={row}>
                    <StyledTableCell align="center">{row.year}</StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.make}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.model}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.color}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        sx={{
                          maxWidth: "80px",
                          margin: 0,
                          padding: 0,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#1d4aa7",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1d4aa7",
                            },
                          },
                          " input": {
                            px: 1,
                            py: 0.5,
                          },
                        }}
                        defaultValue={row.kilometers}
                        //  onChange={(e) => onKilometerChange(e)}
                        //  onFocus={(e) => onKilometerFocus(e)}
                        onBlur={(e) => onKilometerBlur(e, row.id)}
                      />
                      {/* {row.kilometers} */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.veh_in_date}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.list_price}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {
                        <Link to={`/admin/inventory-detail/${row.id}`}>
                          <p
                            style={{
                              color: "#1d4aa7",
                              margin: 0,
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </p>
                        </Link>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[12]}
            component="div"
            count={totalResults || 0}
            rowsPerPage={12}
            page={page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
            sx={{
              ".MuiTablePagination-selectLabel": {
                marginTop: "auto",
              },
              ".MuiTablePagination-displayedRows": {
                marginTop: "auto",
              },
            }}
          />
        </Paper>
      </div>
    </>
  );
};

export default SimpleList;
