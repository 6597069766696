import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";
// import { registerUser } from "actions/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router";
import AuthWrapper from "./auth/AuthWrapper";
import Box from "@mui/material/Box";
import keyConfig from "../_config";
import { Typography } from "@mui/material";
import { Fonts } from "crema/constants/src";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import AppTextField from "../crema/AppFormComponents/AppTextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
// import { countries } from '@crema/mockapi';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  // Button,
} from "reactstrap";

// core components
import bgImage from "assets/img/bg/jan-sendereks.jpg";
import { REGISTER_DEALER_USER } from "constants/actions";
import { countryCode } from "constants/phoneCode";
import { loadStripe } from "@stripe/stripe-js";
import { CHECK_PAYMENT_STATUS } from "constants/actions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { onKeyPress } from "Utilities/ErrorFormat";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  first_name: yup.string().required("First Name Required"),
  last_name: yup.string().required("Last Name Required"),
  email: yup.string().email("Email Format").required("Email Required"),
  password: yup.string().required("password Required"),
  confirm_password: yup.string().required("Confirm Password"),
  contry_code: yup.string().required("Choose Your Contry Code"),
  confirm_password: yup
    .string()
    .required("Repeat Password is required")
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  company_name: yup.string().required("Dealership Name Required"),
  city: yup.string().required("Dealership Address Required"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});

const RegisterDealer = () => {
  const { register, handleSubmit, watch, reset, errors, control } = useForm({
    defaultValues: {
      country_code: "+91",
    },
  });

  const search = useLocation().search;
  const plan_id = new URLSearchParams(search).get("plan_id");
  console.log(plan_id);

  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [alert, setAlert] = React.useState(false);
  const [passwordFocus, setpasswordFocus] = React.useState(false);
  const [registered, setRegistered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [regError, setRegError] = React.useState(false);
  const password = React.useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch();
  let history = useHistory();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const { authenticated, registerError, registerFail, get_registered } =
    useSelector(
      (state) => ({
        auth: state.auth,
        get_registered: state.auth.registeredDealer,
        registerError: state.errors.msg,
        registerFail: state.auth.registeredDealerError,
        authenticated: state.auth.isAuthenticated,
      }),
      shallowEqual
    );
  if (authenticated) {
    history.push("/user/dashboard-sales");
  }

  React.useEffect(() => {
    if (get_registered.status === 201) {
      setIsLoading(false);
      setRegistered(get_registered);
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => goLogin()}
          confirmBtnBsStyle="info"
        >
          You are sucessfully r egister.
        </SweetAlert>
      );
    }
  }, [get_registered]);
  React.useEffect(() => {
    setIsLoading(false);
    if (_.get(registerError, "data")) {
      setRegError(registerError.data?.message);
    }
  }, [registerError]);

  const onSubmit = (data) => {
    setRegError("");
    setIsLoading(true);
    console.log(data, "Data");
    const modifiedData = { ...data, plan_id: plan_id ? plan_id : null };
    dispatch({ type: REGISTER_DEALER_USER, payload: modifiedData });
  };

  function goLogin() {
    setAlert(null);
    reset();
    // history.push("/auth/login");
  }
  const email_validation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return (
    // <>
    //   <div className="register-page">
    //     <Container>
    //       <Row className="justify-content-center">
    //         <Col className="ml-auto" lg="5" md="5">
    //           <div className="info-area info-horizontal mt-5">
    //             <div className="icon icon-primary">
    //               <i className="nc-icon nc-tv-2" />
    //             </div>
    //             <div className="description">
    //               <h5 className="info-title">Secure</h5>
    //               <p className="description">
    //                 Now your information is secure in cloud with named user
    //                 login
    //               </p>
    //             </div>
    //           </div>
    //           <div className="info-area info-horizontal">
    //             <div className="icon icon-primary">
    //               <i className="nc-icon nc-html5" />
    //             </div>
    //             <div className="description">
    //               <h5 className="info-title">All Information</h5>
    //               <p className="description">
    //                 You have all the information to help you work effeciently
    //               </p>
    //             </div>
    //           </div>
    //           <div className="info-area info-horizontal">
    //             <div className="icon icon-info">
    //               <i className="nc-icon nc-atom" />
    //             </div>
    //             <div className="description">
    //               <h5 className="info-title"></h5>
    //               <p className="description"></p>
    //             </div>
    //           </div>
    //         </Col>

    //         <Col lg={6} md={8} xs={12}>
    //           <Card className="card-signup">
    //             <CardHeader className="text-center">
    //               <CardTitle tag="h4">Dealer Register</CardTitle>
    //               {/* <p className="card-description">
    //                 Only Pre-Registered and Authorized employees can register
    //               </p> */}
    //             </CardHeader>
    //             <CardBody>

    //               <Form onSubmit={handleSubmit(onSubmit)}>
    //                 <FormGroup
    //                   className={errors.first_name ? "has-danger" : ""}
    //                 >
    //                   <Label className="text-dark">First name</Label>
    //                   <Input
    //                     type="text"
    //                     name="first_name"

    //                     placeholder="First name"
    //                     innerRef={register({
    //                       required: "First name is required",
    //                       maxlength: 20,
    //                     })}
    //                   />
    //                   <p className="text-danger ">
    //                     {errors.first_name && (
    //                       <span>First name is required</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>
    //                 <FormGroup
    //                   className={errors.last_name ? "has-danger" : ""}
    //                 >
    //                   <Label className="text-dark">Last name</Label>
    //                   <Input
    //                     type="text"
    //                     name="last_name"

    //                     placeholder="Last name"
    //                     innerRef={register({
    //                       required: "Last name is required",
    //                       maxlength: 20,
    //                     })}
    //                   />
    //                   <p className="text-danger ">
    //                     {errors.last_name && (
    //                       <span>Last name is required</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>

    //                 <FormGroup className={errors.email ? "has-danger" : ""}>
    //                   <Label className="text-dark">Email</Label>
    //                   <Input
    //                     type="email"
    //                     name="email"

    //                     placeholder="Email Id"
    //                     innerRef={register({
    //                       required: "Email is required",
    //                       pattern: {
    //                         value: email_validation,
    //                         message: "invalid email address",
    //                       },
    //                       maxlength: 20,
    //                     })}
    //                   />
    //                   <p className="text-danger ">
    //                     {errors.email && <span>{errors.email.message}</span>}
    //                     {/* {errors.email && errors.email.pattern  && (
    //                           <span>Email format not valid</span>
    //                         )} */}
    //                   </p>
    //                 </FormGroup>
    //                 <FormGroup className={errors.phone_number ? "has-danger" : ""}>
    //                   <Label className="text-dark">Phone number</Label>
    //                   <div className="d-flex align-items-center">
    //                     <Input type="select" name="country_code" className='mr-2 selectForm' innerRef={register({
    //                       required: "Country code is required",
    //                       pattern: {
    //                         value: errors.country_code,
    //                         message: "Country code is required",
    //                       },
    //                       maxlength: 20,
    //                     })}>
    //                       {
    //                         countryCode?.map((item, key) => <option key={key} value={item.value}>{item.name}</option>)
    //                       }
    //                     </Input>
    //                     <Input
    //                       type="text"
    //                       name="phone_number"
    //                       className='w-100'
    //                       placeholder="Phone number"
    //                       onKeyPress={onKeyPress}
    //                       innerRef={register({
    //                         required: "Phone number is required",
    //                         pattern: {
    //                           value: /^[0-9\b]+$/,
    //                           message: "Phone number is required",
    //                         },
    //                         maxlength: 20,
    //                       })}
    //                     />
    //                   </div>
    //                   <p className="text-danger ">
    //                     {errors.phone_number && <span>Phone number is required</span>}
    //                     {/* {errors.email && errors.email.pattern  && (
    //                           <span>Email format not valid</span>
    //                         )} */}
    //                   </p>
    //                 </FormGroup>
    //                 <FormGroup className={errors.password ? "has-danger" : ""}>
    //                   <Label className="text-dark">Password</Label>
    //                   <InputGroup
    //                     className={passwordFocus ? "input-group-focus" : ""}
    //                   >
    //                     {/* <InputGroupAddon addonType="prepend"> */}
    //                     <InputGroupText>
    //                       <i className="now-ui-icons ui-1_lock-circle-open" />
    //                     </InputGroupText>
    //                     {/* </InputGroupAddon> */}
    //                     <Input
    //                       type={showPass?"text":"password"}
    //                       name="password"
    //                       placeholder="Password"
    //                       innerRef={register({
    //                         required: "You must specify a password",
    //                         minLength: {
    //                           value: 8,
    //                           message:
    //                             "Password must have at least 8 characters",
    //                         },
    //                       })}
    //                     />
    //                     <button type="button" className="position-absolute  password_icon" onClick={() => setShowPass(!showPass)} >
    //                       {
    //                         showPass ?
    //                           <Visibility />
    //                           :
    //                           <VisibilityOff />
    //                       }
    //                     </button>
    //                   </InputGroup>
    //                   <p className="text-danger ">
    //                     {errors.password && (
    //                       <span>{errors.password.message}</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>

    //                 <FormGroup className={errors.confirm_password ? "has-danger" : ""}>
    //                   <Label className="text-dark">Repeat Password</Label>
    //                   <InputGroup
    //                     className={"" + passwordFocus ? "input-group-focus" : ""}
    //                   >
    //                     {/* <InputGroupAddon addonType="prepend"> */}
    //                     <InputGroupText>
    //                       <i className="now-ui-icons ui-1_lock-circle-open" />
    //                     </InputGroupText>
    //                     {/* </InputGroupAddon> */}
    //                     <Input
    //                       type={showPassConf?"text":"password"}
    //                       name="confirm_password"
    //                       placeholder="Re Type Password"
    //                       innerRef={register({
    //                         validate: (value) =>
    //                           value === password.current ||
    //                           "The passwords do not match",
    //                       })}
    //                     />

    //                     <button type="button" className="position-absolute top-50 password_icon" onClick={() => setShowPassConf(!showPassConf)} >
    //                       {
    //                         showPassConf ?
    //                           <Visibility />
    //                           :
    //                           <VisibilityOff />
    //                       }
    //                     </button>
    //                   </InputGroup>
    //                   <p className="text-danger ">
    //                     {errors.confirm_password && (
    //                       <span>{errors.confirm_password.message}</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>
    //                 <FormGroup
    //                   className={errors.company_name ? "has-danger" : ""}
    //                 >
    //                   <Label className="text-dark">Dealership Name</Label>
    //                   <Input
    //                     type="text"
    //                     name="company_name"

    //                     placeholder="Dealership Name"
    //                     innerRef={register({
    //                       required: "Dealership name is required",
    //                       maxlength: 20,
    //                     })}
    //                   />
    //                   <p className="text-danger ">
    //                     {errors.company_name && (
    //                       <span>Dealership name is required</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>
    //                 <FormGroup
    //                   className={errors.city ? "has-danger" : ""}
    //                 >
    //                   <Label className="text-dark">Dealership Address</Label>
    //                   <Input
    //                     type="text"
    //                     name="city"

    //                     placeholder="Dealership address"
    //                     innerRef={register({
    //                       required: "Dealership address is required",
    //                       maxlength: 20,
    //                     })}
    //                   />
    //                   <p className="text-danger ">
    //                     {errors.city && (
    //                       <span>Dealership address is required</span>
    //                     )}
    //                   </p>
    //                 </FormGroup>
    //               </Form>
    //             </CardBody>
    //             <CardFooter className="text-center">
    //               <div className="text-danger mb-3">
    //                 {regError ? regError : null} <br />
    //                 {/*      <span>{registerFail}</span> */}
    //               </div>
    //               <Button
    //                 color="primary"
    //                 size="lg"
    //                 className="btn-round"
    //                 onClick={handleSubmit(onSubmit)}
    //               >
    //                 {
    //                   isLoading ? 'Loading...' : 'Get Started'
    //                 }
    //               </Button>
    //             </CardFooter>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    //   <div
    //     className="full-page-background"
    //     style={{ backgroundImage: "url(" + bgImage + ")" }}
    //   />
    //   {alert}
    // </>
    <>
      <AuthWrapper
        rightSection={
          <>
            <Box
              sx={{
                maxWidth: 320,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "2em",
                  }}
                >
                  {/* <img
                style={{
                  width: 100,
                  // height: 100,
                  objectFit: "cover",
                }}
                src={AppHtmlLogo}
                alt="app-logo"
              /> */}
                  <i className="nc-icon nc-tv-2" />
                </Box>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 30,
                    mb: 2,
                    mt: 0.8,
                  }}
                >
                  Secure
                </Typography>
              </Box>
              <Typography>
                Now your information is secure in cloud with named user login
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: 320,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                {/* <img
                style={{
                  width: 100,
                  // height: 100,
                  objectFit: "cover",
                }}
                src={AppHtmlLogo}
                alt="app-logo"
              /> */}
                <Box
                  sx={{
                    fontSize: "2em",
                    mt: 0.8,
                  }}
                >
                  <i className="nc-icon nc-html5" />
                </Box>
                <Typography
                  component="h2"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 30,
                    mt: 2,
                    mb: 2,
                  }}
                >
                  All Information
                </Typography>
              </Box>
              <Typography>
                You have all the information to help you work effeciently
              </Typography>
            </Box>
          </>
        }
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", mb: 1.5 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                mb: 2,
              }}
            >
              Dealer Register
            </Box>
            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              mb: 1.5,
            }}
          >
            Only Pre-Registered and Authorized employees can register
          </Box> */}
            <Formik
              validateOnChange={true}
              initialValues={{
                // dealer_code: "",
                first_name: "",
                last_name: "",
                email: "",
                contry_code: "",
                password: "",
                confirm_password: "",
                company_name: "",
                city: "",
                phone: "",
                // agreeTerms: false,
              }}
              // validationSchema={validationSchema}
              // onSubmit={(data, { setSubmitting }) => {
              //   setSubmitting(true);
              //   console.log("data", data);
              //   onSubmit(data);
              //   // registerUserWithEmailAndPassword(data);
              //   console.log(
              //     "registerUserWithEmailAndPassword"
              //     // registerUserWithEmailAndPassword
              //   );
              //   setSubmitting(false);
              //   const modifiedData = {
              //     ...data,
              //     plan_id: plan_id ? plan_id : null,
              //   };
              //   dispatch({ type: REGISTER_DEALER_USER, payload: modifiedData });
              // }}
              onSubmit={onSubmit}
            >
              {({ field, values, errors, isSubmitting, setFieldValue }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  {/* <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                  <AppTextField
                    label="Dealer Code"
                    name="dealer_code"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        fontSize: 14,
                      },
                    }}
                  />
                </Box> */}

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="First Name"
                      name="first_name"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Last Name"
                      name="last_name"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Email"
                      name="email"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", mb: { xs: 2, xl: 3 } }}>
                    <FormControl sx={{ mr: 1, minWidth: 120 }}>
                      <InputLabel id="demo-select-small-label">
                        Contry
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="AgContrye"
                        value={values.contry_code}
                        name="contry_code"
                        onChange={(e) => {
                          setFieldValue("contry_code", e.target.value);
                        }}
                      >
                        {countryCode?.map((item, key) => (
                          <MenuItem key={key} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <AppTextField
                      label="Phone Number"
                      name="phone"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Password"
                      name="password"
                      type="password"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Repeat Password"
                      name="confirm_password"
                      type="password"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Dealership Name"
                      name="company_name"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: { xs: 2, xl: 3 } }}>
                    <AppTextField
                      label="Dealership Address"
                      name="city"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          fontSize: 14,
                        },
                      }}
                    />
                  </Box>
                  {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={values.agreeTerms}
                        onChange={() => {
                          setFieldValue("agreeTerms", event.target.checked);
                        }}
                        color="primary"
                        sx={{ ml: -1.5 }}
                      />
                    }
                    label={
                      <>
                        <Box
                          component="span"
                          sx={{
                            mr: 0.5,
                            color: "grey.500",
                            fontSize: 14,
                            mb: -0.5,
                          }}
                        >
                          I agree to the
                          <Link
                            to={"/auth/acceptable-use-policy"}
                            style={{
                              textDecoration: "none",
                              color: "#1d4aa7",
                              marginLeft: "5px",
                            }}
                          >
                            terms and conditions.
                          </Link>
                        </Box>
                      </>
                    }
                  />
                </Box>
                {errors.agreeTerms && (
                  <Box
                    component="span"
                    sx={{
                      color: "red",
                      fontSize: 12,
                    }}
                  >
                    {errors.agreeTerms}
                  </Box>
                )} */}

                  <div>
                    <Button
                      variant="contained"
                      color="preAuto"
                      disabled={isLoading}
                      sx={{
                        minWidth: 160,
                        fontWeight: 400,
                        fontSize: 16,
                        padding: "4px 16px 8px",
                        textTransform: "inherit !important",
                      }}
                      type="submit"
                    >
                      Get Started
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
          {alert}
          {/* <Box
            sx={{
              color: "grey.500",
              mb: { xs: 1, md: 0 },
            }}
          >
            <span style={{ marginRight: 4 }}>Already Have Account</span>
            <Box
              component="span"
              sx={{
                fontWeight: 500,
                "& a": {
                  color: "#1d4aa7",
                  textDecoration: "none",
                },
              }}
            >
              <Link to="/signIn">Sign In</Link>
            </Box> */}
          {/* </Box> */}
        </Box>
      </AuthWrapper>
    </>
  );
};

export default RegisterDealer;
