import { 
    GET_LEAD_STAGES,
    GET_DEFAULT_LEAD_STAGES,
    DELETE_LEAD_STAGE,
    ADD_LEAD_STAGE,
    EDIT_LEAD_STAGE, 
    FETCH_LEAD_STAGE,
    CLEAR_ALL } from "constants/actions";

const initialState = {
    leadstages: [],
 
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LEAD_STAGES:
            return {
                ...state,
                leadstages: action.payload
            };
        case GET_DEFAULT_LEAD_STAGES:
            return {
                ...state,
                default: action.payload
            };
        case EDIT_LEAD_STAGE:
            return { ...state, [action.payload.id]: action.payload };
        case FETCH_LEAD_STAGE:
                return { ...state, [action.payload.id]: action.payload };
        case DELETE_LEAD_STAGE:
            return {
                ...state,
                leadstages: state.leadstages.filter(web => web.id !== action.payload)
            };
        case ADD_LEAD_STAGE:
            return {
                ...state,
                leadstages: [...state.leadstages, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                leadstages: []
            };
        default:
            return state;
    }
}