import React from 'react';
import { Link } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    
  } from "reactstrap";

import List from "TradeIns/List"
const index = () => {
    return (
        <>
        <div className="content">
        <Card className="card-tasks">
          <CardBody>
        <Row>
            <Col>
            <CardHeader>
          <CardTitle tag="h4">Trade Ins</CardTitle>
          <h5 className="card-category">All Open Trade Ins</h5>
        </CardHeader>
             
            </Col>
            <Col className="ml-auto" md="2">
                    {/* <Button
                      color="default"
                      size="sm"
                      
                      to="/admin/trade-in-create"
                      tag={Link}
                    >
                      Create New
                    </Button> */}
                  </Col>
                 
          </Row>
          
        
        
            <List />
            </CardBody>
        <CardFooter>
          <hr />
          {/* <div className="stats">
            <i className="fa fa-refresh spin" />
            Updated 3 minutes ago
          </div> */}
        </CardFooter>
      </Card>
        </div>
        </>
    );
};

export default index;