import React from "react";

// reactstrap components
import {

  Table,
 
} from "reactstrap";

// import "./style.css"
const TechnicalSpecifications = ({ equipment }) => {
  const [equipmentList, setEquipmentList] = React.useState([]);
  React.useEffect(() => {
    if (equipment) {
      if (equipment[0]) {
      setEquipmentList(equipment);
    }
    }
    
  }, [equipment]);
  // console.log(equipment);
  return (
    <>
      <div className="content">
        <Table striped bordered>
          <tbody>
            {equipmentList? equipmentList.map((p, k) => {
              return (
                <tr key={k}>
                  <td className="table-head-content">{p.name}</td>
                  <td>{p.value}</td>
                </tr>
              );
            }):  null}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TechnicalSpecifications;
