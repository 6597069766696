import { EditorInLineConvertToHTML } from 'Inventory/InventoryDetail/EditorConvertToHTML';
import React from 'react'
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Input,
    Form,
  } from "reactstrap";
  import {
    CASH_FOR_CARS_CMS,
    FETCH_CASH_FOR_CARS_CMS_VALUE,
    LOAD_GET_CASHFORCARS,
    UPDATE_CASH_FOR_CARS,
    CMS_PRIVACY_POLICY_TYPE,
    LOAD_CMS_PRIVACY_TYPE,
    UPDATE_CMS_PRIVACY_PAGE_TYPE,
    CMS_TERMS_TYPE,
    LOAD_CMS_TERMS_TYPE,
    UPDATE_CMS_TERMS_PAGE_TYPE,
  } from "constants/actions";
  import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
export default function TermsCMS() {




  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const [editSubject, setEditSubject] = React.useState("");
  const notify = () => toast.success(`Data Updated successfully`);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_CMS_TERMS_TYPE, payload: id });
  }, [dispatch, id]);


  const { get_auth, get_terms } = useSelector(
    (state) => ({
     get_terms: state.terms.terms,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_terms) {
      setEditSubject(get_terms["results"]?.[0]?.subjectContent);
    } 
  }, [get_terms]);

  const BtnCallBackTermsPage = (data) => {
    dispatch({
      type: UPDATE_CMS_TERMS_PAGE_TYPE,
      payload: {
        id: get_terms["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  //   const onSubmit = (data) => {
  //   dispatch({
  //     type: CMS_TERMS_TYPE,
  //     payload: {
  //       subjectContent: data.subjectContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //  notify()
  // };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">Terms & Conditions CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>
              </Row>

              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Subject And Content</label>
                  <Input
                    type="text"
                    name="subjectContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form> */}
              
              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject & Content "
                     editContent={editSubject}
                    BtnCallBack={BtnCallBackTermsPage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
