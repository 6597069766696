// import React from "react";
// import { Link, NavLink, useParams } from "react-router-dom";
// import { Nav, Collapse } from "reactstrap";
// // javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
// import logo from "assets/img/datastruk.png";
// import AdminNavbar from "../Navbars/AdminNavbar"
// import InSideBar from "User/InSideBar"
// import { Dropdown } from "bootstrap";
// var ps;

// class Sidebar extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = this.getCollapseStates(props.routes);
//   }

//   // this creates the intial state of this component based on the collapse routes
//   // that it gets through props.routes
//   getCollapseStates = routes => {
//     // const logged_user_id = props.logged_user.role_id

//     let initialState = {};
//     routes.map((prop, key) => {
//       if (prop.collapse) {
//         initialState = {
//           [prop.state]: this.getCollapseInitialState(prop.views),
//           ...this.getCollapseStates(prop.views),
//           ...initialState
//         };
//       }
//       return null;
//     });
//     return initialState;
//   };
//   // this verifies if any of the collapses should be default opened on a rerender of this component
//   // for example, on the refresh of the page,
//   // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
//   getCollapseInitialState(routes) {
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
//         return true;
//       } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
//         return true;
//       }
//     }
//     return false;
//   }

//   // this function creates the links and collapses that appear in the sidebar (left menu)
//   createLinks = routes => {
//     // console.log(props.logged_user)
//     return routes.map((prop, key) => {
//       if(this.props?.routeSelected[prop.name])
//       {
//         return null;
//       }
//       if (prop.redirect) {
//         return null;
//       }

//       if (props.isStaff === "true" || props.isStaff === true) {

//         if (prop.isStaff === false) {
//           return null;
//         }
//       }

//       if (props.isStaff === "false" || props.isStaff === false) {
//         if (prop.isStaff === true) {
//           return null;
//         }
//       }
//       // if(props.company_id === 2 && prop.name === "Vin Decode Full")
//       // {
//       //   return null;
//       // }
//       // if(props.company_id === 2 && prop.name === "BlackBook")
//       // {
//       //   return null;
//       // }
//       // if(props.company_id === 2 && prop.name === "CMS")
//       // {
//       //   return null;
//       // }
//       // if (!props.isStaff) {
//       //   // console.log(this.props)
//       //   if (prop.isStaff) {
//       //     console.log(prop.isStaff, prop.path)
//       //     console.log(props.logged_user.is_staff)
//       //     return null;
//       //   }
//       // }

//       if (props.logged_user && prop.role ) {
//         // console.log("Evalution", prop.name,props.logged_user.role_id, "role", prop.role, "user role id", props.userroleid)
//         if (parseInt(props.logged_user.role_id) > parseInt(prop.role)) {
//           return null;
//         }
//         if (parseInt(prop.role) <= parseInt(props.userroleid)) {
//           return null;
//           }

//           if (!prop.visible) {
//             return null;
//           }
//       }

//       // Check Role here

//       if (prop.collapse) {
//         var st = {};
//         st[prop["state"]] = !this.state[prop.state];
//         return (
//           <li
//             className={this.getCollapseInitialState(prop.views) ? "active" : ""}
//             key={key}
//           >
//             <a
//               href="#pablo"
//               data-toggle="collapse"
//               aria-expanded={this.state[prop.state]}
//               onClick={e => {
//                 e.preventDefault();
//                 this.setState(st);
//               }}
//             >
//               {prop.icon !== undefined ? (
//                 <>
//                   {/* <i className={prop.icon} />
//                   <p>
//                     {prop.name}f
//                     <b className="caret" />
//                   </p> */}

//                   <li class="nav-item active">
//                   <a class="nav-link" href="#">
//                     <span class="menu-title">{prop.name}</span>
//                     <b className="caret menu-arrow" />
//                     <i class="menu-icon"><i className={prop.icon} /></i>
//                       {/*  */}
//                     </a>
//                     </li>
//                 </>
//               ) : (
//                 <>
//                   <span className="sidebar-mini-icon">{prop.mini}</span>
//                   <span className="sidebar-normal">
//                     {prop.name}
//                     <b className="caret" />
//                   </span>
//                 </>
//               )}
//             </a>
//             <Collapse isOpen={this.state[prop.state]}>
//               <ul className="nav">{this.createLinks(prop.views)}</ul>
//             </Collapse>
//           </li>
//         );
//       }
//       return (
//         <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
//           <NavLink to={prop.layout + prop.path} activeClassName="">
//             {prop.icon !== undefined ? (
//               <>
//                 {/* <i className={prop.icon} />
//                 <p>{prop.name}</p> */}
//                 <li class="nav-item active">
//                   <a class="nav-link" href="/demo/purple-react-free/template/demo_1/preview/dashboard">
//                     <span class="menu-title">{prop.name}</span>
//                     <i class="menu-icon"><i className={prop.icon} /></i>
//                       {/*  */}
//                     </a>
//                     </li>
//               </>
//             ) : (
//               <>
//                 <span className="sidebar-mini-icon">{prop.mini}</span>
//                 <span className="sidebar-normal">{prop.name}</span>
//               </>
//             )}
//           </NavLink>
//         </li>
//       );
//     });
//   };
//   // verifies if routeName is the one active (in browser input)
//   activeRoute = routeName => {
//     return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
//   };
//   componentDidMount() {
//     // if you are using a Windows Machine, the scrollbars will have a Mac look
//     if (navigator.platform.indexOf("Win") > -1) {
//       ps = new PerfectScrollbar(this.refs.sidebar, {
//         suppressScrollX: true,
//         suppressScrollY: false
//       });
//     }
//   }
//   componentWillUnmount() {
//     // we need to destroy the false scrollbar when we navigate
//     // to a page that doesn't have this component rendered
//     if (navigator.platform.indexOf("Win") > -1) {
//       ps.destroy();
//     }
//   }
//   render() {

//     return (
//       <>
//       <div
//         className="sidebar sidebar-drawer"

//         data-color={props.bgColor}
//         data-active-color={props.activeColor}
//       >
//         <div className="logo">
//           <a
//             href="/"
//             className="simple-text logo-mini"
//             >
//             <div className="logo-img">
//               <img src={logo} alt="react-logo" />
//             </div>
//           </a>
//           <a
//             href="/"
//             className="simple-text logo-normal"
//           >
//             Pre Auto Co
//           </a>
//         </div>

//         <div className="sidebar-wrapper sidebar-drawer" ref="sidebar">
//          <InSideBar user={props.logged_user}/>
//          {/* <AdminNavbar user={props.logged_user}/> */}
//           <Nav>{this.createLinks(props.routes)}</Nav>
//         </div>
//       </div>
//       </>
//     );
//   }
// }

// export default Sidebar;

import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import logo from "assets/img/datastruk.png";
import AdminNavbar from "../Navbars/AdminNavbar";
import InSideBar from "User/InSideBar";
import { Dropdown } from "bootstrap";
import AppSidebar from "./AppSidebar";
import { compact as _compact } from "lodash";
import AppHeader from "../AppHeader";
let ps;

const Sidebar = ({ isNavCollapsed, toggleNavCollapsed, ...props }) => {
  const [collapseStates, setCollapseStates] = useState({});
  const sidebarRef = useRef(null);

  // useEffect(() => {
  //   setCollapseStates(getCollapseStates(props.routes));

  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }

  //   return () => {
  //     // we need to destroy the false scrollbar when we navigate
  //     // to a page that doesn't have this component rendered
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // }, [props.routes]);

  // Function to get the initial state of collapses
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.forEach((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
    });
    return initialState;
  };

  // Function to verify if any of the collapses should be default opened on a rerender of this component
  const getCollapseInitialState = (routes) => {
    // for (let i = 0; i < routes.length; i++) {
    //   if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
    //     return true;
    //   } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
    //     return true;
    //   }
    // }
    return false;
  };

  // Function to create the links and collapses that appear in the sidebar (left menu)
  // const createLinks = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (props?.routeSelected[prop.name]) {
  //       return null;
  //     }
  //     if (prop.redirect) {
  //       return null;
  //     }

  //     if (props.isStaff === "true" || props.isStaff === true) {
  //       if (prop.isStaff === false) {
  //         return null;
  //       }
  //     }

  //     if (props.isStaff === "false" || props.isStaff === false) {
  //       if (prop.isStaff === true) {
  //         return null;
  //       }
  //     }

  //     if (props.logged_user && prop.role) {
  //       if (parseInt(props.logged_user.role_id) > parseInt(prop.role)) {
  //         return null;
  //       }
  //       if (parseInt(prop.role) <= parseInt(props.userroleid)) {
  //         return null;
  //       }

  //       if (!prop.visible) {
  //         return null;
  //       }
  //     }

  //     if (prop.collapse) {
  //       const st = {};
  //       st[prop["state"]] = !collapseStates[prop.state];
  //       return (
  //         <li
  //           className={getCollapseInitialState(prop.views) ? "active" : ""}
  //           key={key}
  //         >
  //           <a
  //             href="#pablo"
  //             data-toggle="collapse"
  //             aria-expanded={collapseStates[prop.state]}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               setCollapseStates(st);
  //             }}
  //           >
  //             {prop.icon !== undefined ? (
  //               <>
  //                 <li class="nav-item active">
  //                   <a class="nav-link" href="#">
  //                     <span class="menu-title">{prop.name}</span>
  //                     <b className="caret menu-arrow" />
  //                     <i class="menu-icon">
  //                       <i className={prop.icon} />
  //                     </i>
  //                   </a>
  //                 </li>
  //               </>
  //             ) : (
  //               <>
  //                 <span className="sidebar-mini-icon">{prop.mini}</span>
  //                 <span className="sidebar-normal">
  //                   {prop.name}
  //                   <b className="caret" />
  //                 </span>
  //               </>
  //             )}
  //           </a>
  //           <Collapse isOpen={collapseStates[prop.state]}>
  //             <ul className="nav">{createLinks(prop.views)}</ul>
  //           </Collapse>
  //         </li>
  //       );
  //     }
  //     return (
  //       <li className={activeRoute(prop.layout + prop.path)} key={key}>
  //         <NavLink to={prop.layout + prop.path} activeClassName="">
  //           {prop.icon !== undefined ? (
  //             <>
  //               <li class="nav-item active">
  //                 <a
  //                   class="nav-link"
  //                   href="/demo/purple-react-free/template/demo_1/preview/dashboard"
  //                 >
  //                   <span class="menu-title">{prop.name}</span>
  //                   <i class="menu-icon">
  //                     <i className={prop.icon} />
  //                   </i>
  //                 </a>
  //               </li>
  //             </>
  //           ) : (
  //             <>
  //               <span className="sidebar-mini-icon">{prop.mini}</span>
  //               <span className="sidebar-normal">{prop.name}</span>
  //             </>
  //           )}
  //         </NavLink>
  //       </li>
  //     );
  //   });
  // };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (props?.routeSelected[prop.name]) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }

      if (props.isStaff === "true" || props.isStaff === true) {
        if (prop.isStaff === false) {
          return null;
        }
      }

      if (props.isStaff === "false" || props.isStaff === false) {
        if (prop.isStaff === true) {
          return null;
        }
      }

      // if(props.company_id === 2 && prop.name === "Vin Decode Full")
      // {
      //   return null;
      // }
      // if(props.company_id === 2 && prop.name === "BlackBook")
      // {
      //   return null;
      // }
      // if(props.company_id === 2 && prop.name === "CMS")
      // {
      //   return null;
      // }
      // if (!props.isStaff) {
      //   // console.log(this.props)
      //   if (prop.isStaff) {
      //     console.log(prop.isStaff, prop.path)
      //     console.log(props.logged_user.is_staff)
      //     return null;
      //   }
      // }

      if (props.logged_user && prop.role) {
        if (parseInt(props.logged_user.role) === 10 && !prop.isDealer) {
          return null;
        }

        // console.log("Evalution", prop.name,props.logged_user.role_id, "role", prop.role, "user role id", props.userroleid)
        if (
          parseInt(props.logged_user.role_id) > parseInt(prop.role) &&
          Number(props.logged_user.role) !== 8 &&
          Number(props.logged_user.role) !== 6
        ) {
          return null;
        }
        if (
          parseInt(prop.role) < parseInt(props.userroleid) &&
          Number(props.logged_user.role) !== 8 &&
          Number(props.logged_user.role) !== 6
        ) {
          //show employee routes
          return null;
        }

        if (!prop.visible) {
          return null;
        }
      }

      // Check Role here

      return prop;
    });
  };

  // verifies if routeName is the one active (in browser input)
  // activeRoute = routeName => {
  //   return props.location?.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // componentDidMount() {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebar, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //   }
  // }
  // componentWillUnmount() {
  //   // we need to destroy the false scrollbar when we navigate
  //   // to a page that doesn't have this component rendered
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  // }
  // render() {

  // Function to verify if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    // return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <>
      <AppSidebar
        routesConfig={_compact(createLinks(props.routes))}
        isNavCollapsed={isNavCollapsed}
        toggleNavCollapsed={toggleNavCollapsed}
        user={props.logged_user}
      ></AppSidebar>

      {/* <div
        className="sidebar sidebar-drawer"
        data-color={props.bgColor}
        data-active-color={props.activeColor}
      >
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a href="/" className="simple-text logo-normal">
            Pre Auto Co
          </a>
        </div> */}

      {/* <div className="sidebar-wrapper sidebar-drawer" ref={sidebarRef}> */}
      {/* <InSideBar user={props.logged_user} /> */}
      {/* <Nav>{createLinks(props.routes)}</Nav> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Sidebar;
