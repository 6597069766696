import { call, put, all, takeLatest } from 'redux-saga/effects';

import { GET_UPLOAD_FILES_PENDING, 
  GET_UPLOAD_FILES, 
  LOAD_UPLOAD_FILES, 
    UPLOAD_REQUEST, UPLOAD_PROGRESS, 
    ADD_FILE_UPLOAD, UPLOAD_FAILURE, 
    FETCH_UPLOAD_FILES_PENDING, 
    FETCH_UPLOAD_FILES, SG_FETCH_UPLOAD_FILES, 
    DELETE_UPLOAD_FILE, ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';

function* uploadFile(action) {
    yield put({type: UPLOAD_PROGRESS});
    // console.log(action.payload)
    try {
    const json = yield call(api.UPLOAD_FILE, action.payload)
    // console.log(json.data)
    yield put({ type: ADD_FILE_UPLOAD, payload: json.data });
    }
    catch (e) {
    yield put({type: UPLOAD_FAILURE,  payload: e});
    yield put({type: ALL_ERRORS, message: e});
    }
}

export function* fileUploadRequest() {
   yield takeLatest(UPLOAD_REQUEST, uploadFile);
}



function* getAllFileUploads(action) {
    yield put({ type: GET_UPLOAD_FILES_PENDING });
    try {
      const json = yield call(api.GET_ALL_FILE_UPLOADS, action.payload)
      yield put({ type: GET_UPLOAD_FILES, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* fileuploadsLoad() {
    yield takeLatest(LOAD_UPLOAD_FILES, getAllFileUploads);
  }
  
  function* fetchUpload(action) {
    yield put({ type: FETCH_UPLOAD_FILES_PENDING });
    try {
      const json = yield call(api.FETCH_UPLOADED_FILE, action.payload)
      yield put({ type: FETCH_UPLOAD_FILES, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* fileuploadFetchOne() {
    yield takeLatest(SG_FETCH_UPLOAD_FILES, fetchUpload);
  }

export default function* index() {
    yield all([fileUploadRequest(), 
      fileuploadsLoad(), 
      fileuploadFetchOne()]);
  }
