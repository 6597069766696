import React from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

import _ from "lodash";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Button as MUIButton, TextField } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// reactstrap components
import { Button, Alert, Spinner, Fade, Row, Col } from "reactstrap";

import {
  LOAD_INVENTORY_LIST,
  SG_FETCH_RELATED_DEALER,
  LOAD_GSHEET_INVENTORY_LIST,
  LOAD_VALID_VIN_UPLOAD_LIST,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_MILEAGE,
} from "constants/actions";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

// const columnMap = {
//     "VIN": "vin",
//     "Make": "make",
//     "Year": "year",
//     "Equip": "notes",
//     "Ext Color": "color",
//     "Kilometers": "mileage",
//     "Retail Price": "retail"
// };
const LocationId = 4;

const ShowDetails = ({ inventoryList, p }) => {
  const Inventory = inventoryList.filter((fl) => fl.vin === p.vin)[0];
  if (Inventory) {
    return (
      <>
        Mfd Col: {Inventory.mfd_color_code ? Inventory.mfd_color_code : ""}
        <hr />
        Seats: {Inventory.pass_capacity ? Inventory.pass_capacity : ""}
        <hr />
        Engine: {Inventory.engine ? Inventory.engine : ""}
        <hr />
        Images: {Inventory.total_images ? Inventory.total_images : ""}
      </>
    );
  } else {
    return (
      // <p style={{ background: "#f33333" }} className="text-white">
      //   <small>
      //     <strong>Resolve</strong>
      //   </small>
      // </p>
      <Chip
        label={"Resolve"}
        //  icon={<MdGroups size={20} />}
        sx={{
          backgroundColor: "#ffefd5",
          padding: "4px 12px",
          marginTop: 1.5,
          marginRight: { xs: 1, xl: 2 },
          // marginLeft: { xs: 1, xl: 2 },
          border: "1px solid",
          textAlign: "left",
          //  borderColor: grey[500],
          borderRadius: 2,
        }}
      />
    );
  }
};

const ViewBtn = ({ inventoryList, p }) => {
  // if (inventoryList.id === 350) {
  const VinFound = inventoryList
    .filter((fl) => fl.id == 351)
    .map((tl) => tl.vin)[0];

  return inventoryList.filter((fl) => fl.vin === p.vin)[0] ? (
    <Link
      to={`/admin/inventory-detail/${
        inventoryList.filter((fl) => fl.vin === p.vin)[0].id
      }`}
    >
      <RemoveRedEyeOutlinedIcon color={"preAuto"} />
    </Link>
  ) : (
    ""
  );
};

const GSheetCompare = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [creating, setCreating] = React.useState();
  const [pending, setPending] = React.useState();
  const [inventoryList, setInventoryList] = React.useState([]);
  const [comparedList, setComparedList] = React.useState([]);
  const [columnMap, setColumnMap] = React.useState({
    VIN: "vin",
    Make: "make",
    Year: "year",
    Equip: "notes",
    "Ext Color": "color",
    Kilometers: "mileage",
    "Retail Price": "retail",
  });
  const [vinupload, setVinupload] = React.useState([]);
  const [vinuploadList, setVinuploadList] = React.useState([]);

  const [pricesUpdated, setPricesUpdated] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const onDismiss = () => setVisible(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_LIST });
    dispatch({ type: LOAD_VALID_VIN_UPLOAD_LIST });
    dispatch({ type: LOAD_GSHEET_INVENTORY_LIST });
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const {
    get_inventories,
    get_compared,
    get_pending,
    get_vinupload,
    get_prices_updated,
    get_dealer_location,
  } = useSelector(
    (state) => ({
      get_inventories: state.inventories.inventoryList,
      get_compared: state.inventories.gsheet,
      get_pending: state.vinupload.pending,
      get_vinupload: state.vinupload,
      get_prices_updated: state.inventories.updated_prices,
      get_dealer_location: state.dealers.related,
    }),
    shallowEqual
  );

  // console.log(comparedList)
  // console.log(columnMap)
  const newObj = {};

  if (get_dealer_location) {
    console.log(
      get_dealer_location.find(({ id }) => id === LocationId)?.data_url
    );
  }

  // SurveyList.find(({ id }) => id === surveyType)?.name

  React.useEffect(() => {
    if (get_inventories) {
      setInventoryList(get_inventories);
    }
  }, [get_inventories]);

  React.useEffect(() => {
    if (get_compared) {
      //
      setColumnMap(get_compared.column_map);
    }
  }, [get_compared]);

  React.useEffect(() => {
    if (comparedList && columnMap) {
      if (get_compared.results) {
        setComparedList(get_compared.results);
        get_compared.results.map((rst) => {
          for (var prop in rst) {
            if (rst.hasOwnProperty(prop) && prop in columnMap) {
              // console.log(rst[prop])
              rst[columnMap[prop]] = rst[prop];
              // console.log(rst[columnMap[prop]])
              delete rst[prop];
            }
          }
        });
      }
    }
  }, [comparedList, columnMap]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinupload(get_vinupload);
    }
  }, [get_vinupload]);

  React.useEffect(() => {
    if (get_prices_updated) {
      setPricesUpdated(get_prices_updated);
    }
  }, [get_prices_updated]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinuploadList(get_vinupload.validlist);
    }
  }, [get_vinupload]);

  // console.log(vinupload)
  // console.log(vinuploadList)

  React.useEffect(() => {
    if (get_vinupload?.created?.id) {
      history.push(
        `/admin/full-vin-decode-edit/${get_vinupload.created.id}/${get_vinupload.created.inventory_id}?return-url=gsheet-inventory`
      );
    }
  }, [get_vinupload, get_pending]);

  React.useEffect(() => {
    setCreating(get_pending);
  }, [get_pending]);

  const PullRecent = () => {
    // dispatch({ type: LOAD_COMPARED_INVENTORY_LIST,
    //   payload: "pull=True" });
    setVisible(true);
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
        // console.log(p)
        const invId = inventoryList.filter((fl) => fl.vin === p.vin)[0];
        //  console.log(invId)
        dispatch({
          type: SG_EDIT_MILEAGE,
          payload: { id: invId.id, list_price: p.retail },
        });
      }
    });
  };

  const NotFound = [];
  if (comparedList && inventoryList) {
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
      } else {
        NotFound.push({
          vin: p.vin,
          year: p.year,
          make: p.make,
          mode: p.model,
        });
      }
    });
  }

  const PullFile = (e) => {
    // console.log(e);
    dispatch({
      type: SG_ADD_VIN_UPLOAD,
      payload: {
        ...e,
        location_id: LocationId,
        model: "",
        retail_price: e.retail,
        return_url: window.location,
      },
    });
  };
  // const PullFile = (e) => {
  //   dispatch({
  //     type: SG_ADD_VIN_UPLOAD,
  //     payload: { ...e, location_id: LocationId, retail_price: e.retail },
  //   });
  // };

  const color1 = () => {
    inventoryList.filter((fl) => fl.vin === p.vin)[0].status_text;
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <MUIButton
              type="submit"
              variant="contained"
              color={"preAuto"}
              onClick={() => PullRecent()}
              style={{
                padding: "5px 7px",
                fontSize: "15px",
                marginRight: "10px",
              }}
            >
              Update All Prices
            </MUIButton>
            {/* <Button color="primary" onClick={() => PullRecent()}>
              Update All Prices
            </Button> */}
            <a
              color="secondary"
              size="lg"
              target="_blank"
              rel="noopener noreferrer"
              href={
                get_dealer_location &&
                get_dealer_location.find(({ id }) => id === LocationId)
                  ?.data_url
              }
            >
              <MUIButton variant="outlined" color={"preAuto"}>
                View the Google Sheet
              </MUIButton>
            </a>
          </Col>
          <Col>
            {pricesUpdated.length > 0 ? (
              <Alert color="info" isOpen={visible} toggle={onDismiss}>
                <h6>
                  {pricesUpdated.reduce(
                    (total, x) => (x.status == 1 ? total + 1 : total),
                    0
                  )}{" "}
                  Inventory Prices have been updated
                </h6>
                {pricesUpdated.map((p, k) => (
                  <p key={k}>
                    {p.year} {p.make} {p.model} {p.list_price}
                  </p>
                ))}
              </Alert>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <div className={pending ? "" : "d-none mt-3"}>
          <Fade in={pending} tag="h5">
            <div className="d-flex justify-content-center">
              <Spinner>Loading...</Spinner>
            </div>
          </Fade>
        </div>
        {/* <div className={pending ? "d-none" : ""}>
          <Row>
            <Col md="12">
              <Table responsive>
                <thead className="table-active">
                  <tr>
                    <th>No</th>
                    <th>Purchased</th>
                    <th>
                      Year Make Model <br />
                      VIN
                    </th>
                    <th>Color Engine Images</th>
                    <th>Mileage</th>
                    <th>Price</th>
                    <th>Found</th>
                    <th>More</th>
                  </tr>
                </thead>
                <tbody>
                  {comparedList
                    ? comparedList.map((p, k) => (
                        <tr key={k}>
                          <td>{k + 1}</td>
                          <td
                            className={
                              inventoryList.filter((fl) => fl.vin === p.vin)[0]
                                ? inventoryList.filter(
                                    (fl) => fl.vin === p.vin
                                  )[0].status === 4
                                  ? "bg-success"
                                  : "bg-primary"
                                : "bg-danger"
                            }
                          >
                            {p.purchased} <br />
                            <small>
                              {inventoryList.filter((fl) => fl.vin === p.vin)[0]
                                ? inventoryList.filter(
                                    (fl) => fl.vin === p.vin
                                  )[0].status_text
                                : "Not Found"}
                            </small>
                          </td>

                          <td>
                            {p.year} {p.make} {p.model} <br />
                            {p.vin} <br />
                            {p.notes}
                          </td>
                          <td>
                            {p.color} <br />
                            <ShowDetails p={p} inventoryList={inventoryList} />
                          </td>
                          <td>{p.mileage}</td>
                          <td>
                            Backend: {p.retail}
                            <hr />
                            Website:
                            {inventoryList.filter((fl) => fl.vin === p.vin)[0]
                              ? inventoryList.filter(
                                  (fl) => fl.vin === p.vin
                                )[0].list_price
                              : "Not Found"}
                          </td>
                          <td>
                            {vinuploadList.filter(
                              (fl) => fl.vin === p.vin
                            )[0] ? (
                              <Link
                                to={`/admin/full-vin-decode-edit/${
                                  vinuploadList.filter(
                                    (fl) => fl.vin === p.vin
                                  )[0].id
                                }/${
                                  vinuploadList.filter(
                                    (fl) => fl.vin === p.vin
                                  )[0].inventory_id
                                }?return-url=gsheet-inventory`}
                              >
                                <Button size="sm" color="info">
                                  Resolve
                                </Button>
                              </Link>
                            ) : (
                              <Button
                                size="sm"
                                color="success"
                                onClick={() => PullFile(p)}
                              >
                                Decode
                              </Button>
                            )}
                          </td>

                          <td>
                            <ViewBtn p={p} inventoryList={inventoryList} />
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
          <CSVLink
            data={NotFound}
            filename={"inventory-missing.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Download Missing
          </CSVLink>
        </div> */}
        <div className={pending ? "d-none" : ""}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell align="center">Purchased</StyledTableCell>
                    <StyledTableCell align="center">
                      Year Make Model <br />
                      VIN
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Color Engine Images
                    </StyledTableCell>
                    <StyledTableCell align="center">Mileage</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                    <StyledTableCell align="center">Found</StyledTableCell>
                    <StyledTableCell align="center">MORE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {comparedList.map((p, k) => (
                    <StyledTableRow key={k}>
                      <StyledTableCell align="left">{k + 1}</StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                        // className={
                        // inventoryList.filter((fl) => fl.vin === p.vin)[0]
                        //   ? inventoryList.filter(
                        //     (fl) => fl.vin === p.vin
                        //   )[0].status === 4
                        //     ? "bg-success"
                        //     : "bg-primary"
                        //   : "bg-danger"
                        // }
                      >
                        <Box
                          component="div"
                          sx={{
                            px: 3,
                            py: 1,
                            color: "#ffffff",
                            borderRadius: "30px",
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            textAlign: "center",
                            fontSize: 15,
                            backgroundColor:
                              inventoryList.find((item) => item.vin === p.vin)
                                ?.status_text == "Under Process"
                                ? "#51cbce"
                                : "#ef8157",
                            // fontWeight: Fonts.SEMI_BOLD,
                            // bgcolor: {()=>{inventoryList.filter(
                            //   (fl) => fl.vin === p.vin
                            // )[0].status_text == "Under Process" ? "Red" : "Black"}},
                          }}
                        >
                          {p.purchased}
                          {inventoryList.filter((fl) => fl.vin === p.vin)[0]
                            ? inventoryList.filter((fl) => fl.vin === p.vin)[0]
                                .status_text
                            : "Not Found"}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {p.year} {p.make} {p.model} <br />
                        {p.vin} <br />
                        {p.notes}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {p.color} <br />
                        <ShowDetails p={p} inventoryList={inventoryList} />
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <TextField
                          sx={{
                            maxWidth: "80px",
                            margin: 0,
                            padding: 0,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#1d4aa7",
                              },
                              "&:hover fieldset": {
                                borderColor: "#1d4aa7",
                              },
                            },
                            " input": {
                              px: 1,
                              py: 0.5,
                            },
                          }}
                          defaultValue={row.kilometers}
                          //  onChange={(e) => onKilometerChange(e)}
                          //  onFocus={(e) => onKilometerFocus(e)}
                          onBlur={(e) => onKilometerBlur(e, row.id)}
                        /> */}
                      {/* {row.kilometers} */}
                      {/* </StyledTableCell> */}
                      <StyledTableCell StyledTableCell align="left">
                        {p.mileage}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Backend: {p.retail}
                        <hr />
                        Website:
                        {inventoryList.filter((fl) => fl.vin === p.vin)[0]
                          ? inventoryList.filter((fl) => fl.vin === p.vin)[0]
                              .list_price
                          : "Not Found"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {vinuploadList.filter((fl) => fl.vin === p.vin)[0] ? (
                          <Link
                            to={`/admin/full-vin-decode-edit/${
                              vinuploadList.filter((fl) => fl.vin === p.vin)[0]
                                .id
                            }/${
                              vinuploadList.filter((fl) => fl.vin === p.vin)[0]
                                .inventory_id
                            }?return-url=gsheet-inventory`}
                          >
                            <MUIButton
                              type="submit"
                              variant="contained"
                              style={{
                                backgroundColor: "blueviolet",
                                color: "#ffffff",
                                padding: "5px 5px",
                              }}
                            >
                              Re Decode
                            </MUIButton>
                          </Link>
                        ) : (
                          // <Button
                          //   size="sm"
                          //   color="success"
                          //   onClick={() => PullFile(p)}
                          // >
                          //   Decode
                          // </Button>
                          <MUIButton
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor: "#F5EDFC",
                              color: "#9E49E6",
                            }}
                            onClick={() => PullFile(p)}
                          >
                            Decode
                          </MUIButton>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {
                          <Link to={`/admin/inventory-detail/${row.id}`}>
                            <p
                              style={{
                                color: "#1d4aa7",
                                margin: 0,
                              }}
                            >
                              <RemoveRedEyeOutlinedIcon />
                            </p>
                          </Link>
                        } */}
                        <ViewBtn p={p} inventoryList={inventoryList} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
          </Paper>
          <Box
            sx={{
              my: 2,
            }}
          >
            <CSVLink
              data={NotFound}
              filename={"inventory-missing.csv"}
              target="_blank"
            >
              <MUIButton color={"preAuto"} variant="contained">
                Download Missing
              </MUIButton>
            </CSVLink>
          </Box>
        </div>
      </div>
    </>
  );
};

export default GSheetCompare;
