import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

import { UPLOAD_EMPLOYEE_PIC } from "constants/actions";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Input,
  FormGroup,
  Progress,
  Button,
  Row,
  Col,
} from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";

const NewImage = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const { control, register, handleSubmit, errors, reset } = useForm();

  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);

  const [auth, setAuth] = React.useState([]);
  const [imageprofileid, setImageprofileid] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [fileErrors, setFileErrors] = React.useState([]);

  const notify2 = () => toast.success(`Uploaded File successfully`);

  const { get_image_profiles, get_errors, get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_image_profiles) {
      // console.log(get_image_profiles)
      setItems(get_image_profiles);
    }
    if (get_errors) {
      setFileErrors(get_errors);
    }
    if (get_auth) {
      setAuth(get_auth);
    }
  }, [get_image_profiles, get_errors, get_auth]);

  function onSelectChange(e) {
    // e.preventDefault();
    // console.log(e.target.value)
    setImageprofileid(e.target.value);
  }
  const onSubmit = (data, e) => {
    // console.log(data.location)
    dispatch({
      type: UPLOAD_EMPLOYEE_PIC,
      payload: { id, picture: data.uploadfile[0] },
    }); //
    e.target.reset(); // reset after form submit
    notify2();
    setImagePreviewUrl(defaultImage);
  };

  // console.log(auth.dealer_id)
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="10" sm="12">
            <Card>
              <CardBody>
                <div className="fileinput text-center">
                  <div className={"thumbnail"}>
                    <img src={imagePreviewUrl} alt="..." />
                  </div>
                  <div>
                    {file ? <Button onClick={handleRemove}>Remove</Button> : ""}
                  </div>
                </div>
                <CardTitle tag="h4">Upload Image</CardTitle>
                <p className="text-danger small">
                  {fileErrors.length > 0
                    ? JSON.stringify(fileErrors.errors)
                    : ""}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    type="file"
                    name="uploadfile"
                    onChange={handleImageChange}
                    innerRef={register({
                      required: "You must select a file",
                    })}
                  />

                  <p className="text-danger small">
                    {errors.uploadfile && (
                      <span>{errors.uploadfile.message}</span>
                    )}
                  </p>

                  <Button type="submit">Upload</Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewImage;
