import React from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_DELETE_INVENTORYIMAGE,
} from "constants/actions";

import { Button, Card, CardBody } from "reactstrap";
const imgWithClick = { cursor: "grabbing" };

const Photo = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  height,
  width,
}) => {
  const { id, slug } = useParams();
  const dispatch = useDispatch();

  const handleRemove = () => {
    // console.log(photo.id, id);
    dispatch({
      type: SG_DELETE_INVENTORYIMAGE,
      payload: { id: photo.id, inventory_id: Number(id) },
    });
  };



  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };
// console.log(photo)
  return (
    <>
      <Card className="ml-1 mr-1 card-product card-plain">
         <CardBody>
            <div className="image-desc">
              {photo.id} {photo.sort}
        {/*  <div className={width === 1280 ? "text-success" : "text-danger"}>
            Width: {width}{" "}
            <span className="text-danger">
              {width === 1280 ? "" : "Must be 1280px"}
            </span>
          </div>{" "}
          <div className={height === 960 ? "text-success" : "text-danger"}>
            Height: {height}{" "}
            <span className="text-danger">
              {height === 960 ? "" : "Must be 960px"}
            </span>
          </div>*/}
        </div> 
       
          <div className="card-image">
            <img
              style={{ cursor: "grabbing" }}
              {...photo}
              onClick={onClick ? handleClick : null}
              alt="img"
            />
          </div>
          <Button color="danger" size="sm" onClick={handleRemove}>
            <i className="fa fa-times" />
            Remove
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

export default Photo;
