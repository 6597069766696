import React from "react";
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from "react-dropzone";

const getClassName = (className, isActive) => {
    if (!isActive) return className;
    return `${className} ${className}-active`;
  };
  
  const maxSize = 1048576;
  const LargFileSize = []

  const Dropzone = ({ onDrop, accept }) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles, rejectedFiles } = useDropzone({
      onDrop,
      accept:"image/png, image/gif, image/jpg, image/jpeg",
      minSize: 0,
      maxSize:{maxSize}
    });
   
    // if (acceptedFiles) {
    //   // console.log(acceptedFiles[0].size)
    //   LargFileSize.push(acceptedFiles[0])
    // }
    
    let isFileTooLarge = null;
    if (rejectedFiles)
    {
      isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
    }
   

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <>
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
      {!isDragActive && 'Click here or drop a file to upload!'}
        {isDragActive && !isDragReject && "Drop it like it's hot!"}
        {isDragReject && "File type not accepted, sorry!"}
        {isFileTooLarge && (
          <div className="text-danger mt-2">
            File is too large.
          </div>
        )}
 
        {/* {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag 'n' drop some files here, or click to select files
          </p>
        )} */}
      </div>
    </div>

    {/* <ul className="list-group mt-2">
  {acceptedFiles.length > 0 && acceptedFiles.map((acceptedFile, k) => (
    <li className="list-group-item list-group-item-success" key={k} >
      {acceptedFile.name}
    </li>
  ))}
</ul> */}
    </>
  );
};

export default Dropzone;