import React, { Component } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateToHTML} from 'draft-js-export-html';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
    Row, Col
  } from "reactstrap";

function EditorConvertToHTML(props) {
const [editorState, setEditorState] = React.useState( EditorState.createEmpty())

  React.useEffect(() => {
    if (props.editContent) {
      setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(props.editContent)
      )))
    }
    
   }, [props])



    return (
      <Card>
      <CardHeader><h6>{props.heading}</h6></CardHeader>
      <CardBody>
        <Editor
          editorState={editorState}
          // wrapperClassName="demo-wrapper"
          // editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
     </CardBody>
      <CardFooter>
      <div className="d-flex justify-content-end">
          <>
          <Button
                        className="btn"
                        color="default"
                        outline
                        size="sm"
                        onClick={() => props.BtnCallBack(stateToHTML(editorState.getCurrentContent()))}
                      >
                       Update
                        
                      </Button>
          </>
        </div>
      </CardFooter>
    </Card>
    );
  
}

export default EditorConvertToHTML