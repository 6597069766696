import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import { GET_WEB_COMMENTS_PENDING,
     LOAD_WEB_COMMENTS, GET_WEB_COMMENTS, DELETE_WEB_COMMENT,
    SG_DELETE_WEB_COMMENT, ADD_WEB_COMMENT, SG_ADD_WEB_COMMENT, 
    FETCH_WEB_COMMENT, SG_FETCH_WEB_COMMENT, 
    UPDATE_WEB_COMMENT, EDIT_WEB_COMMENT, 
    CLEAR_WEB_COMMENT,
    ALL_ERRORS } from "constants/actions";


function* loadWebComments(action) {
    yield put({ type: GET_WEB_COMMENTS_PENDING });
    try {
      const json = yield call(api.GET_WEB_COMMENTS, action.payload);
  
      yield put({ type: GET_WEB_COMMENTS, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* webCommentLoad() {
    yield takeLatest(LOAD_WEB_COMMENTS, loadWebComments);
  }
  
  function* fetchwebComment(action) {
    try {
      const json = yield call(api.FETCH_WEB_COMMENT, action.payload);
  
      yield put({ type: FETCH_WEB_COMMENT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* webCommentFetchOne() {
    yield takeLatest(SG_FETCH_WEB_COMMENT, fetchwebComment);
  }
  
  function* editwebComment(action) {
    try {
      const json = yield call(api.EDIT_WEB_COMMENT, action.payload.id, action.payload);
  
      yield put({ type: EDIT_WEB_COMMENT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* webCommentEdit() {
    yield takeLatest(UPDATE_WEB_COMMENT, editwebComment);
  }
  
  function* addwebComment(action) {
    console.log(action)
    try {
      const json = yield call(api.ADD_WEB_COMMENT, action.payload);
  
      yield put({ type: ADD_WEB_COMMENT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* webCommentAdd() {
    yield takeLatest(SG_ADD_WEB_COMMENT, addwebComment);
  }

  function* deletewebComment(action) {
    try {
      const json = yield call(api.DELETE_WEB_COMMENT, action.payload);
  
      yield put({ type: DELETE_WEB_COMMENT, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e.message });
    }
  }
  
  export function* webCommentDelete() {
    yield takeLatest(SG_DELETE_WEB_COMMENT, deletewebComment);
  }
  
  export default function* index() {
    yield all([
      webCommentLoad(),
      webCommentFetchOne(),
      webCommentEdit(),
      webCommentAdd(),
      webCommentDelete(),
    ]);
  }
  