import {
    GET_WEB_LEADS,
    GET_WEB_LEAD_LIST,
    GET_WEB_LEADS_PENDING,
    DELETE_WEB_LEAD,
    ADD_WEB_LEAD,
    FETCH_WEB_LEAD_PENDING,
    FETCH_WEB_LEAD,
    EDIT_WEB_LEAD,
    GET_CUSTOMER_INVENTORY,
    GET_INVENTORY_CUSTOMER,
    CLEAR_ALL
} from "constants/actions";

const initialState = {
    webleads: [],
    webleadslist: [],
    created: [],
    customer_inventory: [],
    inventory_customer: [],
    loading: false,
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_WEB_LEADS_PENDING:
            return {
                ...state,
                pending: true,
                loading: true,
            }
        case GET_WEB_LEADS:
            return {
                ...state,
                webleads: action.payload,
                loading: false,
                pending: false
            };
        case GET_WEB_LEAD_LIST:
            return {
                ...state,
                webleadslist: action.payload,
                loading: false,
                pending: false
            };
        case GET_CUSTOMER_INVENTORY:
            return {
                ...state,
                customer_inventory: action.payload,
            };
        case GET_INVENTORY_CUSTOMER:
            return {
                ...state,
                inventory_customer: action.payload,
            };
        case FETCH_WEB_LEAD_PENDING:
            return {
                ...state,
                pending: true,
                loading: true,
            }
        case FETCH_WEB_LEAD:
            return {
                ...state, [action.payload.id]: action.payload,
                loading: false,
                pending: false
            };
        case EDIT_WEB_LEAD:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_WEB_LEAD:
            return {
                ...state,
                webleads: state.webleads.filter(web => web.id !== action.payload)
            };
        case ADD_WEB_LEAD:
            return {
                ...state,
                created: action.payload
            };
        // case CLEAR_WEB_LEAD:
        //     return {
        //         ...state,
        //         webleads: []
        //     };
        case CLEAR_ALL:
            return {
                ...state,
                webleads: [],
                webleadslist: [],
                created: [],
                customer_inventory: [],
                inventory_customer: [],
                loading: false,
                pending: false
            };
        default:
            return state;
    }
}