import React, { useState } from "react";

import AppDialog from "../../crema/AppDialog";

import CreateLeadStage from "../Create";

const AddNewLeadStage = ({
  isAddTaskOpen,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
  title,
}) => {
  const [label, setLabel] = useState("");
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={label}
    >
      <CreateLeadStage setAddTaskOpen={setAddTaskOpen} setLabel={setLabel} />
    </AppDialog>
  );
};

export default AddNewLeadStage;
