import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";

import _ from "lodash";

import {
  SG_FETCH_WEB_COMMENT,
  UPDATE_WEB_COMMENT,
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  SG_GET_SELECT_INVENTORIES,
  LOAD_LEADSOURCES,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

import MiniCreate from "TaskLists/MiniCreate";
import TaskList from "TaskLists/List";

import ButtonGrouped from "Utilities/ButtonGrouped";

const commentOptions = [
  { id: 1, name: "New" },
  { id: 2, name: "Under Review" },
  { id: 3, name: "Approved" },
  { id: 4, name: "Removed" },
  { id: 9, name: "Junk" },
];

const WebCommentDetail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const [webLead, setWebLead] = React.useState([]);
  const [customerId, setCustomerId] = React.useState(null);
  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  const [newTaskAdded, setNewTaskAdded] = React.useState(0);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_WEB_COMMENT, payload: id });
    }
    dispatch({ type: SG_GET_SELECT_INVENTORIES });
    dispatch({ type: LOAD_LEADSOURCES });
    dispatch({ type: LOAD_LEADTYPES });
    dispatch({ type: LOAD_LEAD_STAGE });
  }, [dispatch]);

  const {
    get_web_leads,
    get_auth,
    get_errors,
    get_inventory_select,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_web_leads: state.webcomments[parseInt(id)],
      get_inventory_select: state.inventories.select_inventory,
      
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );


  React.useEffect(() => {
    if (get_web_leads) {
      setWebLead(get_web_leads);
      setCustomerId(get_web_leads.customer);

    }
  }, [get_web_leads, id]);

  
  function CreatedNewTask(value) {
    setNewTaskAdded(value);
  }

  const BtnPublish = (data) => {
  
    dispatch({
      type: UPDATE_WEB_COMMENT,
      payload: {
        id,
        comment_status: data,
      },
    });
  };

  const onSubmit = (data, e) => {
    dispatch({
      type: UPDATE_WEB_COMMENT,
      payload: {
        id: id,
        response: data.response,
       
      },
    });
  }

     
  // console.log(customerId)
  // console.log('newTaskAdded', newTaskAdded)
  return (
    <>
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle tag="h4">Web Comment Details</CardTitle>

                      <Link to={`/admin/web-comments/`}>View Entire List</Link>
                    </Col>
                    <Col className="ml-auto" md="2">
                      {/* <Button
                        color="default"
                        size="sm"
                        to={`/admin/web-comment-create/${id}`}
                        tag={Link}
                      >
                        Edit
                      </Button> */}
                    </Col>
                  </Row>

                  <CardTitle tag="h4"></CardTitle>
                  <Row>
                    <Col>
                      <label>Device</label>
                      <p>{webLead ? webLead.device : ""}</p>
                    </Col>
                    <Col>
                      <label>Browser</label>
                      <p>{webLead ? webLead.browser : ""}</p>
                    </Col>
                    <Col>
                      <label>IP</label>
                      <p>{webLead ? webLead.ip : ""}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>First Name </label>
                      <p>{webLead ? webLead.first_name : ""}</p>
                    </Col>

                    <Col>
                      <label>Middle Name </label>
                      <p>{webLead ? webLead.middle_name : ""}</p>
                    </Col>

                    <Col>
                      <label>Last Name </label>
                      <p>{webLead ? webLead.last_name : ""}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {webLead.customer ? (
                        <Link to={`/admin/customer-detail/${webLead.customer}`}>
                          View Customer Details
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Phone</label>
                      <p>{webLead ? webLead.phone : ""}</p>
                    </Col>

                    <Col>
                      <label>Email</label>
                      <p>{webLead ? webLead.email : ""}</p>
                    </Col>
                  </Row>
              

                  <Row>
                    <Col>
                      <label>Source</label>

                      <p>
                        {webLead.web_lead_source
                          ? webLead.web_lead_source.name
                          : ""}
                      </p>
                    </Col>
                    <Col>
                      <label>Type</label>
                      <p>
                        {webLead.web_lead_type
                          ? webLead.web_lead_type.name
                          : ""}
                      </p>
                    </Col>

                    <Col>
                      <label>Stage</label>
                      <p>
                        {webLead.comment_status_text
                          ? webLead.comment_status_text
                          : ""}
                      </p>
                    </Col>
                    <Col>
                      <label>Rating</label>
                      <p>{webLead.rating ? webLead.rating : ""}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {webLead.comment_status ? (
                        <ButtonGrouped
                          status={webLead.comment_status}
                          commentOptions={commentOptions}
                          BtnClick={BtnPublish}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  
                 
                  <Row>
                    <Col>
                      <label>Vehicle Purchased</label>
                      {webLead.inventory ? (
                        <ListGroup flush>
                          {webLead.inventory.map((p, k) => {
                            return (
                              <ListGroupItem
                                key={k}
                                className="list-group-item list-group-item-success"
                              >
                                <Row>
                                  <Col>
                                    <p>
                                      {p.id} {p.year} {p.make} {p.model}{" "}
                                    </p>
                                  </Col>
                                  <Col>
                                    <Link
                                      to={`/admin/inventory-detail/${p.id}`}
                                    >
                                      View
                                    </Link>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      ) : (
                        <p>"None Selected"</p>
                      )}
                    </Col>
                  </Row>

                  <FormGroup>
                    <label>Customer Comments</label>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{webLead ? webLead.description : ""}</p>
                  </FormGroup>

                 
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
      <Col className="ml-auto mr-auto" md="10">
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                        <label>Response</label>
                        <Input
                          type="textarea"
                          defaultValue={webLead ? webLead.response : ""}
                          name="response"
                          id="response"
                          innerRef={register}
                        />
                      </FormGroup>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-fill"
                          color="primary"
                          type="submit"
                        >
                          Respond to Comment
                        </Button>
                      </div>
                    </Form>
          </CardBody>
        
        </Card>
        </Col>
      </Row>
          {customerId ? (
            <MiniCreate
              lead_id={id}
              customer_id={customerId}
              CreatedNewTask={CreatedNewTask}
            />
          ) : (
            ""
          )}
          
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card>
                <CardBody>
                  <CardTitle tag="h4">Open Task Lists for this Customer</CardTitle>
                 {customerId?  <TaskList
                   params={`customer=${customerId}&status=1`}
                    newTaskAdded={newTaskAdded}
                  /> : '' }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};


export default WebCommentDetail
