import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import BlackBookDetails from "./BlackBookInterDetails";
// import "./style.css"
const BlackBookHeader = ({ blackbook, category }) => {
  return (
    <>
      <div className="content">
        {blackbook.map((p, k) => {
          return (
            <div key={k}>
              <Row className="border-top pt-2">
                <Col>
                  {" "}
                  <h4>
                    {p.series} - {p.style}
                  </h4>
                  <p>
                    <small>{p.vin}</small>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BlackBookDetails
                    blackbook={p.full_response}
                    category={category}
                  />
                </Col>
              </Row>
            </div>
          );
        })}

        {/* inventory: 43 */}

        {/* full_response: {gvw: 2310, uvc: "2019940204", vin: "3VV0B7AXK", make: "Volkswagen", msrp: 31475, …} */}
      </div>
    </>
  );
};

export default BlackBookHeader;
