import React, { useEffect } from 'react';
// import { BsArrowLeft } from 'react-icons/bs';
import { Card, Container, Row } from 'reactstrap';
import './PaymentFailure.css';
import failure from './failure.svg';
import bgImage from "assets/img/bg/jan-sendereks.jpg";
import { useLocation } from "react-router-dom";
import axios from "axios";

// import store from './store';
const VERSION = "v1";

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};


export default function PaymentFailure() {

    const search = useLocation().search;
    const name = new URLSearchParams(search).get('session_id');
    React.useEffect(() => {
        const data = { session_id: name }
        dispatchPayment(data);

    }, [name])
    const dispatchPayment = async (session_id) => {

        const body = JSON.stringify(session_id);
        let res = await axios.post(
            `https://api.preautoco.com/api/user/${VERSION}/check-payment-status`,
            body,
            config
        );
        let data = { status: res?.status, statusText: res?.statusText };
        return data;
    }
    return (
        <div className='payment'>
            <Container className='w-100 font mx-auto my-5 py-5'>
                <Card
                    body
                    className="text-center mx-auto shadow-lg rounded-3 border-0 bg-white"
                    style={{
                        width: '596px',
                        height: '570px'

                    }}
                >
                    <Row className='block2 mx-auto mt-5 mx-auto'>
                        <Row className='Row'>
                            <img src={failure} alt="" />
                        </Row>
                    </Row>
                    <Row className='block3  mx-auto my-3'>
                        <h1 className='failed'>Payment failed</h1>
                        <p>Unexpected Issue Occured</p>
                    </Row>
                    <Row className='block4 mx-auto pb-3 pay d-flex flex-column justify-content-center'>
                        <h3 className='border420 pb-5'>Pay with another card</h3>
                    </Row>
                    {/* <Row>
                <p className='mb-0'>Or pay another method</p>
                <Row className='d-flex justify-content-center img mb-5 mt-0'>
                    <img src={applepay} alt=""/> 
                    <img src={stripe} alt="" />
                    <img src={paypal} alt="" />
                    <img src={bitcoin} alt="" /> 
                </Row>
               </Row> */}
                </Card>
            </Container>
            <div
                className="full-page-background"
                style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
        </div>
    );
};