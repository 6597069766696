import React from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
  Spinner,
  Fade,
  Row,
  Col,
} from "reactstrap";

import {
  LOAD_INVENTORY_LIST,
  LOAD_COMPARED_INVENTORY_LIST,
  LOAD_VALID_VIN_UPLOAD_LIST,
  SG_FETCH_RELATED_DEALER,
  SG_ADD_VIN_UPLOAD,
  SG_EDIT_INVENTORY,
  SG_EDIT_MILEAGE,
} from "constants/actions";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { Button as MUIButton, TextField } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

const ShowDetails = ({ inventoryList, p }) => {
  const Inventory = inventoryList.filter((fl) => fl.vin === p.vin)[0];
  if (Inventory) {
    return (
      <>
        Mfd Col: {Inventory.mfd_color_code ? Inventory.mfd_color_code : ""}
        <hr />
        Seats: {Inventory.pass_capacity ? Inventory.pass_capacity : ""}
        <hr />
        Engine: {Inventory.engine ? Inventory.engine : ""}
        <hr />
        Images: {Inventory.total_images ? Inventory.total_images : ""}
      </>
    );
  } else {
    return (
      <p style={{ background: "#f33333" }} className="text-white">
        <small>
          <strong>Resolve</strong>
        </small>
      </p>
    );
  }
};

// const ViewBtn = ({ inventory }) => {
//   // if (inventoryList.id === 350) {

//   return inventory ? (
//     <Link
//       to={`/admin/inventory-detail/${inventory.id}
//         }`}
//     >
//       <Button size="sm" color="dark">
//         View
//       </Button>
//     </Link>
//   ) : (
//     ""
//   );
// };

const XmlCompare = ({ selectedLocation }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [creating, setCreating] = React.useState();
  const [inventoryList, setInventoryList] = React.useState([]);
  const [comparedList, setComparedList] = React.useState([]);

  const [vinuploadList, setVinuploadList] = React.useState([]);
  const [pricesUpdated, setPricesUpdated] = React.useState([]);
  // const [updateAllLists, setUpdateAllLists] = React.useState(false);

  const [removeInvId, setRemoveInvId] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [vinList, setVinList] = React.useState([]);

  const [pending, setPending] = React.useState();

  const [delistModal, setDelistModal] = React.useState(false);

  const onDismiss = () => setVisible(false);
  const toggleDelist = () => setDelistModal(!delistModal);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_LIST });
    dispatch({ type: LOAD_VALID_VIN_UPLOAD_LIST });
    dispatch({ type: LOAD_COMPARED_INVENTORY_LIST });
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);

  const {
    get_inventories,
    get_compared,
    get_pending,
    get_vinupload,
    get_prices_updated,
  } = useSelector(
    (state) => ({
      get_inventories: state.inventories.inventoryList,
      get_compared: state.inventories.compared,
      get_pending: state.vinupload.creating,
      get_vinupload: state.vinupload,
      get_prices_updated: state.inventories.updated_prices,
    }),
    shallowEqual
  );

  // console.log(get_compared.results)

  React.useEffect(() => {
    if (get_inventories) {
      setInventoryList(
        get_inventories.filter(
          (p) => p.status < 11 && p.location === selectedLocation.id
        )
      );
    }
  }, [get_inventories]);

  React.useEffect(() => {
    if (get_compared) {
      setComparedList(get_compared.results);
    }
  }, [get_compared]);

  React.useEffect(() => {
    if (get_vinupload) {
      setVinuploadList(get_vinupload.validlist);
    }
  }, [get_vinupload]);

  React.useEffect(() => {
    if (get_prices_updated) {
      setPricesUpdated(get_prices_updated);
    }
  }, [get_prices_updated]);

  // console.log(get_vinupload)
  // console.log(vinuploadList)

  React.useEffect(() => {
    if (get_vinupload?.created?.id) {
      history.push(
        `/admin/full-vin-decode-edit/${get_vinupload.created.id}/${get_vinupload.created.inventory_id}?return-url=external-inventory?location=${selectedLocation.id}`
      );
    }
  }, [get_vinupload, get_pending]);

  React.useEffect(() => {
    setCreating(get_pending);
  }, [get_pending]);

  const PullRecent = () => {
    // dispatch({ type: LOAD_COMPARED_INVENTORY_LIST,
    //   payload: "pull=True" });
    setVisible(true);
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
        // console.log(p)
        let condition = 2;
        if (p.trade == "1") {
          condition = 3;
        }
        const invId = inventoryList.filter((fl) => fl.vin === p.vin)[0];
        dispatch({
          type: SG_EDIT_MILEAGE,
          payload: {
            id: invId.id,
            list_price: p.retail,
            cost_price: p?.cost,
            condition: condition,
          },
        });
      }
    });
  };

  const NotFound = [];
  if (comparedList && inventoryList) {
    comparedList.map((p) => {
      if (inventoryList.filter((fl) => fl.vin === p.vin)[0]) {
      } else {
        NotFound.push({
          vin: p.vin,
          year: p.year,
          make: p.make,
          mode: p.model,
        });
      }
    });
  }

  React.useEffect(() => {
    if (comparedList) {
      let _listVin1 = [];
      inventoryList.map((m) => _listVin1.push(m.vin));

      let _listVin2 = [];
      comparedList.map((m) => _listVin2.push(m.vin));

      // setVinList(...new Set([..._listVin1, ..._listVin2]))
      //     console.log(_listVin1)
      //     console.log(_listVin2)
      setVinList(Array.from(new Set([..._listVin1, ..._listVin2])));
    }
  }, [inventoryList, comparedList]);

  const PullFile = (e) => {
    dispatch({
      type: SG_ADD_VIN_UPLOAD,
      payload: {
        ...e,
        location_id: selectedLocation.id,
        retail_price: e.retail,
        cost_price: Number(e?.cost),
      },
    });
  };
  const DelistInventory = () => {
    // console.log(Invid)
    // setModal(!modal);
    if (removeInvId) {
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id: removeInvId.id,
          status: 11,
        },
      });
      // history.push("/admin/inventory");
      setDelistModal(false);
    }
  };
  return (
    <>
      <div className="content">
        <div className={creating ? "" : "d-none mt-3"}>
          <Fade in={creating} tag="h5">
            <div className="d-flex justify-content-center">
              <Spinner>Loading...</Spinner>
            </div>
          </Fade>
        </div>
        <div className={creating ? "d-none" : ""}>
          <Row>
            <Col
              style={{
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <MUIButton
                color="preAuto"
                type="submit"
                variant="outlined"
                onClick={() => PullRecent()}
                sx={{
                  mr: 2,
                }}
              >
                Update All Prices
              </MUIButton>
            </Col>
            <Col>
              {pricesUpdated.length > 0 ? (
                <Alert color="info" isOpen={visible} toggle={onDismiss}>
                  <h6>
                    {pricesUpdated.reduce(
                      (total, x) => (x.status == 1 ? total + 1 : total),
                      0
                    )}{" "}
                    Inventory Prices have been updated
                  </h6>
                  {pricesUpdated.map((p, k) => (
                    <p key={k}>
                      {p.year} {p.make} {p.model} {p.list_price}
                    </p>
                  ))}
                </Alert>
              ) : (
                ""
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
              <Table responsive>
                <thead className="table-active">
                  <tr>
                    <th>No</th>
                    <th>Purchased</th>
                    <th>
                      Year Make Model <br />
                      VIN
                    </th>
                    <th>Color Engine Images</th>
                    <th>Mileage</th>
                    <th>Price</th>
                    <th>Found</th>

                    <th>More</th>
                  </tr>
                </thead>
                <tbody>
                  {vinList
                    ? vinList.map((p, k) => (

                      <CheckIfInInventoryList
                        vin={p}
                        comparedList={comparedList}
                        inventoryList={inventoryList}
                        id={k}
                        vinuploadList={vinuploadList}
                        selectedLocation={selectedLocation}
                        PullFile={PullFile}
                        key={k}
                        delistModal={delistModal}
                        setDelistModal={setDelistModal}
                        setRemoveInvId={setRemoveInvId}
                      />
                    ))
                    : null}
                </tbody>

              </Table>
            </Col>
          </Row> */}

          <>
            <div className={pending ? "" : "d-none mt-3"}>
              <Fade in={pending} tag="h5">
                <div className="d-flex justify-content-center">
                  <Spinner>Loading...</Spinner>
                </div>
              </Fade>
            </div>
            <div className={pending ? "d-none" : ""}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>NO</StyledTableCell>
                        <StyledTableCell align="center">
                          PURCHASED
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          YEAR MAKE MODEL VIN
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          COLOR ENGINE IMAGES
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          MILEAGE
                        </StyledTableCell>
                        <StyledTableCell align="center">PRICE</StyledTableCell>
                        <StyledTableCell align="center">FOUND</StyledTableCell>
                        <StyledTableCell align="center">MORE</StyledTableCell>
                        {/* <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell align="center">
                          Purchased
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Year Make Model VIN
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Color Engine Images
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Mileage
                        </StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>
                        <StyledTableCell align="center">Found</StyledTableCell>
                        <StyledTableCell align="center">MORE</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vinList
                        ? vinList.map((p, k) => (
                            <CheckIfInInventoryList
                              vin={p}
                              comparedList={comparedList}
                              inventoryList={inventoryList}
                              id={k}
                              vinuploadList={vinuploadList}
                              selectedLocation={selectedLocation}
                              PullFile={PullFile}
                              key={k}
                              delistModal={delistModal}
                              setDelistModal={setDelistModal}
                              setRemoveInvId={setRemoveInvId}
                            />
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        </div>
        <div className={pending ? "d-none" : ""}>
          <CSVLink
            data={NotFound}
            filename={"inventory-missing.csv"}
            // className="btn btn-primary"
            target="_blank"
          >
            <MUIButton
              color="preAuto"
              type="submit"
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              Download Missing
            </MUIButton>
          </CSVLink>
          <Modal isOpen={delistModal} toggle={toggleDelist}>
            <ModalHeader toggle={toggleDelist}>
              Delist {removeInvId.year} {removeInvId.make} {removeInvId.model}
            </ModalHeader>
            <ModalBody>
              This removes from Website: removes all associated images and
              retains some basic information in the backend. Use it when you
              have to remove the inventory from website.
              <p className="lead">
                {removeInvId.year} {removeInvId.make} {removeInvId.model}{" "}
                {removeInvId.color} with {removeInvId.kilometers} kms
              </p>
            </ModalBody>
            <ModalFooter className="pr-2">
              <Button color="warning" onClick={DelistInventory}>
                De List
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default XmlCompare;

function CheckIfInInventoryList({
  id,
  vin,
  comparedList,
  inventoryList,
  vinuploadList,
  selectedLocation,
  PullFile,
  delistModal,
  setDelistModal,
  setRemoveInvId,
}) {
  let _inventoryFound = null;
  let _gsheetFound = null;
  if (inventoryList) {
    _inventoryFound = inventoryList.filter((p) => p.vin === vin)[0];
  }
  if (comparedList) {
    _gsheetFound = comparedList.filter((p) => p.vin === vin)[0];
  }

  if (_inventoryFound && _gsheetFound) {
    return (
      <RegularRow
        id={Number(id)}
        inventory={_inventoryFound}
        vinuploadList={vinuploadList}
        selectedLocation={selectedLocation}
      />
    );
  }
  if (!_inventoryFound && _gsheetFound) {
    return (
      <DecodeRow id={Number(id)} inventory={_gsheetFound} PullFile={PullFile} />
    );
  }
  if (_inventoryFound && !_gsheetFound) {
    return (
      <RemoveRow
        id={Number(id)}
        inventory={_inventoryFound}
        delistModal={delistModal}
        setDelistModal={setDelistModal}
        setRemoveInvId={setRemoveInvId}
      />
    );
  }
  return (
    <p>
      {Number(id) + 1} Waiting {vin}{" "}
    </p>
  );
}

function RegularRow({ id, inventory, vinuploadList, selectedLocation }) {
  // console.log(vinuploadList)

  return (
    <StyledTableRow key={inventory}>
      <StyledTableCell align="center">{id + 1}</StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.veh_in_date} <br />
        {inventory.status_text}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.year} {inventory.make} {inventory.model} <br />
        {inventory.vin} <br />
        {inventory.notes}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" align="center">
        {inventory.color}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.kilometers}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.list_price}
        <br /> <small>Cost: {inventory?.cost_price}</small>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {vinuploadList.filter((fl) => fl.vin === inventory.vin)[0] ? (
          <Link
            to={`/admin/full-vin-decode-edit/${
              vinuploadList.filter((fl) => fl.vin === inventory.vin)[0].id
            }/${
              vinuploadList.filter((fl) => fl.vin === inventory.vin)[0]
                .inventory_id
            }?return-url=external-inventory?location=${selectedLocation.id}`}
          >
            {inventory.status_text == "Live" ? (
              <MUIButton
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#006a4e",
                  color: "#ffffff",
                  padding: "5px 5px",
                }}
              >
                Re Decode
              </MUIButton>
            ) : (
              <MUIButton
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#F5EDFC",
                  color: "#9E49E6",
                  padding: "5px 5px",
                }}
              >
                Re Decode
              </MUIButton>
            )}
          </Link>
        ) : (
          ""
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory ? (
          <Link
            to={`/admin/inventory-detail/${inventory.id}
        }`}
          >
            <RemoveRedEyeOutlinedIcon color={"preAuto"} />
          </Link>
        ) : (
          ""
        )}
      </StyledTableCell>
    </StyledTableRow>

    // <tr className={
    //   inventory.status === 4
    //     ? "bg-success text-white"
    //     : "bg-primary text-white"}>
    //   <td>{id + 1}</td>
    //   <td>
    //     {inventory.veh_in_date} <br />
    //     {inventory.status_text}

    //   </td>
    //   <td>
    //     {inventory.year} {inventory.make} {inventory.model} <br />
    //     {inventory.vin} <br />
    //     {inventory.notes}
    //   </td>
    //   <td>
    //     {inventory.color} <br />
    //     {/* <ShowDetails Inventory={inventory} /> */}
    //   </td>
    //   <td>{inventory.kilometers}</td>
    //   <td>
    //     {inventory.list_price}
    //     <br /> <small>Cost: {inventory?.cost_price}</small>
    //   </td>
    //   <td>
    //     {vinuploadList.filter(
    //       (fl) => fl.vin === inventory.vin
    //     )[0] ? (
    //       <Link
    //         to={`/admin/full-vin-decode-edit/${vinuploadList.filter(
    //           (fl) => fl.vin === inventory.vin
    //         )[0].id
    //           }/${vinuploadList.filter(
    //             (fl) => fl.vin === inventory.vin
    //           )[0].inventory_id
    //           }?return-url=external-inventory?location=${selectedLocation.id}`}
    //       >
    //         <Button size="sm" color="info">
    //           Re Decode
    //         </Button>
    //       </Link>
    //     ) : ''
    //     }
    //   </td>
    //   <td>
    //     <ViewBtn inventory={inventory} />
    //   </td>
    // </tr>
  );
}

function RemoveRow({ id, inventory, setDelistModal, setRemoveInvId }) {
  function RemoveDelist() {
    setDelistModal(true);
    setRemoveInvId(inventory);
  }
  // console.log(inventory)
  return (
    // <tr className="bg-dark text-white">
    //   <td>{id + 1}</td>
    //   <td colSpan={4}>
    //     {inventory.vin} {'=>'} {inventory.year} {inventory.make}
    //     {' '}  {inventory.model} {inventory.color} with {inventory.kilometers} kms has been deleted from backend inventory and continues to be listed in the website {inventory.status_text} </td>
    //   <td> {inventory.status_text} </td>
    //   <td><Button
    //     size="sm"
    //     color="danger"
    //     onClick={RemoveDelist}
    //   > Remove
    //   </Button>
    //   </td>
    //   <td>
    //     <ViewBtn inventory={inventory} />
    //   </td>
    // </tr>
    <StyledTableRow key={inventory}>
      <StyledTableCell align="center">{id + 1}</StyledTableCell>
      <StyledTableCell colSpan={4} component="th" scope="row" align="start">
        {inventory.vin} {"=>"} {inventory.year} {inventory.make}{" "}
        {inventory.model} {inventory.color} with {inventory.mileage} kms was
        found in backend inventory but not in website inventory
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.status_text}
      </StyledTableCell>
      <StyledTableCell align="center" onClick={() => RemoveDelist}>
        <MUIButton variant="contained" style={{ backgroundColor: "#ff0000" }}>
          Remove
        </MUIButton>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory ? (
          <Link
            to={`/admin/inventory-detail/${inventory.id}
        }`}
          >
            <RemoveRedEyeOutlinedIcon color={"preAuto"} />
          </Link>
        ) : (
          ""
        )}
      </StyledTableCell>
    </StyledTableRow>
  );
}

function DecodeRow({ id, inventory, PullFile }) {
  // console.log(inventory)
  return (
    // <tr className="bg-info text-white">
    //   <td>{id + 1}</td>
    //   <td colSpan={4}> {inventory.vin} {'=>'} {inventory.year} {inventory.make}
    //     {' '}  {inventory.model} {inventory.color} with {inventory.mileage} kms was found in backend inventory but not in website inventory</td>

    //   <td> {inventory.notes}

    //   </td>
    //   <td><Button
    //     size="sm"
    //     color="success"
    //     onClick={() => PullFile(inventory)}
    //   > Decode
    //   </Button>
    //   </td>
    //   <td> {inventory.purchased} </td>
    // </tr>
    <StyledTableRow key={inventory}>
      <StyledTableCell align="center">{id + 1}</StyledTableCell>
      <StyledTableCell colSpan={4} component="th" scope="row" align="start">
        {inventory.vin} {"=>"} {inventory.year} {inventory.make}{" "}
        {inventory.model} {inventory.color} with {inventory.mileage} kms was
        found in backend inventory but not in website inventory
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.notes}
      </StyledTableCell>
      <StyledTableCell align="center" onClick={() => PullFile(inventory)}>
        <MUIButton
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#F5EDFC",
            color: "#9E49E6",
          }}
        >
          Decode
        </MUIButton>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        {inventory.purchased}
      </StyledTableCell>
    </StyledTableRow>
  );
}

{
  /* <tr key={k}>
<td>{k + 1}</td>
<td
  className={
    inventoryList.filter((fl) => fl.vin === p.vin)[0]
      ? inventoryList.filter(
        (fl) => fl.vin === p.vin
      )[0].status === 4
        ? "bg-success"
        : "bg-primary"
      : "bg-danger"
  }
>
  {p.purchased} <br />
  <small>
    {inventoryList.filter((fl) => fl.vin === p.vin)[0]
      ? inventoryList.filter(
        (fl) => fl.vin === p.vin
      )[0].status_text
      : "VIN Not Found in website inventory"}
  </small>
</td>

<td>
  {p.year} {p.make} {p.model} <br />
  {p.vin} <br />
  {p.notes}
</td>
<td>
  {p.color} <br />
  <ShowDetails p={p} inventoryList={inventoryList} />
</td>
<td>{p.mileage}</td>
<td>
  Backend: {p.retail}
  <hr />
  Website:
  {inventoryList.filter((fl) => fl.vin === p.vin)[0]
    ? inventoryList.filter(
      (fl) => fl.vin === p.vin
    )[0].list_price
    : "Not Found in website"}
  {pricesUpdated.filter(
    (fl) => fl.vin === p.vin
  )[0] ? (
    <p>
      {" "}
      <span className="show-updated-text">
        Price Updated
      </span>
    </p>
  ) : (
    ""
  )}
</td>
<td>
  {vinuploadList.filter(
    (fl) => fl.vin === p.vin
  )[0] ? (
    <Link
      to={`/admin/full-vin-decode-edit/${vinuploadList.filter(
        (fl) => fl.vin === p.vin
      )[0].id
        }/${vinuploadList.filter(
          (fl) => fl.vin === p.vin
        )[0].inventory_id
        }?return-url=external-inventory?location=${selectedLocation.id}`}
    >
      <Button size="sm" color="info">
        Resolve
      </Button>
    </Link>
  ) : (
    <Button
      size="sm"
      color="success"
      onClick={() => PullFile(p)}
    >
      Decode
    </Button>
  )}
</td>

<td>
  <ViewBtn p={p} inventoryList={inventoryList} />
</td>
</tr> */
}
