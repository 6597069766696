import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_LEADSOURCES,
  SG_ADD_LEADSOURCE,
  LOAD_DEFAULT_LEADSOURCES,
} from "constants/actions";

import ReactPaginate from "react-paginate";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile, deviceType
// } from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  // Table,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button as MUIButton,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CreateLeadSource from "CompanyInformation/CreateEdit";
import AddNewLeadCampaign from "./AddNewLeadCampaign";

const Leadsource = (props) => {
  const dispatch = useDispatch();
  const [params, setParams] = React.useState(props.params);
  const [leadsources, setLeadsources] = React.useState([]);
  const [generateRows, setGenerateRows] = React.useState(false);
  React.useEffect(() => {
    dispatch({ type: LOAD_LEADSOURCES, payload: params });
  }, [dispatch, params]);

  const { lead_sources, default_lead_sources } = useSelector(
    (state) => ({
      lead_sources: state.leadsources.leadsources,
      default_lead_sources: state.leadsources.default,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (lead_sources) {
      setLeadsources(lead_sources);
    }
  }, [lead_sources]);

  const GenerateDefault = () => {
    dispatch({ type: LOAD_DEFAULT_LEADSOURCES });
    setGenerateRows(true);
  };

  React.useEffect(() => {
    if (default_lead_sources) {
      if (generateRows) {
        default_lead_sources.map((p) => {
          dispatch({
            type: SG_ADD_LEADSOURCE,
            payload: { ...p, company: parseInt(props.id) },
          });
        });
        setGenerateRows(false);
      }
    }
  }, [default_lead_sources, generateRows]);

  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Lead Sources</CardTitle>
                <h5 className="card-category">Evaluate all Lead Sources </h5>
              </Col>
              <Col className="ml-auto" md="2">
                {/* <Link to="/admin/lead-campaign-create"> */}
                <MUIButton
                  color="preAuto"
                  onClick={onOpenAddTask}
                  variant="contained"
                >
                  Create New
                </MUIButton>
                {/* </Link> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell align="center">Mode</StyledTableCell>
                          <StyledTableCell align="center">
                            Started On
                          </StyledTableCell>
                          <StyledTableCell align="center">Cost</StyledTableCell>
                          <StyledTableCell align="center">
                            Revenue
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Revenue
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leadsources.map((p, k) => (
                          <StyledTableRow key={k}>
                            <StyledTableCell>{p.name}</StyledTableCell>
                            <StyledTableCell align="center">
                              {p.status_text}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {p.origin_text}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {moment(p.start_date).format("YYYY-MM-DD")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {p.cost}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {p.revenue}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Link to={`/admin/lead-campaign-detail/${p.id}`}>
                                <RemoveRedEyeOutlinedIcon
                                  size={40}
                                  color={"preAuto"}
                                />
                              </Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults}
              rowsPerPage={12}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            /> */}
                </Paper>
                {/* {leadsources.length > 0 ? (
                  <Table responsive>
                    <thead className="table-active">
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Mode</th>
                        <th className="text-right">Started On</th>
                        <th className="text-right">Cost</th>
                        <th className="text-right">Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leadsources.map((p, k) => (
                        <tr key={k}>
                          <td>{p.name}</td>
                          <td>{p.status_text}</td>
                          <td>{p.origin_text}</td>
                          <td className="text-right">
                            {moment(p.start_date).format("YYYY-MM-DD")}
                          </td>
                          <td className="text-right">{p.cost}</td>
                          <td className="text-right">{p.revenue}</td>
                          <td>
                            <Link to={`/admin/lead-campaign-detail/${p.id}`}>
                              View More
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : props.showDefault ? (
                  <Button color="warning" size="sm" onClick={GenerateDefault}>
                    Generate Lead Source
                  </Button>
                ) : (
                  ""
                )} */}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            {/* <div className="stats">
            <i className="fa fa-refresh spin" />
            Updated 3 minutes ago
          </div> */}
          </CardFooter>
        </Card>
      </div>
      <AddNewLeadCampaign
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Leadsource;
