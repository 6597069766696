import React from 'react';

import {  useDispatch } from 'react-redux'
import {LOGOUT_REQUEST } from "constants/actions";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Alert,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from "reactstrap";

import bgImage from "assets/img/bg/fabio-mangione.jpg"


// 
const Error = () => {
let history = useHistory();

const dispatch = useDispatch();
  React.useEffect(() => {
      dispatch({type: LOGOUT_REQUEST})
      // history.push("/auth/login-page")
    
    }, [dispatch, history]);
  
  return ( <>
  <div className="content">
        <div className="login-page"> 
        
          <Container>
            <Row className="justify-content-center">
             
            
            
              <Col lg={5} md={8} xs={12}>
                <Card className="card-signup">
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Oops there was an error</CardTitle>
                    
                  </CardHeader>
                  <CardBody>
                  <div className="description">
                    <p className="description text-dark">
                    We are on it meanwhile please try login back in
                    </p>
                  </div>
           
                     </CardBody>
                  <CardFooter className="text-center">
                  <Link to={`/auth/login`}>
                 
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-round">
                     Login
                    </Button></Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
  </> );
}
 
export default Error;