import React from "react";
// import MaterialTable from 'material-table';
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_WEB_LEAD, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";

import Truncate from "react-truncate";

// reactstrap components
import {
  Spinner,
  Fade,
  // Table,
  Row,
  Col,
} from "reactstrap";

const List = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let qMakeList = params.get("makes");
  let qYearList = params.get("years");
  let qModelList = params.get("models");

  const [webLeads, setWebLeads] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.pageParams);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);

  // console.log(props.pageParams)
  console.log("leads list");

  React.useEffect(() => {
    if (props.showAll) {
      dispatch({
        type: LOAD_WEB_LEAD,
        payload: { page: pageactivePage, params: pageParams },
      });
    } else {
      dispatch({
        type: LOAD_WEB_LEAD,
        payload: { page: pageactivePage, params: pageParams },
      });
    }
  }, [dispatch, pageParams, pageactivePage]);

  const {
    get_web_leads,
    get_pending,
    get_auth,
    get_errors,
    get_inventory_select,
    get_activePage,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_web_leads: state.webleads.webleads,
      get_pending: state.webleads.pending,
      // get_inventory_select: state.inventories.select_inventory,
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.leadstage.leadstages,
      lead_type: state.leadtypes.leadtypes,
      get_auth: state.auth,
      get_errors: state.errors,
      get_activePage: state.paginate.lead,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelLeadType(lead_type);
  }, [lead_type]);

  React.useEffect(() => {
    setPageParams(props.pageParams);
    if (props.pageParams) {
      setPageActivePage(1);
      setActivePage(0);
    }
  }, [props]);

  React.useEffect(() => {
    setSelLeadStage(lead_stage);
  }, [lead_stage]);

  React.useEffect(() => {
    setSelLeadSource(lead_sources);
  }, [lead_sources]);

  React.useEffect(() => {
    setWebLeads(get_web_leads.results);
  }, [get_web_leads]);

  React.useEffect(() => {
    setPending(get_pending);
  }, [get_pending]);

  React.useEffect(() => {
    if (get_web_leads) {
      setTotalResults(get_web_leads.count);
      if (get_web_leads.page_size) {
        setPerPage(get_web_leads.page_size);
      }
      setTotalPages(Math.ceil(get_web_leads.count / perPage));
    }
  }, [get_web_leads]);

  // console.log('get_activePage', get_activePage)
  // console.log('pageactivePage', pageactivePage)
  // console.log('activePage', activePage)

  React.useEffect(() => {
    // if (get_activePage) {
    setPageActivePage(get_activePage + 1);
    setActivePage(get_activePage);
    // }
  }, [get_activePage]);

  // const handleClick = (page) => {

  //   setActivePage(page.selected);
  //   setPageActivePage(page.selected + 1);
  //   dispatch({
  //     type: SG_UPDATE_ACTIVE_PAGE,
  //     payload: { name: "lead", page: page.selected },
  //   });

  // };
  const [page, setPage] = React.useState(0);
  const handleClick = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "lead", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };
  return (
    <>
      {/* <MaterialTable
      title="Basic Filtering Preview"
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Surname', field: 'surname' },
        { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
        {
          title: 'Birth Place',
          field: 'birthCity',
          lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
        },
      ]}
      data={[
        { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
        { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
      ]}        
      options={{
        filtering: true,
        exportButton: true
      }}
    /> */}
      <Row>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
        <div className={pending ? "" : "d-none mt-3"}>
          <div className="d-flex justify-content-center">
            <Fade in={pending} tag="h5">
              <Spinner>Loading...</Spinner>
            </Fade>
          </div>
        </div>
      </Row>
      <div className={pending ? "d-none" : ""}>
        <Row>
          {/* <Col md="2">
          <ListGroup >
        <ListGroupItem tag="a" href="#">Cras justo odio</ListGroupItem>
        <ListGroupItem tag="a" href="#">Dapibus ac facilisis in</ListGroupItem>
        <ListGroupItem tag="a" href="#">Morbi leo risus</ListGroupItem>
        <ListGroupItem tag="a" href="#">Porta ac consectetur ac</ListGroupItem>
        <ListGroupItem tag="a" href="#">Vestibulum at eros</ListGroupItem>
      </ListGroup>
          </Col> */}

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Customer</StyledTableCell>
                    <StyledTableCell align="center">
                      Description
                    </StyledTableCell>
                    <StyledTableCell align="center">Source</StyledTableCell>
                    <StyledTableCell align="center">Stage</StyledTableCell>
                    <StyledTableCell align="center">Lead Type</StyledTableCell>
                    <StyledTableCell align="center">Created</StyledTableCell>
                    <StyledTableCell align="center">UTM Source</StyledTableCell>
                    <StyledTableCell align="center">Assigned</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {webLeads
                    ? webLeads.map((p, k) => (
                        <StyledTableRow key={k}>
                          <StyledTableCell>
                            {" "}
                            {p.first_name} {p.last_name} <br />
                            <small>
                              {p.customer ? (
                                <Link
                                  to={`/admin/customer-detail/${p.customer}`}
                                  style={{ color: "#1d4aa7" }}
                                >
                                  View
                                </Link>
                              ) : null}
                            </small>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Truncate
                              lines={3}
                              ellipsis={
                                <span>
                                  ...{" "}
                                  <Link
                                    to={`/admin/lead-detail/${p.id}`}
                                    style={{ color: "#1d4aa7" }}
                                  >
                                    Read More
                                  </Link>
                                </span>
                              }
                            >
                              {p.description}
                            </Truncate>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.web_lead_source ? p.web_lead_source.name : null}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {p.web_lead_stage ? p.web_lead_stage.name : null}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {p.web_lead_type ? p.web_lead_type.name : null}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.created_at
                              ? moment(p.created_at).format(
                                  "YYYY-MM-DD hh:mm a"
                                )
                              : "No date given"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.utm_source === "null" ? "" : p.utm_source}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {p.employee_first_name
                              ? `${p.employee_first_name} ${p.employee_last_name}`
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Link to={`/admin/lead-detail/${p.id}`}>
                              <RemoveRedEyeOutlinedIcon
                                size={40}
                                color={"preAuto"}
                              />
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    : " No tasks found"}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[12]}
                component="div"
                count={totalResults || 0}
                rowsPerPage={
                  totalPages > 12 ? webLeads.length || 0 : totalPages || 0
                }
                page={page}
                onPageChange={handleClick}
                ActionsComponent={TablePaginationActions}
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    marginTop: "auto",
                  },
                  ".MuiTablePagination-displayedRows": {
                    marginTop: "auto",
                  },
                }}
              />
            </TableContainer>
          </Paper>

          {/* <Col md="12">
            <Table responsive>
              <thead className="table-active">
                <tr>
                  <th>Customer</th>
                  <th>Description</th>
                  <th>Source</th>
                  <th>Stage</th>
                  <th>Lead Type</th>

                  <th className="text-right">Created</th>
                  <th>UTM Source</th>
                  <th>Assigned</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {webLeads ? (
                  webLeads.map((p, k) => (
                    <tr key={k}>

                      <td>
                        {p.first_name} {p.last_name} <br />
                        <small>
                          {p.customer ? (
                            <Link to={`/admin/customer-detail/${p.customer}`}>
                              View
                            </Link>
                          ) : null}
                        </small>
                      </td>
                      <td>
                        <Truncate
                          lines={3}
                          ellipsis={
                            <span>
                              ...{" "}
                              <Link to={`/admin/lead-detail/${p.id}`}>
                                Read More
                              </Link>
                            </span>
                          }
                        >
                          {p.description}
                        </Truncate>
                      </td>
                      <td>
                        {p.web_lead_source ? p.web_lead_source.name : null}
                      </td>
                      <td>{p.web_lead_stage ? p.web_lead_stage.name : null}</td>
                      <td>{p.web_lead_type ? p.web_lead_type.name : null}</td>

                      <td className="text-right">
                        {p.created_at
                          ? moment(p.created_at).format(
                            "YYYY-MM-DD hh:mm a"
                          )
                          : "No date given"}
                      </td>

                      <td className="text-right">{p.utm_source === 'null' ? '' : p.utm_source}</td>
                      <td>
                        {p.employee_first_name
                          ? `${p.employee_first_name} ${p.employee_last_name}`
                          : null}
                      </td>
                      <td>
                        <Link to={`/admin/lead-detail/${p.id}`}>View More</Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Leads Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col> */}
          {/* {showAll? <td>{p.assigned_to}</td>: null} */}
        </Row>
      </div>
      {/* <Row>
        <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col>
      </Row> */}

      {/* <MaterialTable
      title="Basic Filtering Preview"
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Surname', field: 'surname' },
        { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
        {
          title: 'Birth Place',
          field: 'birthCity',
          lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
        },
      ]}
      data={[
        { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
        { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
      ]}        
      options={{
        filtering: true
      }}
    /> */}
    </>
  );
};

export default List;
