import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Zoom } from "@mui/material";
import AddNewList from "./AddNewList";
// reactstrap components

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import List from "./List";

import Search from "./Search"
import Download from "./Download"

const ListWebLeads = (props) => {
  const [pageParams, setPageParams] = React.useState('');
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);
  const [fetch, setFetch] = React.useState(false)

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Leads</CardTitle>
                    <h5 className="card-category">My Leads</h5>
                  </Col>
                  {/* <Col className="ml-auto" md="2">
                    <Button
                      color="default"
                      size="sm"
                      to="/admin/lead-create"
                      tag={Link}
                    >
                      Create New
                    </Button>
                  </Col> */}
                   <Col className="ml-auto" md={4} lg={4} xl={2} sm={6}>
                    <Box
                      sx={{
                        p: 0,
                        m: 0,
                      }}
                    >
                      {/* <Zoom
                        in
                        style={{ transitionDelay: "300ms" }}
                        sx={{
                          padding: "8px 28px",
                          m: 0,
                          borderRadius: 30,
                        }}
                      > */}
                      <Button
                        variant="outlined"
                        color="preAuto"
                        sx={{
                          borderRadius: 30,
                          "& .MuiSvgIcon-root": {
                            fontSize: 26,
                          },
                          fontSize: 14,
                          // "&:focus": {
                          //   outline: "none",
                          // },
                        }}
                        startIcon={<AddIcon />}
                        onClick={onOpenAddTask}
                      >
                        CREATE NEW
                      </Button>
                      {/* </Zoom> */}
                    </Box>
                    {/* <Button
                      className="btn btn-gradient-success btn-fw"
                      color="default"
                      size="sm"
                      to="/admin/task-list-create"
                      tag={Link}
                    >
                      Create New
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                  <Search setPageParams={setPageParams}/>
                    <List pageParams={pageParams} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
          <Download pageParams={pageParams} 
          fetch={fetch} 
          setFetch={setFetch}
          />
          </Col>
        </Row>
      </div>

      <AddNewList
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default ListWebLeads;
