import { GET_LANDINGPAGES, DELETE_LANDINGPAGE, ADD_LANDINGPAGE, FETCH_LANDINGPAGE, 
    EDIT_LANDINGPAGE, 
    ADD_LANDINGPAGE_IMAGE, 
    GET_LANDINGPAGES_PENDING, 
    NEW_A_LANDINGPAGE,
    CLEAR_ALL } 
    from "constants/actions";

const initialState = {
    landingpages: [],
    created: [],
    pending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LANDINGPAGES_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_LANDINGPAGES:
            return {
                ...state,
                landingpages: action.payload,
                pending: false
            };
        case FETCH_LANDINGPAGE:
            return { ...state, [action.payload.id]: action.payload };
        case EDIT_LANDINGPAGE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LANDINGPAGE:
            return {
                ...state,
                landingpages: state.landingpages.results.filter(Branch => Branch.id !== action.payload)
            };
        case NEW_A_LANDINGPAGE:
                return {
                    ...state,
                    created : []
                };
        case ADD_LANDINGPAGE:
            return {
                ...state,
                created : action.payload
            };
        case ADD_LANDINGPAGE_IMAGE:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_ALL:
            return {
                ...state,
                landingpages: []
            };
        default:
            return state;
    }
}

