import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import EmployeeList from "Employees/EmployeeList";

import Select from "react-select";

// import { RHFInput } from 'react-hook-form-input';
import {
  UPDATE_WEB_COMMENT,
  SG_ADD_WEB_COMMENT,
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  SG_FETCH_WEB_LEAD,
  SG_GET_SELECT_INVENTORIES,
  LOAD_LEADSOURCES,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";

const VehicleOptions = [
  { value: "Car", label: "Car" },
  { value: "SUV", label: "SUV" },
  { value: "Truck", label: "Truck" },
  { value: "Van", label: "Van" },
  { value: "Other as described", label: "Other as described" },
];

const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const CreateWebComment = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [webLead, setWebLead] = React.useState([]);

  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_WEB_LEAD, payload: id });
    }
    dispatch({ type: SG_GET_SELECT_INVENTORIES });
    dispatch({ type: LOAD_LEADSOURCES });
    dispatch({ type: LOAD_LEADTYPES });
    dispatch({ type: LOAD_LEAD_STAGE });
  }, [dispatch]);

  const {
    get_web_leads,
    get_auth,
    get_errors,
    get_inventory_select,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_web_leads: state.webleads,
      get_inventory_select: state.inventories.select_inventory,
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.webleadstage.webleadstages,
      lead_type: state.webleadtype.webleadtypes,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]
  React.useEffect(() => {
    setSelLeadType(lead_type);
  }, [lead_type]);

  React.useEffect(() => {
    setSelLeadStage(lead_stage);
  }, [lead_stage]);

  React.useEffect(() => {
    setSelInventory(get_inventory_select);
  }, [get_inventory_select]);

  React.useEffect(() => {
    setSelLeadSource(lead_sources);
  }, [lead_sources]);

  React.useEffect(() => {
    if (get_web_leads[parseInt(id)]) {
      setWebLead(get_web_leads[parseInt(id)]);

      // const SelectedOption = OriginOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].origin
      // )[0];
      // setSelOrigin(SelectedOption);

      // const SelectedStatus = StatusOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].status
      // )[0];
      // setSelStatus(SelectedStatus);
    }
  }, [get_web_leads, id]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    // console.log(selStatus);
    if (id) {
      if (get_web_leads[parseInt(id)]) {
        setValue(
          "expected_purchase_date",
          moment(get_web_leads[parseInt(id)].expected_purchase_date)
        );
        setValue("web_lead_stage", get_web_leads[parseInt(id)].web_lead_stage);
        setValue("web_lead_type", get_web_leads[parseInt(id)].web_lead_type);
        setValue(
          "web_lead_source",
          get_web_leads[parseInt(id)].web_lead_source
        );
        setValue("inventory", get_web_leads[parseInt(id)].inventory);
      }
    }
  }, [get_web_leads, id]);

  const onSubmit = (data) => {
    // console.log(data.inventory);
    if (id) {
      dispatch({
        type: UPDATE_WEB_COMMENT,
        payload: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.emailiid,
          phone: data.phone,
          description: data.description,
          rating: data.rating,
          web_lead_stage: 6,
        },
      });

      setTimeout(() => {
        setToDetail(true);
      }, 2000);
    } else {
      dispatch({
        type: SG_ADD_WEB_COMMENT,
        payload: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.emailiid,
          phone: data.phone,
          description: data.description,
          rating: data.rating,
          web_lead_stage: 6,
        },
      });

      setTimeout(() => {
        setToHome(true);
      }, 2000);
    }

    // <Redirect />
  };

  //   const ChangedStatus = (value) => {
  //     setSelStatus(value);
  //   };

  return (
    <>
      {toDetail ? <Redirect to={`/admin/lead-detail/${id}`} /> : null}
      {toHome ? <Redirect to={`/admin/leads`} /> : null}
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card>
                <CardBody>
                  <CardTitle tag="h4">{id ? "Edit " : "Create "}Lead</CardTitle>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              First name <span className="icon-danger">*</span>
                            </label>
                            <Input
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              innerRef={register({
                                required: "Please enter First Name",
                              })}
                            />
                            <p className="text-danger small">
                              {errors.first_name && (
                                <span>{errors.first_name.message}</span>
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Last name <span className="icon-danger">*</span>
                            </label>
                            <Input
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              innerRef={register({
                                required: "Please enter Last Name",
                              })}
                            />
                            <p className="text-danger small">
                              {errors.last_name && (
                                <span>{errors.last_name.message}</span>
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <p>
                          Phone <span className="icon-danger">*</span>
                        </p>

                        {/* <Controller
                  as={<NumberFormat format="+1 (###) ###-####" 
                  mask="_" />}
                  control={control}
                  
                  className="border-input"
                  name="phone_no"
                  placeholder="enter the Phone Number"
                  type="text"
                  innerRef={register({
                    required: "Please enter phone number",
                    minlength: 10,
                  })}
                />
                 <p className="text-danger small">
                {errors.phone_no && <span>{errors.phone_no.message}</span>}
              </p> */}
                        <Input
                          className="border-input"
                          name="phone"
                          placeholder="enter the Phone Number"
                          type="text"
                          innerRef={register({
                            required: "Please enter phone number",
                            minlength: 10,
                          })}
                        />
                        <p className="text-danger small">
                          {errors.phone && <span>{errors.phone.message}</span>}
                        </p>
                      </FormGroup>

                      <FormGroup className="label-floating">
                        <label className="control-label">
                          Email address <span className="icon-danger">*</span>
                        </label>
                        <Input
                          name="emailiid"
                          placeholder="Email"
                          type="email"
                          innerRef={register({
                            required: "Email is required",
                            pattern: {
                              value: email_validation,
                              message: "invalid email address",
                            },
                            maxlength: 20,
                          })}
                        />
                        <p className="text-danger small">
                          {errors.emailiid && (
                            <span>{errors.emailiid.message}</span>
                          )}
                        </p>
                      </FormGroup>
                      <FormGroup className="label-floating">
                        <label className="control-label">
                          Your message <span className="icon-danger">*</span>
                        </label>
                        <Input
                          id="description"
                          name="description"
                          placeholder="Message"
                          type="textarea"
                          rows="6"
                          innerRef={register({
                            required: "Please enter Message",
                          })}
                        />
                        <p className="text-danger small">
                          {errors.description && (
                            <span>{errors.description.message}</span>
                          )}
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <label className="control-label">
                          Rate this interaction{" "}
                          <span className="icon-danger">*</span>
                        </label>

                        <Input
                          id="rating"
                          name="rating"
                          placeholder="Rating"
                          type="number"
                          innerRef={register({
                            required: "Please enter rating",
                          })}
                        />

                        <p className="text-danger small">
                          {errors.rating && (
                            <span>{errors.rating.message}</span>
                          )}
                        </p>
                      </FormGroup>

                      <Row>
                        <Col md="6"></Col>
                        <Col md="6">
                          <Button
                            className="pull-right"
                            color="primary"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateWebComment;
