import React from "react";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_FETCH_LEADTYPES, SG_DELETE_LEADTYPE } from "constants/actions";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { Button as MUIButton } from "@mui/material";
import AddNewLeadType from "./AddNewLeadType";

const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [leadType, setLeadType] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)]) {
      dispatch({ type: SG_FETCH_LEADTYPES, payload: id });
    }
  }, [dispatch]);

  const { get_lead_type, get_auth, get_errors } = useSelector(
    (state) => ({
      get_lead_type: state.leadtypes[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_lead_type) {
      setLeadType(get_lead_type);
    }
  }, [get_lead_type]);
  const [isAddTaskOpen, setAddTaskOpen] = React.useState(false);

  const onOpenAddTask = () => {
    setAddTaskOpen(true);
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };
  // console.log(leadType);
  // console.log(get_lead_Type);
  return (
    <>
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Lead Type Details</CardTitle>
                    <h5 className="card-category">Details of Lead Types</h5>
                  </Col>
                  <Col className="ml-auto" md="2">
                    <MUIButton
                      color="preAuto"
                      onClick={onOpenAddTask}
                      variant="contained"
                    >
                      Edit
                    </MUIButton>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Name </label>
                    <p>{leadType ? leadType.name : ""}</p>
                  </Col>
                  <Col>
                    <label>ID </label>
                    <p>{leadType ? leadType.id : ""}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Internal URL</label>
                    <p>{leadType ? leadType.internal_url : ""}</p>
                  </Col>
                  <Col>
                    <label>Sort Order</label>
                    <p>{leadType ? leadType.sort_order : ""}</p>
                  </Col>

                  <Col>
                    <label>Active</label>

                    <p>{leadType.active ? "True" : "False"}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Description</label>
                    <p>{leadType ? leadType.description : ""}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <AddNewLeadType
        isAddTaskOpen={isAddTaskOpen}
        onCloseAddTask={onCloseAddTask}
        setAddTaskOpen={setAddTaskOpen}
      />
    </>
  );
};

export default Detail;
