import React, { useState } from "react";

import PropTypes from "prop-types";
import AppDialog from "../../crema/AppDialog";

import CreateWebLead from "../Create";

const AddNewList = ({
  isAddTaskOpen,
  setAddTaskOpen,
  onCloseAddTask,
  selectedDate,
  title,
}) => {
  const [label, setLabel] = useState("");

  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => onCloseAddTask()}
      title={label}
    >
      <CreateWebLead setAddTaskOpen={setAddTaskOpen} setLabel={setLabel} />
    </AppDialog>
  );
};

export default AddNewList;

AddNewList.propTypes = {
  isAddTaskOpen: PropTypes.bool.isRequired,
  onCloseAddTask: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
};
