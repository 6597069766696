import { put, all, call, takeLatest } from 'redux-saga/effects'

import { GET_YEAR_MAKE_PENDING,
    LOAD_YEAR_MAKE,
    GET_YEAR_MAKE,
    FETCH_YEAR_MAKE,
    SG_FETCH_YEAR_MAKE,
    SUBMIT_YEAR_MAKE,
    SG_SUBMIT_YEAR_MAKE,
    ALL_ERRORS } from "constants/actions";

import * as api from 'constants/api';

function* selectCarYearMakes(action) {
    yield put({ type: GET_YEAR_MAKE_PENDING});
    try {
        const json = yield call(api.GET_YEAR_MAKE, action.payload)
        yield put({ type: GET_YEAR_MAKE, payload: json.data, });
    }
    catch (e) {
        yield put({type: ALL_ERRORS, error: e.message});
    }
}

export function* YearMakeSelect() {
    yield takeLatest(LOAD_YEAR_MAKE, selectCarYearMakes);
}

function* fetchYearMakeSelect(action) {
    yield put({ type: GET_YEAR_MAKE_PENDING});
    try {
        const json = yield call(api.GET_CARYEARS_LIST, action.payload)
        yield put({ type: FETCH_YEAR_MAKE, payload: json.data, });
    } catch (e) {
        yield put({type: ALL_ERRORS, message: e});
    }
}

export function* yearMakeSelectFetchOne() {
    yield takeLatest(SG_FETCH_YEAR_MAKE, fetchYearMakeSelect);
}


export default function* index() {
    yield all([YearMakeSelect(),
        yearMakeSelectFetchOne(),   
    ]);
}