import React, { useState } from "react";
import { useParams } from "react-router";

import { Row, Col } from "reactstrap";

import CreateEdit from "./CreateEdit";

const FullEdit = () => {
  const { id, vinid, fileid } = useParams();

  return (
    <>
      <div className="content">
       
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              
              <CreateEdit id={id} vinid={vinid} fileid={fileid} />
           
          
            </Col>
          </Row>
       
      </div>
    </>
  );
};

export default FullEdit;
