import React from "react";
// import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from 'lodash'
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// import { addDays, subDays } from "date-fns";

import Select from "react-select";

// import { RHFInput } from 'react-hook-form-input';
import {
  UPDATE_COMPANY_INFORMATION,
  CREATE_COMPANY_INFORMATION,
  SG_FETCH_COMPANY_INFORMATION,
} from "constants/actions";
// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  // CardHeader,
  CardTitle,
  // CardFooter,
  Button,
  // Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";


const CreateLeadSource = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [companyInformation, setCompanyInformation] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [pageerrors, setPageErrors] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log(id);
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_COMPANY_INFORMATION, payload: id });
    }
  }, [dispatch]);

  const { get_company_information, get_auth, get_errors } = useSelector(
    (state) => ({
      get_company_information: state.companyinformation.companyinformations,
      get_auth: state.auth,
      get_errors: state.errors
    }),
    shallowEqual
  );

  // [parseInt(id)]

  React.useEffect(() => {
    // console.log(_.isEmpty(loginError.errors))
    if (!_.isEmpty(get_errors.errors)) {
      // console.log(loginError)
     
      setVisible(true)
      setPageErrors(get_errors)
    }
  }, [get_errors])
  React.useEffect(() => {
    if (get_company_information[parseInt(id)]) {
      setCompanyInformation(get_company_information[parseInt(id)]);


    }
  }, [get_company_information, id]);

  // console.log(selStatus);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  
  const onSubmit = (data) => {
    if ([parseInt(id)] > 0) {
      dispatch({
        type: UPDATE_COMPANY_INFORMATION,
        payload: {
          id,
          company: 1,
          name: data?.name
        },
      });

      setTimeout(() => {
        setToDetail(true)
      }, 2000);

    } else {
      dispatch({
        type: CREATE_COMPANY_INFORMATION,
        payload: {
          company: 1,
          name: data?.name,
        },
      });
      setTimeout(() => {
        setToHome(true)
      }, 2000);
    }

    // <Redirect />
  };



  return (
    <>
      <div className="content">
      <Alert color="danger" isOpen={visible} toggle={setVisible}>
      {pageerrors.errors? pageerrors.errors['detail']: JSON.stringify(pageerrors.errors)}

      {/* {JSON.stringify(pageerrors.errors)} */}
    </Alert>
    {toDetail? <Redirect to={`/admin/lead-source-detail/${id}`}  /> : null}
    {toHome? <Redirect to={`/admin/lead-sources`}  /> : null}
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card>
                <CardBody>
                  <CardTitle tag="h4">
                    {id ? "Edit " : "Create "}Lead Source
                  </CardTitle>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <label>Name </label>
                      <Input
                        type="text"
                        name="name"
                        defaultValue={companyInformation ? companyInformation.name : ""}
                        innerRef={register({
                          required: "Please enter Lead Source Name",
                        })}
                      />
                      <p className="text-danger small">
                        {errors.name && <span>{errors.name.message}</span>}
                      </p>
                    </FormGroup>
                    
                    <Button className="btn-fill" 
                    color= {id ? "primary " : "secondary "} 
                    type="submit">
                      {id ? "Update " : "Save "}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateLeadSource;
