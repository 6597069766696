import React from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import List from "Customers/List"
import Download from "./Download"

export default () => {

  return (
    <>
      <div className="content">
      <Row>
        <Col md="12">
        <Card>
        <CardHeader>
          <CardTitle tag="h4">Customers</CardTitle>
        </CardHeader>
        <CardBody>
            <List />
        </CardBody>
        </Card>
           {/* list of customers */}
          <Download />
          </Col>
      </Row>
      </div>
    </>
  )
}
  