import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import ErrorBoundary from "ErrorBoundary";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Alert,
  Input,
  Row,
  Col,
} from "reactstrap";


import {
  EditorConvertToHTML, EditorInLineConvertToHTML,
} from "./EditorConvertToHTML";
// FETCH_INVENTORY_NOTES_ONE
import {
  LOAD_INVENTORY_NOTES,
  UPDATE_INVENTORY_NOTES,
} from "constants/actions";


const InventoryNotes = ({ inventory, standardEquipment }) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const [equipmentList, setEquipmentList] = useState([]);
  const [editShortDesc, setEditShortDesc] = useState(null);

  const [showMessage, setShowMessage] = useState(null)


  const [editWebsiteVdpSyndicate, setEditWebsiteVdpSyndicate] = useState(null);
  const [yearMakeModel, setYearMakeModel] = useState('');
  const [syndicateExtra, setSyndicateExtra] = useState('');
  const [trimExtra, setTrimExtra] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [decodeSummary, setShortDecodeSummary] = useState('');

  // const [editOverall, setEditOverall] = useState(null);
  // const [editInterior, setEditInterior] = useState(null);
  // const [editExterior, setEditExterior] = useState(null);
  // const [editSpecialEquipment, setEditSpecialEquipment] = useState(null);
  // const [editInternalNotes, setEditInternalNotes] = useState(null);

  React.useEffect(() => {
    if (standardEquipment) {
      if (standardEquipment[0]) {
        setEquipmentList(standardEquipment[0].full_response);
      }
    }
  }, [standardEquipment]);


  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_INVENTORY_NOTES, payload: id });
  }, [dispatch, id]);

  const { get_inventory_notes, get_errors, get_error_state } = useSelector(
    (state) => ({
      get_inventory_notes: state.inventorynotes[parseInt(id)],
      get_errors: state.errors,
      get_error_state: state.errorstate,
    }),
    shallowEqual
  );

  // console.log(get_inventory_notes)
  // let mySet = new Set();
  // let LargeString = '';

  // React.useEffect(() => {
  //   if (standardEquipment && id) {
  //     standardEquipment.map((p) => mySet.add(p.name));
  //     for (let item of mySet.values()) {
  //       LargeString += `<h3>${item}</h3> <br />`;
  //       LargeString += `<ul>`
  //       standardEquipment
  //         .filter((fl) => fl.name === item)[0].value
  //         .map((lft) => {
  //           LargeString += `<li>${lft.name}</li> `;
  //           return lft.name;
  //         })
  //       LargeString += `</ul>`
  //       LargeString += `<br />`;
  //       // setEditShortDesc(LargeString);
  //     }
  //     // if (!get_inventory_notes?.short_description) {
  //     //   dispatch({
  //     //     type: UPDATE_INVENTORY_NOTES,
  //     //     payload: { id, short_description: LargeString },
  //     //   });
  //     // }
  //   }
  // }, [standardEquipment, id]);



  React.useEffect(() => {
    if (get_inventory_notes) {
      // short_description
      // setEditShortDesc(get_inventory_notes.short_description ? get_inventory_notes.short_description : LargeString.length > 2 ? LargeString : '<p></p>');
      setEditWebsiteVdpSyndicate(get_inventory_notes?.vdp_header_syndicate)
      setYearMakeModel(get_inventory_notes?.year_make_model_extra ? get_inventory_notes?.year_make_model_extra : '')
      setTrimExtra(get_inventory_notes?.trim_extra ? get_inventory_notes?.trim_extra : '')
      // Syndicate extra
      setSyndicateExtra(get_inventory_notes?.syndicate_extra ? get_inventory_notes?.syndicate_extra : '')
      setShortDescription(get_inventory_notes?.short_description)
      setShortDecodeSummary(get_inventory_notes?.descode_summary_html)

      // setEditOverall(get_inventory_notes?.overall);
      // setEditInterior(get_inventory_notes?.interior);
      // setEditExterior(get_inventory_notes?.exterior);
      // setEditSpecialEquipment(get_inventory_notes.special_equipment);
      // setEditInternalNotes(get_inventory_notes.internal_notes);
    }
  }, [get_inventory_notes]);


  const BtnCallBackYMMT = () => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, year_make_model_extra: yearMakeModel },
    });
    setShowMessage(`Updated Year make model with ${yearMakeModel}`)
  };

  const BtnCallBackTrimExtra = () => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, trim_extra: trimExtra },
    });
    setShowMessage(`Updated Trim Extra with ${trimExtra}`)
  };

  const BtnCallBackSyndicateExtra = () => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, syndicate_extra: syndicateExtra },
    });
    setShowMessage(`Updated Syndicate Trim Extra with ${syndicateExtra}`)
  };

  const BtnCallBackShortDesc = (data) => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, short_description: data },
    });
  };

  const BtnCallBackVdpSyndicate = (data) => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, vdp_header_syndicate: data },
    });
  };

  const BtnCallBackDecodeSummary = (data) => {
    dispatch({
      type: UPDATE_INVENTORY_NOTES,
      payload: { id, descode_summary_html: data },
    });
  };



  return (
    <>
      <ErrorBoundary>
        {/* <VoiceFilter /> */}

        {showMessage && <Alert
          color="info"
          toggle={() => setShowMessage(null)}
        >
          {showMessage}
        </Alert>}

        <Card>
          <CardBody>
            <CardTitle tag="h4">{inventory.year} {inventory.make} {inventory.model} {inventory.trim} {inventory.color} {inventory?.engine}</CardTitle>
            {/* <p>OLD DATA:

              <div dangerouslySetInnerHTML={{ __html: get_inventory_notes?.short_description?.replace(/(<? *script)/gi, 'illegalscript') }} ></div>
            </p> */}
            <p>Roughly same size as website</p>
            <Row>
              <Col md="9">
                <Row>
                  <Col md="4">
                    <Card
                      className="ml-1 mr-1 card-product card-plain
       inventory-card"
                    >
                      <CardBody>
                        <CardTitle>
                          <Row>
                            <Col>
                              <div className="border-div">

                                <Row>
                                  <Col className="text-danger">
                                    <span className="year-make">
                                      {yearMakeModel}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-danger">
                                    <span className="model-trim">
                                      {trimExtra}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <span className="product-description">
                                      <div dangerouslySetInnerHTML={{ __html: shortDescription?.replace(/(<? *script)/gi, 'illegalscript') }} ></div>
                                    </span>
                                  </Col>
                                </Row>


                              </div>
                            </Col>
                          </Row>
                        </CardTitle>
                      </CardBody>

                    </Card>

                  </Col>

                </Row>

              </Col>
            </Row>


            <Row>
              <Col md="10">
                <FormGroup>
                  <label>Year Make Model</label>
                  <Input
                    type="text"
                    name="year_make_model"
                    value={yearMakeModel}
                    onChange={(e) => setYearMakeModel(e.target.value)}

                  />
                  {/* <p className="text-danger small">
                        {errors.first_name && (
                          <span>{errors.first_name.message}</span>
                        )}
                      </p> */}
                </FormGroup>
              </Col>
              <Col md="2" className="mt-3">
                <Button onClick={BtnCallBackYMMT}>Update</Button>
              </Col>
            </Row>
            <Row>
              <Col md="10">
                <FormGroup>
                  <label>Extra Long Trim</label>
                  <Input
                    type="text"
                    name="extra_long_trim"

                    value={trimExtra}
                    onChange={(e) => setTrimExtra(e.target.value)}

                  />

                  {/* <p className="text-danger small">
                        {errors.first_name && (
                          <span>{errors.first_name.message}</span>
                        )}
                      </p> */}
                </FormGroup>
              </Col>
              <Col md="2" className="mt-3">
                <Button onClick={BtnCallBackTrimExtra}>Update</Button>
              </Col>
            </Row>

            <Row>
              <Col md="10">
                <FormGroup>
                  <label>Extended Trim for Syndication</label>
                  <Input
                    type="text"
                    name="syndicate_extra"
                    value={syndicateExtra}
                    onChange={(e) => setSyndicateExtra(e.target.value)}

                  />
                  {/* <p className="text-danger small">
                        {errors.first_name && (
                          <span>{errors.first_name.message}</span>
                        )}
                      </p> */}
                </FormGroup>
              </Col>
              <Col md="2" className="mt-3">
                <Button onClick={BtnCallBackSyndicateExtra}>Update</Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md="12">

                <EditorInLineConvertToHTML
                  heading="Short Description Website Listing (check website for content shoud be about 3 lines or so check the above box for ref)"
                  editContent={shortDescription}
                  BtnCallBack={BtnCallBackShortDesc}
                />

              </Col>

              {/* setTrimExtra(data) */}
            </Row>
            <Row>
              <Col>
                <div className="mt-4 pt-4">
                  <div
                    className="extra-text-about"
                    dangerouslySetInnerHTML={{ __html: editWebsiteVdpSyndicate?.replace(/(<? *script)/gi, 'illegalscript') }} >
                  </div>
                </div>

                {/* <div className="mt-4 pt-4">
                  <div
                    className="extra-text-finance"
                    dangerouslySetInnerHTML={{ __html: get_inventory_notes?.company_website?.website?.replace(/(<? *script)/gi, 'illegalscript') }} >
                  </div>
                </div> */}
              </Col>
            </Row>
            <Row className="pt-1">
              <Col md="12">
                <EditorInLineConvertToHTML
                  heading="VDP Website and syndication"
                  editContent={editWebsiteVdpSyndicate}
                  BtnCallBack={BtnCallBackVdpSyndicate}
                />
              </Col>
            </Row>

            <Row className="pt-1">
              <Col md="12">
                <EditorInLineConvertToHTML
                  heading="Decode Summary for Syndication (Not used for now)"
                  editContent={decodeSummary}
                  BtnCallBack={BtnCallBackDecodeSummary}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* <Row className="pt-1">
          <Col md="12">
            {editSpecialEquipment ? (
              <EditorConvertToHTML
                heading="Special Equipment"
                editContent={editSpecialEquipment}
                BtnCallBack={BtnCallBackSpecial}
              />
            ) : (
              <p>Loading ...</p>
            )}
          </Col>
        </Row>

        <Row className="pt-1">
          <Col md="12">
            {editInternalNotes ? (
              <EditorConvertToHTML
                heading="Internal Notes (not visible on website)"
                editContent={editInternalNotes}
                BtnCallBack={BtnCallBackInternal}
              />
            ) : (
              <p>Loading ...</p>
            )}
          </Col>
        </Row> */}
      </ErrorBoundary>
    </>
  );
};

export default InventoryNotes;
