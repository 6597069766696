
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import success from './success.svg';
import React, { useEffect } from 'react';
import { Button, Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import bgImage from "assets/img/bg/jan-sendereks.jpg";
import { useHistory } from "react-router-dom";

const VERSION = "v1";
const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

export default function PaymentSuccess() {
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('session_id');
    const dispatch = useDispatch();
    let history = useHistory();
   React.useEffect(() => {
        const data = { session_id: name }
     dispatchPayment(data);
        
    }, [name])
const dispatchPayment =async(session_id)=>{

    const body = JSON.stringify(session_id);
    let res = await axios.post(
        `https://api.preautoco.com/api/user/${VERSION}/check-payment-status`,
        body,
        config
      );
      let data = { status: res?.status, statusText: res?.statusText };
      return data;
}
const backToLogin=()=>{

    history.push( "/auth/login")
}
    return (
        <div className="payment">
        <Container className='w-100 font mx-auto my-5 py-5'>
            <Row className='block1  mb-3 pb-2 mx-auto'>
                <h1>Yes, your subscription has been made!</h1>
            </Row>
            <Card 
                body
                className="text-center mx-auto shadow-lg rounded-3 border-0 bg-white"
                style={{
                    width: '596px',
                    height: '570px'
                   
                }}
            >
                <Row className='block2 mx-auto mt-5 mx-auto'>
                    <Row className='Row'>
                        <img src={success} alt="" />
                    </Row>
                </Row>
                <Row className='block3  mx-auto my-3'>
                    <h4>Thank you very much for your subscription.</h4>
                    <p>Your subscription has been successful. you can now enjoy your subscription.</p>
                </Row>
               
                    <Button color="primary mx-3 mt-5" onClick={()=>{backToLogin()}}>
                        Back to Login
                    </Button>
                </Card>
            </Container>
            <div
                className="full-page-background"
                style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
        </div>
    );
};
