import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_TASK_LISTS, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  // Table,
  Row,
  Col,
} from "reactstrap";
import ColumnGroupingTable from "mui/tables/ColumnGroupingTable";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/material";
import TablePaginationActions from "mui/tables/TablePaginationActions";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

const List = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState([]);
  const [tasklist, setTasklist] = React.useState([]);

  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);

  // Check for params Lead Id / customer id / Employee Id

  // console.log('newTaskAdded', props.newTaskAdded)
  React.useEffect(() => {
    dispatch({
      type: LOAD_TASK_LISTS,
      params: pageParams,
      page: pageactivePage,
    });
  }, [dispatch, props.newTaskAdded, pageactivePage, pageParams]);

  const { get_task_lists, get_auth, get_errors } = useSelector(
    (state) => ({
      get_task_lists: state.tasklist.tasklists,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (props.params) {
      setPageParams(props.params);
    }
  }, [props.params]);

  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)
  // console.log(user)
  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_task_lists) {
      setTasklist(get_task_lists.results);
      setTotalResults(get_task_lists.count);
      setPerPage(get_task_lists.page_size);
      setTotalPages(Math.ceil(get_task_lists.count / get_task_lists.page_size));
    }
  }, [get_task_lists]);

  const [page, setPage] = React.useState(0);

  // console.log(user)
  const handleClick = (page) => {
    // console.log('page',page)
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: page.selected });
    setActivePage(page.selected);
    setPageActivePage(page.selected + 1);
  };
  const handleChangePage = (event, newPage) => {
    // console.log('page',page)
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: newPage + 1 });
    setActivePage(newPage + 1);
    setPageActivePage(newPage + 1 + 1);

    setPage(newPage);
  };

  // console.log(get_auth)
  return (
    <>
      <Row>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
        <Col md="12" className="mt-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">For Date</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasklist && tasklist.length > 0 ? (
                  tasklist.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.description?.substring(0, 30) +
                          (row?.description?.length > 30 ? "..." : "")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.status_text}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.scheduled_at}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Link to={`/admin/task-list-detail/${row.id}`}>
                          <p
                            style={{
                              color: "#1d4aa7",
                              margin: 0,
                            }}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </p>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow key={"s"}> No Data </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults || 0}
              rowsPerPage={
                totalResults > 12 ? tasklist.length || 0 : totalResults || 0
              }
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            />
          </TableContainer>
          {/* <Table responsive>
            <thead className="table-active">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th className="text-right">For Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tasklist ? (
                tasklist.map((p, k) => (
                  <tr key={k}>
                    <td>{p.name}</td>
                    <td>{p.description}</td>
                    <td>{p.status_text}</td>
                    <td>
                      {moment(p.scheduled_at).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td>
                      <Link to={`/admin/task-list-detail/${p.id}`}>
                        View More
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No tasks found</td>
                </tr>
              )}
            </tbody>
          </Table> */}
        </Col>
        <Col md="12">
          {/* <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div> */}
        </Col>
      </Row>
    </>
  );
};

export default List;
