import { put, all, call, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";

import {
  LOAD_PRODUCT_CONTRACTS,
  GET_PRODUCT_CONTRACTS,
  LOAD_PRODUCT_ACTIVE_CONTRACTS,
  REMOVE_PRODUCT_CONTRACT,
  DELETE_PRODUCT_CONTRACT,
  CREATE_PRODUCT_CONTRACT,
  ADD_PRODUCT_CONTRACT,
  ONE_PRODUCT_CONTRACT,
  FETCH_PRODUCT_CONTRACT,
  UPDATE_PRODUCT_CONTRACT,
  EDIT_PRODUCT_CONTRACT,
  CLEAR_PRODUCT_CONTRACT,
  ALL_ERRORS,
} from "constants/actions";

function* loadProductContract(action) {
  try {
    const json = yield call(api.GET_PRODUCT_CONTRACTS, action.payload);
    yield put({ type: GET_PRODUCT_CONTRACTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productContractLoad() {
  yield takeLatest(LOAD_PRODUCT_CONTRACTS, loadProductContract);
}


function* loadActiveProductContract(action) {
  try {
    const json = yield call(api.GET_ACTIVE_PRODUCT_CONTRACTS, action.payload);
    yield put({ type: GET_PRODUCT_CONTRACTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productActiveContractLoad() {
  yield takeLatest(LOAD_PRODUCT_ACTIVE_CONTRACTS, loadActiveProductContract);
}

function* fetchProductContract(action) {
  try {
    const json = yield call(api.FETCH_PRODUCT_CONTRACT, action.payload);

    yield put({ type: FETCH_PRODUCT_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productContractFetch() {
  yield takeLatest(ONE_PRODUCT_CONTRACT, fetchProductContract);
}

function* editProductContract(action) {
  try {
    const json = yield call(api.EDIT_PRODUCT_CONTRACT, action.payload);
    yield put({ type: EDIT_PRODUCT_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productContractEdit() {
  yield takeLatest(UPDATE_PRODUCT_CONTRACT, editProductContract);
}


function* createProductContract(action) {
  try {
    const json = yield call(api.ADD_PRODUCT_CONTRACT, action.payload);
    yield put({ type: ADD_PRODUCT_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productContractCreate() {
  yield takeLatest(CREATE_PRODUCT_CONTRACT, createProductContract);
}

function* deleteProductContract(action) {
  try {
    const json = yield call(api.DELETE_PRODUCT_CONTRACT, action.payload);
    yield put({ type: DELETE_PRODUCT_CONTRACT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* productContractDelete() {
  yield takeLatest(REMOVE_PRODUCT_CONTRACT, deleteProductContract);
}

export default function* index() {
  yield all([
    productContractLoad(),
    productActiveContractLoad(),
    productContractFetch(),
    productContractEdit(),
    productContractCreate(),
    productContractDelete(),
  ]);
}
