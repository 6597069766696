import { GET_CATEGORIES, DELETE_CATEGORY, ADD_CATEGORY, FETCH_CATEGORY, EDIT_CATEGORY, CLEAR_ALL } from "constants/actions";;
const initialState = {
    categories: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case FETCH_CATEGORY:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CATEGORY:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(Branch => Branch.id !== action.payload)
            };
        case ADD_CATEGORY:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                categories: []
            };
        default:
            return state;
    }
}

