export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL ='CLEAR_ALL';
export const VALIDATION_ERRORS = 'VALIDATION_ERRORS'
export const AUTH_ERRORS = 'AUTH_ERRORS'
export const SG_CLEAR_ALL ='SG_CLEAR_ALL';
export const SET_ERROR_STATE = "SET_ERROR_STATE"
//
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
//

export const GET_LEADS_PENDING = "GET_LEADS_PENDING";
export const GET_LEADS = "GET_LEADS";
export const LOAD_LEADS = "LOAD_LEADS";
export const FETCH_LEADS_PENDING = "FETCH_LEADS_PENDING";
export const FETCH_LEADS = "FETCH_LEADS";
export const SG_FETCH_LEADS = "SG_FETCH_LEADS";
export const DELETE_LEAD = "DELETE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const CREATE_LEAD = "CREATE_LEAD";
export const EDIT_LEADS = 'EDIT_LEADS';
export const UPDATE_LEADS = 'UPDATE_LEADS';
export const CLEAR_LEADS = 'CLEAR_LEADS';
//
// 
export const GET_FEATURES = "GET_FEATURES";
export const DELETE_FEATURE = "DELETE_FEATURE";
export const ADD_FEATURE = "ADD_FEATURE";
export const FETCH_FEATURE = "FETCH_FEATURE";
export const UPDATE_FEATURES = 'UPDATE_FEATURES';
export const EDIT_FEATURE = 'EDIT_FEATURE';
export const CLEAR_FEATURES = 'CLEAR_FEATURES';
// 
export const GET_CATEGORIES = "GET_CATEGORIES";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
// 
export const GET_SPECS = "GET_SPECS";
export const LOAD_SPECS = "LOAD_SPECS";
export const DELETE_SPEC = "DELETE_SPEC";
export const ADD_SPEC = "ADD_SPEC";
export const FETCH_SPEC = "FETCH_SPEC";
export const UPDATE_SPECS = 'UPDATE_SPECS';
export const EDIT_SPEC = 'EDIT_SPEC'
export const CLEAR_SPECS = 'CLEAR_SPECS';
//
export const LOAD_INVENTORY = "LOAD_INVENTORY";
export const GET_INVENTORIES = "GET_INVENTORIES";

export const PUT_INVENTORY = "PUT_INVENTORY";
export const UPDATED_INVENTORY = "UPDATED_INVENTORY";

export const RESET_PUT_INVENTORY = "RESET_PUT_INVENTORY";
export const RESET_UPDATED_INVENTORY = "RESET_UPDATED_INVENTORY";

export const LOAD_VALID_INVENTORIES = "LOAD_VALID_INVENTORIES";
export const GET_VALID_INVENTORIES = "GET_VALID_INVENTORIES";
export const GET_INVENTORYLIST = "GET_INVENTORYLIST";
export const GET_INVENTORYLIST_ERROR = "GET_INVENTORYLIST_ERROR"
export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const DESTROY_INVENTORY = "DESTROY_INVENTORY";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const CREATE_INVENTORY = "CREATE_INVENTORY";
export const RESET_ADD_INVENTORY = "RESET_ADD_INVENTORY";
export const RESET_CREATE_INVENTORY = "RESET_CREATE_INVENTORY";
export const CLEAR_INVENTORY = "CLEAR_INVENTORY";
export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const FETCH_INVENTORY_PENDING = "FETCH_INVENTORY_PENDING";
export const FETCH_INVENTORY_ONE = "FETCH_INVENTORY_ONE";
export const EDIT_INVENTORY = "EDIT_INVENTORY";
export const SG_EDIT_INVENTORY = "SG_EDIT_INVENTORY";
export const SG_EDIT_MILEAGE = "SG_EDIT_MILEAGE";
export const UPDATED_INVENTORY_PRICES = "UPDATED_INVENTORY_PRICES";
//
export const LOAD_INVENTORY_LIST = "LOAD_INVENTORY_LIST";
export const GET_INVENTORY_LIST = "GET_INVENTORY_LIST";
//
export const INVENTORY_LIST = "INVENTORY_LIST";
export const SG_INVENTORY_LIST = "SG_INVENTORY_LIST";
//
export const GET_INVENTORIES_PENDING = 'GET_INVENTORIES_PENDING'; 
export const GET_SELECT_INVENTORIES = "GET_SELECT_INVENTORIES";
export const SG_GET_SELECT_INVENTORIES = "SG_GET_SELECT_INVENTORIES";
// 
export const LOAD_INVENTORY_EXTRA = "LOAD_INVENTORY_EXTRA";
export const GET_INVENTORY_EXTRA_PENDING = "GET_INVENTORY_EXTRA_PENDING";
export const GET_INVENTORY_EXTRA = "GET_INVENTORY_EXTRA";
export const GET_INVENTORY_EXTRA_ERROR = "GET_INVENTORY_EXTRA_ERROR"
export const DELETE_INVENTORY_EXTRA = "DELETE_INVENTORY_EXTRA";
export const ADD_INVENTORY_EXTRA = "ADD_INVENTORY_EXTRA";
export const CLEAR_INVENTORY_EXTRA = "CLEAR_INVENTORY_EXTRA";
export const FETCH_INVENTORY_EXTRA = "FETCH_INVENTORY_EXTRA";
export const FETCH_INVENTORY_EXTRA_PENDING = "FETCH_INVENTORY_EXTRA_PENDING";
export const FETCH_INVENTORY_EXTRA_ONE = "FETCH_INVENTORY_EXTRA_ONE";
export const EDIT_INVENTORY_EXTRA = "EDIT_INVENTORY_EXTRA";
// 
export const LOAD_INVENTORY_FINANCE = "LOAD_INVENTORY_FINANCE";
export const GET_INVENTORY_FINANCE_PENDING = "GET_INVENTORY_FINANCE_PENDING";
export const GET_INVENTORY_FINANCE = "GET_INVENTORY_FINANCE";
export const GET_INVENTORY_FINANCE_ERROR = "GET_INVENTORY_FINANCE_ERROR"
export const DELETE_INVENTORY_FINANCE = "DELETE_INVENTORY_FINANCE";
export const ADD_INVENTORY_FINANCE = "ADD_INVENTORY_FINANCE";
export const CLEAR_INVENTORY_FINANCE = "CLEAR_INVENTORY_FINANCE";
export const FETCH_INVENTORY_FINANCE = "FETCH_INVENTORY_FINANCE";
export const FETCH_INVENTORY_FINANCE_PENDING = "FETCH_INVENTORY_FINANCE_PENDING";
export const FETCH_INVENTORY_FINANCE_ONE = "FETCH_INVENTORY_FINANCE_ONE";
export const EDIT_INVENTORY_FINANCE = "EDIT_INVENTORY_FINANCE";
// 
export const LOAD_INVENTORY_NOTES = "LOAD_INVENTORY_NOTES";
export const GET_INVENTORY_NOTES_PENDING = "GET_INVENTORY_NOTES_PENDING";
export const GET_INVENTORY_NOTES = "GET_INVENTORY_NOTES";
export const GET_INVENTORY_NOTES_ERROR = "GET_INVENTORY_NOTES_ERROR"
export const DELETE_INVENTORY_NOTES = "DELETE_INVENTORY_NOTES";
export const ADD_INVENTORY_NOTES = "ADD_INVENTORY_NOTES";
export const CLEAR_INVENTORY_NOTES = "CLEAR_INVENTORY_NOTES";
export const FETCH_INVENTORY_NOTES = "FETCH_INVENTORY_NOTES";
export const FETCH_INVENTORY_NOTES_PENDING = "FETCH_INVENTORY_NOTES_PENDING";
export const SG_FETCH_INVENTORY_NOTES = "SG_FETCH_INVENTORY_NOTES";
export const EDIT_INVENTORY_NOTES = "EDIT_INVENTORY_NOTES";
export const UPDATE_INVENTORY_NOTES = "UPDATE_INVENTORY_NOTES";
// 
export const LOAD_CARIMAGES = "LOAD_CARIMAGES";
export const GET_CARIMAGES_PENDING = "GET_CARIMAGES_PENDING";
export const GET_CARIMAGES = "GET_CARIMAGES";

export const CARIMAGE_DELETE = "CARIMAGE_DELETE";
export const DELETE_CARIMAGE = "DELETE_CARIMAGE";
export const DELETE_CARIMAGE_PENDING = "DELETE_CARIMAGE_PENDING";
export const ADD_CARIMAGE = "ADD_CARIMAGE";
export const CLEAR_CARIMAGE = "CLEAR_CARIMAGE";
export const FETCH_CARIMAGE = "FETCH_CARIMAGE";
export const EDIT_CARIMAGE = "EDIT_CARIMAGE";
//
export const LOAD_INVENTORYIMAGES = "LOAD_INVENTORYIMAGES";
export const GET_INVENTORYIMAGES = "GET_INVENTORYIMAGES";
export const INVENTORY_IMAGE_PENDING = "INVENTORY_IMAGE_PENDING";
export const DELETE_INVENTORYIMAGE = "DELETE_INVENTORYIMAGE";
export const SG_DELETE_INVENTORYIMAGE = "SG_DELETE_INVENTORYIMAGE";
export const ADD_INVENTORYIMAGE = "ADD_INVENTORYIMAGE";
export const SG_ADD_INVENTORYIMAGE = "SG_ADD_INVENTORYIMAGE";
export const CLEAR_INVENTORYIMAGE = "CLEAR_INVENTORYIMAGE";
export const FETCH_INVENTORYIMAGE = "FETCH_INVENTORYIMAGE";
export const EDIT_INVENTORYIMAGE = "EDIT_INVENTORYIMAGE";
export const SG_EDIT_INVENTORYIMAGE = "SG_EDIT_INVENTORYIMAGE";
//
export const GET_IMAGEPROFILES = "GET_IMAGEPROFILES";
export const IMAGE_PROFILE_PENDING = "IMAGE_PROFILE_PENDING"
export const SG_GET_IMAGEPROFILES = "SG_GET_IMAGEPROFILES";
export const DELETE_IMAGEPROFILE = "DELETE_IMAGEPROFILE";
export const ADD_IMAGEPROFILE = "ADD_IMAGEPROFILE";
export const CLEAR_IMAGEPROFILE = "CLEAR_IMAGEPROFILE";
export const FETCH_IMAGEPROFILE = "FETCH_IMAGEPROFILE";
export const SG_FETCH_IMAGEPROFILE = "SG_FETCH_IMAGEPROFILE";
export const EDIT_IMAGEPROFILE = "EDIT_IMAGEPROFILE";
export const SG_EDIT_IMAGEPROFILE = "SG_EDIT_IMAGEPROFILE";
//
export const GET_WEBSITEIMAGES = "GET_WEBSITEIMAGES";
export const DELETE_WEBSITEIMAGE = "DELETE_WEBSITEIMAGE";
export const ADD_WEBSITEIMAGE = "ADD_WEBSITEIMAGE";
export const CLEAR_WEBSITEIMAGE = "CLEAR_WEBSITEIMAGE";
export const FETCH_WEBSITEIMAGE = "FETCH_WEBSITEIMAGE";
export const EDIT_WEBSITEIMAGE = "EDIT_WEBSITEIMAGE";
//
export const GET_WEBCONTENTS = "GET_WEBCONTENTS";
export const DELETE_WEBCONTENT = "DELETE_WEBCONTENT";
export const ADD_WEBCONTENT = "ADD_WEBCONTENT";
export const CLEAR_WEBCONTENT = "CLEAR_WEBCONTENT";
export const FETCH_WEBCONTENT = "FETCH_WEBCONTENT";
export const EDIT_WEBCONTENT = "EDIT_WEBCONTENT";
//
export const GET_WEBHEADERS = "GET_WEBHEADERS";
export const DELETE_WEBHEADER = "DELETE_WEBHEADER";
export const ADD_WEBHEADER = "ADD_WEBHEADER";
export const CLEAR_WEBHEADER = "CLEAR_WEBHEADER";
export const FETCH_WEBHEADER = "FETCH_WEBHEADER";
export const EDIT_WEBHEADER = "EDIT_WEBHEADER";
//
export const LOAD_LANDINGPAGES = "LOAD_LANDINGPAGES";
export const GET_LANDINGPAGES = "GET_LANDINGPAGES";
export const DELETE_LANDINGPAGE = "DELETE_LANDINGPAGE";

export const NEW_LANDINGPAGE = "NEW_LANDINGPAGE";
export const NEW_A_LANDINGPAGE = "NEW_A_LANDINGPAGE";

export const ADD_LANDINGPAGE = "ADD_LANDINGPAGE";
export const CREATE_LANDINGPAGE = "CREATE_LANDINGPAGE";
export const CLEAR_LANDINGPAGE = "CLEAR_LANDINGPAGE";
export const FETCH_LANDINGPAGE = "FETCH_LANDINGPAGE";
export const SG_FETCH_LANDINGPAGE = "SG_FETCH_LANDINGPAGE";
export const EDIT_LANDINGPAGE = "EDIT_LANDINGPAGE";
export const UPDATE_LANDINGPAGE = "UPDATE_LANDINGPAGE";
export const UPLOAD_LANDINGPAGE_IMAGE = "UPLOAD_LANDINGPAGE_IMAGE";
export const ADD_LANDINGPAGE_IMAGE = "ADD_LANDINGPAGE_IMAGE";
export const DELETE_LANDINGPAGE_IMAGE = "DELETE_LANDINGPAGE_IMAGE";
export const REMOVE_LANDINGPAGE_IMAGE = "REMOVE_LANDINGPAGE_IMAGE";
export const GET_LANDINGPAGES_PENDING = 'GET_LANDINGPAGES_PENDING'; 
//
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_CASHFORCARS = "GET_CASHFORCARS";
export const LOAD_GET_STRIPE_CUSTOMER = "LOAD_GET_STRIPE_CUSTOMER";
export const GET_STRIPE_CUSTOMER = "GET_STRIPE_CUSTOMER";

export const LOAD_GET_STRIPE_PRODUCTS = "LOAD_GET_STRIPE_PRODUCTS";
export const GET_STRIPE_PRODUCTS = "GET_STRIPE_PRODUCTS";

export const GET_EMPLOYEES_PENDING = "GET_EMPLOYEES_PENDING"
export const LOAD_ALL_EMPLOYEES = "LOAD_ALL_EMPLOYEES";
export const LOAD_GET_CASHFORCARS = "LOAD_GET_CASHFORCARS";
export const LOAD_CMS_CONTACT_PAGE = "LOAD_CMS_CONTACT_PAGE";
export const LOAD_CMS_CONTACT_PAGE_TYPE = "LOAD_CMS_CONTACT_PAGE_TYPE";
export const LOAD_CMS_PRIVACY = "LOAD_CMS_PRIVACY";
export const LOAD_CMS_PRIVACY_TYPE = "LOAD_CMS_PRIVACY_TYPE";
export const LOAD_HOMEPAGE_TYPE = "LOAD_HOMEPAGE_TYPE";
export const LOAD_HOMEPAGE = "LOAD_HOMEPAGE";
export const LOAD_CMS_TERMS = "LOAD_CMS_TERMS";
export const LOAD_CMS_TERMS_TYPE = "LOAD_CMS_TERMS_TYPE";

export const LOAD_CMS_ABOUT = "LOAD_CMS_ABOUT";
export const LOAD_CMS_ABOUT_TYPE = "LOAD_CMS_ABOUT_TYPE";
export const LOAD_CMS_PRIVACY_PAGE = "LOAD_CMS_PRIVACY_PAGE";
export const LOAD_CMS_PRIVACY_PAGE_TYPE = "LOAD_CMS_PRIVACY_PAGE_TYPE";
export const LOAD_EMPLOYEE = "LOAD_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const CASH_FOR_CARS = "CASH_FOR_CARS";
export const CASH_FOR_CARS_CMS = "CASH_FOR_CARS_CMS";
export const CMS_CREATE_CONTACT_PAGE = "CMS_CREATE_CONTACT_PAGE";
export const CMS_PRIVACY_POLICY = "CMS_PRIVACY_POLICY";
export const CMS_PRIVACY_POLICY_TYPE = "CMS_PRIVACY_POLICY_TYPE";
export const CMS_HOMEPAGE = "CMS_HOMEPAGE";
export const CMS_HOMEPAGE_TYPE = "CMS_HOMEPAGE_TYPE";
export const STRIPE_CREATE_INVOICE = "STRIPE_CREATE_INVOICE";
export const STRIPE_CREATE_INVOICE_TYPE = "STRIPE_CREATE_INVOICE_TYPE";
export const STRIPE_CREATE_CUSTOMER = "STRIPE_CREATE_CUSTOMER";
export const STRIPE_CREATE_CUSTOMER_TYPE = "STRIPE_CREATE_CUSTOMER_TYPE";
export const STRIPE_FETCH_INVOICE = "STRIPE_FETCH_INVOICE";
export const STRIPE_FETCH_INVOICE_TYPE = "STRIPE_FETCH_INVOICE_TYPE";
export const CMS_TERMS_TYPE = "CMS_TERMS_TYPE";
export const CMS_TERMS = "CMS_TERMS";
export const CMS_CREATE_CONTACT_PAGE_TYPE = "CMS_CREATE_CONTACT_PAGE_TYPE";
export const CMS_ABOUT = "CMS_ABOUT";
export const CMS_ABOUT_TYPE = "CMS_ABOUT_TYPE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_CASH_FOR_CARS = "UPDATE_CASH_FOR_CARS";
export const UPDATE_CMS_CONTACT_PAGE = "UPDATE_CMS_CONTACT_PAGE";
export const UPDATE_CMS_CONTACT_PAGE_TYPE = "UPDATE_CMS_CONTACT_PAGE_TYPE";

export const UPDATE_CMS_PRIVACY_PAGE = "UPDATE_CMS_PRIVACY_PAGE";
export const UPDATE_CMS_PRIVACY_PAGE_TYPE = "UPDATE_CMS_PRIVACY_PAGE_TYPE";

export const UPDATE_CMS_HOME_PAGE = "UPDATE_CMS_HOME_PAGE";
export const UPDATE_CMS_HOME_PAGE_TYPE = "UPDATE_CMS_HOME_PAGE_TYPE";

export const UPDATE_CMS_TERMS_PAGE = "UPDATE_CMS_TERMS_PAGE";
export const UPDATE_CMS_TERMS_PAGE_TYPE = "UPDATE_CMS_TERMS_PAGE_TYPE";

export const UPDATE_CMS_ABOUT_PAGE = "UPDATE_CMS_ABOUT_PAGE";
export const UPDATE_CMS_ABOUT_PAGE_TYPE = "UPDATE_CMS_ABOUT_PAGE_TYPE";

export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";
export const FETCH_EMPLOYEE_PENDING = "FETCH_EMPLOYEE_PENDING";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const FETCH_CASH_FOR_CARS_CMS = "FETCH_CASH_FOR_CARS_CMS";
export const FETCH_CASH_FOR_CARS_CMS_VALUE = "FETCH_CASH_FOR_CARS_CMS_VALUE";
export const SELECTED_EMPLOYEE = "SELECTED_EMPLOYEE";
export const SG_SELECT_EMPLOYEE = "SG_SELECT_EMPLOYEE";
export const SG_FETCH_EMPLOYEE = "SG_FETCH_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const EDIT_CASH_FOR_CARS = "EDIT_CASH_FOR_CARS";
export const ADD_EMPLOYEE_PIC = "ADD_EMPLOYEE_PIC";
export const UPLOAD_EMPLOYEE_PIC = "UPLOAD_EMPLOYEE_PIC";
//
export const DEALER_WEBSITE_REQUESTED = "DEALER_WEBSITE_REQUESTED";
export const GET_DEALERS_PENDING = "GET_DEALERS_PENDING"
export const LOAD_ALL_DEALERS = "LOAD_ALL_DEALERS";
export const GET_DEALERS = "GET_DEALERS";
export const GET_RELATED_DEALERS = "GET_RELATED_DEALERS";
export const GET_DEALERS_FAILED = "GET_DEALERS_FAILED";
export const DELETE_DEALER = "DELETE_DEALER";
export const ADD_DEALER = "ADD_DEALER";
export const CLEAR_DEALER = "CLEAR_DEALER";
export const FETCH_DEALER_PENDING = "FETCH_DEALER_PENDING";
export const FETCH_DEALER = "FETCH_DEALER";
export const SG_FETCH_DEALER = "SG_FETCH_DEALER";
export const FETCH_RELATED_DEALER_PENDING = "FETCH_RELATED_DEALER_PENDING";
export const FETCH_RELATED_DEALER = "FETCH_RELATED_DEALER";
export const SG_FETCH_RELATED_DEALER = "SG_FETCH_RELATED_DEALER";
export const JSON_LOGGED_DEALER = "JSON_LOGGED_DEALER";
export const EDIT_DEALER = "EDIT_DEALER";
export const LOAD_DEALER = "LOAD_DEALER";
export const ADD_DEALER_LOGO = "ADD_DEALER_LOGO";
export const UPLOAD_DEALER_LOGO = "UPLOAD_DEALER_LOGO";
//
export const GET_COMPANIES_PENDING = "GET_COMPANIES_PENDING"
export const FETCH_COMPANIES_PENDING = "FETCH_COMPANIES_PENDING"

export const LOAD_COMPANY = "LOAD_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const REMOVE_COMPANY = "REMOVE_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const SG_FETCH_COMPANY = "SG_FETCH_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const ADD_COMPANY_LOGO = "ADD_COMPANY_LOGO";
export const UPLOAD_COMPANY_LOGO = "UPLOAD_COMPANY_LOGO";
//
export const GET_COMPANY_INFORMATION_PENDING = "GET_COMPANY_INFORMATION_PENDING"
export const LOAD_COMPANY_INFORMATION = "LOAD_COMPANY_INFORMATION";
export const GET_COMPANY_INFORMATIONS = "GET_COMPANY_INFORMATIONS";
export const DELETE_COMPANY_INFORMATION = "DELETE_COMPANY_INFORMATION";
export const REMOVE_COMPANY_INFORMATION = "REMOVE_COMPANY_INFORMATION";
export const ADD_COMPANY_INFORMATION = "ADD_COMPANY_INFORMATION";
export const CREATE_COMPANY_INFORMATION = "CREATE_COMPANY_INFORMATION";
export const ADD_DEFAULT_COMPANY_INFORMATION = "ADD_DEFAULT_COMPANY_INFORMATION";
export const CREATE_DEFAULT_COMPANY_INFORMATION = "CREATE_DEFAULT_COMPANY_INFORMATION";
export const CLEAR_COMPANY_INFORMATION = "CLEAR_COMPANY_INFORMATION";
export const FETCH_COMPANY_INFORMATION = "FETCH_COMPANY_INFORMATION";
export const SG_FETCH_COMPANY_INFORMATION = "SG_FETCH_COMPANY_INFORMATION";
export const EDIT_COMPANY_INFORMATION = "EDIT_COMPANY_INFORMATION";
export const UPDATE_COMPANY_INFORMATION = "UPDATE_COMPANY_INFORMATION";
export const ADD_EDIT_COMPANY_INFORMATION = "ADD_EDIT_COMPANY_INFORMATION";
export const REMOVE_ROW_COMPANY_INFORMATION = "REMOVE_ROW_COMPANY_INFORMATION";

//
export const LOAD_GARAGE = "LOAD_GARAGE";
export const GET_GARAGES = "GET_GARAGES";
export const DELETE_GARAGE = "DELETE_GARAGE";
export const ADD_GARAGE = "ADD_GARAGE";
export const CLEAR_GARAGE = "CLEAR_GARAGE";
export const FETCH_GARAGE = "FETCH_GARAGE";
export const EDIT_GARAGE = "EDIT_GARAGE";
//

// 
export const GET_ACTIVE_PAGE = "GET_ACTIVE_PAGE";
export const SG_GET_ACTIVE_PAGE = "SG_GET_ACTIVE_PAGE";
export const LOAD_ACTIVE_PAGE = "LOAD_ACTIVE_PAGE";
export const UPDATE_ACTIVE_PAGE = "UPDATE_ACTIVE_PAGE";
export const SG_UPDATE_ACTIVE_PAGE = "SG_UPDATE_ACTIVE_PAGE";
export const ADD_ACTIVE_PAGE = "ADD_ACTIVE_PAGE";
//
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_DEALER_USER = "REGISTER_DEALER_USER";
export const CHECK_PAYMENT_STATUS="CHECK_PAYMENT_STATUS";
export const CHECK_PAYMENT_FAIL="CHECK_PAYMENT_FAIL";
export const REGISTER_DEALER_SUCCESS = "REGISTER_DEALER_SUCCESS";
export const REGISTER_DEALER_FAIL = "REGISTER_DEALER_FAIL";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const WEBSITE_RESET_PASSWORD = "WEBSITE_RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_FAIL = "CONFIRM_RESET_PASSWORD_FAIL";

export const CHANGE_PASSWORD= 'CHANGE_PASSWORD'
export const SG_CHANGE_PASSWORD= 'SG_CHANGE_PASSWORD'

export const EMPLOYEE_AUTH_LOADED = 'EMPLOYEE_AUTH_LOADED'
export const SG_EMPLOYEE_AUTH_LOADED = 'SG_EMPLOYEE_AUTH_LOADED'

export const DEALER_AUTH_LOADED = 'DEALER_AUTH_LOADED'
export const SG_DEALER_AUTH_LOADED = 'SG_DEALER_AUTH_LOADED'

export const CLEAR_PASSWORD_DATA = 'CLEAR_PASSWORD_DATA';
//
export const LOAD_CUSTOMER_LIST = "LOAD_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const SEARCH_CUSTOMER_VALUE = "SEARCH_CUSTOMER_VALUE";
export const CLEAR_SEARCH_CUSTOMER_VALUE = "CLEAR_SEARCH_CUSTOMER_VALUE";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_PENDING = 'GET_CUSTOMERS_PENDING'; 
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const SG_DELETE_CUSTOMER = "SG_DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const SG_ADD_CUSTOMER = "SG_ADD_CUSTOMER";
export const FETCH_CUSTOMER_PENDING = "FETCH_CUSTOMER_PENDING";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const SG_FETCH_CUSTOMER = "SG_FETCH_CUSTOMER";
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
//
export const LOAD_SERVICE_REQUESTS = "LOAD_SERVICE_REQUESTS";
export const GET_SERVICE_REQUESTS = "GET_SERVICE_REQUESTS";
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const EDIT_SERVICE_REQUEST = 'EDIT_SERVICE_REQUEST'
export const CLEAR_SERVICE_REQUEST = 'CLEAR_SERVICE_REQUEST'; 
export const GET_SERVICE_REQUEST_PENDING = 'GET_SERVICE_REQUEST_PENDING'; 
//
export const LOAD_CREDITAPPLICATIONS = "LOAD_CREDITAPPLICATIONS";
export const GET_CREDITAPPLICATIONS_PENDING = 'GET_CREDITAPPLICATIONS_PENDING';
export const GET_CREDITAPPLICATIONS = "GET_CREDITAPPLICATIONS";
export const DELETE_CREDITAPPLICATION = "DELETE_CREDITAPPLICATION";
export const ADD_CREDITAPPLICATION = "ADD_CREDITAPPLICATION";
export const FETCH_CREDITAPPLICATION_PENDING = "FETCH_CREDITAPPLICATION_PENDING";
export const FETCH_CREDITAPPLICATION = "FETCH_CREDITAPPLICATION";
export const SG_FETCH_CREDITAPPLICATION = "SG_FETCH_CREDITAPPLICATION";
export const UPDATE_CREDITAPPLICATION = 'UPDATE_CREDITAPPLICATION';
export const EDIT_CREDITAPPLICATION = 'EDIT_CREDITAPPLICATION'
export const CLEAR_CREDITAPPLICATION = 'CLEAR_CREDITAPPLICATION';
//
export const GET_CREDITAPPLICATIONEXTRAS = "GET_CREDITAPPLICATIONEXTRAS";
export const DELETE_CREDITAPPLICATIONEXTRA = "DELETE_CREDITAPPLICATIONEXTRA";
export const ADD_CREDITAPPLICATIONEXTRA = "ADD_CREDITAPPLICATIONEXTRA";
export const FETCH_CREDITAPPLICATIONEXTRA = "FETCH_CREDITAPPLICATIONEXTRA";
export const UPDATE_CREDITAPPLICATIONEXTRA = 'UPDATE_CREDITAPPLICATIONEXTRA';
export const EDIT_CREDITAPPLICATIONEXTRA = 'EDIT_CREDITAPPLICATIONEXTRA'
export const CLEAR_CREDITAPPLICATIONEXTRA = 'CLEAR_CREDITAPPLICATIONEXTRA';
//
export const GET_CREDITAPPLICATIONADDRESSES = "GET_CREDITAPPLICATIONADDRESSES";
export const DELETE_CREDITAPPLICATIONADDRESS = "DELETE_CREDITAPPLICATIONADDRESS";
export const ADD_CREDITAPPLICATIONADDRESS = "ADD_CREDITAPPLICATIONADDRESS";
export const FETCH_CREDITAPPLICATIONADDRESS = "FETCH_CREDITAPPLICATIONADDRESS";
export const UPDATE_CREDITAPPLICATIONADDRESS = 'UPDATE_CREDITAPPLICATIONADDRESS';
export const EDIT_CREDITAPPLICATIONADDRESS = 'EDIT_CREDITAPPLICATIONADDRESS'
export const CLEAR_CREDITAPPLICATIONADDRESS = 'CLEAR_CREDITAPPLICATIONADDRESS';
//
export const GET_CREDITAPPLICATIONEMPLOYMENTS = "GET_CREDITAPPLICATIONEMPLOYMENTS";
export const DELETE_CREDITAPPLICATIONEMPLOYMENT = "DELETE_CREDITAPPLICATIONEMPLOYMENT";
export const ADD_CREDITAPPLICATIONEMPLOYMENT = "ADD_CREDITAPPLICATIONEMPLOYMENT";
export const FETCH_CREDITAPPLICATIONEMPLOYMENT = "FETCH_CREDITAPPLICATIONEMPLOYMENT";
export const UPDATE_CREDITAPPLICATIONEMPLOYMENT = 'UPDATE_CREDITAPPLICATIONEMPLOYMENT';
export const EDIT_CREDITAPPLICATIONEMPLOYMENT = 'EDIT_CREDITAPPLICATIONEMPLOYMENT'
export const CLEAR_CREDITAPPLICATIONEMPLOYMENT = 'CLEAR_CREDITAPPLICATIONEMPLOYMENT';
//
export const GET_CREDITAPPLICATIONPERSONALDETAILS = "GET_CREDITAPPLICATIONPERSONALDETAILS";
export const DELETE_CREDITAPPLICATIONPERSONALDETAIL = "DELETE_CREDITAPPLICATIONPERSONALDETAIL";
export const ADD_CREDITAPPLICATIONPERSONALDETAIL = "ADD_CREDITAPPLICATIONPERSONALDETAIL";
export const FETCH_CREDITAPPLICATIONPERSONALDETAIL = "FETCH_CREDITAPPLICATIONPERSONALDETAIL";
export const UPDATE_CREDITAPPLICATIONPERSONALDETAIL = 'UPDATE_CREDITAPPLICATIONPERSONALDETAIL';
export const EDIT_CREDITAPPLICATIONPERSONALDETAIL = 'EDIT_CREDITAPPLICATIONPERSONALDETAIL'
export const CLEAR_CREDITAPPLICATIONPERSONALDETAIL = 'CLEAR_CREDITAPPLICATIONPERSONALDETAIL';
//
export const GET_CREDITAPPLICATIONLOGS = "GET_CREDITAPPLICATIONLOGS";
export const DELETE_CREDITAPPLICATIONLOG = "DELETE_CREDITAPPLICATIONLOG";
export const ADD_CREDITAPPLICATIONLOG = "ADD_CREDITAPPLICATIONLOG";
export const FETCH_CREDITAPPLICATIONLOG = "FETCH_CREDITAPPLICATIONLOG";
export const UPDATE_CREDITAPPLICATIONLOG = 'UPDATE_CREDITAPPLICATIONLOG';
export const EDIT_CREDITAPPLICATIONLOG = 'EDIT_CREDITAPPLICATIONLOG'
export const CLEAR_CREDITAPPLICATIONLOG = 'CLEAR_CREDITAPPLICATIONLOG';
export const GET_CREDITAPPLICATIONLOGS_PENDING = 'GET_CREDITAPPLICATIONLOGS_PENDING'; 
//
export const LOAD_RESET_YEARS = "LOAD_RESET_YEARS"
export const LOAD_CARYEARS = "LOAD_CARYEARS"
export const GET_CARYEARS = "GET_CARYEARS";
export const DELETE_CARYEAR = "DELETE_CARYEAR";
export const ADD_CARYEAR = "ADD_CARYEAR";
export const FETCH_CARYEAR = "FETCH_CARYEAR";
export const UPDATE_CARYEAR = 'UPDATE_CARYEAR';
export const EDIT_CARYEAR = 'EDIT_CARYEAR'
export const CLEAR_CARYEAR = 'CLEAR_CARYEAR';
//
export const LOAD_RESET_MAKES = "LOAD_RESET_MAKES"
export const LOAD_CARMAKES = "LOAD_CARMAKES";
export const GET_CARMAKES = "GET_CARMAKES";
export const DELETE_CARMAKE = "DELETE_CARMAKE";
export const ADD_CARMAKE = "ADD_CARMAKE";
export const FETCH_CARMAKE = "FETCH_CARMAKE";
export const UPDATE_CARMAKE = 'UPDATE_CARMAKE';
export const EDIT_CARMAKE = 'EDIT_CARMAKE'
export const CLEAR_CARMAKE = 'CLEAR_CARMAKE';
//
export const LOAD_RESET_MODELS = "LOAD_RESET_MODELS"
export const LOAD_CARMODELS = "LOAD_CARMODELS"
export const GET_CARMODELS = "GET_CARMODELS";
export const DELETE_CARMODEL = "DELETE_CARMODEL";
export const ADD_CARMODEL = "ADD_CARMODEL";
export const FETCH_CARMODEL = "FETCH_CARMODEL";
export const UPDATE_CARMODEL = 'UPDATE_CARMODEL';
export const EDIT_CARMODEL = 'EDIT_CARMODEL'
export const CLEAR_CARMODEL = 'CLEAR_CARMODEL';
//
export const LOAD_CARTRIMS = "LOAD_CARTRIMS";
export const GET_CARTRIMS = "GET_CARTRIMS";
export const DELETE_CARTRIM = "DELETE_CARTRIM";
export const ADD_CARTRIM = "ADD_CARTRIM";
export const FETCH_CARTRIM = "FETCH_CARTRIM";
export const UPDATE_CARTRIM = 'UPDATE_CARTRIM';
export const EDIT_CARTRIM = 'EDIT_CARTRIM'
export const CLEAR_CARTRIM = 'CLEAR_CARTRIM';
//
export const RESET_YEARS = "RESET_YEARS";
export const RESET_MODELS = 'RESET_MODELS';
export const RESET_MAKES = 'RESET_MAKES'
//
export const GET_LEADSOURCES_PENDING = "GET_LEADSOURCES_PENDING";
export const GET_LEADSOURCES = "GET_LEADSOURCES";
export const LOAD_LEADSOURCES = "LOAD_LEADSOURCES";
export const GET_DEFAULT_LEADSOURCES = "GET_DEFAULT_LEADSOURCES";
export const LOAD_DEFAULT_LEADSOURCES = "LOAD_DEFAULT_LEADSOURCES";
export const FETCH_LEADSOURCES_PENDING = "FETCH_LEADSOURCES_PENDING";
export const FETCH_LEADSOURCES = "FETCH_LEADSOURCES";
export const SG_FETCH_LEADSOURCES = "SG_FETCH_LEADSOURCES";
export const DELETE_LEADSOURCE = "DELETE_LEADSOURCE";
export const SG_DELETE_LEADSOURCE = "SG_DELETE_LEADSOURCE";
export const ADD_LEADSOURCE = "ADD_LEADSOURCE";
export const SG_ADD_LEADSOURCE = "SG_ADD_LEADSOURCE";
export const EDIT_LEADSOURCES = 'EDIT_LEADSOURCES';
export const UPDATE_LEADSOURCES = 'UPDATE_LEADSOURCES';
export const CLEAR_LEADSOURCES = 'CLEAR_LEADSOURCES';
// 
export const GET_LEADTYPES_PENDING = "GET_LEADTYPES_PENDING";
export const GET_LEADTYPES = "GET_LEADTYPES";
export const LOAD_LEADTYPES = "LOAD_LEADTYPES";
export const GET_DEFAULT_LEADTYPES = "GET_DEFAULT_LEADTYPES";
export const LOAD_DEFAULT_LEADTYPES = "LOAD_DEFAULT_LEADTYPES";
export const FETCH_LEADTYPES_PENDING = "FETCH_LEADTYPES_PENDING";
export const FETCH_LEADTYPES = "FETCH_LEADTYPES";
export const SG_FETCH_LEADTYPES = "SG_FETCH_LEADTYPES";
export const DELETE_LEADTYPE = "DELETE_LEADTYPE";
export const SG_DELETE_LEADTYPE = "SG_DELETE_LEADTYPE";
export const ADD_LEADTYPE = "ADD_LEADTYPE";
export const SG_ADD_LEADTYPE = "SG_ADD_LEADTYPE";
export const EDIT_LEADTYPES = 'EDIT_LEADTYPES';
export const UPDATE_LEADTYPES = 'UPDATE_LEADTYPES';
export const CLEAR_LEADTYPES = 'CLEAR_LEADTYPES';
// 
export const GET_TRADE_INS_PENDING = "GET_TRADE_INS_PENDING";
export const GET_TRADE_INS = "GET_TRADE_INS";
export const LOAD_TRADE_INS = "LOAD_TRADE_INS";
export const LOAD_ADMIN_TRADE_INS = "LOAD_ADMIN_TRADE_INS";
export const GET_ADMIN_TRADE_INS = "GET_ADMIN_TRADE_INS";

export const FETCH_TRADE_INS_PENDING = "FETCH_TRADE_INS_PENDING";
export const FETCH_TRADE_INS = "FETCH_TRADE_INS";
export const SG_FETCH_TRADE_INS = "SG_FETCH_TRADE_INS";
export const DELETE_TRADE_IN = "DELETE_TRADE_IN";
export const REMOVE_TRADE_IN = "REMOVE_TRADE_IN";
export const NEW_TRADE_IN = "NEW_TRADE_IN";
export const ADD_TRADE_IN = "ADD_TRADE_IN";
export const CREATE_TRADE_IN = "CREATE_TRADE_IN";
export const EDIT_TRADE_INS = 'EDIT_TRADE_INS';
export const UPDATE_TRADE_INS = 'UPDATE_TRADE_INS';
export const CLEAR_TRADE_INS = 'CLEAR_TRADE_INS';
// 
export const GET_TASK_LISTS_PENDING = "GET_TASK_LISTS_PENDING";
export const GET_TASK_LISTS = "GET_TASK_LISTS";
export const LOAD_TASK_LISTS = "LOAD_TASK_LISTS";
export const FETCH_TASK_LISTS_PENDING = "FETCH_TASK_LISTS_PENDING";
export const FETCH_TASK_LISTS = "FETCH_TASK_LISTS";
export const SG_FETCH_TASK_LISTS = "SG_FETCH_TASK_LISTS";
export const DELETE_TASK_LIST = "DELETE_TASK_LIST";
export const ADD_TASK_LIST = "ADD_TASK_LIST";
export const CREATE_TASK_LIST = "CREATE_TASK_LIST";
export const EDIT_TASK_LISTS = 'EDIT_TASK_LISTS';
export const UPDATE_TASK_LISTS = 'UPDATE_TASK_LISTS';
export const CLEAR_TASK_LISTS = 'CLEAR_TASK_LISTS';
//
export const GET_UPLOAD_FILES_PENDING = "GET_UPLOAD_FILES_PENDING";
export const GET_UPLOAD_FILES = "GET_UPLOAD_FILES";
export const LOAD_UPLOAD_FILES = "LOAD_UPLOAD_FILES";
export const FETCH_UPLOAD_FILES_PENDING = "FETCH_UPLOAD_FILES_PENDING";
export const FETCH_UPLOAD_FILES = "FETCH_UPLOAD_FILES";
export const SG_FETCH_UPLOAD_FILES = "SG_FETCH_UPLOAD_FILES";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";

export const UPLOAD_REQUEST =  'UPLOAD_REQUEST';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const ADD_FILE_UPLOAD =  'ADD_FILE_UPLOAD';
export const UPLOAD_FAILURE =  'UPLOAD_FAILURE';
export const DELETE_UPLOAD_FILE = "DELETE_UPLOAD_FILE";

export const NEW_VIN_UPLOAD_CREATING = "NEW_VIN_UPLOAD_CREATING";
export const GET_VIN_UPLOAD_PENDING = "GET_VIN_UPLOAD_PENDING";
export const GET_VIN_UPLOAD = "GET_VIN_UPLOAD";
export const LOAD_VIN_UPLOAD = "LOAD_VIN_UPLOAD";
export const FETCH_VIN_UPLOAD_PENDING = "FETCH_VIN_UPLOAD_PENDING";

export const FETCH_VIN_UPLOAD = "FETCH_VIN_UPLOAD";
export const SG_FETCH_VIN_UPLOAD = "SG_FETCH_VIN_UPLOAD";
export const ADD_VIN_UPLOAD = "ADD_VIN_UPLOAD";
export const SG_ADD_VIN_UPLOAD = "SG_ADD_VIN_UPLOAD";
export const EDIT_VIN_UPLOAD = "EDIT_VIN_UPLOAD";
export const SG_EDIT_VIN_UPLOAD = "SG_EDIT_VIN_UPLOAD";

export const DELETE_VIN_UPLOAD = "DELETE_VIN_UPLOAD";

export const UPDATE_VIN_UPLOAD = "UPDATE_VIN_UPLOAD";
export const SG_UPDATE_VIN_UPLOAD = "SG_UPDATE_VIN_UPLOAD";
//
export const LOAD_VALID_VIN_UPLOAD_LIST = "LOAD_VALID_VIN_UPLOAD_LIST";
export const GET_VALID_VIN_UPLOAD_LIST = "GET_VALID_VIN_UPLOAD_LIST";
//

export const GET_INVENTORY_ENGINE = "GET_INVENTORY_ENGINE" 
export const GET_INVENTORY_ENGINE_PENDING = "GET_INVENTORY_ENGINE_PENDING" 
export const LOAD_INVENTORY_ENGINE = "LOAD_INVENTORY_ENGINE" 
export const FETCH_INVENTORY_ENGINE = "FETCH_INVENTORY_ENGINE" 
export const EDIT_INVENTORY_ENGINE = "EDIT_INVENTORY_ENGINE" 
export const SG_UPDATE_INVENTORY_ENGINE = "SG_UPDATE_INVENTORY_ENGINE" 
export const ADD_INVENTORY_ENGINE = "ADD_INVENTORY_ENGINE"  
export const DELETE_INVENTORY_ENGINE = "DELETE_INVENTORY_ENGINE" 
export const SG_DELETE_INVENTORY_ENGINE = "SG_DELETE_INVENTORY_ENGINE" 
//
export const LOAD_WEB_LEAD_REPORT = "LOAD_WEB_LEAD_REPORT";
export const GET_WEB_LEAD_REPORT = "GET_WEB_LEAD_REPORT";
export const LOAD_WEB_LEAD_LIST = "LOAD_WEB_LEAD_LIST";
export const GET_WEB_LEAD_LIST = "GET_WEB_LEAD_LIST";
export const LOAD_WEB_LEAD = "LOAD_WEB_LEAD";
export const GET_WEB_LEADS = "GET_WEB_LEADS";
export const GET_WEB_LEADS_PENDING = 'GET_WEB_LEADS_PENDING'; 
export const DELETE_WEB_LEAD = "DELETE_WEB_LEAD";
export const SG_DELETE_WEB_LEAD = "SG_DELETE_WEB_LEAD";
export const ADD_WEB_LEAD = "ADD_WEB_LEAD";
export const SG_ADD_WEB_LEAD = "SG_ADD_WEB_LEAD";
export const FETCH_WEB_LEAD_PENDING = "FETCH_WEB_LEAD_PENDING";
export const FETCH_WEB_LEAD = "FETCH_WEB_LEAD";
export const SG_FETCH_WEB_LEAD = "SG_FETCH_WEB_LEAD";
export const UPDATE_WEB_LEAD = 'UPDATE_WEB_LEAD';
export const EDIT_WEB_LEAD = 'EDIT_WEB_LEAD'

//
export const LOAD_LEAD_STAGE = "LOAD_LEAD_STAGE";
export const GET_LEAD_STAGES = "GET_LEAD_STAGES";
export const LOAD_DEFAULT_LEAD_STAGE = "LOAD_DEFAULT_LEAD_STAGE";
export const GET_DEFAULT_LEAD_STAGES = "GET_DEFAULT_LEAD_STAGES";
export const DELETE_LEAD_STAGE = "DELETE_LEAD_STAGE";
export const SG_DELETE_LEAD_STAGE = "SG_DELETE_LEAD_STAGE";
export const ADD_LEAD_STAGE = "ADD_LEAD_STAGE";
export const SG_ADD_LEAD_STAGE = "SG_ADD_LEAD_STAGE";
export const FETCH_LEAD_STAGE = "FETCH_LEAD_STAGE";
export const SG_FETCH_LEAD_STAGE = "SG_FETCH_LEAD_STAGE";
export const UPDATE_LEAD_STAGE = 'UPDATE_LEAD_STAGE';
export const EDIT_LEAD_STAGE = 'EDIT_LEAD_STAGE'
//
export const LOAD_WEB_COMMENTS = "LOAD_WEB_COMMENTS";
export const GET_WEB_COMMENTS_PENDING = 'GET_WEB_COMMENTS_PENDING';
export const GET_WEB_COMMENTS = "GET_WEB_COMMENTS";
export const DELETE_WEB_COMMENT = "DELETE_WEB_COMMENT";
export const SG_DELETE_WEB_COMMENT = "SG_DELETE_WEB_COMMENT";
export const ADD_WEB_COMMENT = "ADD_WEB_COMMENT";
export const SG_ADD_WEB_COMMENT = "SG_ADD_WEB_COMMENT";
export const FETCH_WEB_COMMENT = "FETCH_WEB_COMMENT";
export const SG_FETCH_WEB_COMMENT = "SG_FETCH_WEB_COMMENT";
export const UPDATE_WEB_COMMENT = 'UPDATE_WEB_COMMENT';
export const EDIT_WEB_COMMENT = 'EDIT_WEB_COMMENT'
export const CLEAR_WEB_COMMENT = 'CLEAR_WEB_COMMENT'
//
export const LOAD_CUSTOMER_APPOINTMENTS = "LOAD_CUSTOMER_APPOINTMENTS";
export const GET_CUSTOMER_APPOINTMENTS_PENDING = 'GET_CUSTOMER_APPOINTMENTS_PENDING';
export const GET_CUSTOMER_APPOINTMENTS = "GET_CUSTOMER_APPOINTMENTS";
export const DELETE_CUSTOMER_APPOINTMENT = "DELETE_CUSTOMER_APPOINTMENT";
export const SG_DELETE_CUSTOMER_APPOINTMENT = "SG_DELETE_CUSTOMER_APPOINTMENT";
export const ADD_CUSTOMER_APPOINTMENT = "ADD_CUSTOMER_APPOINTMENT";
export const SG_ADD_CUSTOMER_APPOINTMENT = "SG_ADD_CUSTOMER_APPOINTMENT";
export const FETCH_CUSTOMER_APPOINTMENT = "FETCH_CUSTOMER_APPOINTMENT";
export const SG_FETCH_CUSTOMER_APPOINTMENT = "SG_FETCH_CUSTOMER_APPOINTMENT";
export const UPDATE_CUSTOMER_APPOINTMENT = 'UPDATE_CUSTOMER_APPOINTMENT';
export const EDIT_CUSTOMER_APPOINTMENT = 'EDIT_CUSTOMER_APPOINTMENT'
export const CLEAR_CUSTOMER_APPOINTMENT = 'CLEAR_CUSTOMER_APPOINTMENT'
//
export const LOAD_KIJIJI_LISTINGS = "LOAD_KIJIJI_LISTINGS";
export const GET_KIJIJI_LISTINGS = "GET_KIJIJI_LISTINGS";
export const DELETE_KIJIJI_LISTING = "DELETE_KIJIJI_LISTING";
export const SG_DELETE_KIJIJI_LISTING = "SG_DELETE_KIJIJI_LISTING";
export const ADD_KIJIJI_LISTING = "ADD_KIJIJI_LISTING";
export const SG_ADD_KIJIJI_LISTING = "SG_ADD_KIJIJI_LISTING";
export const FETCH_KIJIJI_LISTING = "FETCH_KIJIJI_LISTING";
export const SG_FETCH_KIJIJI_LISTING = "SG_FETCH_KIJIJI_LISTING";
export const UPDATE_KIJIJI_LISTING = 'UPDATE_KIJIJI_LISTING';
export const EDIT_KIJIJI_LISTING = 'EDIT_KIJIJI_LISTING'
export const CLEAR_KIJIJI_LISTING = 'CLEAR_KIJIJI_LISTING'
//
export const LOAD_WEB_LEAD_ROUTINGS = "LOAD_WEB_LEAD_ROUTINGS";
export const GET_WEB_LEAD_ROUTINGS = "GET_WEB_LEAD_ROUTINGS";
export const DELETE_WEB_LEAD_ROUTING = "DELETE_WEB_LEAD_ROUTING";
export const SG_DELETE_WEB_LEAD_ROUTING = "SG_DELETE_WEB_LEAD_ROUTING";
export const ADD_WEB_LEAD_ROUTING = "ADD_WEB_LEAD_ROUTING";
export const SG_ADD_WEB_LEAD_ROUTING = "SG_ADD_WEB_LEAD_ROUTING";
export const FETCH_WEB_LEAD_ROUTING = "FETCH_WEB_LEAD_ROUTING";
export const SG_FETCH_WEB_LEAD_ROUTING = "SG_FETCH_WEB_LEAD_ROUTING";
export const UPDATE_WEB_LEAD_ROUTING = 'UPDATE_WEB_LEAD_ROUTING';
export const EDIT_WEB_LEAD_ROUTING = 'EDIT_WEB_LEAD_ROUTING'
export const CLEAR_WEB_LEAD_ROUTING = 'CLEAR_WEB_LEAD_ROUTING'
//
export const LOAD_YEAR_MAKE_MODEL_TRIM = "LOAD_YEAR_MAKE_MODEL_TRIM"
export const GET_YEAR_MAKE_MODEL_TRIM = "GET_YEAR_MAKE_MODEL_TRIM";
export const FETCH_YEAR_MAKE_MODEL_TRIM = "FETCH_YEAR_MAKE_MODEL_TRIM"
export const SG_FETCH_YEAR_MAKE_MODEL_TRIM = "SG_FETCH_YEAR_MAKE_MODEL_TRIM"
export const SUBMIT_YEAR_MAKE_MODEL_TRIM = "SUBMIT_YEAR_MAKE_MODEL_TRIM"
export const SG_SUBMIT_YEAR_MAKE_MODEL_TRIM = "SG_SUBMIT_YEAR_MAKE_MODEL_TRIM"
//
//
export const LOAD_YEAR_SELECT = "LOAD_YEAR_SELECT"
export const GET_YEAR_SELECT = "GET_YEAR_SELECT";
export const FETCH_YEAR_SELECT = "FETCH_YEAR_SELECT"
export const SG_FETCH_YEAR_SELECT = "SG_FETCH_YEAR_SELECT"
//
export const LOAD_MAKE_SELECT = "LOAD_MAKE_SELECT"
export const GET_MAKE_SELECT = "GET_MAKE_SELECT";
export const FETCH_MAKE_SELECT = "FETCH_MAKE_SELECT"
export const SG_FETCH_MAKE_SELECT = "SG_FETCH_MAKE_SELECT"
//
export const LOAD_MODEL_SELECT = "LOAD_MODEL_SELECT"
export const GET_MODEL_SELECT = "GET_MODEL_SELECT";
export const FETCH_MODEL_SELECT = "FETCH_MODEL_SELECT"
export const SG_FETCH_MODEL_SELECT = "SG_FETCH_MODEL_SELECT"
//
export const LOAD_TRIM_SELECT = "LOAD_TRIM_SELECT"
export const GET_TRIM_SELECT = "GET_TRIM_SELECT";
export const FETCH_TRIM_SELECT = "FETCH_TRIM_SELECT"
export const SG_FETCH_TRIM_SELECT = "SG_FETCH_TRIM_SELECT"
//

export const GET_LOANS = "GET_LOANS"
export const SG_GET_LOANS = "SG_GET_LOANS";
export const DELETE_LOAN = "DELETE_LOAN";
export const SG_DELETE_LOAN = "SG_DELETE_LOAN";
export const ADD_LOAN = "ADD_LOAN";
export const SG_ADD_LOAN = "SG_ADD_LOAN";
export const FETCH_LOAN = "FETCH_LOAN";
export const SG_FETCH_LOAN = 'SG_FETCH_LOAN';
export const EDIT_LOAN = 'EDIT_LOAN'
export const SG_EDIT_LOAN = 'SG_EDIT_LOAN'
export const CLEAR_LOAN = 'CLEAR_LOAN';
//

// export const LOAD_CARMAKES = "LOAD_CARMAKES";
// export const GET_CARMAKES = "GET_CARMAKES";
// export const DELETE_CARMAKE = "DELETE_CARMAKE";
// export const ADD_CARMAKE = "ADD_CARMAKE";
// export const FETCH_CARMAKE = "FETCH_CARMAKE";
// export const UPDATE_CARMAKE = 'UPDATE_CARMAKE';
// export const EDIT_CARMAKE = 'EDIT_CARMAKE'
// export const CLEAR_CARMAKE = 'CLEAR_CARMAKE';
//

// export const LOAD_CARMODELS = "LOAD_CARMODELS"
// export const GET_CARMODELS = "GET_CARMODELS";
// export const DELETE_CARMODEL = "DELETE_CARMODEL";
// export const ADD_CARMODEL = "ADD_CARMODEL";
// export const FETCH_CARMODEL = "FETCH_CARMODEL";
// export const UPDATE_CARMODEL = 'UPDATE_CARMODEL';
// export const EDIT_CARMODEL = 'EDIT_CARMODEL'
// export const CLEAR_CARMODEL = 'CLEAR_CARMODEL';
//
// export const LOAD_CARTRIMS = "LOAD_CARTRIMS";
// export const GET_CARTRIMS = "GET_CARTRIMS";
// export const DELETE_CARTRIM = "DELETE_CARTRIM";
// export const ADD_CARTRIM = "ADD_CARTRIM";
// export const FETCH_CARTRIM = "FETCH_CARTRIM";
// export const UPDATE_CARTRIM = 'UPDATE_CARTRIM';
// export const EDIT_CARTRIM = 'EDIT_CARTRIM'
// export const CLEAR_CARTRIM = 'CLEAR_CARTRIM';
//
export const LOAD_CARCOLORS = "LOAD_CARCOLORS";
export const GET_CARCOLORS = "GET_CARCOLORS";
export const DELETE_CARCOLOR = "DELETE_CARCOLOR";
export const ADD_CARCOLOR = "ADD_CARCOLOR";
export const FETCH_CARCOLOR = "FETCH_CARCOLOR";
export const UPDATE_CARCOLOR = 'UPDATE_CARCOLOR';
export const EDIT_CARCOLOR = 'EDIT_CARCOLOR'
export const CLEAR_CARCOLOR = 'CLEAR_CARCOLOR';
//
export const LOAD_CARBODYTYPES = "LOAD_CARBODYTYPES";
export const GET_CARBODYTYPES = "GET_CARBODYTYPES";
export const DELETE_CARBODYTYPE = "DELETE_CARBODYTYPE";
export const ADD_CARBODYTYPE = "ADD_CARBODYTYPE";
export const FETCH_CARBODYTYPE = "FETCH_CARBODYTYPE";
export const UPDATE_CARBODYTYPE = 'UPDATE_CARBODYTYPE';
export const EDIT_CARBODYTYPE = 'EDIT_CARBODYTYPE'
export const CLEAR_CARBODYTYPE = 'CLEAR_CARBODYTYPE';
//
export const LOAD_KM_PRICES = "LOAD_KM_PRICES";
export const GET_KM_PRICES = "GET_KM_PRICES";
export const DELETE_KM_PRICE = "DELETE_KM_PRICE";
export const ADD_KM_PRICE = "ADD_KM_PRICE";
export const FETCH_KM_PRICE = "FETCH_KM_PRICE";
export const UPDATE_KM_PRICE = 'UPDATE_KM_PRICE';
export const EDIT_KM_PRICE = 'EDIT_KM_PRICE'
export const CLEAR_KM_PRICE = 'CLEAR_KM_PRICE';
//
export const LOAD_LOGIN_TRAIL = "LOAD_LOGIN_TRAIL";
export const GET_LOGIN_TRAIL = "GET_LOGIN_TRAIL";
export const ONE_LOGIN_TRAIL = "ONE_LOGIN_TRAIL";
export const FETCH_LOGIN_TRAIL = "FETCH_LOGIN_TRAIL";
//
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const LOAD_ACTIVE_PRODUCTS = "LOAD_ACTIVE_PRODUCTS";

export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ONE_PRODUCT = "ONE_PRODUCT";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
//
export const LOAD_PRODUCT_GROUPS = "LOAD_PRODUCT_GROUPS";
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";
export const REMOVE_PRODUCT_GROUP = "REMOVE_PRODUCT_GROUP";
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP";
export const CREATE_PRODUCT_GROUP = "CREATE_PRODUCT_GROUP";
export const ADD_PRODUCT_GROUP = "ADD_PRODUCT_GROUP";
export const ONE_PRODUCT_GROUP = "ONE_PRODUCT_GROUP"
export const FETCH_PRODUCT_GROUP = "FETCH_PRODUCT_GROUP";
export const UPDATE_PRODUCT_GROUP = 'UPDATE_PRODUCT_GROUP';
export const EDIT_PRODUCT_GROUP = 'EDIT_PRODUCT_GROUP'
export const CLEAR_PRODUCT_GROUP = 'CLEAR_PRODUCT_GROUP';
//
export const LOAD_PRODUCT_CONTRACTS = "LOAD_PRODUCT_CONTRACTS";
export const GET_PRODUCT_CONTRACTS = "GET_PRODUCT_CONTRACTS";
export const LOAD_PRODUCT_ACTIVE_CONTRACTS = "LOAD_PRODUCT_ACTIVE_CONTRACTS";

export const REMOVE_PRODUCT_CONTRACT = "REMOVE_PRODUCT_CONTRACT";
export const DELETE_PRODUCT_CONTRACT = "DELETE_PRODUCT_CONTRACT";
export const CREATE_PRODUCT_CONTRACT = "CREATE_PRODUCT_CONTRACT";
export const ADD_PRODUCT_CONTRACT = "ADD_PRODUCT_CONTRACT";
export const ONE_PRODUCT_CONTRACT = "ONE_PRODUCT_CONTRACT";
export const FETCH_PRODUCT_CONTRACT = "FETCH_PRODUCT_CONTRACT";
export const UPDATE_PRODUCT_CONTRACT = 'UPDATE_PRODUCT_CONTRACT';
export const EDIT_PRODUCT_CONTRACT = 'EDIT_PRODUCT_CONTRACT'
export const CLEAR_PRODUCT_CONTRACT = 'CLEAR_PRODUCT_CONTRACT';
//
export const GET_BLACK_BOOK_LIST = 'GET_BLACK_BOOK_LIST'
export const LOAD_BLACK_BOOK_LIST = 'LOAD_BLACK_BOOK_LIST';
//
export const LOAD_BLACKBOOK_PENDING = "LOAD_BLACKBOOK_PENDING";
export const LOAD_BLACKBOOK_VALUES = "LOAD_BLACKBOOK_VALUES";
export const GET_BLACKBOOK_VALUES = "GET_BLACKBOOK_VALUES";
export const FETCH_BLACKBOOK_VALUES = "FETCH_BLACKBOOK_VALUES";
export const CLEAR_BLACKBOOK_VALUES = "CLEAR_BLACKBOOK_VALUES";
//
export const GET_YEAR_MAKE_PENDING = "GET_YEAR_MAKE_PENDING"
export const LOAD_YEAR_MAKE = "LOAD_YEAR_MAKE"
export const GET_YEAR_MAKE = "GET_YEAR_MAKE";
export const FETCH_YEAR_MAKE = "FETCH_YEAR_MAKE"
export const SG_FETCH_YEAR_MAKE = "SG_FETCH_YEAR_MAKE"
export const SUBMIT_YEAR_MAKE = "SUBMIT_YEAR_MAKE"
export const SG_SUBMIT_YEAR_MAKE = "SG_SUBMIT_YEAR_MAKE"
//
export const LOAD_COMPARED_INVENTORY_LIST = "LOAD_COMPARED_INVENTORY_LIST";
export const GET_COMPARED_INVENTORY_LIST = "GET_COMPARED_INVENTORY_LIST";
//
export const GET_CUSTOMER_INVENTORY = "GET_CUSTOMER_INVENTORY";
export const SG_GET_CUSTOMER_INVENTORY = "SG_GET_CUSTOMER_INVENTORY";
//
export const GET_INVENTORY_CUSTOMER = "GET_INVENTORY_CUSTOMER";
export const SG_GET_INVENTORY_CUSTOMER = "SG_GET_INVENTORY_CUSTOMER";
//
export const LOAD_GSHEET_INVENTORY_LIST = "LOAD_GSHEET_INVENTORY_LIST";
export const GET_GSHEET_INVENTORY_LIST = "GET_GSHEET_INVENTORY_LIST";
//
export const SW_INIT ="SW_INIT"
export const SW_UPDATE = 'SW_UPDATE'
//
export const LEAD_REPORTS_PENDING ="LEAD_REPORTS_PENDING"
export const LOAD_LEAD_REPORTS ="LOAD_LEAD_REPORTS"
export const GET_LEAD_REPORTS = 'GET_LEAD_REPORTS'
//