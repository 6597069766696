import { put, all, call, takeLatest } from 'redux-saga/effects'
import {
  GET_LOANS,
  SG_GET_LOANS,
  DELETE_LOAN,
  SG_DELETE_LOAN,
  ADD_LOAN,
  SG_ADD_LOAN,
  FETCH_LOAN,
  SG_FETCH_LOAN,
  EDIT_LOAN,
  SG_EDIT_LOAN,
  CLEAR_LOAN,
  ALL_ERRORS,
} from "constants/actions";

import * as api from '../constants/api';


function* loanLoad(action) {
  // console.log(action)
  try {
    const json = yield call(api.GET_LOAN, action)
    yield put({ type: GET_LOANS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* loadLoan() {
  yield takeLatest(SG_GET_LOANS, loanLoad);
}


function* fetchLoan(action) {
  try {
    const json = yield call(api.FETCH_LOAN, action.payload)

    yield put({ type: FETCH_LOAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* loanFetch() {
  yield takeLatest(SG_FETCH_LOAN, fetchLoan);
}


function* editLoan(action) {

  try {
    const json = yield call(api.EDIT_LOAN, action.payload.id, action.payload);

    yield put({ type: EDIT_LOAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* loanEdit() {
  yield takeLatest(SG_EDIT_LOAN, editLoan);
}

function* addLoan(action) {
  try {
    const json = yield call(api.ADD_LOAN, action.payload);
    yield put({ type: ADD_LOAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* loanAdd() {
  yield takeLatest(SG_ADD_LOAN, addLoan);
}

function* deleteLoan(action) {
  try {
    const json = yield call(api.DELETE_LOAN, action.payload);
    yield put({ type: DELETE_LOAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* loanDelete() {
  yield takeLatest(SG_DELETE_LOAN, deleteLoan);
}

export default function* index() {
  yield all([
    loanLoad(),
    loanFetch(),
    loanEdit(),
    loanAdd(),
    loanDelete(),
  ]);
}