import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";

import {
  INVENTORY_IMAGE_PENDING,
  LOAD_INVENTORYIMAGES,
  GET_INVENTORYIMAGES,
  DELETE_INVENTORYIMAGE,
  SG_DELETE_INVENTORYIMAGE,
  ADD_INVENTORYIMAGE,
  SG_ADD_INVENTORYIMAGE,
  CLEAR_INVENTORYIMAGE,
  FETCH_INVENTORYIMAGE,
  EDIT_INVENTORYIMAGE,
  SG_EDIT_INVENTORYIMAGE,
  LOAD_CARIMAGES,
  GET_CARIMAGES_PENDING,
  GET_CARIMAGES,
  UPLOAD_FAILURE,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "constants/api";

function* uploadImage(action) {
  // yield put({type: INVENTORY_IMAGE_PENDING});

  try {
    const json = yield call(api.ADD_INVENTORY_IMAGE, action.payload);

    yield put({ type: ADD_INVENTORYIMAGE, payload: json.data });
  } catch (e) {
    yield put({ type: UPLOAD_FAILURE, payload: e });
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* imageUploadRequest() {
  yield takeEvery(SG_ADD_INVENTORYIMAGE, uploadImage);
}

function* getAllInventoryImages(action) {
  // console.log(action.payload)
  try {
    const json = yield call(api.GET_INVENTORY_IMAGES, action.payload);
    yield put({ type: GET_INVENTORYIMAGES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryImagesLoad() {
  yield takeLatest(LOAD_INVENTORYIMAGES, getAllInventoryImages);
}

function* deleteInventoryImage(action) {
  yield put({ type: INVENTORY_IMAGE_PENDING });
  try {
    const json = yield call(api.DELETE_INVENTORY_IMAGE, action.payload);
    yield put({ type: DELETE_INVENTORYIMAGE, payload: action.payload.id });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* imageuploadDelete() {
  yield takeEvery(SG_DELETE_INVENTORYIMAGE, deleteInventoryImage);
}

function* editInventoryImage(action) {

  try {
    const json = yield call(
      api.EDIT_INVENTORY_IMAGE,
      action.payload.id,
      action.payload,
      action.payload.param
    );
    yield put({ type: EDIT_INVENTORYIMAGE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryImageEdit() {
  yield takeEvery(SG_EDIT_INVENTORYIMAGE, editInventoryImage);
}

// function* deleteImage(action) {
//   try {
//       // api call to delete the car image
//       const json = yield call(api.getPagedInventories, action.payload)
//       yield put({ type: DELETE_INVENTORYIMAGE, payload: (action.payload) });
//   }
//   catch (e) {
//       yield put({type: ALL_ERRORS, error: e.message});
//   }
// }

// export function* imageDelete() {

//   yield takeEvery(SG_DELETE_INVENTORYIMAGE, deleteImage);
// }
// function* fetchImage(action) {
//     yield put({type: FETCH_INVENTORY_PENDING})
//     try {
//     // const json = yield call(api.getPagedInventories, action.payload)
//     let car = car_images.filter(function(item) { return item.car_id === parseInt(action.payload); })[0]
//     yield put({ type: FETCH_INVENTORY, payload: car, });
//     }
//     catch (e) {
//     yield put({type: ALL_ERRORS, error: e.message});
//     }
// }

// export function* inventoryFetchOne() {
//     yield takeLatest(FETCH_INVENTORY_ONE, fetchImage);
//  }

export default function* index() {
  yield all([
    imageUploadRequest(),
    inventoryImagesLoad(),
    imageuploadDelete(),
    inventoryImageEdit(),
    //  imageLoad(),
    // imageDelete()
  ]);
}
