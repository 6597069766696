import { put, all, call, takeLatest } from "redux-saga/effects";
import {
  GET_LEADS_PENDING,
  GET_LEADS,
  LOAD_LEADS,
  FETCH_LEADS_PENDING,
  FETCH_LEADS,
  SG_FETCH_LEADS,
  ADD_LEAD,
  EDIT_LEADS,
  UPDATE_LEADS,
  DELETE_LEAD,
  CREATE_LEAD,

  CLEAR_LEADS,
  ALL_ERRORS,
} from "constants/actions";

import * as api from "../constants/api";


function* loadLead(action) {
  yield put({ type: GET_LEADS_PENDING });
  try {
    const json = yield call(api.getPagedInventories, action.payload);

    yield put({ type: GET_LEADS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* leadLoad() {
  yield takeLatest(LOAD_LEADS, loadLead);
}


function* fetchLead(action) {
  yield put({ type: FETCH_LEADS_PENDING });
  try {
    const json = yield call(api.getPagedInventories, action.payload);

    yield put({ type: FETCH_LEADS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* leadFetchOne() {
  yield takeLatest(SG_FETCH_LEADS, fetchLead);
}

function* updateLead(action) {
  yield put({ type: FETCH_LEADS_PENDING });
  try {
    const json = yield call(api.getPagedInventories, action.payload);

    yield put({ type: EDIT_LEADS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* leadUpdate() {
  yield takeLatest(UPDATE_LEADS, updateLead);
}

function* createLead(action) {
  try {
    const json = yield call(api.getPagedInventories, action.payload);

    yield put({ type: ADD_LEAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* leadCreate() {
  yield takeLatest(CREATE_LEAD, createLead);
}

export default function* index() {
  yield all([leadLoad(), leadFetchOne(), leadUpdate(), leadCreate()]);
}
