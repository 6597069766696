import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import _ from "lodash";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import List from "./List";

const ListWebLeads = () => {
  // React.useEffect(() => {
  //   // Run! Like go get some data from an API.

  //     dispatch({ type: LOAD_WEB_LEAD, payload: id });

  // }, [dispatch]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Feedback</CardTitle>
                    <h5 className="card-category">Website Comments</h5>
                  </Col>
                  <Col className="ml-auto" md="2">
                    {/* <Button
                      color="default"
                      size="sm"
                      to="/admin/web-comment-create"
                      tag={Link}
                    >
                      Create New
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <List />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ListWebLeads;
