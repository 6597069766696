import { combineReducers } from "redux";

// import { reducer as formReducer } from "redux-form";

import error_state from "./error_state"
import inventories from "./inventory";
import inventoryextra from "./inventoryextra";
import inventoryfinance from "./inventoryfinance";
import inventorynotes from "./inventorynotes";
import inventoryimages from "./inventoryimage";
import inventoryengine from "./inventoryengine";
import imageprofiles from './imageprofile';
import paginate from "./paginate";
import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import employees from "./employees";
import cashforcars from "./cms"
import stripecustomer from "./cms"
import stripeproducts from "./cms"
import stripeinvoice from "./cms"
import customerStripeCreate from "./cms"
import contactpage from "./cms"
import privacy from "./cms"
import about from "./cms"
import terms from "./cms"
import homepage from "./cms"
import dealers from "./dealers";
import servicerequest from "./servicerequest";
import leads from "./leads";

import leadsources from "./leadsource";
import leadtypes from "./leadtype";
import leadstage from './leadstage';
import tradein from "./tradein";
import tasklist from "./tasklist";
import customer from "./customer";
import creditapplication from "./creditapplication"
import customerappointment from "./customerappointment"
import fileupload from "./fileupload";
import vinupload from "./vinupload";
import landingpages from "./landingpages";
import websiteimages from './websiteimages';

import webcomments from './webcomments';

import selectyear from "./selectyear"
import selectmake from "./selectmake"
import selectmodel from "./selectmodel"
import selecttrim from "./selecttrim"

import blackbook from "./blackbook"
import yearmakemodel from './yearmakemodel';
import yearmake from './yearmake';

import spec from "./specs"

import product from "./product"
import productgroup from "./productgroup"
import productcontract from "./productcontract"

import logintrail from "./logintrail"
import kijiji from "./kijiji"

import reports from "./reports"
import loan from "./loan";
import categories from "./categories";
import garage from "./garage";
import company from './company';
import companyinformation from './companyinformation';
import caryears from "./caryears";
import carmakes from "./carmakes";
import carmodels from "./carmodels";
import cartrims from "./cartrims";
import carimages from "./carimages";
import webleads from "./webleads"
import resetpageparams from "./resetpageparams.js";
import serviceworker from "./serviceworker"

export default combineReducers({
  // form: formReducer,
  error_state,
  errors,
  messages,
  auth,
  carimages,
  carmakes,
  carmodels,
  cartrims,
  caryears,
  company,
  companyinformation,
  spec,
  categories,
  creditapplication,
  customerappointment,
  customer,
  dealers,
  employees,
  cashforcars,
  stripecustomer,
  stripeproducts,
  stripeinvoice,
  customerStripeCreate,
  contactpage,
  privacy,
  about,
  terms,
  homepage,
  fileupload, 
  vinupload,
  // garage,
  imageprofiles,
  inventories,
  inventoryengine,
  inventoryextra,
  inventoryfinance,
  inventorynotes,
  inventoryimages,
  // leads,
  blackbook,
  selectyear,
  selectmake,
  selectmodel,
  selecttrim,
  logintrail,
  leads,
  leadsources,
  leadtypes,
  leadstage,
  landingpages,
  paginate,
  product,
  productcontract,
  productgroup,
  resetpageparams,
  reports,
  servicerequest,
  tasklist,
  tradein,
  loan,
  webleads,
  webcomments,
  websiteimages,
  yearmake,
  yearmakemodel,
  // landingpages,
  serviceworker,
  kijiji,
});
