import React from 'react';
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
  } from "reactstrap";
  
import {options} from "./CharConstants";

  function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  const extData = () => {
    return ([getRandomInt(50, 200), getRandomInt(100, 150)])
  }
  
  
  const data = (data) => ({
    labels: [
        'Lost',
        'Won',
    ],
	datasets: [{
		data: [data[0], data[1]],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		]
	}]
});


const SalesWon = () => {
  const [valData, setValData] = React.useState(extData)
  const [chrData, setChrData] = React.useState(data(valData))
  const [chrOptions, setChrOptions] = React.useState(options(valData))
  // console.log("options changed valData", valData)
  // console.log("options changed extData", extData)
  React.useEffect(() => {
    setInterval(() => {
      setChrData(data(valData));
      setChrOptions(options(valData))
      setValData(extData)
		}, 10000);
  }, [valData]
  )
    return (
        <Card>
                <CardHeader>
                  <CardTitle>Total Deals</CardTitle>
                  <p className="card-category">Sales Won</p>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={chrData}
                    options={chrOptions}
                    className="ct-chart ct-perfect-fourth"
                    height={350}
                    width={456}
                  />
                </CardBody>
                <CardFooter>
                 
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" />
                    Total Deals in last 30 days
                  </div>
                </CardFooter>
              </Card>
    );
};

export default SalesWon;