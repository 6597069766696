import React from "react";
import NotificationAlert from "react-notification-alert";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import ManagementDash from "Dashboard/MgmtReports/InventoryReports"
import SalesDashBoard from "Dashboard/SalesDashboard"

function Dashboard () {
  // const [user, setUser] = React.useState([]);
  const [toSalesDash, setToSalesDash] = React.useState(false);
  const [toMgmtDasg, setToMgmtDasg] = React.useState(false);
  const { logged_user } = useSelector(
    state => ({
      logged_user: state.auth
    }),
    shallowEqual
  );

  // console.log(logged_user)

  React.useEffect(() => {
    if (logged_user) {
      // setUser(logged_user);
      if (logged_user.role_id < 6)
      setToMgmtDasg(true)
    }
  }, [logged_user]);
// console.log(user)
// ADMIN = 1
// MANAGER = 3
// SUPERVISOR = 6
// SALES = 8
// EMPLOYEE = 99

// designation
// Sales, Marketing, Operation, Finance, GM
let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = `${yyyy}-${mm}-${dd}`

// setToMgmtDasg(user.designation==="Marketing"? true: false)
// setToMgmtDasg(user.designation==="Marketing"? true: false)
// console.log(user, today)

// console.log("logged_user" ,logged_user, today)

    return (
      <>
        <div className="content">
        
        {toMgmtDasg ? 
        <ManagementDash user={logged_user} today={today} /> 
        : <SalesDashBoard user={logged_user} today={today} />}
        </div>
      </>
    );

}

export default Dashboard;
