import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import { StyledTableCell } from "mui/tables/StyledTableCell";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function ColumnGroupingTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DATE</StyledTableCell>
            <StyledTableCell align="right">LEADS</StyledTableCell>
            <StyledTableCell align="right">CUSTOMERS</StyledTableCell>
            <StyledTableCell align="right">SOLD</StyledTableCell>
            <StyledTableCell align="right">VALUE</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="right">{row.leads}</StyledTableCell>
                <StyledTableCell align="right">{row.customer}</StyledTableCell>
                <StyledTableCell align="right">{row.sold}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.sold_value}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow key={"s"}> No Data </StyledTableRow>
          )}
          <TableRow>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                fontWeight: "bold",
              }}
            >
              Total
            </StyledTableCell>

            <StyledTableCell
              align="right"
              sx={{
                fontWeight: "bold",
              }}
            >
              {data && data.length > 0
                ? data
                    .map((row) => row.leads)
                    .reduce((acc, val) => acc + val, 0)
                : 0}
            </StyledTableCell>
            <StyledTableCell
              align="right"
              sx={{
                fontWeight: "bold",
              }}
            >
              {data && data.length > 0
                ? data
                    .map((row) => row.customer)
                    .reduce((acc, val) => acc + val, 0)
                : 0}
            </StyledTableCell>
            <StyledTableCell
              align="right"
              sx={{
                fontWeight: "bold",
              }}
            >
              {data && data.length > 0
                ? data.map((row) => row.sold).reduce((acc, val) => acc + val, 0)
                : 0}
            </StyledTableCell>
            <StyledTableCell
              align="right"
              sx={{
                fontWeight: "bold",
              }}
            >
              {data && data.length > 0
                ? data
                    .map((row) => row.sold_value)
                    .reduce((acc, val) => acc + val, 0)
                : 0}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
