import { put, call, takeLatest, all } from "redux-saga/effects";
import { GET_COMPANIES_PENDING, LOAD_COMPANY, 
    GET_COMPANIES, DELETE_COMPANY, REMOVE_COMPANY, 
    ADD_COMPANY, CREATE_COMPANY,
    CLEAR_COMPANY, 
    FETCH_COMPANY, SG_FETCH_COMPANY, 
    EDIT_COMPANY, 
    UPDATE_COMPANY,
    ADD_COMPANY_LOGO, UPLOAD_COMPANY_LOGO,
    ALL_ERRORS
} from "constants/actions";

import * as api from "constants/api";
import { FETCH_COMPANIES_PENDING } from "constants/actions";

function* loadCompanies(action) {
  yield put({ type: GET_COMPANIES_PENDING });
  try {
    const json = yield call(api.GET_COMPANIES, action.payload)
    yield put({ type: GET_COMPANIES, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companiesLoad() {
  yield takeLatest(LOAD_COMPANY, loadCompanies);
}

function* fetchCompany(action) {
  yield put({ type: FETCH_COMPANIES_PENDING });  
  try {
    const json = yield call(api.FETCH_COMPANY, action.payload)
    yield put({ type: FETCH_COMPANY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companyFetchOne() {
  yield takeLatest(SG_FETCH_COMPANY, fetchCompany);
}

function* updateCompany(action) {

  try {
    const json = yield call(api.EDIT_COMPANY, action.payload.id, action.payload)
    yield put({ type: EDIT_COMPANY, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companyUpdate() {
  yield takeLatest(UPDATE_COMPANY, updateCompany);
}

function* addCompany(action) {

    try {
      const json = yield call(api.ADD_COMPANY, action.payload)
      yield put({ type: ADD_COMPANY, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyAdd() {
    yield takeLatest(CREATE_COMPANY, addCompany);
  }

  function* deleteCompany(action) {

    try {
      const json = yield call(api.DELETE_COMPANY, action.payload)
      yield put({ type: DELETE_COMPANY, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyDelete() {
    yield takeLatest(REMOVE_COMPANY, deleteCompany);
  }

function* logoCompany(action) {
  try {
   const json = yield call(api.ADD_COMPANY_LOGO, action.payload)
    yield put({ type: ADD_COMPANY_LOGO, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}

export function* companyLogo() {
  yield takeLatest(UPLOAD_COMPANY_LOGO, logoCompany);
}

export default function* index() {
  yield all([companiesLoad(),
    companyFetchOne(),
    companyUpdate(),
    companyAdd(),
    companyDelete(),
    companyLogo()]);
}
// export default inventoryLoad;
