import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// import { RHFInput } from 'react-hook-form-input';
import {
    LOAD_SPECS,
    LOAD_CATEGORIES,
    FETCH_INVENTORY_ONE, 
    SG_EDIT_INVENTORY,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";


const SpecInfo = ({inventory}) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();


  const [selSpec, setSelSpec] = React.useState([]);
  const [selCategory, setSelCategory] = React.useState([]);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: LOAD_CATEGORIES, payload: id });
    }
    dispatch({ type: LOAD_SPECS });
    dispatch({ type: LOAD_CATEGORIES });
  }, [dispatch]);

  const {

    get_specs,
    get_categories,
    get_auth,
    get_errors,
  } = useSelector(
    (state) => ({

      get_specs: state.spec.specs,
      get_categories: state.categories.categories,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelSpec(get_specs);
  }, [get_specs]);

  React.useEffect(() => {
    setSelCategory(get_categories);
  }, [get_categories]);

//   React.useEffect(() => {
//     if (get_web_leads[parseInt(id)]) {
//       setWebLead(get_web_leads[parseInt(id)]);

//       // const SelectedOption = OriginOptions.filter(
//       //   (fl) => fl.label === get_lead_source[parseInt(id)].origin
//       // )[0];
//       // setSelOrigin(SelectedOption);

//       // const SelectedStatus = StatusOptions.filter(
//       //   (fl) => fl.label === get_lead_source[parseInt(id)].status
//       // )[0];
//       // setSelStatus(SelectedStatus);
//     }
//   }, [get_web_leads, id]);



  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    // console.log(selStatus);
      if (inventory.specs) {
        setValue("specs", inventory.specs);
        setValue("categories", inventory.categories);
      
      }
  }, [inventory]);



  const onSpecSubmit = (data) => {
  
    
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id,
          company: get_auth.dealer_id,
          specs:data.specs
        },
      });

  };

  const onCategorySubmit = (data) => {
    console.log(data)
    
      dispatch({
        type: SG_EDIT_INVENTORY,
        payload: {
          id,
          company: get_auth.dealer_id,
          categories:data.categories
        },
      });

  };
  // console.log(errors)

  return (
    <>
    {/* {toDetail? <Redirect to={`/admin/lead-detail/${id}`}  /> : null}
    {toHome? <Redirect to={`/admin/leads`}  /> : null} */}
      <div className="content">
        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              
                  <Form onSubmit={handleSubmit(onSpecSubmit)}>
                   

 

                    <FormGroup>
                      <label>Add Listing Spec </label>
                      <Controller
                        as={<CreatableSelect isMulti />}
                        name="specs"
                        options={selSpec}
                        getOptionLabel={(option) =>
                          option.name
                        }
                        getOptionValue={(option) => `${option.id}`}
                        getNewOptionData={(inputValue, optionLabel) => ({
                            id: optionLabel,
                            name: inputValue,
                            __isNew__: true
                        })}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please select or enter a value" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.specs && <span>{errors.specs.message}</span>}
                      </p>

                      {/* <CreatableSelect 
                        isMulti
                        options={selSpec}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        className="text-dark"
                        getNewOptionData={(inputValue, optionLabel) => ({
                            id: optionLabel,
                            name: inputValue,
                            __isNew__: true
                        })}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onChange={(opt, meta) => console.log(opt, meta)} /> */}
                    </FormGroup>

                    
                    <Button className="btn-fill" color="primary" type="submit">
                      Update
                    </Button>
                  </Form>
             
            </Col>
          </Row>
        </ErrorBoundary>

        <ErrorBoundary>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              
                  <Form onSubmit={handleSubmit(onCategorySubmit)}>
                   

 

                    <FormGroup>
                      <label>Category</label>
                      <Controller
                        as={<CreatableSelect isMulti />}
                        name="categories"
                        options={selCategory}
                        getOptionLabel={(option) =>
                          option.name
                        }
                        getOptionValue={(option) => `${option.id}`}
                        getNewOptionData={(inputValue, optionLabel) => ({
                            id: optionLabel,
                            name: inputValue,
                            __isNew__: true
                        })}
                        className="text-dark"
                        control={control}
                        rules={{ required: "Please select or enter a value" }}
                      />{" "}
                      <p className="text-danger small">
                        {errors.categories && <span>{errors.categories.message}</span>}
                      </p>

                      {/* <CreatableSelect 
                        isMulti
                        options={selSpec}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        className="text-dark"
                        getNewOptionData={(inputValue, optionLabel) => ({
                            id: optionLabel,
                            name: inputValue,
                            __isNew__: true
                        })}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onChange={(opt, meta) => console.log(opt, meta)} /> */}
                    </FormGroup>

                    
                    <Button className="btn-fill" color="primary" type="submit">
                      Update
                    </Button>
                  </Form>
             
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default SpecInfo;
