import { EditorInLineConvertToHTML } from 'Inventory/InventoryDetail/EditorConvertToHTML';
import React from 'react'
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Input,
    Form,
  } from "reactstrap";
  import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  CASH_FOR_CARS_CMS,
  FETCH_CASH_FOR_CARS_CMS_VALUE,
  LOAD_GET_CASHFORCARS,
  UPDATE_CASH_FOR_CARS,
  CMS_PRIVACY_POLICY_TYPE,
  LOAD_CMS_PRIVACY_TYPE,
  UPDATE_CMS_PRIVACY_PAGE_TYPE,
  UPDATE_CMS_ABOUT_PAGE_TYPE,
  LOAD_CMS_ABOUT_TYPE,
  CMS_ABOUT_TYPE,
} from "constants/actions";
export default function AboutCMS() {

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [dealerId, setDealerId] = React.useState();
  const { id, slug } = useParams();
  const [editSubject, setEditSubject] = React.useState("");
  const [editMiddleContent, setEditMiddleContent] = React.useState("");
  const [editBottomContent, setEditBottomContent] = React.useState("");
  const notify = () => toast.success(`Data Updated successfully`);
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_CMS_ABOUT_TYPE, payload: id });
  }, [dispatch, id]);

  const { get_auth, get_about } = useSelector(
    (state) => ({
       get_about: state.about.about,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_about) {
      setEditSubject(get_about["results"]?.[0]?.subjectContent);
      setEditMiddleContent(get_about["results"]?.[0]?.middleContent);
      setEditBottomContent(get_about["results"]?.[0]?.bottomContent);
    } 
  }, [get_about]);


  const BtnCallBackAboutPage = (data) => {
    dispatch({
      type: UPDATE_CMS_ABOUT_PAGE_TYPE,
      payload: {
        id: get_about["results"]?.[0]?.id,
        subjectContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackAboutMiddle = (data) => {
    dispatch({
      type: UPDATE_CMS_ABOUT_PAGE_TYPE,
      payload: {
        id: get_about["results"]?.[0]?.id,
        middleContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };
  const BtnCallBackAboutBottom = (data) => {
    dispatch({
      type: UPDATE_CMS_ABOUT_PAGE_TYPE,
      payload: {
        id: get_about["results"]?.[0]?.id,
        bottomContent: data,
        company: dealerId ? dealerId : get_auth.dealer_id,
      },
    });
    notify()
  };

  //   const onSubmit = (data) => {
  //   dispatch({
  //     type: CMS_ABOUT_TYPE,
  //     payload: {
  //       subjectContent: data.subjectContent,
  //       middleContent: data.middleContent,
  //       bottomContent: data.bottomContent,
  //       company: dealerId ? dealerId : get_auth.dealer_id,
  //     },
  //   });
  //   notify()
  // };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardHeader>
                    <CardTitle tag="h4">About Page CMS</CardTitle>
                    {/* <h5 className="card-category">My Tasks</h5> */}
                  </CardHeader>
                </Col>
              </Row>

              {/* Create Api for future use */}
              {/* <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>Subject And Content</label>
                  <Input
                    type="text"
                    name="subjectContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Middle Content</label>
                  <Input
                    type="text"
                    name="middleContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Bottom Content</label>
                  <Input
                    type="text"
                    name="bottomContent"
                    innerRef={register({
                      required: "Please enter First Name",
                    })}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </Form> */}

              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Subject & Content"
                     editContent={editSubject}
                     BtnCallBack={BtnCallBackAboutPage}
                  />
                </Col>
              </Row>

              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Middle Content"
                     editContent={editMiddleContent}
                     BtnCallBack={BtnCallBackAboutMiddle}
                  />
                </Col>
              </Row>

              <Row className="pt-1">
                <Col md="12">
                  <EditorInLineConvertToHTML
                    heading="Bottom Content"
                     editContent={editBottomContent}
                     BtnCallBack={BtnCallBackAboutBottom}
                  />
                </Col>
              </Row>
             
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
