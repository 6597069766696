import {
  GET_INVENTORIES,
  GET_VALID_INVENTORIES,
  UPDATED_INVENTORY_PRICES,
  FETCH_INVENTORY,
  GET_INVENTORYLIST,
  ADD_INVENTORY,
  RESET_ADD_INVENTORY,
  CLEAR_ALL,
  EDIT_INVENTORY,
  PUT_INVENTORY,
  RESET_PUT_INVENTORY,
  DELETE_INVENTORY,
  GET_INVENTORIES_PENDING,
  GET_SELECT_INVENTORIES,
  GET_INVENTORY_LIST,
  GET_GSHEET_INVENTORY_LIST,
  GET_COMPARED_INVENTORY_LIST,
} from "constants/actions";

const initialState = {
  inventory: [],
  inventoryList: [],
  valid_inventory: [],
  select_inventory: [],
  updated_prices: [],
  compared: [],
  gsheet: [],
  created: [],
  updated: false,
  loadinginventory: false,
  pending: false,
  flash: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORIES_PENDING:
      return {
        ...state,
        pending: true,
        loadinginventory: true,
      };
    case GET_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case GET_INVENTORY_LIST:
      return {
        ...state,
        inventoryList: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case GET_GSHEET_INVENTORY_LIST:
      return {
        ...state,
        gsheet: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case GET_COMPARED_INVENTORY_LIST:
      return {
        ...state,
        compared: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case PUT_INVENTORY:
      return {
        ...state,
        updated: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case RESET_PUT_INVENTORY:
      return {
        ...state,
        updated: false,
        loadinginventory: false,
        pending: false,
      };

    case GET_VALID_INVENTORIES:
      return {
        ...state,
        valid_inventory: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case GET_SELECT_INVENTORIES:
      return {
        ...state,
        select_inventory: action.payload,
        loadinginventory: false,
        pending: false,
      };
    case GET_INVENTORYLIST:
      return {
        ...state,
        inventorylist: action.payload,
      };
    case FETCH_INVENTORY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadinginventory: false,
      };

    case EDIT_INVENTORY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        loadinginventory: false,
      };


    
    case UPDATED_INVENTORY_PRICES:
        return {
          ...state,
          updated_prices: action.payload? [...state.updated_prices, action.payload]: [...state.updated_prices],
          loadinginventory: false,
        };
    case DELETE_INVENTORY:
      return {
        ...state,
        inventories: state.inventory.filter(
          (Inventory) => Inventory.id !== action.payload
        ),
      };
    case ADD_INVENTORY:
      return {
        ...state,
        created: [action.payload],
        loadinginventory: false,
      };
    case RESET_ADD_INVENTORY:
      return {
        ...state,
        inventory: [],
        inventoryList: [],
        valid_inventory: [],
        compared: [],
        select_inventory: [],
        created: [],
        updated: false,
        loadinginventory: false,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        inventory: [],
        inventoryList: [],
        valid_inventory: [],
        select_inventory: [],
        created: [],
        updated: false,
        loadinginventory: false,
        pending: false,
      };
    default:
      return state;
  }
}

// import _ from 'lodash'

// export default (state = {}, action) => {
//     switch (action.type) {
//         case GET_INVENTORIES:
//             // console.log(`Reducer: ${JSON.stringify(state)}`)
//             return { ...state, ..._.mapKeys(action.payload, 'id') }
//         case FETCH_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case ADD_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case EDIT_INVENTORY:
//             return { ...state, [action.payload.id]: action.payload };
//         case CLEAR_ALL:
//             return { ...state, inventories: [] };
//         case DELETE_INVENTORY:
//             // payload is the id for delete
//             return _.omit(state, action.payload)
//         default:
//             return state;
//     }
// }
