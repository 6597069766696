import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";

const StatGraphs = ({ data, area, title }) => {
  const theme = useTheme();
  return (
    <Card style={{ width: "100%", padding: 0 }}>
      <CardHeader>
        <Row>
          <Col>
            <Box
              component={"h3"}
              sx={{
                margin: 0,
                fontWeight: "bold",
                color: "rgb(17, 24, 39)",
                fontWeight: 600,
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              // className="numbers pull-left"
            >
              {title}
            </Box>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <ResponsiveContainer width="100%" height={260}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 0, left: -25, bottom: 10 }}
          >
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              padding={{ left: 20, right: 20 }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              padding={{ left: 5, right: 5 }}
            />
            <Tooltip
              labelStyle={{ color: "black" }}
              contentStyle={{
                borderRadius: 12,
                borderColor: "#31354188",
                background: "#FFFFFFCA",
              }}
            />
            <CartesianGrid stroke="#eee" horizontal={true} vertical={false} />
            <Line
              type={area.type}
              dataKey={area.dataKey}
              stroke={area.stroke}
              dot={false}
              strokeWidth={2}
              activeDot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

export default StatGraphs;

StatGraphs.defaultProps = {
  data: [],
};

StatGraphs.propTypes = {
  data: PropTypes.array,
};
