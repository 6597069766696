import React, { useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Container,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

import { CREATE_TRADE_IN, LOAD_BLACKBOOK_VALUES } from "constants/actions";
import { Button as MUIButton } from "@mui/material";

const BlackbookList = (props) => {
  const dispatch = useDispatch();

  const [blackbookVehTrim, setBlackbookVehTrim] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const {
    register,
    handleSubmit,
    // watch,
    errors,
    setValue,
    control,
  } = useForm();

  const { get_blackbook, set_loading } = useSelector(
    (state) => ({
      set_loading: state.blackbook.pending,
      get_blackbook: state.blackbook.blackbook,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_blackbook?.results?.autocomplete) {
      setBlackbookVehTrim(get_blackbook.results.autocomplete);
    }

    setLoading(set_loading);
  }, [get_blackbook, set_loading]);

  const onSubmit = (data) => {
    // console.log(data);
    dispatch({
      type: CREATE_TRADE_IN,
      payload: {
        black_book_uvc: data.vehicle_trim.uvc,
        kilometers: data.kilometers,
      },
    });
    props.setUpdatePage(Math.random());
    // MakeContactVisible(2);
    // dispatch({type: LOGIN_REQUEST, payload: {email: data.email, password: data.password, dealer_token: keyConfig.portalkey} })
  };

  const handleTrimInputChange = (newValue) => {
    dispatch({
      type: LOAD_BLACKBOOK_VALUES,
      payload: { params: newValue },
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <p>Year Make Model Trim</p>
          <Controller
            as={<Select />}
            getOptionLabel={(option) => `${option.description}`}
            getOptionValue={(option) => `${option.uvc}`}
            options={blackbookVehTrim}
            isLoading={loading}
            placeholder="Year Make Model Trim"
            name="vehicle_trim"
            className="text-dark"
            control={control}
            onInputChange={handleTrimInputChange}
          />

          <p className="text-danger small">
            {errors.vehicle_trim && <span>{errors.vehicle_trim.message}</span>}
          </p>
        </FormGroup>

        <FormGroup>
          <p>
            Kilometers <span className="icon-danger">*</span>
          </p>
          <Input
            className="border-input"
            name="kilometers"
            placeholder="enter the kilometers..."
            type="text"
            innerRef={register({
              required: "Please enter odometer mileage",
            })}
          />
          <p className="text-danger small">
            {errors.kilometers && <span>{errors.kilometers.message}</span>}
          </p>
        </FormGroup>

        <div className="d-flex justify-content-end">
          <div className="p-2"></div>
          <div className="p-2">
            <MUIButton color="preAuto" variant="contained" type="submit">
              Process
            </MUIButton>
          </div>
        </div>
      </Form>
    </>
  );
};

export default BlackbookList;
