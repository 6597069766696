import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import {
  Row,
  Col,
  Spinner,
  Fade,
  UncontrolledAlert,
  Badge,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import Select from "react-select";

import SalesCardStats from "Dashboard/Reports/SalesCardStats";
import DayList from "Dashboard/Reports/DayList";
import TotalLeads from "Dashboard/Reports/TotalLeads";
import TotalVisits from "Dashboard/Reports/TotalVisits";
import TotalCustomers from "Dashboard/Reports/TotalCustomers";
import WebsiteVisits from "Dashboard/Reports/WebsiteVisits";
import StackedAreaChart from "../../crema/recharts/Area/Components/StackedAreaChart";
import ComposedChartWithAxisLabels from "../../crema/recharts/Composed/Components/ComposedChartWithAxisLabels";
import { LOAD_LEAD_REPORTS } from "constants/actions";
// import { date } from "faker";
import { searchOptions, WebLabelColors } from "constants/dashboardinfo";
import StickyHeadTable from "../../mui/tables/StickyHeadTable";
import { Box, Typography } from "@mui/material";
import StatGraphs from "../../crema/recharts/StatGraphs/StatGraphs";
import ColumnGroupingTable from "mui/tables/ColumnGroupingTable";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
const SalesReports = () => {
  const dispatch = useDispatch();
  const [pageparams, setPageparams] = React.useState(null);
  const [pending, setPending] = React.useState();
  const [reports, setReports] = React.useState([]);
  const [totals, setTotals] = React.useState([]);
  const [carViews, setCarViews] = React.useState([]);

  const [daysSelected, setDaysSelected] = React.useState({
    value: 7,
    label: "Last 7 days",
  });

  const [dateLabel, setDateLabel] = React.useState([]);
  const [customerLabel, setCustomerLabel] = React.useState([]);
  const [leadLabel, setLeadLabel] = React.useState([]);
  const [webVisits, setWebVisits] = React.useState([]);
  const [stackedData, setStackedData] = React.useState([]);
  const [leadSourceData, setLeadSourceData] = React.useState([]);
  const [leadStageData, setLeadStageData] = React.useState([]);
  const [leadTypeData, setLeadTypeData] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_LEAD_REPORTS, payload: { params: pageparams } });
  }, [dispatch, pageparams]);

  const { get_reports, get_pending, get_car_views, get_lead_rotation } =
    useSelector(
      (state) => ({
        get_reports: state.reports.lead_reports,
        get_pending: state.reports.pending,
        get_car_views: state.reports.car_views,
        get_lead_rotation: state.reports.lead_reports.lead_rotation,
      }),
      shallowEqual
    );

  const ChangeDaySelected = (e) => {
    setDaysSelected(e);
    setPageparams(`days=${e.value}`);
  };

  React.useEffect(() => {
    setPending(get_pending);
  }, [get_pending]);

  React.useEffect(() => {
    let datesArray = [];

    if (get_reports) {
      setReports(get_reports.results);
      setTotals(get_reports.totals);

      if (get_reports?.results) {
        let customers = [];
        let leads = [];
        let web_visits = [];

        get_reports.results.map((p) => {
          datesArray.push(p.date);
          customers.push(p.customer);
          leads.push(p.leads);
          web_visits.push(p.webvisits_all);
        });

        setDateLabel(datesArray.reverse());
        setCustomerLabel(customers.reverse());
        setLeadLabel(leads.reverse());
        setWebVisits(web_visits.reverse());
      }
    }

    let complete_array = [];
    if (get_reports?.totals?.webvisits_name) {
      get_reports.totals.webvisits_name.map((p, i) => {
        let temp_data_set = [];
        get_reports.results.map((m) => {
          temp_data_set.push(
            m.webvisits_split.filter(
              (wv) => wv.utm_source === p.utm_source
            )?.[0]
              ? m.webvisits_split.filter(
                  (wv) => wv.utm_source === p.utm_source
                )[0]?.id__count
              : 0
          );
        });
        complete_array.push({
          datasetKeyProvider: i,
          label: p.utm_source,
          data: temp_data_set.reverse(),
          backgroundColor: WebLabelColors.filter(
            ({ value }) => value === p.utm_source
          )[0]?.color,
        });
      });
    }

    setStackedData({
      labels: datesArray,
      datasets: complete_array,
    });

    let leadsource_array = [];
    if (get_reports?.totals?.leadsource_name) {
      get_reports.totals.leadsource_name.map((p, i) => {
        let temp_data_set = [];
        get_reports.results.map((m) => {
          temp_data_set.push(
            m.websource_split.filter(
              (wv) => wv.web_lead_source__name === p.web_lead_source__name
            )?.[0]
              ? m.websource_split.filter(
                  (wv) => wv.web_lead_source__name === p.web_lead_source__name
                )[0]?.id__count
              : 0
          );
        });
        leadsource_array.push({
          datasetKeyProvider: i,
          label: p.web_lead_source__name,
          data: temp_data_set.reverse(),
          backgroundColor: WebLabelColors.filter(
            ({ value }) => value === p.web_lead_source__name
          )[0]?.color,
        });
      });
    }

    setLeadSourceData({
      labels: datesArray,
      datasets: leadsource_array,
    });

    let leadstage_array = [];
    if (get_reports?.totals?.leadstage_name) {
      get_reports.totals.leadstage_name.map((p, i) => {
        let temp_data_set = [];
        get_reports.results.map((m) => {
          temp_data_set.push(
            m.webstages_split.filter(
              (wv) => wv.web_lead_stage__name === p.web_lead_stage__name
            )?.[0]
              ? m.webstages_split.filter(
                  (wv) => wv.web_lead_stage__name === p.web_lead_stage__name
                )[0]?.id__count
              : 0
          );
        });

        leadstage_array.push({
          datasetKeyProvider: i,
          label: p.web_lead_stage__name,
          data: temp_data_set.reverse(),
          backgroundColor: WebLabelColors.filter(
            ({ value }) => value === p.web_lead_stage__name
          )[0]
            ? WebLabelColors.filter(
                ({ value }) => value === p.web_lead_stage__name
              )[0].color
            : "#5F9EA0",
        });
      });
    }

    setLeadStageData({
      labels: datesArray,
      datasets: leadstage_array,
    });

    let leadtype_array = [];
    if (get_reports?.totals?.leadtype_name) {
      get_reports.totals.leadtype_name.map((p, i) => {
        let temp_data_set = [];
        get_reports.results.map((m) => {
          temp_data_set.push(
            m.weblead_type.filter(
              (wv) => wv.web_lead_type__name === p.web_lead_type__name
            )?.[0]
              ? m.weblead_type.filter(
                  (wv) => wv.web_lead_type__name === p.web_lead_type__name
                )[0]?.id__count
              : 0
          );
        });

        leadtype_array.push({
          datasetKeyProvider: i,
          label: p.web_lead_type__name,
          data: temp_data_set.reverse(),
          backgroundColor: WebLabelColors.filter(
            ({ value }) => value === p.web_lead_type__name
          )[0]
            ? WebLabelColors.filter(
                ({ value }) => value === p.web_lead_type__name
              )[0].color
            : "#5F9EA0",
        });
      });
    }

    setLeadTypeData({
      labels: datesArray,
      datasets: leadtype_array,
    });
  }, [get_reports]);

  return (
    <>
      <div className={pending ? "" : "d-none mt-3"}>
        <Fade in={pending} tag="h5">
          <div className="d-flex justify-content-center">
            <Spinner>Loading...</Spinner>
          </div>
        </Fade>
      </div>
      <div className={pending ? "d-none mt-3" : ""}>
        <Row className="mb-4">
          <Col md="3">
            <Select
              name="search"
              options={searchOptions}
              className="text-dark"
              value={daysSelected}
              onChange={ChangeDaySelected}
            />
          </Col>
        </Row>
        {/* <BarChart /> */}
        <SalesCardStats
          totals={totals}
          days={daysSelected.value}
          get_lead_rotation={get_lead_rotation}
        />
        <Row>
          <Col md="4" sm="12">
            <StatGraphs
              data={dateLabel.map((key, index) => {
                return {
                  name: key,
                  // ["Total Customers"]: Math.random() * 100,
                  ["Total Customers"]: customerLabel[index],
                };
              })}
              area={{
                type: "monotone",
                dataKey: "Total Customers",
                stackId: "1",
                fill: "#6bd69c",
                stroke: "#6bd69c",
              }}
              title={"Total Customers"}
            />

            {/* <TotalCustomers
              dateLabel={dateLabel}
              customerLabel={customerLabel}
            /> */}
          </Col>
          <Col md="4" sm="12">
            <StatGraphs
              data={dateLabel.map((key, index) => {
                return {
                  name: key,
                  // ["Total Customers"]: Math.random() * 100,
                  ["Total Leads"]: leadLabel[index],
                };
              })}
              area={{
                type: "monotone",
                dataKey: "Total Leads",
                stackId: "1",
                fill: "#f992a4",
                stroke: "#f992a4",
              }}
              title={"Total Leads"}
            />

            {/* <TotalLeads dateLabel={dateLabel} leadLabel={leadLabel} /> */}
          </Col>

          <Col md="4" sm="12">
            <StatGraphs
              data={dateLabel.map((key, index) => {
                return {
                  name: key,
                  // ["Total Customers"]: Math.random() * 100,
                  ["Web Visits"]: webVisits[index],
                };
              })}
              area={{
                type: "monotone",
                dataKey: "Web Visits",
                stackId: "1",
                fill: "#7abdff",
                stroke: "#7abdff",
              }}
              title={"Web Visits"}
            />
            {/* <TotalVisits dateLabel={dateLabel} leadLabel={webVisits} /> */}
          </Col>
        </Row>

        <StatGraphs
          data={dateLabel.map((key, index) => {
            return {
              name: key,
              // ["Lead Source Splits"]: Math.random() * 100,
              ["Lead Source Splits"]: leadSourceData["datasets"][index]
                ? leadSourceData["datasets"][index]["data"]
                : 0,
            };
          })}
          area={{
            type: "monotone",
            dataKey: "Lead Source Splits",
            stackId: "1",
            fill: "#2997ff",
            stroke: "#2997ff",
          }}
          title={"Lead Source Splits"}
        />

        <StatGraphs
          data={dateLabel.map((key, index) => {
            return {
              name: key,
              // ["Web Traffic Source"]: Math.random() * 100,
              ["Web Traffic Source"]: stackedData["datasets"][index]
                ? stackedData["datasets"][index]["data"]
                : 0,
            };
          })}
          area={{
            type: "monotone",
            dataKey: "Web Traffic Source",
            stackId: "1",
            fill: "#2997ff",
            stroke: "#2997ff",
          }}
          title={"Web Traffic Source"}
        />

        <StatGraphs
          data={dateLabel.map((key, index) => {
            return {
              name: key,
              // ["Lead Type Split"]: Math.random() * 100,
              ["Lead Type Split"]: leadTypeData["datasets"][index]
                ? leadTypeData["datasets"][index]["data"]
                : 0,
            };
          })}
          area={{
            type: "monotone",
            dataKey: "Lead Type Split",
            barSize: 20,
            stackId: "1",
            fill: "#2997ff",
            stroke: "#2997ff",
          }}
          title={"Lead Type Split"}
        />

        <StatGraphs
          data={dateLabel.map((key, index) => {
            return {
              name: key,
              // ["Lead Stage Split"]: Math.random() * 100,
              ["Lead Stage Split"]: leadStageData["datasets"][index]
                ? leadStageData["datasets"][index]["data"]
                : 0,
            };
          })}
          area={{
            type: "monotone",
            dataKey: "Lead Stage Split",
            barSize: 20,
            stackId: "1",
            fill: "#2997ff",
            stroke: "#2997ff",
          }}
          title={"Lead Stage Split"}
        />
        {/* <WebsiteVisits
          stackedData={leadSourceData}
          header={"Lead Source Split"}
        />
        <WebsiteVisits
          stackedData={stackedData}
          header={"Web Traffic Source"}
        />
        <WebsiteVisits stackedData={leadTypeData} header={"Lead Type Split"} />
        <WebsiteVisits
          stackedData={leadStageData}
          header={"Lead Stage Split"}
        /> */}

        <Row style={{ margin: "5px" }}>
          <Card style={{ width: "100%" }}>
            <CardHeader>
              <Row>
                <Col>
                  <Box
                    component={"h3"}
                    sx={{
                      margin: 0,
                      fontWeight: "bold",
                      color: "rgb(17, 24, 39)",
                      fontWeight: 600,
                      fontSize: 16,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    // className="numbers pull-left"
                  >
                    Daily Report
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Box
                    component={"h4"}
                    sx={{
                      margin: 0,
                      fontWeight: "bold",
                      color: "rgb(17, 24, 39)",
                      fontWeight: 500,
                      fontSize: 14,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                    // className="numbers pull-left"
                  >
                    Date wise Numbers
                  </Box>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ColumnGroupingTable data={reports} />
              {/* <StickyHeadTable
                columns={[
                  { id: "date", label: "Date", minWidth: 170 },
                  { id: "leads", label: "Leads", minWidth: 100 },
                  { id: "customer", label: "Customers", minWidth: 100 },
                  { id: "sold", label: "Sold", minWidth: 100 },
                  { id: "sold_value", label: "Value", minWidth: 100 },
                ]}
                data={reports}
              /> */}
            </CardBody>
          </Card>
        </Row>
        {/* <Row>
          <Col md="6" sm="12">
            <LeadType
              dateLabel={dateLabel}
              customerLabel={customerLabel}
            />
          </Col>
          <Col md="6" sm="12">
            <LeadSource dateLabel={dateLabel} leadLabel={leadLabel} />
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default SalesReports;
