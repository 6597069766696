import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {

    return (
      <footer
        className={"footer" + (props.default ? " footer-default" : "")}
      >
        <Container fluid={props.fluid ? true : false}>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a 
                  href="https://preautoco.com"
                  rel="noopener noreferrer">PreAutoCo</a>
                </li>
               
                <li>
                  <a
                    href="https://preautoco.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()}, Powered {" "}
                <i className="fa fa-heart heart" /> by PreAutoCo
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
