import {
    LEAD_REPORTS_PENDING,
    GET_LEAD_REPORTS, 
    CLEAR_ALL } from "constants/actions";
const initialState = {
    lead_reports: [],
    pending: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LEAD_REPORTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_LEAD_REPORTS:
            return {
                ...state,
                lead_reports: action.payload,
                pending: false,
            };
        
        case CLEAR_ALL:
            return {
                ...state,
                lead_reports: [],
                selected: false,
                pending: false,
            };
        default:
            return state;
    }
}
