import React from "react";
import { Button } from "@mui/material";
import AppGridContainer from "../../../../crema/AppGridContainer";
import Grid from "@mui/material/Grid";
import IntlMessages from "../../../../crema/helpers/src/IntlMessages";
import Box from "@mui/material/Box";
import ProfileConnection from "./ProfileConnection";
import { Form } from "formik";
import PropTypes from "prop-types";
import AppTextField from "../../../../crema/AppFormComponents/AppTextField";

const SocialForm = ({ social }) => {
  console.log({ social });
  return (
    <AppGridContainer spacing={4}>
      <Grid item xs={12}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <ProfileConnection profileConnection={social} />
        </Box>
      </Grid>
    </AppGridContainer>
  );
};

export default SocialForm;

SocialForm.propTypes = {
  social: PropTypes.array,
};
