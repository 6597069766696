import { GET_CARIMAGES, DELETE_CARIMAGE, ADD_CARIMAGE, FETCH_CARIMAGE, EDIT_CARIMAGE,  CLEAR_ALL } from "constants/actions";

const initialState = {
    carimages: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CARIMAGES:
            return {
                ...state,
                carimages: action.payload
            };
        case FETCH_CARIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_CARIMAGE:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_CARIMAGE:
            
            return {
                ...state,
                carimages: state.carimages.filter(car => car.id !== action.payload),
            };
        case ADD_CARIMAGE:
            return {
                ...state,
                carimages: [...state.carimages, action.payload]
            };
        case CLEAR_ALL:
            return {
                ...state,
                carimages: []
            };
        default:
            return state;
    }
}
