import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import * as jsonexport from "jsonexport/dist";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_PRODUCTS,
  LOAD_PRODUCT_CONTRACTS,
  REMOVE_PRODUCT_CONTRACT,
} from "constants/actions";

import CreateSubscription from "./CreateSubscription";
import CreateEdit from "./CreateEdit";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile, deviceType
// } from "react-device-detect";
import "./style.css";
// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";

const Product = (props) => {
  const dispatch = useDispatch();
  const [productList, setProductList] = React.useState([]);
  const [productContract, setProductContract] = React.useState([]);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [productId, setProductId] = React.useState(false);
  const [contractId, setContractId] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);

  const [newProduct, setNewProduct] = React.useState(false);

  const toggle = () => setModal(!modal);

  React.useEffect(() => {
    dispatch({ type: LOAD_PRODUCTS });
    if (props.company_id) {
      dispatch({
        type: LOAD_PRODUCT_CONTRACTS,
        payload: { params: `company=${props.company_id}` },
      });
    }

  }, [dispatch, updated]);

  const { get_product_list, get_product_contract, get_auth } = useSelector(
    (state) => ({
      get_product_list: state.product.products,
      get_product_contract: state.productcontract.productcontracts,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_product_list) {
      setProductList(get_product_list);
    }
  }, [get_product_list, updated]);

  React.useEffect(() => {
    if (get_product_contract) {
      setProductContract(get_product_contract);
    }
  }, [get_product_contract, updated]);

  const SelectedContract = (id, contractId) => {
    setProductId(id);
    setContractId(contractId);
    toggle();
  };

  const SelectedProduct = (id) => {
    setProductId(id);
    setColWidth([4, 8]);
  };

  const resetColWidth = (updated) => {
    // setShowVinDecode(false);
    setUpdated(updated);
    setColWidth([12, 0]);
  };
  const RemoveContract = (id) => {
    dispatch({ type: REMOVE_PRODUCT_CONTRACT, payload: {id:id,companyId:props.company_id} });
    dispatch({
      type: LOAD_PRODUCT_CONTRACTS,
      payload: { params: `company=${props.company_id}` },
    });
    dispatch({ type: LOAD_PRODUCTS });
  };
  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Product Information</CardTitle>
                <h5 className="card-category">
                  Details about subscribed Product
                </h5>
              </Col>
              <Col className="ml-auto" md="2">
                <Button
                  className="small-button"
                  size="sm"
                  onClick={() => SelectedProduct(null)}
                  color="success"
                >
                  New Product
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {productList.length > 0 ? (
              <Row>
                <Col md={colWidth[0]}>
                  <Table bordered striped responsive>
                    <thead>
                      <tr>
                        <th>Sort</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th className="text-right">Updated</th>
                        {props.company_id ? <th>Your Price</th> : null}
                        {props.company_id ? <th>Subscribed</th> : null}
                        {props.company_id ? null : <th>Edit </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {productList.map((p, k) => (
                        <tr key={k}>
                          <td>{p.sort_order}</td>
                          <td>{p.product_no}</td>
                          <td>
                            <small>{p.group}</small>
                            <br />
                            {p.name}
                          </td>
                          <td className="text-right">
                            CA${p.price}/<br />
                            <small>{p.bill_cycle}</small>
                          </td>
                          <td>{p.status}</td>
                          <td className="text-right">
                            {moment(p.updated_at).format("YYYY-MM-DD")}
                          </td>
                          {props.company_id ? (
                            <td className="text-right">
                              {productContract.filter(
                                (fl) => fl.product === p.id
                              )[0]
                                ? `CA$${productContract.filter(
                                  (fl) => fl.product === p.id
                                )[0].price
                                }`
                                : ""}
                            </td>
                          ) : null}

                          {props.company_id ? (
                            <td className="text-center">
                              {productContract.filter(
                                (fl) => fl.product === p.id
                              )[0] ? (
                                <>
                                  <Button
                                    className="btn small-button"
                                    size="sm"
                                    onClick={() =>
                                      SelectedContract(
                                        p.id,
                                        productContract.filter(
                                          (fl) => fl.product === p.id
                                        )[0]
                                      )
                                    }
                                    color="info"
                                  >
                                    Edit
                                  </Button>
                                  <hr />
                                  <Button
                                    className="btn small-button"
                                    size="sm"
                                    onClick={() =>
                                      RemoveContract(
                                        productContract.filter(
                                          (fl) => fl.product === p.id
                                        )[0].id
                                      )
                                    }
                                    color="danger"
                                  >
                                    Remove
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  className="btn"
                                  size="sm"
                                  onClick={() => SelectedContract(p.id)}
                                  color="success"
                                >
                                  Add
                                </Button>
                              )}
                            </td>
                          ) : null}
                          {!props.company_id ? (
                            <td>
                              {/* {console.log(p.id)} */}
                              <Button
                                className="small-button"
                                size="sm"
                                onClick={() => SelectedProduct(p.id)}
                                color="warning"
                              >
                                Edit
                              </Button>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>
                  <CreateEdit id={productId} resetColWidth={resetColWidth} />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </CardBody>
        </Card>

        <Modal isOpen={modal} toggle={toggle} keyboard={true}>
          <ModalHeader toggle={toggle}>
            {productId ? "Edit " : "Create "}Subscription
          </ModalHeader>
          <ModalBody>
            <CreateSubscription
              id={productId}
              toggle={toggle}
              contractId={contractId}
              company_id={props.company_id}
              resetColWidth={resetColWidth}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Product;
