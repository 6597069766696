import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  LOAD_WEB_LEAD_REPORT,
  GET_WEB_LEAD_REPORT,
  LOAD_WEB_LEAD_LIST,
  GET_WEB_LEAD_LIST,
  LOAD_WEB_LEAD,
  GET_WEB_LEADS,
  GET_WEB_LEADS_PENDING,
  DELETE_WEB_LEAD,
  SG_DELETE_WEB_LEAD,
  ADD_WEB_LEAD,
  SG_ADD_WEB_LEAD,
  FETCH_WEB_LEAD_PENDING,
  FETCH_WEB_LEAD,
  SG_FETCH_WEB_LEAD,
  UPDATE_WEB_LEAD,
  EDIT_WEB_LEAD,
  GET_CUSTOMER_INVENTORY,
  SG_GET_CUSTOMER_INVENTORY,
  GET_INVENTORY_CUSTOMER,
  SG_GET_INVENTORY_CUSTOMER,
  ALL_ERRORS,
} from "constants/actions";

function* loadWebLeadReport(action) {
  try {
    const json = yield call(api.GET_WEB_LEADS, action.payload);
    yield put({ type: GET_WEB_LEAD_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadLoadReport() {
  yield takeLatest(LOAD_WEB_LEAD_REPORT, loadWebLeadReport);
}

function* loadInventoryCustomer(action) {
  try {
    const json = yield call(api.GET_INVENTORY_CUSTOMER, action.payload);
    yield put({ type: GET_INVENTORY_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* inventoryCustomerLoad() {
  yield takeLatest(SG_GET_INVENTORY_CUSTOMER, loadInventoryCustomer);
}

function* loadCustomerInventory(action) {
  try {
    const json = yield call(api.GET_CUSTOMER_INVENTORY, action.payload);
    yield put({ type: GET_CUSTOMER_INVENTORY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* customerInventoryLoad() {
  yield takeLatest(SG_GET_CUSTOMER_INVENTORY, loadCustomerInventory);
}


function* loadWebLeadList(action) {
  try {
    const json = yield call(api.GET_WEBLEAD_LIST, action.payload);
    yield put({ type: GET_WEB_LEAD_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadLoadList() {
  yield takeLatest(LOAD_WEB_LEAD_LIST, loadWebLeadList);
}

function* loadWebLead(action) {
  yield put({ type: GET_WEB_LEADS_PENDING });
  try {
    const json = yield call(api.GET_WEB_LEADS, action.payload);

    yield put({ type: GET_WEB_LEADS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadLoad() {
  yield takeLatest(LOAD_WEB_LEAD, loadWebLead);
}

function* fetchWeblead(action) {
  yield put({ type: FETCH_WEB_LEAD_PENDING });
  try {
    const json = yield call(api.FETCH_WEB_LEAD, action.payload);

    yield put({ type: FETCH_WEB_LEAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadFetchOne() {
  yield takeLatest(SG_FETCH_WEB_LEAD, fetchWeblead);
}

function* editWebLead(action) {
  yield put({ type: FETCH_WEB_LEAD_PENDING });
  try {
    const json = yield call(api.EDIT_WEB_LEAD, action.payload.id, action.payload);

    yield put({ type: EDIT_WEB_LEAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e });
  }
}

export function* webleadEdit() {
  yield takeLatest(UPDATE_WEB_LEAD, editWebLead);
}

function* addWebLead(action) {
  // console.log(action)
  try {
    const json = yield call(api.ADD_WEB_LEAD, action.payload);

    yield put({ type: ADD_WEB_LEAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* webleadAdd() {
  yield takeLatest(SG_ADD_WEB_LEAD, addWebLead);
}

export default function* index() {
  yield all([
    webleadLoadReport(),
    customerInventoryLoad(),
    inventoryCustomerLoad(),
    webleadLoadList(),
    webleadLoad(),
    webleadFetchOne(),
    webleadEdit(),
    webleadAdd(),
  ]);
}
