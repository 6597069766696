import React from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Row, Col } from "reactstrap";
import Select from "react-select";
import { LOAD_ALL_EMPLOYEES } from "constants/actions";

const EmployeeList = (props) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const [selEmployee, setSelEmployee] = React.useState([]);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelEmployee(get_employees);
  }, [get_employees]);

  return (
    <>
      <Row>
        <Col>
          <label>Assign To (working on this)</label>
          <Select
            name="employee"
            options={selEmployee}
            defaultValue={14}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name} - ${option.role_text}`
            }
            getOptionValue={(option) => `${option.id}`}
            className="text-dark"
          />
        </Col>
      </Row>
    </>
  );
};

export default EmployeeList;
