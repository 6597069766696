import { put, call, takeLatest, all } from "redux-saga/effects";
import {
    GET_EMPLOYEES,
    LOAD_ALL_EMPLOYEES,
    GET_EMPLOYEES_PENDING,
    DELETE_EMPLOYEE,
    ADD_EMPLOYEE,
    CREATE_EMPLOYEE,
    UPDATE_EMPLOYEE,
    CLEAR_EMPLOYEE,
    FETCH_EMPLOYEE,
    SELECTED_EMPLOYEE,
    SG_SELECT_EMPLOYEE,
    FETCH_EMPLOYEE_PENDING,
    SG_FETCH_EMPLOYEE,
    UPLOAD_EMPLOYEE_PIC,
    ADD_EMPLOYEE_PIC,
    EDIT_EMPLOYEE,
  ALL_ERRORS
} from "constants/actions";

import * as api from "constants/api";



function* loadEmployees(action) {
  yield put({ type: GET_EMPLOYEES_PENDING });
  try {
    const json = yield call(api.GET_EMPLOYEES, action.payload)
    yield put({ type: GET_EMPLOYEES, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* employeesLoad() {
  yield takeLatest(LOAD_ALL_EMPLOYEES, loadEmployees);
}

function* fetchEmployee(action) {
  yield put({ type: FETCH_EMPLOYEE_PENDING });
  try {
   const json = yield call(api.FETCH_EMPLOYEE, action.payload)
    yield put({ type: FETCH_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}


export function* employeeFetchOne() {
  yield takeLatest(SG_FETCH_EMPLOYEE, fetchEmployee);
}


function* selectEmployee(action) {
  yield put({ type: FETCH_EMPLOYEE_PENDING });

  try {
   const json = yield call(api.FETCH_EMPLOYEE, action.payload)
    yield put({ type: SELECTED_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}


export function* employeeSelect() {
  yield takeLatest(SG_SELECT_EMPLOYEE, selectEmployee);
}
// SELECTED_EMPLOYEE

function* createEmployee(action) {
  try {
   const json = yield call(api.ADD_EMPLOYEE, action.payload)
    yield put({ type: ADD_EMPLOYEE, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}

export function* employeeCreate() {
  yield takeLatest(CREATE_EMPLOYEE, createEmployee);
}


function* editEmployee(action) {
  try {
   const json = yield call(api.EDIT_EMPLOYEE, action.payload)
    yield put({ type: EDIT_EMPLOYEE, payload: json.data });
    yield put({ type: LOAD_ALL_EMPLOYEES, payload: `company=${action.payload.company}` });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}

export function* employeeEdit() {
  yield takeLatest(UPDATE_EMPLOYEE, editEmployee);
}

function* pictureEmployee(action) {
  try {
   const json = yield call(api.ADD_EMPLOYEE_PIC, action.payload)
    yield put({ type: ADD_EMPLOYEE_PIC, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}

export function* employeePicture() {
  yield takeLatest(UPLOAD_EMPLOYEE_PIC, pictureEmployee);
}
// 

export default function* index() {
  yield all([employeesLoad(), 
    employeeFetchOne(),
    employeeSelect(),
    employeeCreate(),
    employeeEdit(),
    employeePicture(),
    
  ]);
}
// export default inventoryLoad;
