import React from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  LOAD_CUSTOMER_LIST,
} from "constants/actions";

import {
    Button,

  } from "reactstrap";
const Download = () => {
    const dispatch = useDispatch();
  const [customerList, setCustomerList] = React.useState([]);
  const [fetch, setFetch] = React.useState(false)

  function GetList() {
    dispatch({
      type: LOAD_CUSTOMER_LIST,
      payload: {},
    });
    
  }

  const { get_customer_list, get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_customer_list: state.customer.customer_list,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_customer_list.length > 0) {
      setCustomerList(get_customer_list);
      setFetch(true)
    }
  }, [get_customer_list]);
console.log(Number(get_auth?.role_id) < 6)
  return (
    <>
    {get_auth?
        Number(get_auth?.role_id) < 6?
        
        fetch? 
         <CSVLink
        data={customerList}
        filename={"customer-list.csv"}
        className="btn btn-success"
        target="_blank"
      >
        Download Customer CSV
      </CSVLink>  : <Button onClick={GetList} className="btn btn-primary">
      Start Download
      </Button>
    : ''
    : ''
    }
    </>
  );
};

export default Download;
