import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import {
  SG_UPDATE_ACTIVE_PAGE,
  LOAD_TASK_LISTS,
  LOAD_ALL_EMPLOYEES,
} from "constants/actions";

// import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers";

import {
  CustomInput,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button as MuiButton,
} from "@mui/material";
import { SearchRounded } from "@material-ui/icons";

const Search = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [pageParams, setPageParams] = React.useState(props.params);

  const [selOpenOnly, setSelOpenOnly] = React.useState(false);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);
  const [selEmployee, setSelEmployee] = React.useState([]);

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)

    dispatch({ type: LOAD_TASK_LISTS });
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();
  const { get_auth, get_errors, get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees.employees,

      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setSelEmployee(get_employees);
  }, [get_employees]);

  // React.useEffect(() => {
  //   if (id) {
  //     if (get_web_leads[parseInt(id)]) {
  //       setValue("expected_purchase_date", moment(get_web_leads[parseInt(id)].expected_purchase_date));
  //       setValue("web_lead_stage", get_web_leads[parseInt(id)].web_lead_stage);
  //       setValue("web_lead_type", get_web_leads[parseInt(id)].web_lead_type);
  //       setValue("web_lead_source", get_web_leads[parseInt(id)].web_lead_source);
  //       setValue("inventory", get_web_leads[parseInt(id)].inventory);
  //       setValue("employee", get_web_leads[parseInt(id)].employee);

  //     }
  //   } else {
  //     setValue("expected_purchase_date", moment());
  //     setValue("employee", get_auth.employee);
  //   }
  // }, [get_web_leads]);

  const onSubmit = (data) => {
    console.log(data);
    let param_string = "";
    param_string += data.web_lead_source
      ? `&web_lead_source=${data.web_lead_source.id}`
      : "";
    param_string += data.web_lead_type
      ? `&web_lead_type=${data.web_lead_type.id}`
      : "";
    param_string += data.web_lead_stage
      ? `&web_lead_stage=${data.web_lead_stage.id}`
      : "";
    param_string += data.employee ? `&employee=${data.employee.id}` : "";
    param_string += data.startdate
      ? `&startdate=${moment(data.startdate).format("YYYY-MM-DD")}`
      : "";
    param_string += data.enddate
      ? `&enddate=${moment(data.enddate).format("YYYY-MM-DD")}`
      : "";
    props.setPageParams(param_string);
    dispatch({ type: SG_UPDATE_ACTIVE_PAGE, payload: 0 });
    dispatch({
      type: LOAD_TASK_LISTS,
      payload: {
        page: 1,
        params: param_string,
      },
    });
  };
  const sliderOnOpen = () => {
    setSelOpenOnly(!selOpenOnly);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <InputLabel>From Date</InputLabel>

            <Controller
              as={
                <DatePicker
                  sx={{
                    "& input": {
                      // Add your custom styles for the input here
                      borderRadius: "8px",
                      padding: "8px",
                    },
                  }}
                />
              }
              control={control}
              name="startdate"
              dateFormat="YYYY-MM-DD"
              closeOnSelect={true}
              inputProps={{
                className: "form-control",
                placeholder: "Select Date",
              }}
              timeFormat={false}
            />
          </Col>

          <Col>
            <InputLabel>To Date</InputLabel>

            <Controller
              as={
                <DatePicker
                  sx={{
                    "& input": {
                      // Add your custom styles for the input here
                      borderRadius: "8px",
                      padding: "8px",
                    },
                  }}
                />
              }
              control={control}
              name="enddate"
              dateFormat="YYYY-MM-DD"
              closeOnSelect={true}
              inputProps={{
                className: "form-control",
                placeholder: "Select Date",
              }}
              timeFormat={false}
            />
          </Col>
          <Col>
            {/* <label>Assigned To</label> */}
            <InputLabel id="assigned-to-select-outlined-label">
              Assign To
            </InputLabel>
            <Controller
              name="employee"
              control={control}
              as={
                <Select
                  className="text-dark"
                  labelId="assigned-to-select-outlined-label"
                  displayEmpty
                  inputProps={{
                    id: "employee",
                  }}
                  name="employee"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  sx={{
                    "& div": {
                      // Add your custom styles for the input here
                      borderRadius: "8px",
                      padding: "8px",
                    },
                  }}
                >
                  {selEmployee.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.first_name} ${option.last_name} - ${option.role_text} (${option.status_text})`}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Col>
          <Col
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          // justifyContent: "center",
          //   margin: 0,
          //   padding: 0,
          //   marginTop: "1%",
          // }}
          >
            <InputLabel>&nbsp;</InputLabel>
            {/* <Button className="btn-fill mt-4" color="primary" type="submit">
              Search
            </Button> */}
            <MuiButton
              sx={{
                position: "relative",
                minWidth: 100,
                // "&:focus": {
                //   outline: "none",
                // },
              }}
              color="preAuto"
              variant="outlined"
              type="submit"
            >
              Search
            </MuiButton>
          </Col>
        </Row>
      </Form>

      {/* <Row>
              
              <Col md="3">
              <CustomInput
            type="switch"
            id="showOpen"
            onClick={sliderOnOpen}
            checked={selOpenOnly}
            name="showOpen"
            label="Show Open Tasks"
          />
              </Col>
          </Row> */}
    </>
  );
};

export default Search;
