export const searchOptions = [
  { value: 7, label: "Last 7 days" },
  { value: 30, label: "Last 30 days" },
  { value: 90, label: "Last 90 days" },
];

export const muiSearchOptions = [
  { id: 7, name: "Last 7 days", value: 7 },
  { id: 30, name: "Last 30 days", value: 30 },
  { id: 90, name: "Last 90 days", value: 90 },
];

export const WebLabelColors = [
  { value: "Canada Drives", color: "#C71585" },
  { value: "Footpath Marketing", color: "#78468f" },
  { value: "Kijiji Leads", color: "#4B0082" },
  { value: "Loans Canada", color: "#B22222" },
  { value: "Website lead", color: "#008000" },
  { value: "Auto Trader Leads", color: "#FFD700" },
  { value: "Ava Equifax", color: "#6d81d1" },
  { value: "Carguru", color: "#b66dd1" },
  { value: "autoTRADER", color: "#32CD32" },
  { value: "kijiji", color: "#695620" },
  { value: "kijijiautos", color: "#ff00cc" },
  { value: "Stingray", color: "#008080" },
  { value: "Stingray_Programmatic", color: "#7FFFD4" },
  { value: "null", color: "#5F9EA0" },
  { value: "In Progress", color: "#6495ED" },
  { value: "New", color: "#00008B" },
  { value: "Need Co-signer", color: "#BC8F8F" },
  { value: "Processing Application", color: "#D2691E" },
  { value: "On Hold", color: "#8B4513" },
  { value: "Interested", color: "#800000" },
  { value: "Not interested at this time", color: "#696969" },
  { value: "No Contact", color: "#595759" },
  { value: "Junk - Un contactable", color: "#8B008B" },
  { value: "Contact Us", color: "#568f46" },
  { value: "Credit Application", color: "#203869" },
  { value: "Interested in Car", color: "#FF4500" },
  { value: "facebook", color: "#FF4500" },
  { value: null, color: "#DCDCDC" },
];
