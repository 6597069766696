import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from 'constants/api';

import {
  LOAD_INVENTORY_NOTES,
  GET_INVENTORY_NOTES_PENDING,
  SG_INVENTORY_LIST,
  INVENTORY_LIST,
  GET_INVENTORY_NOTES,
  GET_INVENTORY_NOTES_ERROR,
  DELETE_INVENTORY_NOTES,
  ADD_INVENTORY_NOTES,
  CLEAR_INVENTORY_NOTES,
  FETCH_INVENTORY_NOTES,
  FETCH_INVENTORY_NOTES_PENDING,
  SG_FETCH_INVENTORY_NOTES,
  UPDATE_INVENTORY_NOTES,
  EDIT_INVENTORY_NOTES, ALL_ERRORS
} from 'constants/actions';

function* UpdateInventoryNotes(action) {

  yield put({ type: FETCH_INVENTORY_NOTES_PENDING });
  try {
    const json = yield call(api.EDIT_DESCRIPTION, action.payload.id, action.payload);
    yield put({ type: EDIT_INVENTORY_NOTES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryNoteUpdate() {
  yield takeLatest(UPDATE_INVENTORY_NOTES, UpdateInventoryNotes);
}

function* getInventoryList(action) {
  yield put({ type: GET_INVENTORY_NOTES_PENDING });
  try {
    const json = yield call(api.GET_DESCRIPTION_LIST, action.payload);
    yield put({ type: INVENTORY_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryListGet() {
  yield takeLatest(SG_INVENTORY_LIST, getInventoryList);
}

function* getInvetoryNotes(action) {
  yield put({ type: GET_INVENTORY_NOTES_PENDING });
  try {
    const json = yield call(api.GET_DESCRIPTION, action.payload);
    yield put({ type: GET_INVENTORY_NOTES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryNotesGet() {
  yield takeLatest(LOAD_INVENTORY_NOTES, getInvetoryNotes);
}

function* FetchInventoryNotes(action) {
  yield put({ type: FETCH_INVENTORY_NOTES_PENDING });
  try {
    const json = yield call(api.GET_DESCRIPTION, action.payload);
    yield put({ type: FETCH_INVENTORY_NOTES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* inventoryNoteFetch() {
  yield takeLatest(SG_FETCH_INVENTORY_NOTES, FetchInventoryNotes);
}

export default function* index() {
  yield all([
    inventoryNoteUpdate(),
    inventoryListGet(),
    inventoryNotesGet(),
    inventoryNoteFetch(),
  ]);
}
