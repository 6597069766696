import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_CREDITAPPLICATIONS , SG_UPDATE_ACTIVE_PAGE} from "constants/actions";

import ReactPaginate from 'react-paginate';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile, deviceType
} from "react-device-detect";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Table,
    Row,
    Col
  } from "reactstrap";

const List = (props) => {
  const dispatch = useDispatch();

  const [application, setApplication] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
 // pagination end



  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)
    dispatch({ type: LOAD_CREDITAPPLICATIONS, payload: {page: pageactivePage, params: pageParams}});
  }, [dispatch, pageParams, pageactivePage]);

  const {
    get_application,
    get_auth,
    get_errors,
  } = useSelector(
    (state) => ({
      get_application: state.creditapplication.creditapplications,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]

  React.useEffect(() => {
    setApplication(get_application.results);
  }, [get_application]);


  // console.log("application", application)
  // console.log("Browser", isBrowser, deviceType)

React.useEffect(() => {
  // console.log('get_activePage', get_activePage, "****************************************")
 if (get_application) {
  
  setTotalResults(get_application.count)
  if (get_application.page_size)
  {
    setPerPage(get_application.page_size) 
  } 
  setTotalPages(Math.ceil(get_application.count / perPage))
 }
}, [get_application]);
  // console.log(user)
  const handleClick = (page) => {

    dispatch({type: SG_UPDATE_ACTIVE_PAGE, 
      payload: page.selected
    })
      setActivePage(page.selected);
      setPageActivePage(page.selected + 1) 
    
  }

    return (
        <>
  
          <Row>
          <Col md="12">
            <div className="d-flex justify-content-end">
                <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                // breakLabel={isMobile? '': <a className="page-link">...</a>}
                breakClassName={'page-item'}
                pageCount={totalPages}
                marginPagesDisplayed={isMobile? 0: 2}
                pageRangeDisplayed={isMobile? 0: 5}
                onPageChange={handleClick}
                containerClassName={'pagination'}
                subContainerClassName={'page-item pagination'}
                activeClassName={'page-item active'}
                pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  forcePage={activePage}
              />
          </div>
              </Col>
              <Col md="12">
              {application? 
              
              <Table responsive>
                        <thead className="table-active">
                          <tr>                      
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Residence</th>
                            <th>Employment</th>
                            <th>Credit Check</th>
                            <th className="text-right">Created</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {application.map((p,k) => 
                          <tr key={k}>                                       
                            <td>{p.first_name}</td>
                            <td>{p.last_name}</td>
                             <td>
                             {_.get(p, 'full_response.residence')?
                              "Yes": "No"}</td>
                            <td>
                            {_.get(p, 'full_response.employment')? "Yes": "No"}
                              </td>
                              <td>
                            {_.get(p, 'full_response.credit_auth')? "Yes": "No"}
                              </td> 
                            <td>{p.updated_at}</td>
                            <td>
                            <Link to={`/admin/credit-application-detail/${p.id}`}>View More</Link>
                               </td>
                          </tr>
                          )}
                        </tbody>
                      </Table>
                      : ''}
              </Col>
              <Col md="12">
            <div className="d-flex justify-content-end">
                <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                // breakLabel={isMobile? '': <a className="page-link">...</a>}
                breakClassName={'page-item'}
                pageCount={totalPages}
                marginPagesDisplayed={isMobile? 0: 2}
                pageRangeDisplayed={isMobile? 0: 5}
                onPageChange={handleClick}
                containerClassName={'pagination'}
                subContainerClassName={'page-item pagination'}
                activeClassName={'page-item active'}
                pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  forcePage={activePage}
              />
          </div>
              </Col>              
          </Row>
       
        </>
    );
};

export default List;