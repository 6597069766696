import React from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import List from 'CreditApplications/List';



export default () => {
// console.log(application_list)
  return (
    <>
      <div className="content">
      <Row>
        <Col md="12">
          <Card>
           
            <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Credit Applications</CardTitle>
                  </Col>
                  <Col className="ml-auto" md="2">
                    <Button
                      color="default"
                      size="sm"
                      // to="/admin/lead-create"
                      // tag={Link}
                    >
                      Create New
                    </Button>
                  </Col>
                </Row>
              </CardHeader>


            <CardBody>
            
              <List />
            </CardBody>
          </Card>
          </Col>
      </Row>
      </div>
    </>
  )
}
  