export const updateVinData = (data, media = [], revised_trim_id, id) => {
    let bodyType = ''
    let mediaGallery = null
    if(revised_trim_id){
        bodyType = data.vehicles.filter(items => revised_trim_id === items.styleId)[0]?.bodyType
        mediaGallery = media.filter(items => revised_trim_id === items.styleId)[0]
    }else{
        bodyType = data.vehicles?.length? data.vehicles[0]?.bodyType : ''
        mediaGallery = media?.length ? media[0] : null
    }
    console.log(mediaGallery,media,'media===');
    const objStandard = {
        'Powertrain and Mechanical': 'MECHANICAL',
        'Exterior and Appearance': 'EXTERIOR',
        'In-car Entertainment': 'ENTERTAINMENT',
        'Comfort': 'INTERIOR',
        'Safety and Security': 'SAFETY'
    }
    const VehicleDescription = {
        "@modelYear": data.year,
        "@bestMakeName": data.make,
        "@bestModelName": data.model,
        "@bodyType": bodyType,
        "responseStatus": {
            "@responseCode": "Successful",
            "@description": "Successful"
        },
        "vinDescription": {
            "@vin": data.vinSubmitted,
            "@modelYear": data.year,
            "@division": data.make,
            "@modelName": data.model,
            "@bodyType": bodyType,
            // "marketClass": {
            //     "@id": "43",
            //     "#text": "4-door Compact Passenger Car"
            // }
        },
        "style": data.vehicles?.map(items => {
            return {
                "@id": items.styleId,
                "@modelYear": data.year,
                "@name": items.styleDescription,
                "@trim": items.trim,
                "@mfrModelCode": items.mfrModelCode,
                "@passDoors": items.doors,
                "@drivetrain": "All Wheel Drive",
                "division": {
                    "#text": data.make
                },
                "subdivision": {
                    "#text": data.make
                },
                "model": {
                    "#text": data.model
                },
                "basePrice": {
                    "@invoice": "46911.0",
                    "@msrp": data.baseMSRP,
                    "@destination": data.destinationCharge
                },
                "bodyType": {
                    // "@primary": "true",
                    // "@id": "2",
                    // "#text": "4dr Car"
                },
                "marketClass": {
                    // "@id": "43",
                    // "#text": "4-door Compact Passenger Car"
                },
                "mediaGallery": {
                    "@styleId": mediaGallery !== null? mediaGallery?.styleId : null,
                    "view":mediaGallery !== null? mediaGallery?.mediaGallery['media:MediaGallery']?.view : [],
                    "colorized": mediaGallery !== null? mediaGallery?.mediaGallery['media:MediaGallery']?.colorized : []
                },
            }
        }),
        "engine": {
            "@highOutput": "false",
            "engineType": {
                "@id": "",
                "#text": ""
            },
            "fuelType": {
                "@id": "",
                "#text": ""
            },
            "horsepower": {
                "@value": "",
                "@rpm": ""
            },
            "netTorque": {
                "@value": "",
                "@rpm": ""
            },
            "cylinders": "",
            "displacement": {
                "value": {
                    "@unit": "",
                    "#text": ""
                }
            },
            "fuelEconomy": {
                "@unit": "",
                "city": {
                    "@low": "",
                    "@high": ""
                },
                "hwy": {
                    "@low": "",
                    "@high": ""
                }
            },
            "fuelCapacity": {
                "@unit": "",
                "@low": "",
                "@high": ""
            },
            "installed": {
                "@cause": ""
            }
        },
        "standard": data.features?.filter(items => items.styles?.length && items.styles[0]?.isStandard).map(items => {
            return {
                "header": {
                    "@id": items.subSectionId,
                    "#text": objStandard[items.sectionName]
                },
                "description": items.name,
                "category": [],
                "styleId": items.styles?.length && items.styles[0]?.styleIds,
                "installed": {
                    "@cause": "Engine"
                }
            }
        }),
        "genericEquipment": data.features.filter(items => items.styles?.length && !items.styles[0]?.isStandard).map(items => {
            return {
                "header": {
                    "@id": items.subSectionId,
                    "#text": objStandard[items.sectionName]
                },
                "definition": {
                    "group": {
                        "@id":items.subSectionId,
                        "#text":objStandard[items.sectionName]
                    },
                    "header": {
                        "@id": "",
                        "#text": items.name
                    },
                    "category": {
                        "@id": "",
                        "#text": items.description
                    }
                },
                "styleId": items.styles?.length && items.styles[0]?.styleIds,
                "installed": {
                    "@cause": "Engine"
                }
            }
        }),
        "consumerInformation": [],
        "technicalSpecification": data.techSpecs?.map(items => {
            return {
                "definition": {
                    // "@measurementUnit": "N/A",
                    // "group": {
                    //     "@id": "7",
                    //     "#text": "Summary"
                    // },
                    // "header": {
                    //     "@id": "16",
                    //     "#text": "Summary"
                    // },
                    "title": {
                        "#text": items.name
                    }
                },
                "value": {
                    "@value": items.description,
                    "@condition": "",
                    "styleId": items.styles?.length ? items.styles[0]?.styleIds : []
                }
            }
        }),
        "exteriorColor": data.exteriorColors?.map(items => {
            return {
                ...items,
                "@colorCode": items.colorCode,
                "@colorName": items.description,
                "@rgbValue": items.rgbHexValue?.replace("#", ""),
                "genericColor": {
                    "@name": items.genericDesc,
                    "@primary": items.primary
                },
                "styleId": items.styles
            }
        }),
        "interiorColor": data.interiorColors?.map(items => {
            return {
                "@colorCode": items.colorCode,
                "@colorName": items.description,
                "styleId": items.styles[0]
            }
        }),
        "genericColor": data.exteriorColors?.map(items => {
            return {
                ...items,
                "@name": items.genericDesc,
                "@primary": items.primary,
            }
        }),
    }
    console.log(VehicleDescription,'VehicleDescription==');
    return JSON.stringify({ 'S:Envelope': { 'S:Body': { VehicleDescription: VehicleDescription } } })
}