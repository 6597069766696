import { put, call, all, takeEvery, takeLatest } from 'redux-saga/effects'

import { LOAD_LEAD_REPORTS, 
    GET_LEAD_REPORTS, 
    LEAD_REPORTS_PENDING,
    CLEAR_ALL, ALL_ERRORS } from "constants/actions";

import * as api from '../constants/api';

function* getLeadReports(action) {
    yield put({ type: LEAD_REPORTS_PENDING });
    try {
        const json = yield call(api.GET_LEAD_REPORTS, action.payload)
        yield put({ type: GET_LEAD_REPORTS, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, error: e });
    }
}

export function* loadLeadReports() {
   yield takeLatest(LOAD_LEAD_REPORTS, getLeadReports);
}



export default function* index() {
    yield all([loadLeadReports(),])
    
  }
