import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// reactstrap components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";

import {
  LOAD_YEAR_MAKE,
  LOAD_BLACKBOOK_VALUES,
  SG_SUBMIT_YEAR_MAKE_MODEL_TRIM,
} from "constants/actions";

const CreateEdit = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [employeeId, setEmployeeId] = React.useState(props.employeeId);

  const [defaultYear, setDefaultYear] = React.useState();
  const [defaultTrim, setDefaultTrim] = React.useState();
  const [queryYear, setQueryYear] = React.useState(0);
  const [queryTrim, setQueryTrim] = React.useState();

  // let history = useHistory();
  const [yearMakeLoading, setYearMakeLoading] = React.useState(false);
  const [VehicleTrim, setVehicleTrim] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [yearmake, setYearmake] = React.useState(false);

  const {
    register,
    handleSubmit,
    // watch,
    errors,
    setValue,
    control,
  } = useForm();

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_YEAR_MAKE });
  }, [dispatch]);

  const {
    get_year_make,
    get_selected,
    get_blackbook_values,
    get_pending,
  } = useSelector(
    (state) => ({
      get_selected: state.yearmakemodel.selected,

      get_year_make: state.yearmake.yearmakes,
      get_loading: state.yearmake.loading,

      get_blackbook_values: state.blackbook.blackbook.results,
      get_pending: state.blackbook.pending,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_blackbook_values) {
      setVehicleTrim(get_blackbook_values.autocomplete);
    }
  }, [get_blackbook_values]);

  React.useEffect(() => {
    if (yearmake) {
      dispatch({
        type: LOAD_BLACKBOOK_VALUES,
        payload: `search=${yearmake.name}`,
      });
    }
  }, [dispatch, yearmake]);

  React.useEffect(() => {
    if (get_selected) {
      setYearmake(get_selected);
    }
  }, [get_selected]);

  const handleYearSelected = (newValue) => {
    setYearmake(newValue[0]);
    dispatch({
      type: LOAD_BLACKBOOK_VALUES,
      payload: `search=${newValue[0].name}`,
    });
    return newValue;
  };

  const handleYearInputChange = (newValue) => {
    dispatch({
      type: LOAD_YEAR_MAKE,
      payload: `${newValue}`,
    });
    return newValue;
  };

  const handleTrimInputChange = (newValue) => {
    dispatch({
      type: LOAD_BLACKBOOK_VALUES,
      payload: `search=${yearmake.name} ${newValue}`,
    });
  };
  

  React.useEffect(() => {
    register(
      { name: "vehicle_trim" },
      { required: "Please select a Model and Trim" }
    );
    register(
      { name: "year_make" },
      { required: "Please select a Year and Model" }
    );
  }, [register, VehicleTrim]);

  React.useEffect(() => {
    setValue("year_make", yearmake);
    // setValue("make", defaultMake);
    // setValue("model", defaultModel);
    //   // Previous
  }, [setValue, yearmake]);

  const [selectedTrim, setSelectedTrim] = React.useState([]);

  React.useEffect(() => {
    setLoading(get_pending);
  }, [get_pending]);

  React.useEffect(() => {
    setSelectedTrim(get_selected);
  }, [get_selected]);

  const onSubmit = (data) => {
    // console.log(data)
    let _trim,
      _trim_id = null;
    if (data.trim) {
      _trim = data.trim[0].name;
      _trim_id = data.trim[0].id;
    }
    dispatch({
      type: SG_SUBMIT_YEAR_MAKE_MODEL_TRIM,
      payload: {
        year: data.vehicle_trim.model_year,
        make: data.vehicle_trim.make,
        model: data.vehicle_trim.model,
        trim: `${data.vehicle_trim.series} ${data.vehicle_trim.style}`,
        black_book_uvc: data.vehicle_trim.uvc,
        kilometers: data.kilometers,
      },
    });
    // MakeContactVisible(2);
    // dispatch({type: LOGIN_REQUEST, payload: {email: data.email, password: data.password, dealer_token: keyConfig.portalkey} })
  };
  console.log(errors)
  return (
    <>
      <div className="content">
        <Card className="card-tasks">
          <CardBody>
            <CardTitle className="text-center" tag="h3"></CardTitle>
            <div className="d-flex justify-content-between">
              <CardTitle tag="h4">
                {employeeId ? "Edit " : "Create "}Trade In value of Vehicle
              </CardTitle>

              <Button color="link" onClick={() => props.resetColWidth(false)}>
                <i className="fa fa-times fa-2x" aria-hidden="true"></i>
              </Button>

              
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <p>
                  Year Make Model<span className="icon-danger">*</span>
                </p>
                <Controller
                  as={<Select />}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  options={get_year_make}
                  isLoading={yearMakeLoading}
                  name="year_make"
                  placeholder="Enter any Year Make Model"
                  className="text-dark"
                  control={control}
                  onInputChange={handleYearInputChange}
                  onChange={handleYearSelected}
                />
                <p className="text-danger small">
                  {errors.year_make && <span>{errors.year_make.message}</span>}
                </p>
              </FormGroup>
              <FormGroup>
                <p>
                  Trim <span className="icon-danger">*</span>
                </p>
                <Controller
                  as={<Select />}
                  getOptionLabel={(option) =>
                    `${option.series} ${option.style}`
                  }
                  getOptionValue={(option) => `${option.uvc}`}
                  options={VehicleTrim}
                  isDisabled={yearmake ? false : true}
                  isLoading={loading}
                  placeholder="Trim"
                  name="vehicle_trim"
                  className="text-dark"
                  control={control}
                  onInputChange={handleTrimInputChange}
                />

                <p className="text-danger small">
                  {errors.vehicle_trim && (
                    <span>{errors.vehicle_trim.message}</span>
                  )}
                </p>
              </FormGroup>

              <FormGroup>
                <p>
                  Kilometers <span className="icon-danger">*</span>
                </p>
                <Input
                  className="border-input"
                  name="kilometers"
                  placeholder="enter the kilometers..."
                  type="text"
                  innerRef={register({
                    required: "Please enter odometer mileage",
                  })}
                />
                <p className="text-danger small">
                  {errors.kilometers && (
                    <span>{errors.kilometers.message}</span>
                  )}
                </p>
              </FormGroup>

              <CardFooter>
                <div className="d-flex justify-content-end">
                  <div className="p-2"></div>
                  <div className="p-2">
                    <Button className="btn" color="primary" type="submit">
                      Show
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CreateEdit;
