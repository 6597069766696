import React, { useState } from "react";

import PropTypes from "prop-types";
import AppDialog from "../../crema/AppDialog";
import VinCreate from "TradeIns/VinCreate";

const AddNewLead = ({
  isAddTaskOpen,
  onCloseAddTask,
  setAddTaskOpen,
  setUpdatePage,
  resetColWidth,
  tradeIn,
  ShowDetails,
  label,
  setLabel,
}) => {
  return (
    <AppDialog
      dividers
      maxWidth="md"
      open={isAddTaskOpen}
      onClose={() => {
        onCloseAddTask();
        resetColWidth();
      }}
      title={label}
    >
      <VinCreate
        setAddTaskOpen={setAddTaskOpen}
        setLabel={setLabel}
        setUpdatePage={setUpdatePage}
        resetColWidth={resetColWidth}
        tradeIn={tradeIn}
        ShowDetails={ShowDetails}
        label={label}
      />
    </AppDialog>
  );
};

export default AddNewLead;
