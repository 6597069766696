import { put, call, throttle, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  LOAD_CUSTOMER,
  GET_CUSTOMERS,
  LOAD_CUSTOMER_LIST,
  GET_CUSTOMER_LIST,
  GET_CUSTOMERS_PENDING,
  DELETE_CUSTOMER,
  SG_DELETE_CUSTOMER,
  SG_ADD_CUSTOMER,
  ADD_CUSTOMER,
  FETCH_CUSTOMER_PENDING,
  FETCH_CUSTOMER,
  SG_FETCH_CUSTOMER,
  UPDATE_CUSTOMER,
  EDIT_CUSTOMER,
  CLEAR_CUSTOMER,
  CLEAR_ALL,
  ALL_ERRORS,
} from "constants/actions";


function* loadCustomerList(action) {
  yield put({ type: GET_CUSTOMERS_PENDING });
  try {
    const json = yield call(api.GET_CUSTOMER_LIST, action.payload);
    yield put({ type: GET_CUSTOMER_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* customerLoadList() {
  yield throttle(5000, LOAD_CUSTOMER_LIST, loadCustomerList);
}


function* loadCustomer(action) {
  yield put({ type: GET_CUSTOMERS_PENDING });

  try {
    const json = yield call(api.GET_CUSTOMERS, action.payload);

    yield put({ type: GET_CUSTOMERS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}


export function* customerLoad() {
  yield takeLatest(LOAD_CUSTOMER, loadCustomer);
}

function* fetchCustomer(action) {
  yield put({ type: FETCH_CUSTOMER_PENDING });
  try {
    const json = yield call(api.FETCH_CUSTOMER, action.payload);

    yield put({ type: FETCH_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* customerFetchOne() {
  yield takeLatest(SG_FETCH_CUSTOMER, fetchCustomer);
}

function* editCustomer(action) {
  yield put({ type: FETCH_CUSTOMER_PENDING });
  try {
    const json = yield call(api.EDIT_CUSTOMER, action.payload.id, action.payload);

    yield put({ type: EDIT_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* customerEdit() {
  yield takeLatest(UPDATE_CUSTOMER, editCustomer);
}

function* addCustomer(action) {
  try {
    const json = yield call(api.ADD_CUSTOMER,  action.payload);

    yield put({ type: ADD_CUSTOMER, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, error: e.message });
  }
}

export function* customerAdd() {
  yield takeLatest(SG_ADD_CUSTOMER, addCustomer);
}

export default function* index() {
  yield all([
    customerLoad(),
    customerLoadList(),
    customerFetchOne(),
    customerEdit(),
    customerAdd(),
  ]);
}
