import React from "react";

import EmployeeList from "./List";
const index = () => {
  return (
    <>
      <EmployeeList />
    </>
  );
};

export default index;
