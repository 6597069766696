import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// plugin that creates slider
// import Slider from "nouislider";

// reactstrap components
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Container,
  Spinner,
  Fade,
  Row,
  Col,
} from "reactstrap";

const ListEquipments = ({ equipment }) => {
  const [descriptionList, setDescriptionList] = React.useState([]);

  React.useEffect(() => {
    setDescriptionList(equipment);
  }, [equipment]);
  return (
    <Table bordered striped>
      <tbody>
        {descriptionList.map((p, k) => {
          return (
            <tr key={k}>
              <td>{p.id}</td>
              <td>{p.name}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const StandardEquipment = ({ equipment }) => {
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };


  return (
    <>
      <Row>
        <Col>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            {equipment[0]? equipment.map((p,k) => {

              return (
                <Card className="card-plain" key={k}>
              <CardHeader role="tab">
                <a
                  aria-expanded={collapses.includes(k+1)}
                  href="#pablo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    changeCollapse(k+1);
                  }}
                >
                  {p.name} <i className="nc-icon nc-minimal-down" />
                </a>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={collapses.includes(k+1)}>
                <CardBody>
                  <ListEquipments equipment={p.value} />
                </CardBody>
              </Collapse>
            </Card>
              )
            }):  null}
            
           
          </div>
        </Col>
      </Row>
    </>
  );
};

export default StandardEquipment;
