import React from "react";
import _ from "lodash";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import * as jsonexport from "jsonexport/dist";

import { Redirect } from "react-router-dom";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SG_FETCH_COMPANY_INFORMATION,
  UPDATE_COMPANY_INFORMATION,
  REMOVE_COMPANY_INFORMATION,
} from "constants/actions";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "reactstrap";


const Detail = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [leadSource, setLeadSource] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [filedownloadlink, setFiledownloadlink] = React.useState("");
  const [resetPage, setResetPage] = React.useState(false);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // console.log("Forced to update", forceUpdate)
    if ([parseInt(id)]) {
      dispatch({ type: SG_FETCH_COMPANY_INFORMATION, payload: id });
    }
  }, [dispatch, forceUpdate]);

  const { get_company_information, get_auth, get_errors } = useSelector(
    (state) => ({
      get_company_information: state.companyinformation[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (get_company_information) {
      setLeadSource(get_company_information);
      DownloadFile(get_company_information.full_information);
    }
  }, [get_company_information]);
  // console.log(leadSource);
  // console.log(get_lead_source);
  const SelectedRow = (e) => {
    // console.log(e);
    setSelectedRow(e);
  };

  function DownloadFile(FileName) {
    jsonexport(FileName, function (err, csv) {
      if (err) return console.log(err);
      var myURL = window.URL || window.webkitURL; //window.webkitURL works in Chrome and window.URL works in Firefox
      const obj = FileName;
      var blob = new Blob([JSON.stringify(obj, null, 2)], {
        type: "application/js",
      });
      var csvUrl = myURL.createObjectURL(blob);
      setFiledownloadlink(csvUrl);
    });
  }

  const DeleteFile = (id) => {
    // config.map(p => console.log(p)) REMOVE_COMPANY_INFORMATION
    dispatch({ type: REMOVE_COMPANY_INFORMATION, payload: id });
    setResetPage(leadSource.company);
  };
  // console.log(leadSource)
  return (
    <>
      {resetPage ? (
        <Redirect to={`/admin/company-detail/${resetPage}`} />
      ) : null}
      <div className="content">
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Company Information Details</CardTitle>
                    <h5 className="card-category">
                      All Details Company Information
                    </h5>
                  </Col>
                  <Col className="ml-auto" md="2">
                    {/* <Button
                      color="primary"
                      size="sm"
                      to={`/admin/comp-info-create/${id}`}
                      tag={Link}
                    >
                      Edit
                    </Button> */}

                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => DeleteFile(id)}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label>Name </label>
                    <p>{leadSource ? leadSource.name : ""}</p>
                  </Col>
                  <Col>
                    <label>Create Date </label>
                    {/* start_date */}
                    <p>
                      {leadSource
                        ? moment(leadSource.created_at).format("YYYY-MM-DD")
                        : ""}
                    </p>
                  </Col>
                  <Col>
                    <label>Update Date</label>

                    <p>
                      {leadSource
                        ? moment(leadSource.updated_at).format("YYYY-MM-DD")
                        : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Key Information</label>
                    {_.isEmpty(leadSource.full_information) ? (
                      <p>"No Information"</p>
                    ) : (
                      <ListGroup flush>
                        {leadSource.full_information.map((p, k) => {
                          return (
                            <ListGroupItem key={k} className="list-group-item">
                              <Row>
                                {/* <Col>
                                  <p>{p.key? p.key : null}</p>
                                </Col> */}
                                <Col>
                                  <p>{p.name ? p.name : null}</p>
                                </Col>
                                <Col>
                                  <p>{p.value ? p.value : null}</p>
                                </Col>
                                {/* <Col>
                                  <Button
                                    color="default"
                                    size="sm"
                                    onClick={() => SelectedRow(p)}
                                  >
                                    Edit
                                  </Button>
                                </Col> */}
                              </Row>
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    )}
                  </Col>
                </Row>
                <CardFooter>
                  {filedownloadlink && (
                    <a download="_config.json" href={filedownloadlink}>
                      Download Config File for website
                    </a>
                  )}
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* {_.isEmpty(leadSource.full_information)? 
       
        <GenerateConfig setForceUpdate={setForceUpdate} />
        :
        <RowAddEdit selectedRow={selectedRow} 
        setSelectedRow={setSelectedRow}
        setForceUpdate={setForceUpdate} />
        } */}
      </div>
    </>
  );
};

export default Detail;
