import React from "react";

import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LOAD_WEB_COMMENTS, SG_UPDATE_ACTIVE_PAGE } from "constants/actions";
import ReactPaginate from "react-paginate";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType,
} from "react-device-detect";

import Truncate from "react-truncate";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Spinner,
  Fade,
  Row,
  Col,
} from "reactstrap";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "mui/tables/StyledTableCell";
import { StyledTableRow } from "mui/tables/StyledTableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TablePaginationActions from "mui/tables/TablePaginationActions";

const List = (props) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let qMakeList = params.get("makes");
  let qYearList = params.get("years");
  let qModelList = params.get("models");

  const [webComments, setWebComments] = React.useState([]);

  // pagination
  const [pending, setPending] = React.useState();
  const [activePage, setActivePage] = React.useState(0);
  const [pageactivePage, setPageActivePage] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [pageParams, setPageParams] = React.useState(props.params);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  // pagination end

  React.useEffect(() => {
    // console.log(pageactivePage)
    // console.log(pageParams)
    dispatch({
      type: LOAD_WEB_COMMENTS,
      payload: { page: pageactivePage, params: pageParams },
    });
  }, [dispatch, pageParams, pageactivePage]);

  const { get_web_comments, get_pending, get_auth, get_errors } = useSelector(
    (state) => ({
      get_web_comments: state.webcomments.web_comments,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]
  // console.log("Mobile", isMobile, deviceType)
  // console.log("Browser", isBrowser, deviceType)
  React.useEffect(() => {
    setWebComments(get_web_comments.results);
  }, [get_web_comments]);

  React.useEffect(() => {
    // console.log('get_activePage', get_activePage, "****************************************")
    if (get_web_comments) {
      setTotalResults(get_web_comments.count);
      if (get_web_comments.page_size) {
        setPerPage(get_web_comments.page_size);
      }
      setTotalPages(Math.ceil(get_web_comments.count / perPage));
    }
  }, [get_web_comments]);
  // console.log(user)
  const [page, setPage] = React.useState(0);

  const handleClick = (event, newPage) => {
    dispatch({
      type: SG_UPDATE_ACTIVE_PAGE,
      payload: { name: "landingpage", page: newPage + 1 },
    });
    setActivePage(newPage);
    setPageActivePage(newPage + 1);
    setPage(newPage);
  };
  return (
    <>
      <Row>
        {/* <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col> */}
        <div className={pending ? "" : "d-none mt-3"}>
          <div className="d-flex justify-content-center">
            <Fade in={pending} tag="h5">
              <Spinner>Loading...</Spinner>
            </Fade>
          </div>
        </div>
      </Row>
      <div className={pending ? "d-none" : ""}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="center">Rating</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {webComments
                  ? webComments.map((p, k) => (
                      <StyledTableRow key={k}>
                        <StyledTableCell>
                          {p.first_name} {p.last_name} <br />
                          <small>
                            {p.customer ? (
                              <Link
                                to={`/admin/customer-detail/${p.customer}`}
                                style={{ color: "#1d4aa7" }}
                              >
                                View
                              </Link>
                            ) : null}
                          </small>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {p.rating}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Truncate lines={3} ellipsis={<span>... </span>}>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {p.description}
                            </p>
                          </Truncate>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {p.comment_status_text}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Link to={`/admin/web-comment-detail/${p.id}`}>
                            <RemoveRedEyeOutlinedIcon
                              size={40}
                              color={"preAuto"}
                            />
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : " No tasks found"}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[12]}
              component="div"
              count={totalResults || 0}
              rowsPerPage={
                totalPages > 12 ? webComments.length || 0 : totalPages || 0
              }
              page={page}
              onPageChange={handleClick}
              ActionsComponent={TablePaginationActions}
              sx={{
                ".MuiTablePagination-selectLabel": {
                  marginTop: "auto",
                },
                ".MuiTablePagination-displayedRows": {
                  marginTop: "auto",
                },
              }}
            />
          </TableContainer>
        </Paper>
        {/* <Row>
          <Col md="12">
            <Table responsive>
              <thead className="table-active">
                <tr>
                  <th>Name</th>

                  <th>Rating</th>
                  <th>Comments</th>
                  <th>Status</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {webComments ? (
                  webComments.map((p, k) => (
                    <tr key={k}>
                    
                      <td>
                        {p.first_name} {p.last_name} <br />
                        <small>
                          {p.customer ? (
                            <Link to={`/admin/customer-detail/${p.customer}`}>
                              View
                            </Link>
                          ) : null}
                        </small>
                      </td>
                      <td>{p.rating}</td>
                      <td>
                        <Truncate lines={3} ellipsis={<span>... </span>}>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{p.description}</p>

                        </Truncate>
                      </td>
                      <td>{p.comment_status_text}</td>
                      <td>
                        <Link to={`/admin/web-comment-detail/${p.id}`}>
                          View More
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Leads Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row> */}
      </div>
      {/* <Row>
        <Col md="12">
          <div className="d-flex justify-content-end">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              // breakLabel={isMobile? '': <a className="page-link">...</a>}
              breakClassName={"page-item"}
              pageCount={totalPages}
              marginPagesDisplayed={isMobile ? 0 : 2}
              pageRangeDisplayed={isMobile ? 0 : 5}
              onPageChange={handleClick}
              containerClassName={"pagination"}
              subContainerClassName={"page-item pagination"}
              activeClassName={"page-item active"}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              forcePage={activePage}
            />
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default List;
