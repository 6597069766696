import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
// reactstrap components
import {
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";

import { LOAD_COMPANY } from "constants/actions";

const List = (props) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = React.useState([]);
  const [pageParams, setPageParams] = React.useState(props.params);


  React.useEffect(() => {

    dispatch({ type: LOAD_COMPANY, payload: {params: pageParams}});
  }, [dispatch, pageParams]);


  const { get_auth, company_list } = useSelector(
    (state) => ({
      company_list: state.company.companies,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setCompanies(company_list);
  }, [company_list]);

  return (
    <>
      <Row>
        <Col md="12">
          <Table responsive>
            <thead className="table-active">
              <tr>
                <th>Dealer</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Website</th>
                <th>Status</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {companies ? (
                companies.map((p, k) => (
                  <tr key={k}>
                 
                    <td>
                      {p.name} 
                     
                    </td>
                    <td>{p.address}</td>

                    <td>{p.phone}</td>
                    <td>{p.website}</td>
                    <td>{p.status_text}</td>

                                        <td>
                                          {p.company_type === 1?
                                           <Link to={`/admin/company-detail/${p.id}`}>View More</Link>
                                          : 'Not Main Company'}
                     
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Company Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default List;
