import { GET_PRODUCTS, 
    FETCH_PRODUCT, 
    DELETE_PRODUCT, ADD_PRODUCT, EDIT_PRODUCT, 
    CLEAR_PRODUCT, CLEAR_ALL } 
    from "constants/actions";
 
const initialState = {
    products: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case FETCH_PRODUCT:
            return { ...state, [action.payload.id]: action.payload };

        case EDIT_PRODUCT:
            return { ...state, [action.payload.id]: action.payload };

        case DELETE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(webheader => webheader.id !== action.payload)
            };
        case ADD_PRODUCT:
            return {
                ...state,
                products: [...state.products, action.payload]
            };
        case CLEAR_PRODUCT:
            return {
                ...state,
                products: []
            };
        case CLEAR_ALL:
            return {
                ...state,
                products: []
            };
        default:
            return state;
    }
}
