import React from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import _ from 'lodash'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import EmployeeList from "Employees/List"
import InventoryLocation from "Dealer/InventoryLocation"
import Codes from "Dealer/Codes"

import Billing from "Billing"
import ProductContract from "Product/Contract"

import { SG_FETCH_RELATED_DEALER, SG_FETCH_DEALER } from "constants/actions";
import defaultImage from "assets/img/image_placeholder.jpg";
const Newindex = () => {
  const dispatch = useDispatch();
  const [dealer, setDealer] = React.useState([]);
  const [relatedDealer, setRelatedDealer] = React.useState([]);

  React.useEffect(() => {
    // console.log('props.newTaskAdded dispatch', props.newTaskAdded)
    dispatch({ type: SG_FETCH_RELATED_DEALER });
  }, [dispatch]);


  const { get_auth, related_dealer } = useSelector(
    state => ({
      get_auth: state.auth,
      related_dealer: state.dealers.related,
    }),
    shallowEqual
  );

  React.useEffect(() => {
   
    if (related_dealer) {
      setDealer(
        related_dealer.filter(
          p => p.id === Number(get_auth?.dealer_id))?.[0]);
      setRelatedDealer(related_dealer);
    }

  }, [related_dealer, get_auth]);

  // console.log(get_auth)
  // console.log(dealer)
  // console.log(related_dealer)

  return (
    <>
      <div className="content">

        <Row>

          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Dealer Profile
                  <small>{" "}{dealer ? dealer.company_external_code : ''} </small>
                </h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="9">

                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label>Dealer</label>
                            <p>{dealer ? dealer.name : ''}</p>

                          </FormGroup>
                        </Col>

                      </Row>


                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <p>{dealer ? dealer.address : ''}</p>

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>City</label>
                            <p>{dealer ? dealer.city : ''}</p>

                          </FormGroup>
                        </Col>

                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label>Postal Code</label>
                            <p>{dealer ? dealer.postalcode : ''}</p>

                          </FormGroup>
                        </Col>


                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label>Country</label>

                            <Input
                              disabled
                              defaultValue="Canada"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>

                  </Col>
                  <Col md="3">
                    <div className={"thumbnail"}>
                      <img src={dealer.logo ? dealer.logo : defaultImage} alt="..." />
                    </div>
                  </Col>

                </Row>

              </CardBody>
              {/* <CardFooter>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            >
                            Edit
                        </Button>
                    </div>
                  
                </CardFooter> */}
            </Card>
          </Col>
          <Col>

          </Col>
        </Row>

        <Row>

          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Website: {dealer ? dealer.website : ''} </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>


                  <Row>

                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Phone</label>
                        <p>{dealer ? dealer.phone : ''}</p>

                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>Toll Free</label>
                        <p>{dealer ? dealer.tollfree : ''}</p>

                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>
                          Email
                          </label>
                        <p>{dealer ? dealer.email : ''}</p>

                      </FormGroup>
                    </Col>
                  </Row>



                </Form>
              </CardBody>
              {/* <CardFooter>
                    <div className="d-flex flex-row-reverse">
                        <Button
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            >
                            Update
                        </Button>
                    </div>
                  
                </CardFooter> */}
            </Card>
          </Col>
        </Row>

        <InventoryLocation relatedDealers={relatedDealer} />

        <EmployeeList params={`company=${dealer.id}`} showAdd={true} />

        <Codes dealer={dealer} />

        {/* <ProductContract/> */}
        {/* <Billing /> */}
      </div>
    </>
  );
};

export default Newindex;