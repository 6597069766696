import React from "react";
import _ from "lodash";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  FETCH_INVENTORY_ONE,
  LOAD_CARIMAGES,
  SG_EDIT_INVENTORY,
  RESET_CREATE_INVENTORY,
  RESET_UPDATED_INVENTORY,
  DESTROY_INVENTORY,
} from "constants/actions";

import { TransitionGroup } from "react-transition-group";
// reactstrap components
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import FullCreateEdit from "Vinupload/Full/CreateEdit";
import BasicCreateEdit from "Vinupload/Basic/CreateEdit";

import VinDecodeFull from "Inventory/InventoryDetail/VinDecodeFull";

import BlackBookDetails from "./InventoryDetail/BlackBookDetails";
import BlackBookHeader from "./InventoryDetail/BlackBookHeader";
import LeadList from "Leads/List";
import BaseInfo from "./InventoryDetail/BaseInfo";

import "./style.css";
import FactoryImages from "./Images/FactoryImages";

import DropImage from "./Images/DropImage";

import { Button as MUIButton } from "@mui/material";

import SpecInfo from "./InventoryDetail/SpecInfo";
import InventoryNotes from "./InventoryDetail/InventoryNotes";
import StandardEquipment from "./InventoryDetail/StandardEquipment";
import ConsumerInformation from "./InventoryDetail/ConsumerInformation";
import GenericEquipment from "./InventoryDetail/GenericEquipment";
import TechnicalSpecifications from "./InventoryDetail/TechnicalSpecifications";

import CustomersInterested from "Leads/CustomersInterested";

const InventoryDetail = () => {
  let history = useHistory();
  // show / hide
  const [inventoryinfo, setInventoryinfo] = React.useState(true);
  const [showLeads, setShowLeads] = React.useState(false);
  const [showSpec, setShowSpec] = React.useState(false);
  const [kijijiInfo, setKijijiInfo] = React.useState(false);
  const [textDescription, setTextDescription] = React.useState(false);
  const [standEquipInfo, setStandEquipInfo] = React.useState(false);
  const [genericEquipInfo, setGenericEquipInfo] = React.useState(false);
  const [techSpecInfo, setTechSpecInfo] = React.useState(false);
  const [consumerInfo, setConsumerInfo] = React.useState(false);
  const [blackbookInfo, setBlackbookInfo] = React.useState(false);
  const [showimages, setShowimages] = React.useState(false);
  const [showfactoryimage, setShowfactoryimage] = React.useState(false);

  const [showVinDecode, setShowVinDecode] = React.useState(false);
  const [showCustomers, setShowCustomers] = React.useState(false);
  const [hideDecode, setHideDecode] = React.useState(true);
  // data
  const [car, setCar] = React.useState([]);
  const [inventory, setInventory] = React.useState([]);

  const [pageerrors, setPageErrors] = React.useState([]);

  const [restrainTypes, setRestrainTypes] = React.useState([]); // Restraint Types
  const [standardEquipment, setStandardEquipment] = React.useState([]); // Standard Equipment
  const [technicalSpecification, setTechnicalSpecification] = React.useState(
    []
  ); // Technical Specification
  const [genericEquipment, setGenericEquipment] = React.useState([]); // Generic Equipment
  const [consumerInformation, setConsumerInformation] = React.useState([]); // Consumer Information
  // const [engine, setEngine] = React.useState([]);
  const [blackbook, setBlackbook] = React.useState([]);
  const [factoryimages, setFactoryimages] = React.useState([]);
  const [inventoryImages, setInventoryImages] = React.useState([]);

  const [inventoryId, setInventoryId] = React.useState(0);
  const [colWidth, setColWidth] = React.useState([12, 0]);
  const [updated, setUpdated] = React.useState(false);

  const dispatch = useDispatch();
  const { id, slug } = useParams();

  const [deleteModal, setDeleteModal] = React.useState(false);
  const [delistModal, setDelistModal] = React.useState(false);

  const toggleDelete = () => setDeleteModal(!deleteModal);
  const toggleDelist = () => setDelistModal(!delistModal);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch(getPaginatedInventories(activePage, pageparams))
    dispatch({ type: RESET_CREATE_INVENTORY });
    dispatch({ type: RESET_UPDATED_INVENTORY });

    dispatch({ type: FETCH_INVENTORY_ONE, payload: id });
    // dispatch({ type: FETCH_INVENTORY_EXTRA_ONE, payload: id });
    // dispatch({ type: FETCH_INVENTORY_NOTES_ONE, payload: id });
    // dispatch({ type: FETCH_INVENTORY_FINANCE_ONE, payload: id });
  }, [dispatch, id, updated]);

  const { get_inventory, get_errors, get_error_state } = useSelector(
    (state) => ({
      get_inventory: state.inventories[parseInt(id)],
      get_errors: state.errors,
      get_error_state: state.errorstate,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_errors) {
      setPageErrors(get_errors);
    }
    if (get_inventory) {
      setCar(get_inventory);
      // setInventory(get_inventory)

      if (_.get(get_inventory, "images")) {
        setInventoryImages(get_inventory.images);
      }

      if (_.get(get_inventory, "inventory_description_inventory")) {
        if (get_inventory.inventory_description_inventory.length > 0) {
          setRestrainTypes(
            get_inventory.inventory_description_inventory.filter(
              (fl) => fl.name === "Restraint Types"
            )
          );
          // console.log(get_inventory)
          // Standard Equipment

          setStandardEquipment(
            get_inventory.inventory_description_inventory.filter(
              (fl) => fl.name === "Standard Equipment"
            )[0].full_response
          );

          // Technical Specification
          setTechnicalSpecification(
            get_inventory.inventory_description_inventory.filter(
              (fl) => fl.name === "Technical Specification"
            )[0].full_response
          );
          // console.log("Technical Specification",techSpec)
          // Generic Equipment
          setGenericEquipment(
            get_inventory.inventory_description_inventory.filter(
              (fl) => fl.name === "Generic Equipment"
            )[0].full_response
          );
          // console.log("Generic Equipment",genEquip)
          // Consumer Information
          setConsumerInformation(
            get_inventory.inventory_description_inventory.filter(
              (fl) => fl.name === "Consumer Information"
            )[0].full_response
          );
        }
      }

      // console.log(get_inventory)
      if (_.get(get_inventory, "factory_images")) {
        setFactoryimages(
          get_inventory.factory_images.filter((im) => im.height < 640)
        );
      }

      if (_.get(get_inventory, "inventory_blackbook_inventory")) {
        setBlackbook(get_inventory.inventory_blackbook_inventory);
      }
    }
  }, [get_inventory, get_errors, get_error_state]);

  const SelectedVinDecode = (invid) => {
    setInventoryId(invid);

    setColWidth([0, 12]);
  };
  const resetColWidth = (updated) => {
    setInventoryId(false);

    setUpdated(updated);
    setColWidth([12, 0]);
  };
  const DelistInventory = () => {
    // console.log(Invid)
    // setModal(!modal);
    dispatch({ type: SG_EDIT_INVENTORY, payload: { id, status: 11 } });
    // history.push("/admin/inventory");
    setDelistModal(false);
  };

  const DeleteInventory = () => {
    // console.log(Invid)
    // setModal(!modal);
    dispatch({ type: DESTROY_INVENTORY, payload: id });
    history.push("/admin/inventory");
  };
  return (
    <>
      <div className="content">
        <div className="d-flex flex-row-reverse">
          <p className="text-white bg-dark p-2">Inventory summary page</p>
        </div>
        <Row>
          <Col className={colWidth[0] > 0 ? "d-block" : "d-none"}>
            <BaseInfo
              inventoryId={inventoryId}
              inventory={car}
              resetColWidth={resetColWidth}
              SelectedVinDecode={SelectedVinDecode}
              toggleDelete={toggleDelete}
              toggleDelist={toggleDelist}
            />
            {car.status < 7 ? (
              <>
                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">
                              Free Text Description
                            </CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={
                                textDescription ? "customWhite" : "preAuto"
                              }
                              sx={{
                                color: (theme) =>
                                  textDescription
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() =>
                                setTextDescription(!textDescription)
                              }
                            >
                              {textDescription ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                    </Card>
                    {textDescription ? (
                      <InventoryNotes
                        standardEquipment={standardEquipment}
                        inventory={car}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                {/* <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Kijiji</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <Button
                              className="btn"
                              color={kijijiInfo ? "default" : "success"}
                              outline
                              size="sm"
                              onClick={() => setKijijiInfo(!kijijiInfo)}
                            >
                              {kijijiInfo ? "Hide" : "Show"}
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {kijijiInfo ? <Kijiji inventoryId={id} /> : ""}
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Vin Decode</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={
                                showVinDecode ? "customWhite" : "preAuto"
                              }
                              sx={{
                                color: (theme) =>
                                showVinDecode
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setShowVinDecode(!showVinDecode)}
                            >
                              {showVinDecode ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {showVinDecode ? (
                          <VinDecodeFull inventoryId={id} />
                        ) : (
                          ""
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Standard Equipment</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={standEquipInfo ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                standEquipInfo
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setStandEquipInfo(!standEquipInfo)}
                            >
                              {standEquipInfo ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {standEquipInfo ? (
                          <StandardEquipment equipment={standardEquipment} />
                        ) : (
                          ""
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Generic Equipment</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={
                                genericEquipInfo ? "customWhite" : "preAuto"
                              }
                              sx={{
                                color: (theme) =>
                                genericEquipInfo
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() =>
                                setGenericEquipInfo(!genericEquipInfo)
                              }
                            >
                              {genericEquipInfo ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {genericEquipInfo ? (
                          <GenericEquipment equipment={genericEquipment} />
                        ) : (
                          " "
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">
                              Technical Specification
                            </CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={techSpecInfo ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                techSpecInfo
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setTechSpecInfo(!techSpecInfo)}
                            >
                              {techSpecInfo ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {techSpecInfo ? (
                          <TechnicalSpecifications
                            equipment={technicalSpecification}
                          />
                        ) : (
                          ""
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Consumer Information</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={consumerInfo ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                  consumerInfo
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setConsumerInfo(!consumerInfo)}
                            >
                              {consumerInfo ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {consumerInfo ? (
                          <ConsumerInformation
                            information={consumerInformation}
                          />
                        ) : (
                          " "
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {get_inventory?.inventory_blackbook_inventory !== false ? (
                  <Row>
                    <Col md="12" sm="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="6">
                              <CardTitle tag="h4">
                                Black Book -{" "}
                                <small>{car.blackbook_category}</small>
                              </CardTitle>
                            </Col>

                            <Col className="ml-auto" md="2">
                              <MUIButton
                                color={
                                  blackbookInfo ? "customWhite" : "preAuto"
                                }
                                sx={{
                                  color: (theme) =>
                                    blackbookInfo
                                      ? theme.palette.preAuto.main
                                      : theme.palette.customWhite.main,
                                }}
                                variant="contained"
                                onClick={() => setBlackbookInfo(!blackbookInfo)}
                              >
                                {blackbookInfo ? "Hide" : "Show"}
                              </MUIButton>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {blackbookInfo ? (
                            <BlackBookHeader
                              blackbook={blackbook}
                              category={car.blackbook_category}
                            />
                          ) : (
                            ""
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : null}

                {get_inventory.factory_images !== false ? (
                  <Row>
                    <Col md="12" sm="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col md="6">
                              <CardTitle tag="h4">Factory Images </CardTitle>
                            </Col>

                            <Col className="ml-auto" md="2">
                              <MUIButton
                                color={
                                  showfactoryimage ? "customWhite" : "preAuto"
                                }
                                sx={{
                                  color: (theme) =>
                                    showfactoryimage
                                      ? theme.palette.preAuto.main
                                      : theme.palette.customWhite.main,
                                }}
                                variant="contained"
                                onClick={() =>
                                  setShowfactoryimage(!showfactoryimage)
                                }
                              >
                                {showfactoryimage ? "Hide" : "Show"}
                              </MUIButton>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {showfactoryimage ? (
                            <FactoryImages images={factoryimages} />
                          ) : (
                            ""
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Car Images </CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={showimages ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                  showimages
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setShowimages(!showimages)}
                            >
                              {showimages ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {showimages ? (
                          <>
                            <DropImage images={inventoryImages} />
                            {/* <NewImage /> */}
                          </>
                        ) : (
                          ""
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Categories / Listing</CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={showSpec ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                  showSpec
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setShowSpec(!showSpec)}
                            >
                              {showSpec ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>

                        {showSpec ? <SpecInfo inventory={car} /> : ""}
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="6">
                            <CardTitle tag="h4">Leads </CardTitle>
                          </Col>

                          <Col className="ml-auto" md="2">
                            <MUIButton
                              color={showLeads ? "customWhite" : "preAuto"}
                              sx={{
                                color: (theme) =>
                                  showLeads
                                    ? theme.palette.preAuto.main
                                    : theme.palette.customWhite.main,
                              }}
                              variant="contained"
                              onClick={() => setShowLeads(!showLeads)}
                            >
                              {showLeads ? "Hide" : "Show"}
                            </MUIButton>
                          </Col>
                        </Row>

                        {showLeads ? (
                          <CustomersInterested inventory_id={id} />
                        ) : (
                          ""
                        )}
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Col>
          <Col className={colWidth[1] > 0 ? "d-block" : "d-none"}>
            <FullCreateEdit
              id={inventoryId}
              resetColWidth={resetColWidth}
              hideDecode={hideDecode}
            />
          </Col>
        </Row>
      </div>

      <Modal isOpen={deleteModal} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>Confirm Delete</ModalHeader>
        <ModalBody>This action is not reversible!</ModalBody>
        <ModalFooter className="pr-2">
          <Button color="danger" onClick={DeleteInventory}>
            Delete
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal isOpen={delistModal} toggle={toggleDelist}>
        <ModalHeader toggle={toggleDelist}>Confirm Delist</ModalHeader>
        <ModalBody>This action is not reversible!</ModalBody>
        <ModalFooter className="pr-2">
          <Button color="warning" onClick={DelistInventory}>
            De List
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InventoryDetail;
