import { put, call, takeLatest, all } from "redux-saga/effects";
import { GET_COMPANY_INFORMATION_PENDING, 
    LOAD_COMPANY_INFORMATION, GET_COMPANY_INFORMATIONS, 

    DELETE_COMPANY_INFORMATION, REMOVE_COMPANY_INFORMATION, 
    ADD_COMPANY_INFORMATION, CREATE_COMPANY_INFORMATION, 
    ADD_DEFAULT_COMPANY_INFORMATION, CREATE_DEFAULT_COMPANY_INFORMATION,
    CLEAR_COMPANY_INFORMATION, 
    FETCH_COMPANY_INFORMATION, 
    SG_FETCH_COMPANY_INFORMATION, EDIT_COMPANY_INFORMATION,
    ADD_EDIT_COMPANY_INFORMATION, 
    REMOVE_ROW_COMPANY_INFORMATION,
    UPDATE_COMPANY_INFORMATION, CLEAR_ALL, ALL_ERRORS
} from "constants/actions";


import * as api from "constants/api";

function* loadCompanies(action) {
  yield put({ type: GET_COMPANY_INFORMATION_PENDING });
  try {
    const json = yield call(api.GET_COMPANY_INFORMATION, action.payload.pageParams)
    yield put({ type: GET_COMPANY_INFORMATIONS, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companiesLoad() {
  yield takeLatest(LOAD_COMPANY_INFORMATION, loadCompanies);
}

function* fetchCompany(action) {
  try {
    const json = yield call(api.FETCH_COMPANY_INFORMATION, action.payload)
    yield put({ type: FETCH_COMPANY_INFORMATION, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companyFetchOne() {
  yield takeLatest(SG_FETCH_COMPANY_INFORMATION, fetchCompany);
}

function* updateCompany(action) {

  try {
    const json = yield call(api.EDIT_COMPANY_INFORMATION, action.payload.id, action.payload)
     yield put({ type: EDIT_COMPANY_INFORMATION, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* companyUpdate() {
  yield takeLatest(UPDATE_COMPANY_INFORMATION, updateCompany);
}

function* updateCompanyInformation(action) {

    try {
      const json = yield call(api.ADD_EDIT_COMPANY_INFORMATION, action.payload.id, action.payload)
      yield put({ type: EDIT_COMPANY_INFORMATION, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyUpdateInfo() {
    yield takeLatest(ADD_EDIT_COMPANY_INFORMATION, updateCompanyInformation);
  }

  function* removeCompanyInformation(action) {

    try {
      const json = yield call(api.REMOVE_COMPANY_INFORMATION, action.payload.id, action.payload)
    //   yield put({ type: EDIT_COMPANY_INFORMATION, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyRemoveInfo() {
    yield takeLatest(REMOVE_ROW_COMPANY_INFORMATION, removeCompanyInformation);
  }

function* addCompany(action) {

    try {
      const json = yield call(api.ADD_COMPANY_INFORMATION, action.payload)
      yield put({ type: ADD_COMPANY_INFORMATION, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyAdd() {
    yield takeLatest(CREATE_COMPANY_INFORMATION, addCompany);
  }


  function* addDefaultCompany(action) {

    try {
      const json = yield call(api.ADD_COMPANY_INFORMATION, action.payload)
      yield put({ type: ADD_DEFAULT_COMPANY_INFORMATION, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyAddDefault() {
    yield takeLatest(CREATE_DEFAULT_COMPANY_INFORMATION, addDefaultCompany);
  }

  function* deleteCompany(action) {

    try {
      const json = yield call(api.DELETE_COMPANY_INFORMATION, action.payload)
      yield put({ type: DELETE_COMPANY_INFORMATION, payload: json.data });
    } catch (e) {
      yield put({type: ALL_ERRORS, message: e});
    }
  }
  
  export function* companyDelete() {
    yield takeLatest(REMOVE_COMPANY_INFORMATION, deleteCompany);
  }



export default function* index() {
  yield all([
    companiesLoad(),
    companyFetchOne(),
    companyUpdate(),
    companyUpdateInfo(),
    companyRemoveInfo(),
    companyAdd(),
    companyAddDefault(),
    companyDelete()]);
}
// export default inventoryLoad;
