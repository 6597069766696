import React from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { SG_FETCH_CREDITAPPLICATION } from "constants/actions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

const Detail = () => {
  const { id, slug } = useParams();
  const dispatch = useDispatch();

  const [application, setApplication] = React.useState([]);

  React.useEffect(() => {
    dispatch({ type: SG_FETCH_CREDITAPPLICATION, payload: id });
  }, [dispatch]);

  const { get_application, get_auth, get_errors } = useSelector(
    (state) => ({
      get_application: state.creditapplication[parseInt(id)],
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    setApplication(get_application);
  }, [get_application]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Customer Application Details</CardTitle>
                  </Col>

                  <Col className="ml-auto " md="2">
                    {_.get(application, "customer") ? (
                      <Button
                        color="default"
                        to={`/admin/customer-detail/${application.customer}`}
                        tag={Link}
                        outline
                        size="sm"
                      >
                        Back to Customer
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <label>First Name</label>
                    <p>{application ? application.first_name : " "}</p>
                  </Col>
                  <Col>
                    <label>Middle Name</label>
                    <p>{application ? application.middle_name : " "}</p>
                  </Col>
                  <Col>
                    <label>Last Name</label>
                    <p>{application ? application.last_name : " "}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Email Id</label>
                    <p>{application ? application.email_id : " "}</p>
                  </Col>
                  <Col>
                    <label>Phone</label>
                    <p>{application ? application.phone : " "}</p>
                  </Col>
                  <Col>
                    <label>Agreed to terms</label>
                    <p>
                      {application
                        ? application.agreed_to_terms
                          ? "Yes"
                          : "No"
                        : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Notes</label>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {application ? application.notes : " "}
                    </p>
                  </Col>
                  <Col>
                    <label>SIN</label>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                    {_.get(application, "full_response.contact_info") ? (<>
                {application.full_response.contact_info.sin}
              </>) : ""}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>User Comments</label>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {application ? application.user_comments : " "}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Last active</label>
                    <p>{application ? application.lastactive : " "}</p>
                  </Col>
                  <Col>
                    <label>Delete by</label>
                    <p>{application ? application.delete_by : " "}</p>
                  </Col>
                  <Col>
                    <label>Expires in</label>
                    <p>{application ? application.expires_in : " "}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Credit Authorisation</CardTitle>
                  </Col>

                  <Col className="ml-auto " md="2"></Col>
                </Row>
              </CardHeader>
             
              {_.get(application, "full_response.credit_auth") ? (
                <CardBody>
                  <Row>
                    <Col>
                      <label>Date Of Birth</label>
                      {application.full_response.contact_info?.date_of_birth ? (
                        <>
                         <p>
                        {/* {application.full_response.contact_info?.date_of_birth} */}
                        {moment(application.full_response.contact_info?.date_of_birth).format("YYYY-MM-DD")}
                      </p>
                        </>
                      ) : (<>
                        <p>
                        {application.full_response.credit_auth?.date_of_birth}
                      </p>
                      </>)}
                    
                    </Col>
                    <Col>
                      <label>Digital Sign</label>
                      <p>
                        {application.full_response.credit_auth.digital_sign}
                      </p>
                    </Col>
                    <Col>
                      <label>Credit Check</label>
                      <p>
                        {application.full_response.credit_auth.second_agree
                          ? "Yes"
                          : "No"}
                      </p>
                    </Col>
                    <Col>
                      <label>SSN</label>
                      <p>
                        {application.full_response.credit_auth.ssn
                          ? application.full_response.credit_auth.ssn
                          : ""}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <CardBody>
                  <p>No Credit Auth found</p>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Employment Details</CardTitle>
                  </Col>

                  <Col className="ml-auto " md="2"></Col>
                </Row>
              </CardHeader>

              {_.get(application, "full_response.employment") ? (
                <CardBody>
                  <Row>
                    <Col>
                    {application.full_response.employment.employment_type ? (
                        <>                      
                       
                       <label>Employer / Income Source</label>
                       <p>
                        {
                          application.full_response.employment.employment_type
                            ?.label
                        }{" "}
                        {application.full_response.employment?.employer}
                      </p>            
                         
                        </>
                      ) : (
                      <>
                      <label>Employment</label>
                      <p>
                      {application.full_response.employment.job_type}
                      </p>
                      </>
                      )}
                      {/* <label>Employer / Income Source</label>
                      { application.full_response.employment.employment_type ? (
                      <>
                      <p>
                        {
                          application.full_response.employment.employment_type
                            ?.label
                        }{" "}
                        {application.full_response.employment?.employer}
                      </p></>
                    ) : (
                      <>
                       <p>
                      {application.full_response.employment.job_type}
                      </p></>
                    )} */}
                      {/* <p>
                        {
                          application.full_response.employment.employment_type
                            ?.label
                        }{" "}
                        {application.full_response.employment?.employer}
                      </p> */}
                    </Col>
                    {application.full_response.employment.address ? (
                      <Col>
                        <label>Address</label>
                        <p>{application.full_response.employment.address}</p>
                      </Col>
                    ) : (
                      " "
                    )}
                    <Col></Col>
                    {application.full_response.employment.employer_emailid ? (
                      <Col>
                        <label>Email Id</label>
                        <p>
                          {
                            application.full_response.employment
                              .employer_emailid
                          }
                        </p>
                      </Col>
                    ) : (
                      " "
                    )}
                    {application.full_response.employment.employer_phone ? (
                      <Col>
                        <label>Phone</label>
                        <p>
                          {application.full_response.employment.employer_phone}
                        </p>
                      </Col>
                    ) : (
                      " "
                    )}
                    <Col>
                      <label>Duration</label>
                      <p>
                        {application.full_response.employment.years_in_job
                          ?.value ? (
                          <>
                            {
                              application.full_response.employment.years_in_job
                                ?.value
                            }{" "}
                            years{" "}
                            {
                              application.full_response.employment.months_in_job
                                ?.value
                            }{" "}
                            months
                          </>
                        ) : (
                          <>
                          <p>{application.full_response.employment?.job_years}{" "}years</p>
                          </>
                        )}
                        {/* {
                          application.full_response.employment.years_in_job
                            ?.value
                        }{" "}
                        years{" "}
                        {
                          application.full_response.employment.months_in_job
                            ?.value
                        }{" "} months */}
                      </p>
                    </Col>
                    <Col>
                      {/* <label>Annual Income</label>
                      <p>
                        {application.full_response.employment.monthly_income}
                      </p> */}
                       {application.full_response.employment.annual_income ? (
                        <>                      
                       
                            <label>Annual Income</label>
                            <p>{application.full_response.employment.annual_income}</p>                 
                         
                        </>
                      ) : (
                      <>
                      <label>Pay</label>
                            <p>{application.full_response.employment.monthly_income}</p>
                      </>
                      )}
                    </Col>
                  </Row>
                  {application.full_response.employment.business_name ? (
                    <>
                     <Row>
                    <Col>
                        <Label>Business Name</Label>
                       <p> {application.full_response.employment.business_name}
                        </p>
                    </Col>
                  </Row>
                    </>
                  ) : ''}
                  {application.full_response.employment.occupation ? (
                    <>
                     <Row>
                    <Col>
                        <Label>Occupation</Label>
                       <p> {application.full_response.employment.occupation}
                        </p>
                    </Col>
                  </Row>
                    </>
                  ) : ''}
                  
                 

                  {application.full_response.employment.prev_employer ? (
                    <Row>
                      <Col>
                        <label>Previous Employer / Income Source</label>
                        <p>
                          {application.full_response.employment.prev_employer}
                        </p>
                      </Col>
                      {application.full_response.employment.prev_address ? (
                        <Col>
                          <label>Address</label>
                          <p>
                            {application.full_response.employment.prev_address}
                          </p>
                        </Col>
                      ) : (
                        " "
                      )}
                      <Col></Col>
                      {application.full_response.employment
                        .prev_employer_emailid ? (
                        <Col>
                          <label>Email Id</label>
                          <p>
                            {
                              application.full_response.employment
                                .prev_employer_emailid
                            }
                          </p>
                        </Col>
                      ) : (
                        " "
                      )}
                      {application.full_response.employment
                        .prev_employer_phone ? (
                        <Col>
                          <label>Phone</label>
                          <p>
                            {
                              application.full_response.employment
                                .prev_employer_phone
                            }
                          </p>
                        </Col>
                      ) : (
                        " "
                      )}
                      <Col>
                        <label>Duration</label>
                        <p>                       
                          {
                            application.full_response.employment
                              .prev_years_in_job.value
                          }{" "}
                          years{" "}
                          {
                            application.full_response.employment
                              .prev_months_in_job.value
                          }{" "}
                          months
                        </p>
                      </Col>
                      <Col>
                        <label>Annual Income</label>
                        <p>
                          {
                            application.full_response.employment
                              .prev_annual_income
                          }
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    " "
                  )}
                </CardBody>
              ) : (
                <CardBody> no employment </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Residence Details</CardTitle>
                  </Col>

                  <Col className="ml-auto " md="2"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                {_.get(application, "full_response.residence") ? (
                  <CardBody>
                    <Row>
                      <Col>
                        <label>Residence Type</label>
                        <p>
                          {
                            application.full_response.residence.residence_type
                              .label
                          }
                        </p>
                      </Col>
                      {application.full_response.residence.address ? (
                        <>
                          {" "}
                          <Col>
                            <label>Address</label>
                            <p>{application.full_response.residence.address}</p>
                          </Col>
                          <Col>
                            <label>City</label>
                            <p>{application.full_response.residence.city}</p>
                          </Col>
                          <Col>
                            <label>Postcode</label>
                            <p>
                              {application.full_response.residence.postcode}
                            </p>
                          </Col>
                        </>
                      ) : (
                        " "
                      )}
                    </Row>
                    {application.full_response.residence.house_value ? (
                      <>
                      <Row>
                        <Col>
                          <label>House Value</label>
                          <p>
                            {application.full_response.residence.house_value}
                          </p>
                        </Col>
                        <Col>
                          <label>Owners Namee</label>
                          <p>
                            {application.full_response.residence.owners_name}
                          </p>
                        </Col>
              
                      </Row>
                      </>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col>
                        <label>Phone</label>
                        <p>{application.full_response.residence.phone}</p>
                      </Col>
                      <Col>
                        <label>Living here for</label>
                        <p>
                        {application.full_response.residence.years_in_home
                              ?.value ? (
                          <>
                           {
                            application.full_response.residence.years_in_home
                              ?.value
                          }{" "}
                          years{" "}
                          {
                            application.full_response.residence.months_in_home
                              ?.value
                          }{" "}
                          months
                          </>
                        ) : (
                          <>
                          {application.full_response.residence.address_years}{" "}years
                          
                          </>
                        )}
                          {/* {
                            application.full_response.residence.years_in_home
                              ?.value
                          }{" "}
                          years{" "}
                          {
                            application.full_response.residence.months_in_home
                              ?.value
                          }{" "}
                          months */}
                        </p>
                      </Col>
                      <Col>
                        {/* <label>Monthly Outgo</label>
                        <p>
                          {application.full_response.residence?.monthly_outgo}
                        </p> */}
                         {application.full_response.residence?.monthly_outgo ? (
                        <>                      
                       
                       <label>Monthly Outgo</label>
                        <p>
                          {application.full_response.residence?.monthly_outgo}
                        </p>                
                         
                        </>
                      ) : (
                      <>
                      <label>Monthly Rent</label>
                            <p>{application.full_response.residence?.rent_in_month}</p>
                      </>
                      )}
                      </Col>
                    </Row>
                    {/* Previous Residence */}
                    {application.full_response.residence.prev_address ? (
                      <>
                        <Row>
                          <Col>
                            <label>Residence Type</label>
                            <p>
                              {
                            application.full_response.residence.prev_residence_type
                              ?.label
                          }
                              Test
                            </p>
                          </Col>
                          {application.full_response.residence.prev_address ? (
                            <>
                              {" "}
                              <Col>
                                <label>Address</label>
                                <p>
                                  {
                                    application.full_response.residence
                                      .prev_address
                                  }
                                </p>
                              </Col>
                              <Col>
                                <label>City</label>
                                <p>
                                  {
                                    application.full_response.residence
                                      .prev_city
                                  }
                                </p>
                              </Col>
                              <Col>
                                <label>Postcode</label>
                                <p>
                                  {
                                    application.full_response.residence
                                      .prev_postcode
                                  }
                                </p>
                              </Col>
                            </>
                          ) : (
                            " "
                          )}
                        </Row>
                        {application.full_response.residence
                          .prev_house_value ? (
                          <Row>
                            <Col>
                              <label>House Value</label>
                              <p>
                                {
                                  application.full_response.residence
                                    .prev_house_value
                                }
                              </p>
                            </Col>
                            <Col>
                              <label>Owners Namee</label>
                              <p>
                                {
                                  application.full_response.residence
                                    .prev_owners_name
                                }
                              </p>
                            </Col>
                   
                          </Row>
                        ) : (
                          ""
                        )}
                        <Row>
                          <Col>
                            <label>Phone</label>
                            <p>
                              {application.full_response.residence.prev_phone}
                            </p>
                          </Col>
                          <Col>
                            <label>Living here for</label>
                            <p>
                              {
                                application.full_response.residence
                                  .prev_years_in_home.value
                              }{" "}
                              years{" "}
                              {
                                application.full_response.residence
                                  .prev_months_in_home.value
                              }{" "}
                              months
                            </p>
                          </Col>
                          <Col>
                            <label>Monthly Outgo</label>
                            <p>
                              {
                                application.full_response.residence
                                  .prev_monthly_outgo
                              }
                            </p>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      " "
                    )}
                  </CardBody>
                ) : (
                  <CardBody>No Residence </CardBody>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Detail;
