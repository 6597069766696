import React from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import _ from "lodash";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { addDays, subDays } from "date-fns";

import EmployeeList from "Employees/EmployeeList";

import Select from "react-select";

import { Box, Button as MUIButton } from "@mui/material";

// import { RHFInput } from 'react-hook-form-input';
import {
  UPDATE_WEB_LEAD,
  SG_ADD_WEB_LEAD,
  LOAD_LEADTYPES,
  LOAD_LEAD_STAGE,
  SG_FETCH_WEB_LEAD,
  SG_GET_SELECT_INVENTORIES,
  LOAD_LEADSOURCES,
  LOAD_ALL_EMPLOYEES,
} from "constants/actions";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import ErrorBoundary from "ErrorBoundary";
import { toast } from "react-toastify";

const VehicleOptions = [
  { value: "Car", label: "Car" },
  { value: "SUV", label: "SUV" },
  { value: "Truck", label: "Truck" },
  { value: "Van", label: "Van" },
  { value: "Other as described", label: "Other as described" },
];

const email_validation =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const CreateWebLead = ({ props, setAddTaskOpen, setLabel }) => {
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const [webLead, setWebLead] = React.useState([]);

  const [selInventory, setSelInventory] = React.useState([]);
  const [selLeadSource, setSelLeadSource] = React.useState([]);
  const [selLeadType, setSelLeadType] = React.useState([]);
  const [selLeadStage, setSelLeadStage] = React.useState([]);
  const [selEmployee, setSelEmployee] = React.useState([]);
  const [employee, setEmployee] = React.useState([]);
  const [newDescription, setNewDescription] = React.useState([]);

  const [allowAll, setAllowAll] = React.useState(false);
  const [webLeadStage, setWebLeadStage] = React.useState(false);

  const [toDetail, setToDetail] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);
  const [leadComments, setLeadComments] = React.useState("");
  const notify2 = (props) => toast.success(`Sucessfully created!`);

  React.useEffect(() => {
    // Run! Like go get some data from an API.
    if ([parseInt(id)] > 0) {
      dispatch({ type: SG_FETCH_WEB_LEAD, payload: id });
    }
    dispatch({ type: SG_GET_SELECT_INVENTORIES });
    dispatch({ type: LOAD_LEADSOURCES });
    dispatch({ type: LOAD_LEADTYPES });
    dispatch({ type: LOAD_LEAD_STAGE });
    dispatch({ type: LOAD_ALL_EMPLOYEES });
  }, [dispatch]);

  const {
    get_web_leads,
    get_auth,
    get_errors,
    get_inventory_select,
    get_employees,
    lead_sources,
    lead_stage,
    lead_type,
  } = useSelector(
    (state) => ({
      get_web_leads: state.webleads,
      get_inventory_select: state.inventories.select_inventory,
      get_employees: state.employees.employees,
      lead_sources: state.leadsources.leadsources,
      lead_stage: state.leadstage.leadstages,
      lead_type: state.leadtypes.leadtypes,
      get_auth: state.auth,
      get_errors: state.errors,
    }),
    shallowEqual
  );
  // [parseInt(id)]
  React.useEffect(() => {
    setSelLeadType(lead_type);
  }, [lead_type]);

  React.useEffect(() => {
    setSelLeadStage(lead_stage);
  }, [lead_stage]);

  React.useEffect(() => {
    setSelInventory(get_inventory_select);
  }, [get_inventory_select]);

  React.useEffect(() => {
    setSelLeadSource(lead_sources);
  }, [lead_sources]);
  React.useEffect(() => {
    setSelEmployee(get_employees.filter((p) => p.status === 1));
    setEmployee(
      get_employees?.find(({ id }) => id === Number(get_auth?.employee_id))
    );
  }, [get_employees]);
  React.useEffect(() => {
    if (get_web_leads[parseInt(id)]) {
      setWebLead(get_web_leads[parseInt(id)]);

      // const SelectedOption = OriginOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].origin
      // )[0];
      // setSelOrigin(SelectedOption);

      // const SelectedStatus = StatusOptions.filter(
      //   (fl) => fl.label === get_lead_source[parseInt(id)].status
      // )[0];
      // setSelStatus(SelectedStatus);
    }
  }, [get_web_leads, id]);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    if (id) {
      if (get_web_leads[parseInt(id)]) {
        setValue(
          "expected_purchase_date",
          moment(get_web_leads[parseInt(id)].expected_purchase_date)
        );
        setValue("web_lead_stage", get_web_leads[parseInt(id)].web_lead_stage);
        setValue("web_lead_type", get_web_leads[parseInt(id)].web_lead_type);
        setValue(
          "web_lead_source",
          get_web_leads[parseInt(id)].web_lead_source
        );
        setValue("inventory", get_web_leads[parseInt(id)].inventory);
        setValue("employee", get_web_leads[parseInt(id)].employee);
      }
    } else {
      setValue("expected_purchase_date", moment());
      setValue("employee", employee);
    }
  }, [get_web_leads, id]);

  React.useEffect(() => {
    const PrevComments = webLead?.description
      ? `${webLead?.description}\n --- --- --- \n`
      : "";
    setNewDescription(
      `${PrevComments} ${leadComments} \n --- updated by ${employee.first_name} ${employee.last_name} on ${today}`
    );
  }, [webLead.description, leadComments]);

  React.useEffect(() => {
    register(
      { name: "web_lead_source" },
      { required: "Please select a web lead source" }
    );
    register(
      { name: "web_lead_type" },
      { required: "Please select a web lead type" }
    );
    register(
      { name: "web_lead_stage" },
      { required: "Please select a web lead stage" }
    );
    register({ name: "employee" }, { required: "Please select an employee" });
    register(
      { name: "email" },
      {
        required: "Email is required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address",
        },
      }
    );
    register(
      { name: "first_name" },
      { required: "Please select a first_name" }
    );
  }, [register]);

  const onSubmit = (data) => {
    setAddTaskOpen(false);
    // console.log(data)
    // console.log(webLeadStage)
    let PayLoad = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      // inventory: data?.inventory || null,
      web_lead_stage: webLeadStage[0] ? webLeadStage[0]?.id : null,
      web_lead_type: data.web_lead_type?.id ? data.web_lead_type.id : null,
      web_lead_source: data.web_lead_source?.id
        ? data.web_lead_source.id
        : null,
      description: newDescription,
      expected_purchase_date: moment(data.expected_purchase_date),
      estimated_budget: data.estimated_budget || 0,
      veh_type: data.veh_type,
      company: get_auth.dealer_id,
      employee: data.employee?.id ? data.employee.id : employee?.id,
    };
    if (data.inventory) {
      PayLoad = { ...PayLoad, inventory: data.inventory };
    }
    if (id) {
      PayLoad = { ...PayLoad, id };
    }
    console.log(PayLoad);
    if (id) {
      dispatch({
        type: UPDATE_WEB_LEAD,
        payload: {
          id,
          ...PayLoad,
        },
      });

      setTimeout(() => {
        setToDetail(true);
      }, 2000);
    } else {
      dispatch({
        type: SG_ADD_WEB_LEAD,
        payload: PayLoad,
      });

      setTimeout(() => {
        setToHome(true);
        notify2();
      }, 2000);
    }

    // <Redirect />
  };

  function ChangedStatus(value) {
    // console.log(value[0]?.sort_order);
    setWebLeadStage(value);
    if (value[0]?.sort_order > 9) {
      setAllowAll(true);
    }
  }

  setLabel && setLabel(id ? "Edit " : "Create " + "Lead");
  return (
    <>
      {toDetail ? <Redirect to={`/admin/lead-detail/${id}`} /> : null}
      {toHome ? <Redirect to={`/admin/leads`} /> : null}
      <div className="content">
        <ErrorBoundary>
          <Row
            style={{
              margin: 0,
            }}
          >
            <Col className="ml-auto mr-auto" md="10">
              {/* <Card>
                <CardBody>
                  <CardTitle tag="h4">{id ? "Edit " : "Create "}Lead</CardTitle> */}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label>First Name</label>
                  <Input
                    type="text"
                    name="first_name"
                    defaultValue={webLead ? webLead.first_name : ""}
                    innerRef={
                      allowAll
                        ? register({
                            required: "Please enter First Name",
                          })
                        : register
                    }
                  />
                  <p className="text-danger small">
                    {errors.first_name && (
                      <span>{errors.first_name.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Middle Name </label>
                  <Input
                    type="text"
                    name="middle_name"
                    defaultValue={webLead ? webLead.middle_name : ""}
                    innerRef={register}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Last Name </label>
                  <Input
                    type="text"
                    name="last_name"
                    defaultValue={webLead ? webLead.last_name : ""}
                    innerRef={
                      allowAll
                        ? register({
                            required: "Please enter Last Name",
                          })
                        : register
                    }
                  />
                  <p className="text-danger small">
                    {errors.last_name && (
                      <span>{errors.last_name.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Phone</label>
                  <Input
                    type="text"
                    name="phone"
                    defaultValue={webLead ? webLead.phone : ""}
                    innerRef={
                      allowAll
                        ? register({
                            required: "Please enter Last Name",
                          })
                        : register
                    }
                  />
                  <p className="text-danger small">
                    {errors.phone && <span>{errors.phone.message}</span>}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Email</label>
                  <Input
                    type="text"
                    name="email"
                    defaultValue={webLead ? webLead.email : ""}
                    innerRef={
                      allowAll
                        ? register({
                            required: "Email is required",
                            pattern: {
                              value: email_validation,
                              message: "invalid email address",
                            },
                            maxlength: 50,
                          })
                        : register
                    }
                  />
                  <p className="text-danger small">
                    {errors.email && <span>{errors.email.message}</span>}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Lead Source</label>
                  <Controller
                    as={<Select />}
                    name="web_lead_source"
                    options={selLeadSource}
                    getOptionLabel={(option) =>
                      `${option.origin_text} - ${option.name}`
                    }
                    getOptionValue={(option) => `${option.id}`}
                    className="text-dark"
                    control={control}
                    rules={
                      allowAll ? { required: "Please enter Lead Source" } : null
                    }
                  />{" "}
                  <p className="text-danger small">
                    {errors.web_lead_source && (
                      <span>{errors.web_lead_source.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Inventory Interested In</label>
                  <Controller
                    as={<Select />}
                    name="inventory"
                    options={selInventory}
                    getOptionLabel={(option) =>
                      `${option.year} - ${option.make} - ${option.model} - ${option.trim}`
                    }
                    getOptionValue={(option) => `${option.id}`}
                    isMulti="true"
                    className="text-dark"
                    control={control}
                  />{" "}
                  <p className="text-danger small">
                    {errors.inventory && (
                      <span>{errors.inventory.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Lead Type</label>
                  <Controller
                    as={<Select />}
                    name="web_lead_type"
                    options={selLeadType}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    className="text-dark"
                    control={control}
                    rules={
                      allowAll ? { required: "Please enter Lead Type" } : null
                    }
                  />{" "}
                  <p className="text-danger small">
                    {errors.web_lead_type && (
                      <span>{errors.web_lead_type.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <label>Lead Stage</label>
                  <Controller
                    as={<Select />}
                    name="web_lead_stage"
                    options={selLeadStage}
                    getOptionLabel={(option) => `${option.name}
                        ${option.sort_order > 9 ? "(Final)" : ""}`}
                    getOptionValue={(option) => `${option.id}`}
                    className="text-dark"
                    control={control}
                    // onChange={ChangedStatus}
                    rules={
                      allowAll ? { required: "Please enter Lead Stage" } : null
                    }
                  />{" "}
                  <p className="text-danger small">
                    {errors.web_lead_stage && (
                      <span>{errors.web_lead_stage.message}</span>
                    )}
                  </p>
                  <span className="lead-stage-explain">
                    <Box
                      component={"div"}
                      sx={{
                        color: "#ffffff",
                        fontSize: "90%",
                        fontWeight: 500,
                        marginTop: "-10px",
                        padding: "5px",
                        backgroundColor: (theme) => theme.palette.preAuto?.main,
                      }}
                    >
                      Unless the lead stage is in one of the Final stages you
                      may get alerts to move to one of them. Final stages are
                      marked (Final) in the above list
                    </Box>
                  </span>
                </FormGroup>

                <Row>
                  <Col>
                    <label>Expected Purchase Date</label>
                    <FormGroup>
                      <Controller
                        as={ReactDatetime}
                        control={control}
                        name="expected_purchase_date"
                        closeOnSelect={true}
                        dateFormat="YYYY-MM-DD"
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select Date",
                        }}
                        timeFormat={false}
                      />
                      <p className="text-danger small">
                        {errors.expected_purchase_date && (
                          <span>{errors.expected_purchase_date.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Estimated or Stated Budget</label>
                      <Input
                        type="number"
                        name="estimated_budget"
                        defaultValue={webLead ? webLead.estimated_budget : 0}
                        innerRef={
                          allowAll
                            ? register({
                                required:
                                  "Please enter estimated or stated budget or 0",
                              })
                            : register
                        }
                      />
                      <p className="text-danger small">
                        {errors.estimated_budget && (
                          <span>{errors.estimated_budget.message}</span>
                        )}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <FormGroup >
                      <label>Description</label>
                      <Input
                        type="textarea"
                        rows={10}
                        
                        defaultValue={webLead ? webLead.description : ""}
                        name="description"
                        id="description"
                        innerRef={register}
                      />
                     
                    </FormGroup> */}
                {webLead?.description && (
                  <>
                    <Label for="description">Description</Label>
                    <FormGroup>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {webLead ? webLead.description : ""}
                      </p>
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <label>Add Comments</label>
                  <textarea
                    className="lead-text-area"
                    defaultValue={""}
                    name="description"
                    id="description"
                    onBlur={(e) => setLeadComments(e.target.value)}
                    rows="5"
                  ></textarea>
                </FormGroup>

                <FormGroup>
                  <label>Assign To</label>
                  <Controller
                    as={<Select />}
                    name="employee"
                    options={selEmployee}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name} - ${option.role_text} (${option.status_text})`
                    }
                    getOptionValue={(option) => `${option.id}`}
                    className="text-dark"
                    control={control}
                    // onChange={ChangedStatus}
                    rules={
                      allowAll
                        ? { required: "Please select an Employee" }
                        : null
                    }
                  />{" "}
                  <p className="text-danger small">
                    {errors.employee && <span>{errors.employee.message}</span>}
                  </p>
                </FormGroup>

                {/* <Button className="btn-fill" color="primary" type="submit">
                  {id ? "Update " : "Save "}
                </Button> */}
                <div className="d-flex justify-content-end">
                  <MUIButton
                    sx={{
                      position: "relative",
                      minWidth: 100,
                      // "&:focus": {
                      //   outline: "none",
                      // },
                    }}
                    color="preAuto"
                    variant="contained"
                    type="submit"
                  >
                    {id ? "Update " : "Save "}
                  </MUIButton>
                </div>
              </Form>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </ErrorBoundary>
      </div>
    </>
  );
};

export default CreateWebLead;
