import { put, call, takeLatest, all } from "redux-saga/effects";
import {
DEALER_WEBSITE_REQUESTED,
GET_DEALERS_PENDING,
GET_DEALERS,
LOAD_ALL_DEALERS,
GET_DEALERS_FAILED,
DELETE_DEALER,
ADD_DEALER,
CLEAR_DEALER,
FETCH_DEALER,
FETCH_DEALER_PENDING,
SG_FETCH_DEALER,
JSON_LOGGED_DEALER,
EDIT_DEALER,
LOAD_DEALER,
ADD_DEALER_LOGO,
UPLOAD_DEALER_LOGO,
FETCH_RELATED_DEALER_PENDING,
FETCH_RELATED_DEALER,
SG_FETCH_RELATED_DEALER,
ALL_ERRORS
} from "constants/actions";



import * as api from "constants/api";



// function* loadDealers(action) {
//   yield put({ type: GET_DEALERS_PENDING });
//   try {
//     // const json = yield call(api.getPagedInventories, action.payload)

//     yield put({ type: GET_DEALERS, payload: dealer_data });
//   } catch (e) {
//     yield put({type: ALL_ERRORS, message: e});
//   }
// }

// export function* dealerLoad() {
//   yield takeLatest(LOAD_ALL_DEALERS, loadDealers);
// }

function* fetchDealer(action) {
  yield put({ type: FETCH_DEALER_PENDING });
  try {
    const json = yield call(api.FETCH_DEALER, action.payload)
    yield put({ type: FETCH_DEALER, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* dealerFetchOne() {
  yield takeLatest(SG_FETCH_DEALER, fetchDealer);
}

function* fetchRelatedDealer(action) {
  yield put({ type: FETCH_RELATED_DEALER_PENDING });
  try {
    const json = yield call(api.GET_RELATED_DEALERS, action.payload)
    yield put({ type: FETCH_RELATED_DEALER, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});
  }
}

export function* dealerFetchRelated() {
  yield takeLatest(SG_FETCH_RELATED_DEALER, fetchRelatedDealer);
}


function* logoDealer(action) {
  try {
   const json = yield call(api.ADD_DEALER_LOGO, action.payload)
    yield put({ type: ADD_DEALER_LOGO, payload: json.data });
  } catch (e) {
    yield put({type: ALL_ERRORS, message: e});;
  }
}

export function* dealerLogo() {
  yield takeLatest(UPLOAD_DEALER_LOGO, logoDealer);
}

export default function* index() {
  yield all([
    // dealerLoad(),
     dealerFetchOne(),
      dealerFetchRelated(),
      dealerLogo()]);
}
// export default inventoryLoad;
