import React from "react";
import { Table } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import CheckoutForm from "./CheckoutForm";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { STRIPE_FETCH_INVOICE_TYPE } from "constants/actions";
import { useState } from "react";
import { useEffect } from "react";
export default function AdminInvioice() {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [auth, setAuth] = useState([]);

  const { get_invoices, get_auth } = useSelector(
    (state) => ({
      get_invoices: state.stripeinvoice.stripeinvoice,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth) {
      setAuth(get_auth?.dealer_id);
      dispatch({
        type: STRIPE_FETCH_INVOICE_TYPE,
        payload: {
          company_id: get_auth?.dealer_id,
        },
      });
    }
  }, [get_auth]);

  useEffect(() => {
    if (get_invoices) {
      setInvoices(get_invoices?.data);
    }
  }, [get_invoices]);

  // React.useEffect(() => {
  //   if (get_invoices) {
  //     setInvoices(get_invoices?.data);
  //     console.log("datatratatta", get_invoices?.data);
  //   }
  // }, [get_invoices]);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Invoice</CardTitle>
                    {/* <h5 className="card-category">My Leads</h5> */}
                  </Col>
                  <Col className="ml-auto" md="2">
                    <Button
                      color="default"
                      size="sm"
                      to="/admin/create-invoice"
                      tag={Link}
                    >
                      Create New Invoice
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {/* <Elements>
                    <CheckoutForm />
                  </Elements> */}
                    <Table responsive>
                      <thead className="table-active">
                        <tr>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Customer</th>
                          <th>Invoice PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices ? (
                          invoices.map((invoice, k) => (
                            <tr key={k}>
                              <td>{(invoice.total/100).toFixed(2)}</td>
                              <td>{invoice.status}</td>
                              <td>{invoice?.customer_name}</td>

                              <td>
                                {" "}
                                <a
                                  href={invoice?.invoice_pdf}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Invoice PDF
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Invoice found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
