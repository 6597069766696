import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

import moment from "moment";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth, authenticated, loginError, expires_in } = useSelector(
    (state) => ({
      auth: state.auth,
      authenticated: state.auth.isAuthenticated,
      expires_in: state.auth.expires_in,
      loginError: state.errors,
    }),
    shallowEqual
  );

  const dateLimit = moment(expires_in, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  const now = moment();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) {
          return <h2>Loading...</h2>;
        } else if (dateLimit.isAfter()) {
          return <Component {...props} />;
        } else if (!auth.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
