import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Fonts } from "../../../../crema/constants/src/AppEnums";
import IntlMessages from "../../../../crema/helpers/src/IntlMessages";
import PropTypes from "prop-types";
import { Formik } from "formik";
import SocialForm from "./SocialForm";
import * as yup from "yup";

const validationSchema = yup.object({
  twitter: yup.string().label("Please Enter your Twitter url"),
  facebook: yup.string().label("Please Enter your Facebook url"),
  google: yup.string().label("Please Enter your Google url"),
  linkedIn: yup.string().label("Please Enter your LinkedIn url"),
  instagram: yup.string().label("Please Enter your Instagram url"),
  quora: yup.string().label("Please Enter your Quora url"),
});

const Social = ({ social }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <SocialForm social={social} />
    </Box>
  );
};

export default Social;

Social.propTypes = {
  social: PropTypes.array,
};
