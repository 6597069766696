import { put, takeLatest } from 'redux-saga/effects'
import { RESET_YEARS, LOAD_RESET_YEARS, RESET_MAKES, LOAD_RESET_MAKES, RESET_MODELS, LOAD_RESET_MODELS } from "constants/actions";

function* updateYears(action) {
    yield put({ type: RESET_YEARS, payload: action.payload, });    
}

export function* updateYearLoad() {
    yield takeLatest(LOAD_RESET_YEARS, updateYears);
}

function* updateMakes(action) {
    yield put({ type: RESET_MAKES, payload: action.payload, });    
}

export function* updateMakesLoad() {
    yield takeLatest(LOAD_RESET_MAKES, updateMakes);
}

function* updateModels(action) {
    yield put({ type: RESET_MODELS, payload: action.payload, });    
}

export function* updateModelsLoad() {
    yield takeLatest(LOAD_RESET_MODELS, updateModels);
}
